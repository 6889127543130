import React from "react";
import styled from "styled-components";

import Button from "../Button/Button";

// styled-components
const StyledParagraph = styled.p`
  margin-bottom: 0.5rem;
  font-style: normal;
  font-size: 16px;
  line-height: 1.2;
  color: #191919;

  &.marginY {
    margin-top: 2rem !important;
    margin-bottom: 1.5rem;
  }
`;

const CertificateGuideInfo = ({ setStep }) => {
  const onStartApprovingCertificate = () => {
    setStep((currPage) => currPage + 1);
  };
  return (
    <>
      <StyledParagraph>
        Please check carefully all of your data in the next steps.
      </StyledParagraph>
      <StyledParagraph>
        If you have any remarks leave us a comment so we can check again some of
        your data or scores.
      </StyledParagraph>
      <StyledParagraph className="marginY">
        Thank you for your effort!
      </StyledParagraph>
      <Button
        text="START"
        type="button"
        variant="blueBtn"
        onClick={onStartApprovingCertificate}
      />
    </>
  );
};
export default CertificateGuideInfo;
