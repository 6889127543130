import React from "react";

const sortTableData = (array, { key, direction }) => {
  if (key === "name") {
    return array.sort((a, b) => {
      if (a[key] < b[key]) return direction === "ascending" ? -1 : 1;
      if (a[key] > b[key]) return direction === "ascending" ? 1 : -1;

      return 0;
    });
  } else {
    return array.sort((a, b) => {
      if (direction === "ascending") return a[key] - b[key];
      if (direction === "descending") return b[key] - a[key];
    });
  }
};

const useSortableData = (items = []) => {
  const [sortConfig, setSortConfig] = React.useState();

  const sortedItems = React.useMemo(() => {
    // If no config was defined then return the unsorted array
    if (!sortConfig) return items;

    return sortTableData(items, { ...sortConfig });
  }, [items, sortConfig]);

  const requestSort = (key) => {
    let direction = "descending";

    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig?.direction === "descending"
    ) {
      direction = "ascending";
    }

    setSortConfig({ key, direction });
  };

  return { items: sortedItems, requestSort, sortConfig };
};

export default useSortableData;
