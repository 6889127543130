import React from "react";
import { RiBubbleChartFill, RiTeamFill } from "react-icons/ri"
import { GiProgression } from "react-icons/gi"
import { BsFillPersonCheckFill } from "react-icons/bs"

import TextField from "../TextField/TextField";

const AcademyScore = ({ formData }) => {
    
    const isHackathon = formData?.points?.activities?.Hackathons?.RegularAvailable
    
    return(
        <>
        <TextField icon={<RiBubbleChartFill />} description={"Projects Total"} content={`${formData?.points?.activities?.Projects?.RegularAcquired} / ${formData?.points?.activities?.Projects?.RegularAvailable}`}/>
        <TextField icon={<GiProgression /> } description={"Challenges Total"} content={`${formData?.points?.activities?.Challenges?.RegularAcquired} / ${formData?.points?.activities?.Challenges?.RegularAvailable}`}/>
        {isHackathon !== 0 && <TextField icon={<RiTeamFill />} description={"Hackathons Total"} content={`${formData?.points?.activities?.Hackathons?.RegularAcquired} / ${formData?.points?.activities?.Hackathons?.RegularAvailable}`}/>}
        <TextField icon={<BsFillPersonCheckFill />} description={"Attendance"} content={`${formData?.points?.presence?.Acquired} / ${formData?.points?.presence?.Available}`}/>
        </>
    )
}
export default AcademyScore;