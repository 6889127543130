import React from "react";
import styled, { css } from "styled-components";

import { Text } from "../../Consts/Text";
import theme from "../../Consts/theme";
import LoaderDots from "../Loader/LoaderDots";

const variantOptions = {
  blueBtn: {
    backgroundColor: theme.darkBlue2,
    color: theme.white,
    hover: theme.darkBlue,
  },
  lightBlueBtn: {
    backgroundColor: "rgba(0, 94, 255, 0.15)",
    color: theme.lightBlue,
    hover: theme.darkBlue,
    hoverColor: theme.white,
  },
  grayBtn: {
    backgroundColor: theme.gray,
    color: theme.black,
    hoverColor: theme.lightBlue,
  },
  declineBtn: {
    backgroundColor: theme.gray,
    color: "red",
    hover: theme.lightLightGray,
  },
  blackBtn: {
    backgroundColor: theme.black,
    color: theme.white,
    padding: "10px 20px",
  },
  greenBtn: {
    backgroundColor: theme.green,
    color: theme.white,
    hover: theme.darkGreen,
  },
};

// styled-components
const StyledButton = styled.button`
  width: 28%;
  padding: 12px 8px;
  border: none;
  outline: none;
  color: #fff;
  font-size: 22px;
  border-radius: 4px;
  text-align: center;
  position: relative;
  overflow: hidden;
  cursor: ${(props) => (!props.disabled ? "pointer" : "not-allowed")};
  display: block;

  .loader {
    padding-top: 0;
    margin-top: 0;
  }

  .check-box {
    width: 44px;
    height: 44px;
    border-top-left-radius: 50%;
    border-bottom-left-radius: 50%;
    position: absolute;
    top: 0;
    right: -40px;
    opacity: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .check-box svg {
    width: 30px;
  }

  svg path {
    stroke-width: 3;
    stroke: #fff;
    stroke-dasharray: 34;
    stroke-dashoffset: 34;
    stroke-linecap: round;
  }

  &.active {
    animation: backgroundFade 1s ease forwards;
  }

  &.active .check-box {
    animation: checkBoxAppear 1s ease forwards;
  }

  &.active .btn-text {
    animation: moveText 1s ease forwards;
  }

  &.active svg path {
    animation: drawCheckmark 1s ease forwards 1s; /* Delayed start */
  }

  ${({ variant }) =>
    variant &&
    variantOptions[variant] &&
    css`
      background-color: ${(props) =>
        !props.disabled
          ? variantOptions[variant].backgroundColor
          : props.theme.gray};
      color: ${(props) =>
        !props.disabled ? variantOptions[variant].color : "#929296"};
      padding: ${variantOptions[variant].padding};
      transition: background-color 0.3s;
      :hover,
      :active {
        color: ${(props) =>
          !props.disabled ? variantOptions[variant].hoverColor : "#929296"};
        background-color: ${(props) =>
          !props.disabled ? variantOptions[variant].hover : props.theme.gray};
        transition: all 0.3s ease-in-out;
      }
    `}

  // animations
  @keyframes backgroundFade {
    from {
      background: rgba(0, 94, 255, 0.15);
    }
    to {
      color: #fff;
      background: ${theme.green};
    }
  }

  @keyframes moveText {
    from {
      margin-right: 0;
    }
    to {
      margin-right: 80px;
    }
  }

  @keyframes checkBoxAppear {
    from {
      right: -40px;
      opacity: 0;
    }
    to {
      right: 0;
      opacity: 1;
    }
  }

  @keyframes drawCheckmark {
    from {
      stroke-dashoffset: 34;
    }
    to {
      stroke-dashoffset: 0;
    }
  }
`;

const SubmitButton = ({
  variant,
  hasSubmitted,
  type,
  onClick,
  disabled,
  isLoading,
}) => {
  return (
    <StyledButton
      variant={variant}
      type={type}
      disabled={disabled}
      className={`${hasSubmitted ? "active" : ""}`}
      onClick={onClick}
    >
      {!isLoading ? (
        <>
          <Text fontSize="16px" fontWeight={700} className="btn-text">
            {hasSubmitted ? "Saved" : "Save Changes"}
          </Text>
          <div className="check-box">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
              <path fill="transparent" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
            </svg>
          </div>
        </>
      ) : (
        <LoaderDots
          className="loader"
          isLoading={isLoading}
          dotsBgColor={theme.blueBtn}
          dotHeight="5px"
          dotWidth="5px"
          dotMargin="4px"
        />
      )}
    </StyledButton>
  );
};

export default SubmitButton;
