import React, { useContext, useEffect, useState } from "react";

import { GlobalContext } from "../../../Context/GlobalProvider";
import { axios, makeApiRoute } from "../../../Router";
import Button from "../../Button/Button";
import ModuleAchievementBody from "./ModuleAchievementBody";

const ModuleAchievements = () => {
  const { user, popupConfig, setPopupConfig, setPopupDefaultValues } =
    useContext(GlobalContext);
  // state
  const [moduleAchievements, setModuleAchievements] = useState([]);
  const [activeModuleAchievement, setActiveModuleAchievement] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  // hasErrors flag is added in case there are errors on PUT request to prevent showing MA pop up
  const [hasErrors, setHasErrors] = useState(false)


  // fetching data
  useEffect(() => {
    axios
      .get(makeApiRoute("attendanceRates"))
      .then((response) => {
        setModuleAchievements(response.data);
      })
      .catch(function (error) {});
  }, []);

  useEffect(() => {
    // find module achievements that are not seen yet
    const moduleAchievementsNotSeen = moduleAchievements?.filter(
      (achievement) => achievement?.is_seen === 0
      );
      // updating the state of activeModuleAchievement
      setActiveModuleAchievement(moduleAchievementsNotSeen[0] ?? null);

      // show pop up if there are achievements not seen yet and if there is no survey active
      if (moduleAchievementsNotSeen.length > 0 && user?.activeSurvey === null) {
        !hasErrors && setPopupConfig({
        ...popupConfig,
        show: true,
        confirmation: false,
        maxWidth: "500px",
        title: "Module Achievement",
        subTitle:
          moduleAchievementsNotSeen?.length > 1 &&
          `You have ${moduleAchievementsNotSeen?.length - 1} more achievement${
            moduleAchievementsNotSeen?.length === 2 ? "" : "s"
          } left to see`,

        body: (
          <ModuleAchievementBody
            activeModuleAchievement={moduleAchievementsNotSeen[0]}
            onMarkAsSeen={onMarkAsSeen}
            onCloseShareModal={onCloseShareModal}
            isLoading={isLoading}
          />
        ),
      });
    } else if (
      moduleAchievementsNotSeen.length === 0 &&
      user?.activeSurvey === null
    ) {
      // close pop up
      setPopupDefaultValues();
    }
  }, [moduleAchievements, activeModuleAchievement, user?.activeSurvey, isLoading, hasErrors]);

  // close sharing achievement modal on social media
  const onCloseShareModal = () => {
    setActiveModuleAchievement((activeModuleAchievement.is_seen = 1));
  };

  // update is_seen property of activeModuleAchievement object on click on Cancel/Share button
  const onMarkAsSeen = () => {
    setIsLoading(true);

    axios
      .put(
        makeApiRoute("updateAttendanceRates", {
          studentUnitAttendanceRate:
            activeModuleAchievement?.attendance_rate?.id,
        })
      )
      .then((response) => {
        setIsLoading(false);
        setHasErrors(false)

        const moduleAchievementsArr = moduleAchievements.map((item) => {
          if (
            item.attendance_rate.id ===
            activeModuleAchievement.attendance_rate.id
          ) {
            item.is_seen = 1;
          }

          return item;
        });
        setModuleAchievements(moduleAchievementsArr);
        setPopupConfig({
          ...popupConfig,
          confirmation: true,
        });
      })
      .catch((error) => {
        setIsLoading(false);
        setHasErrors(true)

        setPopupConfig({
          ...popupConfig,
          show: true,
          subTitle: "",
          confirmation: false,
          maxWidth: "500px",
          title: "Something Unexpected Happened. Please try again later!",
          bodyClassname: "action-buttons",
          body: (
            <Button
              text="Close"
              variant="blueBtn"
              onClick={() => setPopupDefaultValues()}
            />
          ),
          closeButton: {
            show: true,
            action: () => {
              setPopupDefaultValues();
            },
          },
        });
      });
  };

  return <></>;
};
export default ModuleAchievements;
