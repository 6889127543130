import React from "react";
import styled from "styled-components";

import { Link } from "react-router-dom";
import { makeAppRoute } from "../../../Router";
import SubmitButton from "../../Button/SubmitButton";
import theme from "../../../Consts/theme";

// styled-components
const StyledHeader = styled.header`
  padding: 16px 0;
  position: fixed;
  top: 80px;
  left: 16px;
  right: 16px;
  z-index: 10;
  background-color: #f9f9f9;
  border-bottom: 1px solid ${theme.concreteGray};
  display: flex;
  justify-content: space-between;
  align-items: center;

  button {
    width: 60%;
  }

  @media only screen and (min-width: 375px) {
    button {
    width: 40%;
  }
  }

  @media only screen and (min-width: ${(props) => props.theme.desktop}) {
    display: none;
  }
`;

const MobileHeader = ({ disabled, hasSubmitted, onClick, isLoading }) => {
  return (
    <StyledHeader>
      <Link to={makeAppRoute("profile")}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
        >
          <path
            d="M13.9385 16.0002L19.3693 21.431C19.5539 21.6156 19.6483 21.8476 19.6526 22.1271C19.6569 22.4066 19.5624 22.6429 19.3693 22.8361C19.1761 23.0292 18.9419 23.1258 18.6667 23.1258C18.3915 23.1258 18.1573 23.0292 17.9642 22.8361L11.9719 16.8438C11.8471 16.719 11.7591 16.5874 11.7078 16.4489C11.6565 16.3105 11.6309 16.1609 11.6309 16.0002C11.6309 15.8395 11.6565 15.6899 11.7078 15.5515C11.7591 15.413 11.8471 15.2814 11.9719 15.1566L17.9642 9.16433C18.1488 8.97971 18.3808 8.88527 18.6603 8.881C18.9398 8.87671 19.1761 8.97115 19.3693 9.16433C19.5624 9.35749 19.659 9.59167 19.659 9.86687C19.659 10.1421 19.5624 10.3762 19.3693 10.5694L13.9385 16.0002Z"
            fill="black"
          />
        </svg>
      </Link>

      {onClick ? <SubmitButton
        variant="lightBlueBtn"
        type="submit"
        disabled={disabled}
        hasSubmitted={hasSubmitted}
        onClick={onClick}
        isLoading={isLoading}
      /> : ""}
    </StyledHeader>
  );
};

export default MobileHeader;
