import React, {useEffect, useState } from "react";
import styled from "styled-components";

// styled-components
const StyledRoundContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid ${(props) => (props.isChecked ? "#005EFF" : "#C5C5C5")};
  height: 24px;
  width: 24px;
  border-radius: 50%;
  align-self: center;

  input[type="radio"] {
    position: relative;
    appearance: none;
    width: 12px;
    height: 12px;
    background-color: ${(props) =>
      props.isChecked ? "#005EFF" : "transparent"};
    border-radius: 50%;
    cursor: pointer;
    transition: 0.4s;
  }
`;

const RadioButton = ({ value, onChange, resetFilters }) => {
  const [isChecked, setIsChecked] = useState(false);

  useEffect( () => {
    if(resetFilters?.length === 0) setIsChecked(false)
  }, [resetFilters])


  return (
    <StyledRoundContainer className="container" isChecked={isChecked} onClick={() => setIsChecked(true)}>
      <input
        type="radio"
        id="radio"
        value={value}
        onChange={onChange}
        checked={isChecked}
      />
    </StyledRoundContainer>
  );
};
export default RadioButton;
