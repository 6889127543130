import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { axios, makeApiRoute } from "../../Router";
import Notification from "../Notification/Notification";
import Button from "../Button/Button";
import { useContext } from "react";
import { GlobalContext } from "../../Context/GlobalProvider";

// styled-components
const StyledNotification = styled.div`
  text-align: left;
  font-size: 14px;
  width: 100%;

  p.lecture-title {
    -webkit-box-orient: vertical;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    color: ${(props) => props.theme.black};
  }
  p.lecture-link-info {
    margin-bottom: 4px;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: ${(props) => props.theme.black};
    margin-top: 4px;
  }
  div.lecture-link-info-available {
    margin-top: 10px;
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    display: flex;
    color: ${(props) => props.theme.black};
  }
  p.lecture-link-navigate {
    text-decoration: underline;
    margin-top: 15px;
    font-size: 16px;
    cursor: pointer;
  }
  .margin-left {
    margin-left: 5px;
  }

  .font-style-italic {
    font-style: italic;
  }

  @media only screen and (min-width: ${(props) => props.theme.desktop}) {
    width: 70%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    div.lecture-link-info-available {
      align-items: center;
      padding: 10px 20px;
      color: ${(props) => props.theme.white};
      background-color: ${(props) => props.theme.black};
    }
  }
`;
const StyledLectureInfo = styled.div`
  text-align: left;
`;
const StyledLectureTitle = styled.p`
  display: ${(props) =>
    props.hasLectureLink && props.hasLectureLink ? "none" : "block"};

  @media only screen and (min-width: ${(props) => props.theme.desktop}) {
    display: block;
  }
`;
const StyledLoader = styled.div`
  width: 12px;
  height: 12px;
  border: 2px solid #c5c5c5;
  border: 2px solid white;

  border-top: 2px solid #005eff;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  animation: spinner 1.5s linear infinite;

  @keyframes spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
const EventsNotification = () => {
  // context
  const { handleRequestError } = useContext(GlobalContext);
  // state
  const [eventLinks, setEventLinks] = useState({});
  const [events, setEvents] = useState([]);

  let intervals = [];

  useEffect(() => {
    axios
      .get(makeApiRoute("upcomingEvents"))
      .then((response) => {
        setEvents(response.data.data?.events);
      })
      .catch(function (error) {
        throw error.response;
      });
  }, []);

  useEffect(() => {
    if (events.length > 0) {
      events.forEach((event) => {
        if (event?.zoom_link) {
          setEventLinks((prevState) => ({
            ...prevState,
            [event.id]: event.zoom_link,
          }));
          return;
        }
        checkClassStarted(event);
        intervals[event.id] = setInterval(() => {
          checkClassStarted(event);
        }, 30000);
      });
    }
  }, [events]);

  function checkClassStarted(event) {
    axios
      .get(makeApiRoute("joinEvents", { EVENT_ID: event.id }))
      .then((response) => {
        if (response.data.joinUrl !== null) {
          clearInterval(intervals[event.id]);
          setEventLinks((prevState) => ({
            ...prevState,
            [event.id]: response.data.joinUrl,
          }));
        }
      })
      .catch(function (error) {
        handleRequestError();
        throw error.response;
      });
  }

  // mark student attendance and navigate to meeting link
  const handleClickLectureOpen = (event) => {
    const dataToUpdate = {
      eventId: event.id,
    };

    axios
      .put(makeApiRoute("updateAttendance"), dataToUpdate)
      .then((response) => {})
      .catch((error) => {
        handleRequestError();
        throw error.response;
      });

    window.open(eventLinks[event.id], "_blank");
  };

  {
    return (
      events.length > 0 &&
      events.map((event) => (
        <Notification
          key={event.id}
          bgColor="linear-gradient(89.82deg, #F2E635 -36.76%, #EFEFF4 48.25%, #005EFF 123.85%)"
        >
          <StyledNotification key={event.id}>
            <StyledLectureInfo>
              <p className="lecture-link-info">
                <span className="align-self">
                  Lecture starts at {event.start_time.split(":")[0]}:
                  {event.start_time.split(":")[1]}
                </span>
              </p>
              <StyledLectureTitle
                hasLectureLink={eventLinks[event.id]}
                className="lecture-title "
              >{`${event.title}`}</StyledLectureTitle>
            </StyledLectureInfo>
            {eventLinks[event.id] ? (
              <Button
                type="button"
                variant="blackBtn"
                text="Click Here to Join the Lecture"
                onClick={() => handleClickLectureOpen(event)}
              />
            ) : (
              <div className="lecture-link-info-available">
                <StyledLoader />
                <span className="margin-left">
                  {" "}
                  Link will be available shortly
                </span>
              </div>
            )}
          </StyledNotification>
        </Notification>
      ))
    );
  }
};
export default EventsNotification;
