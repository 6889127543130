import styled from "styled-components";

export const StyledMainContainer = styled.main`
  width: 100%;
  padding-bottom: 100px;
  @media only screen and (min-width: ${(props) => props.theme.desktop}) {
    width: 70%;
    margin: 0 auto;
  }
  @media only screen and (min-width: ${(props) => props.theme.large_desktop}) {
    width: 70%;
    margin: 0 auto;
  }
`;
export const StyledHomepageSection = styled.section`
  margin-top: 50px;
  padding: 0 1rem;
`;

export const StyledPageWrapper = styled.div`
  position: relative;
  background: #f9f9f9;
  min-width: 100%;
  max-width: 100%;
  min-height: 100vh;
  overflow: hidden;
  @media only screen and (min-width: ${(props) => props.theme.laptop}) {
    .d-flex {
      display: flex;
    }
  }
`;
export const StyledHomePageTitle = styled.header`
  font-style: normal;
  color: #191919;
  display: flex;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  margin-bottom: 16px;

  @media only screen and (min-width: ${(props) => props.theme.laptop}) {
    font-size: 24px;
    line-height: 28px;
    margin-bottom: 30px;

  }
`;
export const ErrorMessage = styled.p`
  margin-top: 5px;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  color: #ff003a !important;
`;
export const InfoMessage = styled.p`
  margin-top: 5px;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  color: #65676c !important;
`;
// profile page
export const StyledPageContentContainer = styled.section`
  background-color: #f9f9f9;
  width: 100%;
  padding: 16px;
  @media only screen and (min-width: ${(props) => props.theme.desktop}) {
    padding-right: 48px;
    width: 90%;
    margin: 0 auto;
  }
  @media only screen and (min-width: ${(props) => props.theme.large_desktop}) {
    padding: 0;
    width: 80%;
    margin: 0 auto;
  }
`;
export const StyledSectionHeading = styled.h3`
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  color: #65676c;
  margin-top: 30px;
  text-transform: capitalize;

  @media only screen and (min-width: ${(props) => props.theme.desktop}) {
    margin-top: 0px;
    margin-bottom: 30px;
    text-transform: uppercase;
  }
`;
export const StyledForm = styled.form`
  padding: 0 16px 32px;
  width: 100%;
  .error {
    margin-top: 8px;
  }

  @media only screen and (min-width: ${(props) => props.theme.desktop}) {
    padding: 0 24px 32px;
  }
`;
export const StyledLabel = styled.label`
  display: flex;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  color: #4d4d4d;

  span.social-links {
    display: flex;
    align-items: center;
  }
  span.social-links img {
    display: flex;
    align-items: center;
  }
  span.social-links_link {
    align-self: center;
    margin-left: 8px;
  }

  ::after {
    content: attr(data-end);
    color: red;
  }
`;

export const StyledFormInputGroup = styled.fieldset`
  margin-bottom: 12px;
  width: 100%;

  @media only screen and (min-width: ${(props) => props.theme.desktop}) {
    /* width: 49%; */
    .width-100 {
      width: 100%;
    }
  }
`;

export const StyledAddPhotoIcon = styled.label`
  display: inline-block;
  position: absolute;
  border-radius: 50px;
  width: 15px;
  height: 15px;
  background-color: ${(props) => props.theme.black};
  bottom: 0px;
  right: 5px;
  padding: 10px;
  cursor: pointer;
  :before,
  :after {
    content: "";
    position: absolute;
    background: ${(props) => props.theme.white};
  }

  :before {
    left: 50%;
    top: 5px;
    bottom: 5px;
    width: 2px;
    transform: translateX(-50%);
    border-radius: 2px;
  }

  :after {
    top: 50%;
    left: 5px;
    right: 5px;
    height: 2px;
    transform: translateY(-50%);
    border-radius: 2px;
  }

  input {
    display: none;
  }
`;
export const StyledInnerProfileSection = styled.section`
  margin-top: 8px;
  background-color: ${(props) => props.theme.white};
  border: 1px solid #efeff4;
  border-radius: 16px;
  padding-top: 30px;
  padding-bottom: calc(30px - 1rem);

  .basic-info_img {
    display: flex;
    justify-content: center;
    padding: 20px 0 40px;
  }

  @media only screen and (min-width: ${(props) => props.theme.desktop}) {
    padding-left: 50px;
    padding-right: 50px;
    margin-top: 30px;
  }
`;
export const StyledFormRow = styled.div`
  display: flex;
  flex-direction: column;

  @media only screen and (min-width: ${(props) => props.theme.desktop}) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const StyledTextarea = styled.textarea`
  width: 100%;
  outline: none;
  border: 1px solid ${(props) => props.theme.lightLightGray};
  border-radius: 4px;
  margin: 30px auto;
  color: ${(props) => props.theme.textGrey};
  padding: 10px;
  font-size: 14px;

  .margin-0 {
    margin: 0 auto;
  }

  ::placeholder {
    font-size: 12px;
  }
  :focus {
    outline: none;
    border-color: ${(props) => props.theme.lightBlue};
  }
`;

export const StyledPointsContainer = styled.article`
  background-color: #ffffff;
  box-shadow: 0px 8px 30px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
`;
export const StyledHeadTitle = styled.h2`
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  color: ${(props) => props.theme.black};
  width: 100%;

  @media only screen and (min-width: ${(props) => props.theme.desktop}) {
    width: 60%;
  }
`;

export const StyledLogo = styled.header`
  margin-top: 70px;
  display: inline-block;
  width: 100%;
  text-align: center;

  @media only screen and (min-width: ${(props) => props.theme.desktop}) {
    text-align: left;
    margin-top: 0;
  }
`;
export const StyledFormAuth = styled.form`
  margin-top: 50px;

  @media only screen and (min-width: ${(props) => props.theme.laptop}) {
    width: 60%;
    margin-left: auto;
    margin-right: auto;
  }
  @media only screen and (min-width: 1024px) {
    width: 100%;
  }
  div.submit-button {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @media only screen and (min-width: 768px) and (max-width: 992px) {
      justify-content: space-between;
      flex-direction: row-reverse;
    }
    @media only screen and (min-width: 1025px) {
      justify-content: space-between;
      flex-direction: row-reverse;
    }

    button {
      margin-bottom: 16px;
    }
  }
  .error {
    margin-top: 8px;
    margin-left: 25px;
    @media only screen and (min-width: ${(props) => props.theme.desktop}) {
      margin-left: 0px;
    }
  }
`;
export const StyledFormGroup = styled.div`
  width: 100%;
  margin-bottom: 40px;

  font-style: normal;

  label {
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    color: #65676c;
    margin-bottom: 5px;
    margin-left: 25px;
    display: inline-block;
    @media only screen and (min-width: ${(props) => props.theme.desktop}) {
      color: #005eff;
      margin-left: 0px;
    }
  }
`;
export const StyledServerResponse = styled.p`
  font-weight: 700;
  color: #005eff;
  font-size: 12px;
  line-height: 14px;
  margin-top: 8px;
  text-align: center;
  @media only screen and (min-width: ${(props) => props.theme.desktop}) {
    text-align: left;
  }
`;
export const StyledSurveyQuestion = styled.p`
  font-weight: bold;
  font-size: 18px;
  line-height: 20px;
  color: ${(props) => props.theme.darkGray};
  text-align: left;
  margin-bottom: 1em;

  @media only screen and (min-width: ${(props) => props.theme.laptop}) {
    font-size: 20px;
    line-height: 28px;
  }
`;
export const StyledNotificationWrap = styled.div`
  background: radial-gradient(
    131.25% 39562.5% at 1.09% 0%,
    #00d762 15.51%,
    #005eff 100%
  );
  padding: 20px;
  text-align: center;
  color: white;
  font-weight: bold;
  margin-bottom: 8px;

  &:hover {
    cursor: pointer;
  }

  span.underline {
    text-decoration: underline;
    text-decoration-thickness: 2px;
  }
  @media only screen and (min-width: ${(props) => props.theme.laptop}) {
    width: 100%;
    margin: 0 auto 8px;
  
  }
`;
