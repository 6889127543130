import React, { useCallback, useState, useEffect } from "react";
import styled from "styled-components";

import { axios, makeApiRoute } from "../../Router";
import { StyledAddPhotoIcon } from "../CommonStyledComponents/CommonStyledComponents";
import ImageCropper from "../ImageCropper/ImageCropper";

// styled-components
const StyledAcronymContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${props => props.width};
  height: ${props => props.height};
  background-color: white;
  border-radius: 200px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.16);

`;
const StyledAcronymLetters = styled.abbr`

  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 32px;
  background-image: radial-gradient(
    131.25% 39562.5% at 1.09% 0%,
    #00d762 15.51%,
    #005eff 100%
  );
  background-size: 100%;
  background-repeat: repeat;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
`;
const ProfileImageAcronym = ({ data, setData, userName, editProfile, width, height }) => {
  const [image, setImage] = useState(false);
  const [initials, setInitials] = useState("")
  
  // array of name and surname splitted with " "
  const fullName = editProfile ? data?.name?.split(" ") : userName?.split(" ");
  
  useEffect(() => {
    if (fullName?.length === 0) {
      setInitials("")
      return
    } else if (fullName?.length === 1) {
      // if there is only name, display only the first letter from that single element
      setInitials(fullName?.shift()?.charAt(0))
    } else {
      // if there are name and surname, display each first letter from the first and last element
      setInitials(fullName && fullName?.shift()?.charAt(0) + fullName?.pop()?.charAt(0))
    }
  }, [fullName])
  
  
  // invoked on input onChane
  const onFileChange = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      let imageDataUrl = await readFile(file);
      setImage(imageDataUrl);
    } else {
      setImage(false);
    }
  };
  
  // invoked inside onFileChange
  const readFile = useCallback((file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.addEventListener("load", () => resolve(reader.result), false);
      reader.readAsDataURL(file);
    });
  }, []);
  
  // invoked in ImageCroper when cropping and saving the image
  const uploadImage = (image) => {
    axios
    .post(makeApiRoute("studentUploadFile"), image)
    .then((response) => {
      localStorage.setItem(
        "user",
        JSON.stringify(response?.data?.data?.profile)
        );
        setData(response.data.data);
        setImage(false);
      })
      .catch((error) => {
        
      });
    };
    

  return (
    <StyledAcronymContainer width={width} height={height}>
      <StyledAcronymLetters>{initials}</StyledAcronymLetters>
      {image && (
        <ImageCropper
          image={image}
          setImage={setImage}
          uploadImage={uploadImage}
          data={data}
          setData={setData}
        />
      )}
      {editProfile && <StyledAddPhotoIcon>
        <input
          type="file"
          accept="image/*, .pdf"
          onChange={(e) => onFileChange(e)}
          // onClick is added because react cannot use fileinput twice without refreshing the page
          onClick={(e) => onFileChange(e)}
        />
      </StyledAddPhotoIcon>}
    </StyledAcronymContainer>
  );
};
export default ProfileImageAcronym;
