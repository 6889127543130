import React from "react";
import styled from "styled-components";

// styled-components
const StyledScore = styled.p`
  font-size: 3rem;
  font-weight: 900;
  margin-top: 40px;
  margin-bottom: 40px;
`;
const StyledStatus = styled.p`
  font-size: 1.3rem;
  font-weight: 500;
  margin-bottom: 80px;
  text-transform: uppercase;
  &.tracking-in-contract {
    -webkit-animation: tracking-in-contract 0.8s
      cubic-bezier(0.215, 0.61, 0.355, 1) both;
    animation: tracking-in-contract 1.5s cubic-bezier(0.215, 0.61, 0.355, 1)
      both;
  }

  @-webkit-keyframes tracking-in-contract {
  0% {
    letter-spacing: 1em;
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    letter-spacing: normal;
    opacity: 1;
  }
}
@keyframes tracking-in-contract {
  0% {
    letter-spacing: 1em;
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    letter-spacing: normal;
    opacity: 1;
  }
}
`;
const StyledParagraph = styled.p`
  margin-bottom: 15px;
  color: ${(props) => props.theme.textGrey};

 
`;

const FinalScore = ({ formData }) => {


  return (
    <>
      <StyledScore>{`${formData?.points?.FinalScore?.Acquired} / ${formData?.points?.FinalScore?.Available}`}</StyledScore>
      <StyledParagraph>
        Your Student Success Status is:
      </StyledParagraph>
      <StyledStatus  className="tracking-in-contract">{formData?.points?.student_success_status}</StyledStatus>
    </>
  );
};
export default FinalScore;
