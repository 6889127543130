import React from "react";
import styled from "styled-components";

import { useMutation } from "@apollo/client";
import { UpdateCountSharedAchievement } from "../../../../Consts/GraphqlQueries";
import FileActions from "../FileActions";
import ErrorMessageComponent from "../../../ErrorMessageComponent/ErrorMessageComponent";

// styled-components
const StyledFilesContainer = styled.div`
  padding: 0 16px 16px;
  > * {
    margin-bottom: 13px;
  }
`;

const ModuleAchievements = ({ achievement }) => {
  //  ----------------------------------------------------------------
  // GRAPHQL POST REQUEST (MUTATION)
  // This mutation is for counting the number student has shared MA on specific media

  const [UpdateStudentAchievement, { error: mutationError }] = useMutation(
    UpdateCountSharedAchievement
  );

  // ----------------------------------------------------------------
  // VARIABLES

  const handleSocialButtonClick = (id, _service) => {
    UpdateStudentAchievement({ variables: { id: id, service: _service } });
  };

  // -------------------------------------------------------------------------------
  // ERRORS

  if (mutationError)
    return (
      <ErrorMessageComponent
        firstText="Oops!"
        secondText="Something went wrong while sharing your achievement."
        thirdText="Please try again later."
      />
    );

  // -------------------------------------------------------------------------------
  // MAIN COMPONENT

  return (
    <StyledFilesContainer>
      <FileActions
        key={achievement?.id}
        icon="/images/achievementIcon.svg"
        title={achievement?.unit?.title || achievement?.course_title}
        isSharable
        sharingFileId={+achievement?.id}
        sharingLink={achievement?.sharing_link}
        onSocialButtonClick={handleSocialButtonClick}
        linkPath={achievement?.achievement}
      />
    </StyledFilesContainer>
  );
};

export default ModuleAchievements;
