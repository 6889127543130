import React, { useRef, useState } from "react";
import styled from "styled-components";

const StyledLi = styled.li`
  display: flex;
  background-color: ${(props) => props.theme.white};
  justify-content: space-between;
  border-bottom: 2px solid #efeff4;
  padding: 16px 0;
  font-style: normal;
  font-weight: 700;
  cursor: pointer !important;
  transition: background-color 0.6s ease;
  
  :hover,
  :active {
    background-color: ${(props) => props.theme.lightGray2};
  }

  div.message-wrap {
    display: flex;
    width: 100%;
    
  }
  div.message-wrap span.message-title {
    align-self: center;
    margin-left: 16px;
    font-size: 16px;
    line-height: 18px;
    color: ${(props) => props.theme.black};
  }
  span.message-date {
    align-self: center;
    font-size: 12px;
    line-height: 14px;
    color: ${(props) => props.theme.lightLightGray};
  }
`;
const StyledMessageContent = styled.div`
  width: 100%;
  background-color: white;
  overflow: hidden;
  height: ${(props) => props?.maxHeight};
  transition: height 0.6s ease;

  p * {
    font-size: 16px !important;
    line-height: 1.4 !important;
    color: ${(props) => props.theme.lightGrayText} !important;
  }
  a.message-link {
    text-decoration: none;
    color: ${(props) => props.theme.lightBlue};
    display: inline-block;
    margin-top: 16px;
  }
  p.message-author {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    margin-top: 16px;
    color: ${(props) => props.theme.black};
  }
`;
const StyledDiv = styled.div`
  margin: 16px 0;
`;
const Message = ({ message }) => {
  const ref = useRef();
  const [active, setActive] = useState("");
  const [maxHeight, setMaxHeight] = useState("0px");

  const toggleAccordion = () => {
    setActive(active === "" ? "active" : "");
    setMaxHeight(active === "active" ? "0px" : `${ref.current.scrollHeight}px`);

  };
  return (
    <>
      <StyledLi key={message?.id} className={`${active}`}>
        <div className={`message-wrap ${active}`} onClick={toggleAccordion}>
          <img src="/images/message.svg" alt="Message Icon" />
          <span className="message-title">{message.title}</span>
        </div>
        <span className="message-date">{message.createdAt}</span>
      </StyledLi>
      <StyledMessageContent ref={ref} maxHeight={maxHeight}>
        <StyledDiv>
          <p dangerouslySetInnerHTML={{ __html: message?.message }} />
          {message.link ? (
            <a href={message.link} target="_blank" className="message-link" rel="noreferrer">
              {message.link}
            </a>
          ) : (
            ""
          )}
          {message.author ? (
            <p className="message-author">- {message.author}</p>
          ) : (
            ""
          )}
        </StyledDiv>
      </StyledMessageContent>
    </>
  );
};
export default Message;
