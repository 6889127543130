// format date javascript
export const dateFormater = (date, separator) => {
  const rawDate = new Date(date);
  let day = rawDate.getDate();
  // add +1 to month because getMonth() returns month from 0 to 11
  let month = rawDate.getMonth() + 1;
  let year = rawDate.getFullYear();

  // show date and month in two digits
  // if month is less than 10, add a 0 before it
  if (day < 10) {
    day = '0' + day;
  }
  if (month < 10) {
    month = '0' + month;
  }

  // now we have day, month and year
  // use the separator to join them
  return day + separator + month + separator + year;
}


// scroll to top
export const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: "smooth",
  });
}

// calculate function if the user is over 20 years old
export const isTwentyOrOlder = (date_of_birth) => {
  // Parse the date of birth into a Date object
  var birthDate = new Date(date_of_birth);
  
  // Get the current date
  var currentDate = new Date();

  // Calculate the difference in years
  var age = currentDate.getFullYear() - birthDate.getFullYear();

  // Check if the current month and day is before the birth month and day
  var monthDiff = currentDate.getMonth() - birthDate.getMonth();
  if (monthDiff < 0 || (monthDiff === 0 && currentDate.getDate() < birthDate.getDate())) {
      age--;
  }

  // Return true if age is 20 or more, otherwise false
  return age >= 20;
}