import React from "react";
import styled from "styled-components";

import SortButton from "./SortButton";

// styled-components
const StyledHeadingContainer = styled.div`
  background-color: #232426;
  color: #efeff4;
  border-radius: 8px;
  padding: 16px 15px;
  display: flex;
  align-items: baseline;
  width: 100%;
`;
const StyledHeadingName = styled.div`
  span {
    font-style: normal;
    font-weight: 700;
    font-size: 10px;
    line-height: 11px;
    text-transform: uppercase;
    color: #efeff4;
  }

  @media only screen and (min-width: ${(props) => props.theme.desktop}) {
    font-size: 11px;
    padding-left: 25px;
    width: 30%;
  }
`;
const StyledHeadingTitles = styled.div`
  display: flex;
  margin-left: auto;

  span {
    font-style: normal;
    font-weight: 700;
    font-size: 10px;
    line-height: 11px;
    text-transform: uppercase;
    color: #efeff4;
  }

  @media only screen and (min-width: ${(props) => props.theme.desktop}) {
    justify-content: center;
    width: 100%;
    font-size: 11px;
    margin-right: 70px;

    span {
      width: calc(100% / ${(props) => props.length});
      text-align: center;
      font-size: 11px;
    }
  }
`;

const HeadingRow = ({ onClick, direction, sortBy, columnArray }) => {
  const length = columnArray.length;


  return (
    <StyledHeadingContainer length={length}>
      <StyledHeadingName>
        <SortButton
          direction={direction}
          id={"name"}
          onClick={() => onClick("name")}
          sortBy={sortBy}
          label={"Name & Surname"}
        />
      </StyledHeadingName>

      <StyledHeadingTitles length={length}>
        {columnArray.map((column) => (
          <SortButton
            key={column.id}
            direction={direction}
            id={column.id}
            onClick={() => onClick(column.id)}
            sortBy={sortBy}
            label={column.label}
          />
        ))}
      </StyledHeadingTitles>
    </StyledHeadingContainer>
  );
};
export default HeadingRow;
