import React from "react";
import {IoIosSchool} from 'react-icons/io'

import TextField from "../TextField/TextField";
import FormGroup from "../FormGroup/FormGroup";


const PersonalInfo = ({ formData, setFormData, user, error}) => {

    return(
        <>
        <FormGroup type='text' error={error} label={'Full Name*'} value={formData?.name} onInputUpdate={(updatedValue) => setFormData({...formData, name: updatedValue})}/>
       
        <TextField description={'Academy program'} content={user?.group?.academy?.title} icon={<IoIosSchool />}/> 
        </>
    )
}
export default PersonalInfo;