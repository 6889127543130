import React, { useEffect, useState } from "react";
import { RiShareForwardFill } from "react-icons/ri";
import styled from "styled-components";

import SkeletonImage from "../Skeletons/SkeletonImage";

// styled-components

const StyledContainer = styled.section`
  display: ${(props) => (props.show ? "block" : "none")};
  margin: 0 auto;
  border-radius: 10px;
  position: relative;
  z-index: 25;
  &.bg-gray {
    background-color: ${(props) => props.theme.lightGray2};
    padding: 20px 10px;
  }
  span.modal-close {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 1.75rem;
    font-weight: bold;
    padding: 0 0.75rem;
    color: rgba(0, 0, 0, 0.2);
    cursor: pointer;
  }

  @media only screen and (min-width: ${(props) => props.theme.laptop}) {
    &.bg-gray {
    padding: 20px 0px;
  }
  }
`;
const StyledInnerWrap = styled.section`
  display: flex;
  flex-direction: column;

  @media only screen and (min-width: ${(props) => props.theme.laptop}) {
    width: 90%;
    margin: 0 auto;
  }
`;
const StyledImageBox = styled.article`
  width: 100%;
  height: ${(props) => !props.isImageLoaded && props.imageHeight};
  border-radius: 10px;
  position: relative;
`;

const StyledImage = styled.img`
  width: 100%;
  display: block;
  border-radius: 10px;
  overflow: hidden;
`;

const StyledSocialIconsWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  button {
    border: none;
    margin-left: 10px;
    background-color: transparent;
    cursor: pointer;

    :hover {
      transform: scale(1.1);
    }
  }

  img {
    width: 30px;
    display: block;
    -webkit-box-reflect: below 3px
      linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.4));
    -moz-box-reflect: below 3px
      linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.4));
  }

  @media only screen and (min-width: ${(props) => props.theme.desktop}) {
    position: absolute;
    bottom: 0;
    transition: all 500ms cubic-bezier(0.48, 0, 0.12, 1);
    z-index: 100;
    opacity: 0;
    top: 50%;
    left: 50%;
    transform: translateY(225%) translateX(-50%);
    height: 14px;

    &:after {
      content: "";
      position: absolute;
      bottom: -100%;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: ${(props) => props.theme.lightGray2};
      transform-origin: bottom center;
      transition: transform 0.8s cubic-bezier(0.48, 0, 0.12, 1);
      transform: skewY(4deg) scaleY(0);
      z-index: 50;
    }
    img {
      width: 32px;
    }
  }
`;

const StyledButtonsGroup = styled.article`
  width: 100%;
  margin: 20px auto 0;

  h3 {
    text-align: center;
    line-height: 1.5;
    font-size: 1.2rem;
    font-weight: bold;
    color: ${(props) => props.theme.white};

    @media only screen and (min-width: ${(props) => props.theme.desktop}) {
      margin-bottom: 30px;
      font-size: 25px;
    }
  }

  @media only screen and (min-width: ${(props) => props.theme.laptop}) {
    width: 60%;
  }

  @media only screen and (min-width: ${(props) => props.theme.desktop}) {
    align-self: center;
    border-radius: 25px;
    outline: 2px solid ${(props) => props.theme.lightLightGray};
    transition: outline 1s ease-in-out;

    &:hover {
      outline: 2px solid transparent;
    }
  }
`;

const StyleShareHover = styled.div`
  display: none;

  @media only screen and (min-width: ${(props) => props.theme.desktop}) {
    span {
      align-self: center;
      margin-right: 10px;

      svg {
        width: 23px;
        height: 23px;
      }
    }

    p {
      position: relative;
      transition: color 600ms cubic-bezier(0.48, 0, 0.12, 1);
      z-index: 10;
      font-size: 18px;
      line-height: 1.5;
    }
    display: flex;
    width: 100%;
    justify-content: center;
  }
`;
const StyledShareButtonsWrap = styled.div`
  width: 100%;
  text-align: center;
  border-radius: 25px;
  display: flex;
  justify-content: center;
  background-color: ${(props) => props.theme.lightGray2};
  overflow: hidden;
  padding: 15px 0;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;

  @media only screen and (min-width: ${(props) => props.theme.desktop}) {
    background-color: ${(props) => props.theme.white};
    position: relative;
    cursor: pointer;

    &:after {
      content: "";
      position: absolute;
      bottom: -100%;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: ${(props) => props.theme.lightGray2};
      transform-origin: bottom center;
      transition: transform 0.8s cubic-bezier(0.48, 0, 0.12, 1);
      transform: skewY(4deg) scaleY(0);
      z-index: 50;
    }
    &:hover .social-icons {
      transform: translateX(-50%) translateY(-50%);
      opacity: 1;
      transition: all 1s cubic-bezier(0.48, 0, 0.12, 1);
    }

    &:hover {
      outline-width: 0px;
    }
    &:hover:after {
      transform-origin: bottom center;
      transform: skewY(4deg) scaleY(3);
    }
  }
`;
const ShareAchievement = ({
  show,
  imgUrl,
  sharingLink,
  onSocialButtonClick,
  className,
}) => {
  // states
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const [imageHeight, setImageHeight] = useState("500px");

  useEffect(() => {
    if (isImageLoaded) setImageHeight("100%");
  }, [isImageLoaded]);

  const onShareCertificate = (media) => {
    let text = "Brainster Graduate";
    // let urlToShare = imgUrl;
    let urlToShare = sharingLink ? sharingLink : imgUrl;


    let socialMediaShareUrl = false;
    if (typeof onSocialButtonClick == "function") onSocialButtonClick(media);

    switch (media) {
      case "facebook":
        socialMediaShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${urlToShare}&quote=${text}`;
        break;
      case "twitter":
        socialMediaShareUrl = `https://twitter.com/intent/tweet?url=${urlToShare}&text=${text}`;
        break;
      case "linkedin":
        socialMediaShareUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${urlToShare}`;
        break;
      default:
        break;
    }

    if (socialMediaShareUrl) {
      javascript: window.open(
        socialMediaShareUrl,
        `${media} Share`,
        "width=600,height=400"
      );
    }
  };

  return (
    <StyledContainer
      show={show}
      onClick={(e) => e.stopPropagation()}
      className={`modal-content ${className && className}`}
    >
      <StyledInnerWrap>
        <StyledImageBox imageHeight={imageHeight}>
          {!isImageLoaded && (
            <SkeletonImage
              variant="square"
              width="100%"
              height={imageHeight}
            />
          )}

          <StyledImage
            src={imgUrl}
            alt="Certificate"
            onLoad={() => setIsImageLoaded(true)}
          />
        </StyledImageBox>
        <StyledButtonsGroup>
          <StyledShareButtonsWrap>
            <StyleShareHover>
              <span>
                <RiShareForwardFill />
              </span>
              <p>Share now</p>
            </StyleShareHover>

            <StyledSocialIconsWrap className="social-icons">
              <button
                type="button"
                onClick={() => onShareCertificate("linkedin")}
              >
                <img src="/images/icons8-linkedin-2.svg" alt="LinkedIn Icon" />
              </button>
              <button
                type="button"
                onClick={() => onShareCertificate("twitter")}
              >
                <img src="/images/icons8-twitter.svg" alt="Twitter Icon" />
              </button>
              <button
                type="button"
                onClick={() => onShareCertificate("facebook")}
              >
                <img src="/images/icons8-facebook.svg" alt="Facebook Icon" />
              </button>
            </StyledSocialIconsWrap>
          </StyledShareButtonsWrap>
        </StyledButtonsGroup>
      </StyledInnerWrap>
    </StyledContainer>
  );
};
export default ShareAchievement;
