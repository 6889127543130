import React, { useContext, useEffect, useRef, useState } from "react";
import { useQuery, useMutation } from "@apollo/client";
import styled from "styled-components";

import { GlobalContext } from "../../Context/GlobalProvider";
import { isTwentyOrOlder } from "../../Consts/PureFunctions";
import {
  GET_EDUCATION_INFO,
  UpdateUserEducationInfo,
} from "../../Consts/GraphqlQueries";
import { Text } from "../../Consts/Text";
import theme from "../../Consts/theme";
import textPages from "../../Consts/textPages.json";
import useMediaCustom from "../../Hooks/useMediaCustom";
import FormSelectGroup from "../FormGroup/FormSelectGroup";
import Autocomplete from "../Input/Autocomplete";
import LoaderDots from "../Loader/LoaderDots";
import MobileHeader from "./MobileHeader/MobileHeader";
import SubmitButton from "../Button/SubmitButton";
import ErrorMessageComponent from "../ErrorMessageComponent/ErrorMessageComponent";

//  ----------------------------------------------------------------
// Styled-components

const StyledContainer = styled.section`
  gap: 16px;
  border: 1px solid ${theme.concreteGray};
  border-radius: 16px;
  background-color: ${theme.white};

  form {
    padding: 0 24px 32px;
  }
`;

const StyledFormHeader = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 16px 0;
  padding: 16px 0;
  border-bottom: 1px solid ${theme.concreteGray};

  &.mx-header {
    margin-left: 24px;
    margin-right: 24px;
  }
  &.w-100 {
    width: 100%;
  }

  @media only screen and (min-width: ${(props) => props.theme.desktop}) {
    margin-left: 24px;
    margin-right: 24px;

    &.mx-0 {
      margin-left: 0px;
      margin-right: 0px;
      margin-top: -8px;
    }
  }
`;
const StyledInnerWrap = styled.section`
  width: 100%;

  @media only screen and (min-width: ${(props) => props.theme.desktop}) {
    display: flex;
    width: 90%;
  }
`;

const StyledLoaderBox = styled.div`
  height: 60vh;
  width: 100%;
  display: flex;
  justify-content: center;

  .loader {
    padding-top: 0px;
  }
`;
const StyledInfoText = styled.p`
  font-style: normal;
  font-size: 10px;
  line-height: 14px;
  color: ${(props) => props.theme.lightGrayText};
  margin-bottom: 16px;
`;

const StyledForm = styled.form`
  padding: 0 15px;
  fieldset {
    width: 100%;
  }

  @media only screen and (min-width: ${(props) => props.theme.desktop}) {
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    gap: 2%;
    .flex-basis {
      flex-basis: 48%;
    }
  }
`;
// ----------------------------------------------------------------
// DirtyFields will keep track of what input(s) is/are changed and therefore to enable/disable Save button
let dirtyFields = new Set();

// ----------------------------------------------------------------
// Error messages on input validation
const errorMessages = {
  high_school_id: "Please provide high school name you attended",
  still_in_high_school: "Please provide info if you are still in high school",
  study_status_id: "Please provide your education status",
  faculty_id: "Please provide your faculty name",
  study_subfield_id: "Please provide your field of study",
  education_level_id: "Please provide your education level",
};
// Reset error messages
const resetErrors = {
  high_school_id: "",
  still_in_high_school: "",
  study_status_id: "",
  faculty_id: "",
  study_subfield_id: "",
  education_level_id: "",
};
// ----------------------------------------------------------------
/* This is input config that is used inside onInputChange function
   for dynamically getting values based on different input name. */
const highSchoolOtherID = parseInt(process.env.REACT_APP_HIGH_SCHOOL_OTHER_ID);
const facultyOtherID = parseInt(process.env.REACT_APP_FACULTY_OTHER_ID);
const subFiledOtherID = parseInt(process.env.REACT_APP_SUBFIELD_OTHER_ID);

const inputConfigurations = {
  high_school_id: {
    defaultValue: highSchoolOtherID,
    relatedFieldName: "high_school_name",
    parseNumericValue: true,
  },
  study_subfield_id: {
    defaultValue: subFiledOtherID,
    relatedFieldName: "study_subfield_name",
    parseNumericValue: true,
  },
  faculty_id: {
    defaultValue: facultyOtherID,
    relatedFieldName: "faculty_name",
    parseNumericValue: true,
  },
  education_level_id: {
    parseNumericValue: true,
  },
  study_status_id: {
    parseNumericValue: true,
    specialHandling: "setEducationStatus",
  },
  still_in_high_school: {
    useUserInputAsIs: true,
  },
  // Add other fields if needed
};

const EducationDetails = ({ submitFunctionRef }) => {
  const isDesktop = useMediaCustom("(min-width: 992px)");
  const userObjectRef = useRef();
  const { user, setUser } = useContext(GlobalContext);
  // state
  const [errors, setErrors] = useState(resetErrors);
  const [saveBtnDisabled, setSaveBtnDisabled] = useState(true);
  const [educationStatus, setEducationStatus] = useState("");
  const [hasCompletedMutation, setHasCompletedMutation] = useState(false);

  //  ----------------------------------------------------------------
  // GRAPHQL GET REQUEST

  const {
    loading: queryLoading,
    error: queryError,
    data: queryData,
  } = useQuery(GET_EDUCATION_INFO, {
    fetchPolicy: "cache-and-network",
  });

  //  ----------------------------------------------------------------
  // GRAPHQL POST REQUEST (MUTATION)

  const [
    updateUser,
    {
      data: mutationData,
      loading: mutationLoading,
      error: mutationError,
      called: mutationCalled,
    },
  ] = useMutation(UpdateUserEducationInfo, {
    onCompleted: (data) => {
      handleUserUpdate(data.UpdateUser);
      setSaveBtnDisabled(true);
      setHasCompletedMutation(true);
      handleCompletedMutation();
    },
  });

  // ----------------------------------------------------------------
  // VARIABLES
  let hasErrorValue = Object.values(errors).some((value) => value !== "");
  const hasFetchedUser = queryData?.user !== undefined;
  const isUserInHigherEducation = isTwentyOrOlder(queryData?.user?.date_of_birth);
  const highSchoolsWithCities = queryData?.high_schools?.map((school) => ({
    ...school,
    name: `${school.name} - ${school.city?.name}`,
  }));
  const facultiesWithCities = queryData?.faculties?.map((faculty) => ({
    ...faculty,
    name: `${faculty.university}  - ${faculty.faculty} - ${faculty.city?.name}`,
  }));

  useEffect(() => {
    if (mutationCalled) {
      handleUserUpdate(mutationData?.UpdateUser);
      userObjectRef.current = mutationData?.UpdateUser;
      const educationStatusValue = parseInt(
        mutationData?.UpdateUser?.user_info?.study_status_id
      );
      setEducationStatus(educationStatusValue);
      return;
    }
  }, [mutationCalled, queryData, mutationData]);

  useEffect(() => {
    // pass submit function to the ref that is coming from parent where this function is called
    if (submitFunctionRef?.current) {
      submitFunctionRef.current = onFormSubmit;
    }
    if (!mutationCalled && hasFetchedUser) {
      userObjectRef.current = queryData?.user;
      handleUserUpdate(queryData?.user);
      const educationStatusValue = parseInt(
        queryData?.user?.user_info?.study_status_id
      );
      setEducationStatus(educationStatusValue);
    }
  }, [queryLoading, mutationLoading, hasFetchedUser]);

  // ----------------------------------------------------------------
  // Update the user object in Global context  after submission and on page load

  const handleUserUpdate = (updatedUserData) => {
    setUser((prevUser) => ({
      ...prevUser,
      ...updatedUserData,
      user_info: {
        ...prevUser?.user_info,
        ...updatedUserData?.user_info,
      },
    }));
  };

  // ----------------------------------------------------------------
  // Return hasCompletedMutation to false to remove the style for success message

  const handleCompletedMutation = () => {
    const timer = setTimeout(() => {
      setHasCompletedMutation(false);
    }, 4000);

    // Clear the timeout if the component unmounts
    return () => clearTimeout(timer);
  };

  // ----------------------------------------------------------------
  // ON INPUT CHANGE

  const onInputChange = (_inputName, _value) => {
    const config = inputConfigurations[_inputName];
    const isNumberValue = typeof _value === "number";

    if (!_value) {
      userObjectRef.current = {
        ...userObjectRef.current,
        user_info: {
          ...userObjectRef.current.user_info,
          [_inputName]: null,
          [config.relatedFieldName]: null,
        },
      };
    }

    if (config) {
      if (!isNumberValue) {
        // ----------------------------------------------------------------
        // If _value is not a number, it means that the user has typed a custom input that is not mentioned in the suggestion list.
        // In that case we store the _value (string) in profession_name/industry_name, while as ID we manually set the ID for key OTHER as given from server
        // ----------------------------------------------------------------
        const userInputString = _value?.toString();
        if (config.relatedFieldName && userInputString) {
          userObjectRef.current = {
            ...userObjectRef.current,
            user_info: {
              ...userObjectRef.current.user_info,
              [_inputName]: config.defaultValue,
              [config.relatedFieldName]: userInputString,
            },
          };
        } else if (config.useUserInputAsIs) {
          userObjectRef.current = {
            ...userObjectRef.current,
            user_info: {
              ...userObjectRef.current.user_info,
              [_inputName]: _value,
            },
          };
        }
      } else if (config.parseNumericValue) {
        // ----------------------------------------------------------------
        // If _value is a number (id), it means that the user has selected option from the suggestion list.
        // In that case we store the _value (id) in profession_id/industry_id, while as profession_name/industry_name we manually set NULL values.
        // ----------------------------------------------------------------
        const parsedValue = parseInt(_value);
        userObjectRef.current = {
          ...userObjectRef.current,
          user_info: {
            ...userObjectRef.current.user_info,
            [_inputName]: parsedValue,
            [config.relatedFieldName]: null,
          },
        };

        if (_inputName === "study_status_id") {
          setEducationStatus(parsedValue);
        }
      }

      // ----------------------------------------------------------------
      // Show error messages if required field is empty
      if (_value === "") {
        setErrors({
          ...errors,
          [_inputName]: errorMessages[_inputName],
        });
      }

      if (hasErrorValue && _value !== "") {
        setErrors({ ...errors, [_inputName]: "" });
      }

      setSaveBtnDisabled(false);
      return;
    }
  };

  // ----------------------------------------------------------------
  // Validate empty inputs

  const checkRequiredFields = (_array) => {
    const inputIsFilled = _array.length > 0 && _array?.every((item) => item);

    return inputIsFilled;
  };

  // ----------------------------------------------------------------
  // Validate required inputs

  const addRequiredFields = (array, condition, field) => {
    if (condition) {
      array.push(field);
    }
  };

  // ----------------------------------------------------------------
  // ON FORM SUBMIT
  const onFormSubmit = async (e) => {
    e?.preventDefault();

    let formData;
    let requiredFields = [];

    // Saving values
    const educationStatusID = parseInt(
      userObjectRef?.current?.user_info?.study_status_id
    );
    const facultyID = parseInt(userObjectRef?.current?.user_info?.faculty_id);
    const highSchoolID = parseInt(
      userObjectRef?.current?.user_info?.high_school_id
    );
    const studySubFiledID = parseInt(
      userObjectRef?.current?.user_info?.study_subfield_id
    );
    const educationLevelID = parseInt(
      userObjectRef?.current?.user_info?.education_level_id
    );
    const facultyName = userObjectRef?.current?.user_info?.faculty_name;
    const highSchoolName = userObjectRef?.current?.user_info?.high_school_name;
    const studySubFiledName =
      userObjectRef?.current?.user_info?.study_subfield_name;

    // Compare ID values with ID values for OTHER
    const userHasSelectedOtherFaculty = facultyID === facultyOtherID;
    const userHasSelectedOtherSubfield = studySubFiledID === subFiledOtherID;
    const userHasSelectedOtherHighSchool = highSchoolID === highSchoolOtherID;

    /* Depending on the value of the educationStatus, different fields are required 
    and therefore different values (formData) will be sent to server */

    if (educationStatus && educationStatus <= 3) {
      requiredFields = [
        educationStatusID,
        facultyID,
        studySubFiledID,
        educationLevelID,
        highSchoolID,
      ];

      addRequiredFields(
        requiredFields,
        facultyID === facultyOtherID,
        facultyName
      );
      addRequiredFields(
        requiredFields,
        studySubFiledID === subFiledOtherID,
        studySubFiledName
      );
      addRequiredFields(
        requiredFields,
        highSchoolName === highSchoolOtherID,
        highSchoolName
      );

      formData = {
        user_info: {
          study_status_id: +userObjectRef?.current?.user_info?.study_status_id,
          faculty_id: +userObjectRef?.current?.user_info?.faculty_id,
          faculty_name: userObjectRef?.current?.user_info?.faculty_name,
          study_subfield_id:
            +userObjectRef?.current?.user_info?.study_subfield_id,
          study_subfield_name:
            userObjectRef?.current?.user_info?.study_subfield_name,
          education_level_id:
            +userObjectRef?.current?.user_info?.education_level_id,
          high_school_id: +userObjectRef?.current?.user_info?.high_school_id,
          high_school_name: userObjectRef?.current?.user_info?.high_school_name,
          still_in_high_school:
            userObjectRef?.current?.user_info?.still_in_high_school || null,
        },
      };
    } else if (educationStatus === 4) {
      requiredFields = [
        educationStatusID,
        facultyID,
        studySubFiledID,
        highSchoolID,
      ];
      addRequiredFields(
        requiredFields,
        highSchoolID === highSchoolOtherID,
        highSchoolName
      );
      addRequiredFields(
        requiredFields,
        studySubFiledID === subFiledOtherID,
        studySubFiledName
      );
      addRequiredFields(
        requiredFields,
        facultyID === facultyOtherID,
        facultyName
      );

      formData = {
        user_info: {
          study_status_id: +userObjectRef?.current?.user_info?.study_status_id,
          faculty_id: +userObjectRef?.current?.user_info?.faculty_id,
          faculty_name: userObjectRef?.current?.user_info?.faculty_name,
          study_subfield_id:
            +userObjectRef?.current?.user_info?.study_subfield_id,
          study_subfield_name:
            userObjectRef?.current?.user_info?.study_subfield_name,
          education_level_id: null, // This field is missing in the UI for this condition
          high_school_id: +userObjectRef?.current?.user_info?.high_school_id,
          high_school_name: userObjectRef?.current?.user_info?.high_school_name,
          still_in_high_school:
            userObjectRef?.current?.user_info?.still_in_high_school || null,
        },
      };
    } else {
      requiredFields = [educationStatusID, highSchoolID];
      addRequiredFields(
        requiredFields,
        highSchoolName === highSchoolOtherID,
        highSchoolName
      );

      formData = {
        user_info: {
          study_status_id: +userObjectRef?.current?.user_info?.study_status_id,
          faculty_id: null,
          faculty_name: null,
          study_subfield_id: null,
          study_subfield_name: null,
          education_level_id: null,
          high_school_id: +userObjectRef?.current?.user_info?.high_school_id,
          high_school_name: userObjectRef?.current?.user_info?.high_school_name,
          still_in_high_school:
            userObjectRef?.current?.user_info?.still_in_high_school || null,
        },
      };
    }

    // ----------------------------------------------------------------
    // Validate inputs

    if (!checkRequiredFields(requiredFields)) {
      // Update errors state with error messages for empty input fields
      const errorFields = Object.keys(errorMessages).reduce(
        (acc, fieldName) => {
          if (
            fieldName === "faculty_id" &&
            userHasSelectedOtherFaculty &&
            !userObjectRef?.current?.user_info?.faculty_name
          ) {
            acc[fieldName] = [errorMessages[fieldName]];
          } else if (
            fieldName === "high_school_id" &&
            userHasSelectedOtherHighSchool &&
            !userObjectRef?.current?.user_info?.high_school_name
          ) {
            acc[fieldName] = [errorMessages[fieldName]];
          } else if (
            fieldName === "study_subfield_id" &&
            userHasSelectedOtherSubfield &&
            !userObjectRef?.current?.user_info?.study_subfield_name
          ) {
            acc[fieldName] = [errorMessages[fieldName]];
          } else if (!userObjectRef?.current?.user_info?.[fieldName]) {
            acc[fieldName] = [errorMessages[fieldName]];
          }
          return acc;
        },
        {}
      );

      setErrors(errorFields);

      return;
    }

    // ----------------------------------------------------------------
    // Submit the form
    try {
      await updateUser({ variables: { inputData: formData } });
    } catch (err) {
      console.log(err);
    }
  };

  // -------------------------------------------------------------------------------
  // LOADING ERRORS

  if (queryError || mutationError)
    return (
      <ErrorMessageComponent
        firstText="Oops!"
        secondText="Something went wrong while fetching your data."
        thirdText="Please try again later."
      />
    );

  // -------------------------------------------------------------------------------
  // MAIN COMPONENT

  return (
    <div>
      <MobileHeader
        disabled={saveBtnDisabled || mutationLoading}
        hasSubmitted={hasCompletedMutation}
        onClick={onFormSubmit}
        isLoading={mutationLoading}
      />

      <StyledContainer>
        <StyledFormHeader className="mx-header">
          <Text fontSize="14px">High School</Text>
        </StyledFormHeader>
        <StyledInnerWrap ref={submitFunctionRef}>
          {queryLoading ? (
            <StyledLoaderBox>
              <LoaderDots
                className="loader"
                dotsBgColor={theme.darkBlue}
                dotHeight="8px"
                dotWidth="8px"
                dotMargin="8px"
                isLoading={queryLoading}
              />
            </StyledLoaderBox>
          ) : (
            <StyledForm onSubmit={onFormSubmit} ref={submitFunctionRef}>
              <StyledInfoText>* Indicates required</StyledInfoText>

              {/* ------------------------------------ High school field conditions -----------------------------------*/}
              <Autocomplete
                bilingualFilter
                data={highSchoolsWithCities}
                errors={errors.high_school_id}
                label={`${
                  textPages?.profile?.education_details
                    ?.education_details_highSchool
                } *`}
                defaultValue={
                  (user &&
                    +user?.user_info?.high_school_id === highSchoolOtherID &&
                    user?.user_info?.high_school_name) ||
                  (user &&
                    user?.user_info?.high_school_id !== highSchoolOtherID &&
                    +user?.user_info?.high_school_id) ||
                  (user &&
                    +user?.user_info?.high_school_id === highSchoolOtherID &&
                    !user?.user_info?.high_school_name &&
                    "")
                }
                placeholder="Search..."
                getInputValue={(newValue) => {
                  onInputChange("high_school_id", newValue);
                }}
              />

              {!isUserInHigherEducation ? (
                <FormSelectGroup
                  fieldLabel="Are you still in high school? *"
                  options={["Yes", "No"]}
                  errors={errors.still_in_high_school}
                  isRequired
                  selectLabel={"Please choose"}
                  defaultValue={
                    user && user?.user_info?.still_in_high_school === null
                      ? "-1"
                      : user?.user_info?.still_in_high_school
                  }
                  getInputValue={(newValue) => {
                    onInputChange("still_in_high_school", newValue);
                  }}
                />
              ) : (
                ""
              )}
              {/* ---------------------------------- Higher Education --------------------------------------------- */}

              <>
                <StyledFormHeader className="w-100 mx-0">
                  <Text fontSize="14px">Higher Education</Text>
                </StyledFormHeader>
                <FormSelectGroup
                  fieldLabel="Current Education Status *"
                  options={queryData?.study_statuses}
                  errors={errors.study_status_id}
                  isRequired
                  selectLabel={"Please choose current education status"}
                  defaultValue={
                    (user && +user?.user_info?.study_status_id) || "-1"
                  }
                  getInputValue={(newValue) => {
                    onInputChange("study_status_id", parseInt(newValue));
                  }}
                />
                {educationStatus <= 4 ? (
                  <>
                    <Autocomplete
                      bilingualFilter
                      data={facultiesWithCities}
                      errors={errors.faculty_id}
                      label={`${
                        textPages?.profile?.education_details
                          ?.education_details_university
                      } *`}
                      defaultValue={
                        (user &&
                          +user?.user_info?.faculty_id === facultyOtherID &&
                          user?.user_info?.faculty_name) ||
                        (user &&
                          +user?.user_info?.faculty_id !== facultyOtherID &&
                          +user?.user_info?.faculty_id) ||
                        (user &&
                          +user?.user_info?.faculty_id === facultyOtherID &&
                          !user?.user_info?.faculty_name &&
                          "")
                      }
                      placeholder="Search..."
                      getInputValue={(newValue) => {
                        onInputChange("faculty_id", newValue);
                      }}
                    />
                    <Autocomplete
                      data={queryData?.study_subfields}
                      label="Field of study *"
                      errors={errors.study_subfield_id}
                      defaultValue={
                        (user &&
                          +user?.user_info?.study_subfield_id ===
                            subFiledOtherID &&
                          user?.user_info?.study_subfield_name) ||
                        (user &&
                          user?.user_info?.study_subfield_id !==
                            subFiledOtherID &&
                          +user?.user_info?.study_subfield_id) ||
                        (user &&
                          +user?.user_info?.study_subfield_id ===
                            subFiledOtherID &&
                          !user?.user_info?.study_subfield_name &&
                          "")
                      }
                      placeholder="Ex: Economics; Computer Science"
                      getInputValue={(newValue) => {
                        onInputChange("study_subfield_id", newValue);
                      }}
                    />
                  </>
                ) : (
                  ""
                )}
                {educationStatus < 4 ? (
                  <FormSelectGroup
                    fieldLabel={`${textPages?.profile?.education_details?.education_details_formal} *`}
                    options={queryData?.education_levels}
                    errors={errors.education_level_id}
                    isRequired
                    selectLabel={"Please choose your education level"}
                    defaultValue={
                      (user && +user?.user_info?.education_level_id) || "-1"
                    }
                    getInputValue={(newValue) => {
                      onInputChange("education_level_id", parseInt(newValue));
                    }}
                  />
                ) : (
                  ""
                )}
              </>

              {isDesktop ? (
                <SubmitButton
                  variant="lightBlueBtn"
                  type="submit"
                  disabled={saveBtnDisabled || mutationLoading}
                  hasSubmitted={hasCompletedMutation}
                  onClick={onFormSubmit}
                  isLoading={mutationLoading}
                />
              ) : (
                ""
              )}
            </StyledForm>
          )}
        </StyledInnerWrap>
      </StyledContainer>
    </div>
  );
};
export default EducationDetails;
