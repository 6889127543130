import React from "react";
import { BsPatchExclamationFill } from "react-icons/bs";
import styled from "styled-components";
import theme from "../../Consts/theme";
import { Text } from "../../Consts/Text";

const StyledInfo = styled.section`
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  gap: 16px;
  border: 1px solid ${theme.concreteGray};
  border-radius: 16px;
  background-color: ${theme.white};
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;

  div {
    text-align: center;
    width: 90%;
  }

  svg {
    width: 50px;
    height: 50px;
    margin-bottom: 20px;
  }

  @media only screen and (min-width: ${(props) => props.theme.desktop}) {
    
      width: 40%;
  
  }
`;

export default function SignContractNotification() {
  return (
    <StyledInfo>
      <div>
        <BsPatchExclamationFill />
        <Text fontWeight={500}>
        Please sign your pending documents before proceeding to the platform.
        </Text>
      </div>
    </StyledInfo>
  );
}
