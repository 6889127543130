

import React from "react";
import styled from "styled-components";

import FileActions from "../FileActions";

// styled-components
const StyledFilesContainer = styled.div`
  padding: 0 16px 16px;
`;

const AcademyContract = ({ contract }) => {
  const onDownloadContract = () => {
    if (!contract) return;

    const fileUrl = contract?.contract_url;

    if (fileUrl) getContractFileUrlAndOpenTab(fileUrl);
  };

  function getContractFileUrlAndOpenTab(_fileUrl) {
    if (_fileUrl) {
      window.open(_fileUrl, "_blank");
    }
  }

  return (
    <StyledFilesContainer>
      <FileActions
        icon="/images/contractIcon.svg"
        title="Academy Contract"
        onDownload={onDownloadContract}
        isDownloadable
      />
    </StyledFilesContainer>
  );
};

export default AcademyContract;
