import React, { useState } from "react";
import styled from "styled-components";

import HomeworkButton from "../Homework/HomeworkButton";

// styled-components
const StyledActivityCard = styled.article`
  background: #ffffff;
  box-shadow: 0px 8px 30px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
  padding: 24px 16px;

  @media only screen and (min-width: ${(props) => props.theme.laptop}) {
    display: grid;
    grid-template-columns: 25% 45% 15%;
    grid-template-rows: repeat(3, auto);
    grid-column-gap: 40px;
    padding: 32px 20px;
  }
  @media only screen and (min-width: ${(props) => props.theme.large_desktop}) {
    grid-column-gap: 80px;
    grid-template-columns: 25% 40% 20%;
  }
`;
const StyledSubHeading = styled.h3`
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  color: #4d4d4d;
`;
const StyledMainHeading = styled.h2`
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  color: #191919;
  margin-top: 8px;
  margin-bottom: 16px;
`;
const StyledInnerBody = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #4d4d4d;
  margin-top: 8px;
  margin-bottom: 16px;
  line-height: 1.5;
  width: 100%;
  overflow-x: auto;

  &::-webkit-scrollbar {
    height: 2px;
    border-radius: 4px;
  }
  &::-webkit-scrollbar-track-piece {
    background-color: ${(props) => props.theme.lightLightGray};
    border-radius: 4px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${(props) => props.theme.lightGrayText};
    border-radius: 4px;
  }
`;
const StyledReadMoreLess = styled.span`
  color: #0a08e5;
  margin-left: 5px;
  cursor: pointer;
`;
const StyledPoints = styled.p`
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  margin-top: 8px;
  color: #c5c5c5;
  display: flex;
  align-items: center;
  text-align: center;

  @media only screen and (min-width: ${(props) => props.theme.laptop}) {
    font-size: 16px;
    width: 200px;
  }
  @media only screen and (min-width: ${(props) => props.theme.large_desktop}) {
    font-size: 24px;
    width: auto;
  }
`;
const StyledExtraPoints = styled.span`
  background-color: #e6e7eb;
  color: ${(props) => props.theme.black};
  border-radius: 20px;
  font-size: 12px;
  padding: 1px 3px;
  width: 50px;
  text-align: center;
  display: inline-flex;
  align-self: flex-start;
  justify-content: center;
  margin-left: 4px;
  margin-top: -8px;
  @media only screen and (min-width: ${(props) => props.theme.laptop}) {
    width: 40px;
    padding: 1px;

    @media only screen and (min-width: ${(props) => props.theme.large_desktop}) {
      width: 50px;
      padding: 1px 3px;
    }
  }
`;

const StyledAquiredPoints = styled.span`
  color: ${(props) => (props.aquiredPoints ? "#0A08E5" : "#C5C5C5")};
`;

const StyledSubContainer = styled.section`
  margin-top: 16px;

  @media only screen and (min-width: ${(props) => props.theme.desktop}) {
    display: grid;
    grid-template-columns: ${(props) =>
      !props.userLogged || props.fullWidth ? "100%" : "83% 15%"};
    grid-column-gap: 2%;
  }

  @media only screen and (min-width: ${(props) => props.theme.large_desktop}) {
    display: grid;
    grid-template-columns: ${(props) =>
      !props.userLogged || props.fullWidth ? "100%" : "88% 10%"};
  }
`;

const ActivityPoints = ({
  hasSubmitedLink,
  activity,
  setShownPopUp,
  userLogged,
  fullWidth,
  user,
}) => {
  const [showFullDescription, setFullDescription] = useState(false);

  const onShowFullDescription = () => {
    setFullDescription(!showFullDescription);
  };
  return (
    <StyledSubContainer userLogged={userLogged} fullWidth={fullWidth}>
      <StyledActivityCard>
        <div className="sub-container">
          <StyledSubHeading>Deadline {activity.deadline}</StyledSubHeading>
          <StyledMainHeading>{activity.title}</StyledMainHeading>
        </div>
        <div className="sub-container">
          <StyledSubHeading>Comment</StyledSubHeading>
          <StyledInnerBody>
            {showFullDescription
              ? activity.comment
              : activity.comment.slice(0, 40)}{" "}
            {activity.comment.length > 40 ? (
              <StyledReadMoreLess
                readMoreLess
                className="read-more-less"
                onClick={onShowFullDescription}
              >
                 {showFullDescription ? "Read Less" : "...Read More"}{" "}
              </StyledReadMoreLess>
            ) : (
              ""
            )}
          </StyledInnerBody>
        </div>
        <div className="sub-container">
          <StyledSubHeading>Points</StyledSubHeading>
          <StyledPoints>
            <StyledAquiredPoints aquiredPoints>
              {activity.points}
            </StyledAquiredPoints>{" "}
            {activity?.extraPoints !== "0.0" && (
              <StyledExtraPoints>
                {"+" + activity?.extraPoints}
              </StyledExtraPoints>
            )}
            <span>/ {activity.maxPoints}</span>
          </StyledPoints>
        </div>
      </StyledActivityCard>
      { userLogged ? (
        <HomeworkButton
          hasSubmitedLink={hasSubmitedLink}
          setShownPopUp={setShownPopUp}
        />
      ) : (
        ""
      )}
    </StyledSubContainer>
  );
};
export default ActivityPoints;
