import React from "react";

import { StyledSurveyQuestion } from "../CommonStyledComponents/CommonStyledComponents";
import SelectElement from "../SelectElement/SelectElement";


const SurveyInstructorSelect = ({ question, instructorsArray, getInputValue  }) => {
  const splitQuestion = question.split("instructor_name");

  return (
    <StyledSurveyQuestion>
      <span>{splitQuestion[0]}</span>
      <SelectElement
        selectLabel="Please select"
        widthAuto
        options={instructorsArray}
        getInputValue={(newValue) => {
            getInputValue(newValue)
        }}
      />
      <span>{splitQuestion[1]}</span>
    </StyledSurveyQuestion>
  );
};
export default SurveyInstructorSelect;
