import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { axios, makeApiRoute, makeAppRoute } from "../../Router";
import { ErrorMessage } from "../CommonStyledComponents/CommonStyledComponents";
import theme from "../../Consts/theme";
import Button from "../Button/Button";
import LoaderDots from "../Loader/LoaderDots";

// styled-components
const StyledForm = styled.form`
  margin-top: 50px;

  @media only screen and (min-width: ${(props) => props.theme.laptop}) {
    width: 60%;
    margin-left: auto;
    margin-right: auto;
  }
  @media only screen and (min-width: 1024px) {
    width: 100%;
  }

  div.submit-button {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 100px;
    @media only screen and (min-width: 768px) and (max-width: 992px) {
      justify-content: space-between;
      flex-direction: row-reverse;
      margin-top: 0;
    }
    @media only screen and (min-width: 1025px) {
      justify-content: space-between;
      flex-direction: row-reverse;
      margin-top: 0;
    }

    button {
      margin-bottom: 16px;
    }
  }
`;
const StyledFormGroup = styled.div`
  width: 100%;
  margin-bottom: 40px;

  font-style: normal;

  input {
    font-weight: 400;
    padding: 16px 24px;
    background-color: ${(props) => props.theme.lightGray2};
    border: none;
    border-radius: 100px;
    width: 100%;
    font-size: 16px;
    line-height: 18px;
    color: ${(props) => props.theme.lightBlue};

    ::placeholder {
      font-size: 14px;
    }

    :focus {
      outline: 2px solid ${(props) => props.theme.lightBlue};
    }
    @media only screen and (min-width: ${(props) => props.theme.desktop}) {
      border-radius: 0px;
      color: ${(props) => props.theme.lightGrayText};
    }
  }

  label {
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    color: ${(props) => props.theme.lightGrayText};
    margin-bottom: 5px;
    margin-left: 25px;
    display: inline-block;
    @media only screen and (min-width: ${(props) => props.theme.desktop}) {
      color: ${(props) => props.theme.lightBlue};
      margin-left: 0px;
    }
  }
`;
const StyledServerResponse = styled.p`
  font-weight: 700;
  color: ${(props) => props.theme.lightBlue};
  font-size: 12px;
  line-height: 14px;
  margin-top: 8px;
  text-align: center;
  @media only screen and (min-width: ${(props) => props.theme.desktop}) {
    text-align: left;
  }
`;

const RequestPasswordReset = () => {
  const navigate = useNavigate();
  const [value, setValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [serverResponse, setServerResponse] = useState({
    success: "",
    error: "",
  });

  const onInputChange = (e) => {
    setValue(e.target.value);
    setServerResponse({ ...serverResponse, error: "" });
  };

  const handleBackToPage = () => {
    navigate(makeAppRoute("login"));
  };

  const hangleRequestPasswordReset = (e) => {
    e.preventDefault();
    setIsLoading(true);
    axios
      .post(makeApiRoute("requestPasswordReset"), { email: value })
      .then(function (response) {
        if (response.status === 200) {
          setServerResponse({
            success: response?.data?.success?.message,
            error: "",
          });
          setIsLoading(false);
        } else {
          throw new Error("Bad Request");
        }
      })
      .catch(function (error) {
        if (error.response.status === 400 || error.response.status === 500) {
          setServerResponse({
            ...serverResponse,
            error: error.response?.data?.error?.message,
          });
        }
        setIsLoading(false);

        throw error.response;
      });
  };

  return (
    <StyledForm onSubmit={hangleRequestPasswordReset}>
      <StyledFormGroup>
        <label>Email</label>
        <input
          type="email"
          name="email"
          value={value}
          placeholder="example@example.com"
          onChange={onInputChange}
          disabled={isLoading}
        />
        {serverResponse.success !== "" ? (
          <StyledServerResponse>{serverResponse.success}</StyledServerResponse>
        ) : (
          ""
        )}
        {serverResponse.error !== "" ? (
          <ErrorMessage>{serverResponse.error}</ErrorMessage>
        ) : (
          ""
        )}
      </StyledFormGroup>

      <div className="submit-button">
        <Button
          text={
            !isLoading ? (
              "Request password reset"
            ) : (
              <LoaderDots
                isLoading={isLoading}
                dotsBgColor={theme.lightBlue}
                dotHeight="5px"
                dotWidth="5px"
                dotMargin="10px"
                buttonSubmit
              />
            )
          }
          variant="blueBtn"
          className="login-submit"
          disabled={serverResponse.success !== "" || isLoading}
        />

        <Button
          text="Back to Login"
          variant="grayBtn"
          className="login-submit"
          onClick={handleBackToPage}
          disabled={serverResponse.success !== "" || isLoading}
        />
      </div>
    </StyledForm>
  );
};
export default RequestPasswordReset;
