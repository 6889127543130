import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Link } from "react-scroll";
import { Collapse } from "antd";
import "antd/dist/antd.css";
import "./MenuStyles.css";


const { Panel } = Collapse;

// styled-components
const StyledModulesFilterContainer = styled.section`
  display: none;

  @media only screen and (min-width: ${(props) => props.theme.desktop}) {
    display: block;
    border-radius: 80px 80px 0px 0px;
    padding: 0;
    max-height: 85vh;
    position: sticky;
    top: 80px;
  }
`;
const StyledMenuWrap = styled.div`
  min-height: 200px;
  max-height: 85vh;
  width: 100%;
  margin: auto;
  min-height: 200px;
  height: auto;
  filter: drop-shadow(0px 8px 30px rgba(0, 0, 0, 0.08));
  border-radius: 80px 80px 10px 10px;
  background: #ffffff;

  ::-webkit-scrollbar {
    width: 3px;
  }

  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }

  ::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
  }
`;
const StyledBody = styled.div`
  max-height: 85vh;
  overflow: auto;
  padding: 32px 24px;
  background: #ffffff;
  border-radius: 0px 0px 10px 10px;

  &::-webkit-scrollbar {
    width: 4px;
    border-radius: 4px;
  }
  &::-webkit-scrollbar-track-piece {
    background-color: ${(props) => props.theme.lightGray2};
    border-radius: 4px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${(props) => props.theme.lightLightGray};
    border-radius: 4px;
  }
`;
const StyledHeader = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  @media only screen and (min-width: ${(props) => props.theme.desktop}) {
    background-color: #f9f9f9;
    cursor: default;
    height: 88px;
    border-radius: 80px 80px 0px 0px;

    p {
      display: flex;
      align-items: center;
      border-radius: 80px 0px 80px;
      background-color: white;
      width: 60%;
      height: 100%;
      padding: 12px 60px;
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      line-height: 14px;
      /* identical to box height */

      text-transform: uppercase;

      /* Light Gray/Text */

      color: #65676c;
    }
  }
`;
const CollapseModuleTitle = styled.span`
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 18px;
  color: #191919;
`;
const CollapseModuleSubTitle = styled.span`
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  color: #005eff;
`;
const CollapseModuleLecture = styled(Link)`

  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #191919;
  display: block;
  margin: 10px 0px;
`;
const ModulesScrollSpy = ({ slug, materialsData }) => {
  // states
  const [unitsPerCategory, setUnitsPerCategory] = useState();
  const [activeUnit, setActiveUnit] = useState();
  const [activeSubunit, setActiveSubunit] = useState();
  const [unitsMenu, setUnitsMenu] = useState();
  const [subunitsMenu, setSubunitsMenu] = useState();

  useEffect(() => {
    if (materialsData) {
      const allUnits = materialsData
        ? materialsData?.units?.filter((unit) => unit.categorySlug === slug)
        : "";
      setUnitsPerCategory(allUnits);
      // get all units titles and put them in array
      const allUnitsTitles = allUnits?.map((unit) => unit.title);
      setUnitsMenu(["", ...allUnitsTitles]);
      // ---------------------- //
      const [...allSubunits] = allUnits?.map((unit) => unit.subunits);
      // get all subunits IDs and titles and put them in array as a strings
      const allSubunitsTitles = allSubunits
        ?.flat()
        ?.map((subunit) => `${subunit?.id}${subunit?.title}`);
      setSubunitsMenu(["", ...allSubunitsTitles]);
    }
  }, [materialsData, slug]);

  const handleSetActiveUnit = (e) => {
    setActiveUnit(unitsMenu?.indexOf(e));
  };

  const handleSetActiveSubunit = (e) => {
    setActiveSubunit(subunitsMenu?.indexOf(e));
  };

  return (
    <StyledModulesFilterContainer>
      <StyledMenuWrap>
        <StyledHeader>
          <p>Module bar</p>
        </StyledHeader>
        <StyledBody>
          <Collapse accordion bordered={false} ghost activeKey={activeUnit}>
            {unitsPerCategory?.length > 0
              ? unitsPerCategory?.map((unit, index) => (
                  <Panel
                    header={
                      <Link
                        to={unit.title}
                        spy={true}
                        smooth={true}
                        duration={50}
                        onSetActive={handleSetActiveUnit}
                        activeClass="activeMenuItem"
                        offset={-100}
                      >
                        <CollapseModuleTitle>
                          {index + 1 + ". " + unit.title}
                        </CollapseModuleTitle>
                      </Link>
                    }
                    key={unitsMenu?.indexOf(unit.title)}
                    showArrow={false}
                  >
                    <Collapse
                      accordion
                      bordered={false}
                      ghost
                      activeKey={activeSubunit}
                    >
                      {unit.subunits
                        ? unit.subunits.map((subunit, index) =>
                            subunit?.resources?.length > 0 ? (
                              <Panel
                                header={
                                  <Link
                                    to={`${subunit.id}${subunit.title}`}
                                    spy={true}
                                    smooth={true}
                                    duration={50}
                                    onSetActive={handleSetActiveSubunit}
                                    activeClass="activeMenuItem"
                                    offset={-100}
                                  >
                                    <CollapseModuleSubTitle>
                                      {subunit.title}
                                    </CollapseModuleSubTitle>
                                  </Link>
                                }
                                key={subunitsMenu?.indexOf(
                                  `${subunit.id}${subunit.title}`
                                )}
                                showArrow={false}
                              >
                                {subunit.resources.length > 0
                                  ? subunit.resources.map((resource) => (
                                      <CollapseModuleLecture
                                        key={resource.id}
                                        to={`${resource.id}${resource.title}`}
                                        spy={true}
                                        smooth={true}
                                        duration={50}
                                        activeClass="activeMenuItem"
                                        offset={-100}
                                      >
                                        {resource.title}
                                      </CollapseModuleLecture>
                                    ))
                                  : ""}
                              </Panel>
                            ) : (
                              ""
                            )
                          )
                        : ""}
                    </Collapse>
                  </Panel>
                ))
              : ""}
          </Collapse>
        </StyledBody>
      </StyledMenuWrap>
    </StyledModulesFilterContainer>
  );
};
export default ModulesScrollSpy;
