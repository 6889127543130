import React, { useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";

import { axios, makeApiRoute, makeAppRoute } from "../../Router";
import { BsStars } from "react-icons/bs";
import { GlobalContext } from "../../Context/GlobalProvider";
import theme from "../../Consts/theme";
import ProfileImage from "../ProfileImage/ProfileImage";
import CustomCheckboxItem from "../Checkbox/CustomCheckboxItem";
import SyntaxHighlighter from "../SyntaxHighlighter/SyntaxHighlighter";
import LoaderAI from "../Loader/LoaderAI";
import SubmitForm from "./SubmitForm";
import Button from "../Button/Button";
import { useNavigate } from "react-router-dom";

// styled-components
const StyledContainer = styled.div`
  background-color: ${theme.lightGray3};
  min-height: 100vh;
`;

const StyledPromptsWrap = styled.div`
  width: 40%;
  margin: 0 auto;
  min-height: 85vh;
  padding: 30px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const DisplayPromptResult = styled.div`
  margin-top: 100px;
  margin-bottom: 90px;
`;
const StyledGreeting = styled.div`
  display: flex;
  margin-bottom: 25px;
  width: 100%;

  p {
    line-height: 1.6;
    align-self: center;
  }

  div.greeting {
    display: flex;
    width: calc(1.6rem);
    margin-right: 10px;

    figure {
      margin: 0;
    }

    svg {
      fill: #a26bda !important;
      font-size: 1.6rem;
    }
    h2 {
      text-transform: capitalize;
      margin-left: 6px;

      align-self: center;
    }
    img {
      width: 30px;
    }
  }
`;
const StyledOpacity = styled.div`
  background: linear-gradient(
    to bottom,
    rgba(249, 249, 249, 0) 0%,
    rgba(249, 249, 249, 1) 100%
  );
  width: 100%;
  height: 40px;
  position: fixed;
  bottom: 84px;
  left: 0;
  pointer-events: inherit;
`;
const StyledInitialPrompts = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-gap: 16px;

  article {
    font-size: 14px !important;
  }
  fieldset {
    height: 100%;

  }
  fieldset label {
    width: 100%;
    height: 100%;
  }
`;

const AIAssistant = () => {
  const messagesEndRef = useRef(null);
  const { user, popupConfig, setPopupConfig, setPopupDefaultValues } =
    useContext(GlobalContext);

  const [initialMessages, setInitialMessages] = useState({});
  const [chatMessages, setChatMessages] = useState([]);
  const [showInitialPrompts, setShowInitialPrompts] = useState(true);

  const navigate = useNavigate();
  // --------------------------------------------------------
  // VARIABLES
  const initialPromptsSuggestions =
    initialMessages?.prompts && JSON.parse(initialMessages?.prompts);

  useEffect(() => {
    // Retrieve the item with key 'myKey' from session storage
    const conversation = sessionStorage.getItem("conversation");

    if (conversation) setShowInitialPrompts(false);

    fetchInitialPrompt(conversation);
  }, []);

  useEffect(() => {
    scrollToTop();
  }, [chatMessages]);

  const scrollToTop = () => {
    const offset = 170; // Replace this with dynamic calculation if needed
    if (messagesEndRef.current) {
      const topPosition =
        messagesEndRef.current.getBoundingClientRect().top +
        window.scrollY -
        offset;
      window.scrollTo({ top: topPosition, behavior: "smooth" });
    }
  };

  const fetchInitialPrompt = (conversation) => {
    axios
      .get(makeApiRoute("chatInitialGreeting"))
      .then((response) => {
        if (conversation !== null) {
          const historyConversation = JSON.parse(conversation);

          setChatMessages(historyConversation);
        } else {
          setInitialMessages(response.data);
          setChatMessages([response?.data?.greeting]);
        }
      })
      .catch(function (error) {
        setPopupConfig({
          ...popupConfig,
          show: true,
          confirmation: false,
          maxWidth: "500px",
          title: "Meet AIDA, your new AI assistant!",
          subTitle:
            "Welcome to our Learning Management System's AI assistant! Here, you can ask any question related to your coursework, seek guidance, and explore learning resources. We're excited to support your educational journey with this innovative tool. Remember, your interactions will help us enhance your learning experience. Happy learning!<br /><br />By proceeding, you acknowledge and consent to all interactions with the AI chatbot being logged and analyzed by our team and instructors. This process aims to understand how students engage with the tool and identify common questions. Please be aware that your messages will not be anonymized, ensuring a personalized support experience. Click 'Agree' to agree and proceed.",
          bodyClassname: "action-buttons",
          body: (
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-evenly",
              }}
            >
              <Button
                text="Cancel"
                variant="grayBtn"
                onClick={() => {
                  navigate(makeAppRoute(`home`));
                  setPopupDefaultValues();
                }}
              />
              <Button
                text="Agree"
                variant="blueBtn"
                onClick={() => {
                  axios
                    .post(makeApiRoute("aiConsent"))
                    .then((response) => {
                      setPopupDefaultValues();
                      fetchInitialPrompt(conversation);
                    })
                    .catch((error) => {
                      console.log(error);
                    });
                }}
              />
            </div>
          ),
        });
      });
  };

  // SUBMIT A PROMPT
  const submitForm = (e, _value) => {
    e.preventDefault();

    setShowInitialPrompts(false);

    if (_value === "") return;

    setChatMessages((prevMessages) => [
      ...prevMessages,
      { role: "user", content: _value },
    ]);
    setChatMessages((prevMessages) => [
      ...prevMessages,
      { role: "assistant", content: "Thinking..." },
    ]);

    let postData = {
      message: _value,
      history: chatMessages,
    };

    const url = process.env.REACT_APP_API_URL + "/chat";
    streamResponseWithFetch(url, postData);
    async function streamResponseWithFetch(url, postData) {
      try {
        // Define the request options for a POST request
        const requestOptions = {
          method: "POST",
          headers: {
            "Content-Type": "application/json", // Specify the content type as JSON
            Authorization: "Bearer " + localStorage.getItem("userAT"),
          },
          body: JSON.stringify(postData), // Convert the postData object to a JSON string
        };

        // Make a request to the specified URL
        const response = await fetch(url, requestOptions);

        // The response body is a ReadableStream.
        // Get a reader from the response stream
        const reader = response.body.getReader();

        // TextDecoder to decode the stream chunks into strings
        const decoder = new TextDecoder();
        let responseText = "";

        // Function to recursively read the stream
        async function readStream() {
          const { done, value } = await reader.read();

          // If there's no more data to read (stream is complete)
          if (done) {
            const conversation = [
              ...chatMessages,
              {
                role: "user",
                content: _value,
              },
              {
                role: "assistant",
                content: responseText,
              },
            ];

            sessionStorage.setItem(
              "conversation",
              JSON.stringify(conversation)
            );

            return;
          }

          // Decode the Uint8Array chunk into a string and log it
          let chunk = "";
          chunk = decoder.decode(value, { stream: true });

          responseText += chunk;

          setChatMessages([
            ...chatMessages,
            {
              role: "user",
              content: _value,
            },
            {
              role: "assistant",
              content: responseText,
            },
          ]);

          // Recursively read the next chunk
          readStream();
        }

        // Start reading the stream
        readStream();
      } catch (error) {
        console.error("Failed to fetch and stream the response:", error);
      }
    }
  };

  if (chatMessages.length === 0) return <LoaderAI />;

  return (
    <StyledContainer>
      <StyledPromptsWrap>
        <DisplayPromptResult>
          {chatMessages?.map((message, index) => (
            <StyledGreeting key={index}>
              <div className="greeting" ref={messagesEndRef}>
                {message?.role === "user" ? (
                  <ProfileImage user={user} />
                ) : (
                  <BsStars />
                )}
              </div>
              <SyntaxHighlighter response={message?.content} />
            </StyledGreeting>
          ))}
        </DisplayPromptResult>

        {showInitialPrompts ? (
          <StyledInitialPrompts class="form-check">
            {initialPromptsSuggestions?.map((prompt, index) => (
              <article key={index}>
                <CustomCheckboxItem
                  id={index}
                  value={prompt}
                  label={prompt}
                  onChange={(e) => {
                    setShowInitialPrompts(false);
                    submitForm(e, prompt);
                  }}
                />
              </article>
            ))}
          </StyledInitialPrompts>
        ) : (
          ""
        )}

        <StyledOpacity></StyledOpacity>
        <SubmitForm submitForm={submitForm} />
      </StyledPromptsWrap>
    </StyledContainer>
  );
};

export default AIAssistant;
