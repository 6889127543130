import React, { useState, useEffect, useRef, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import styled from "styled-components";

import { makeAppRoute } from "../../Router";
import { GlobalContext } from "../../Context/GlobalProvider";
import { StyledForm } from "../CommonStyledComponents/CommonStyledComponents";
import { Text } from "../../Consts/Text";
import {
  GET_PROGRAM_DATA,
  UpdateUserProgramGoalsAndExpectationsInfo,
} from "../../Consts/GraphqlQueries";
import useMediaCustom from "../../Hooks/useMediaCustom";
import theme from "../../Consts/theme";
import textPages from "../../Consts/textPages.json";
import FormSelectGroup from "../FormGroup/FormSelectGroup";
import LoaderDots from "../Loader/LoaderDots";
import FormMultipleSelectGroup from "../FormGroup/FormMultipleSelectGroup";
import MobileHeader from "./MobileHeader/MobileHeader";
import SubmitButton from "../Button/SubmitButton";
import ErrorMessageComponent from "../ErrorMessageComponent/ErrorMessageComponent";

// Styled-components

const StyledContainer = styled.section`
  gap: 16px;
  border: 1px solid ${theme.concreteGray};
  border-radius: 16px;
  background-color: ${theme.white};
`;
const StyledFormHeader = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 16px;
  padding: 16px 0;
  border-bottom: 1px solid ${theme.concreteGray};

  @media only screen and (min-width: ${(props) => props.theme.desktop}) {
    margin-left: 24px;
    margin-right: 24px;
  }
`;
const StyledInnerWrap = styled.section`
  width: 100%;

  @media only screen and (min-width: ${(props) => props.theme.desktop}) {
    display: flex;
    width: 90%;

  }
`;
const StyledLoaderBox = styled.div`
  height: 60vh;
  width: 100%;
  display: flex;
  justify-content: center;

  .loader {
    padding-top: 0px;
  }
`;
const StyledInfoText = styled.p`
  font-style: normal;
  font-size: 10px;
  line-height: 14px;
  color: ${(props) => props.theme.lightGrayText};
  margin-bottom: 16px;
`;
// ----------------------------------------------------------------
// DirtyFields will keep track of what input(s) is/are changed and therefore to enable/disable Save button
let dirtyFields = new Set();
// ----------------------------------------------------------------
// Error messages on input validation
const errorMessages = {
  enrollment_purpose_id: "Please provide your purpose for enrollment",
  understanding_of_program_topic_id:
    "Please provide the level of your previous experience",
  expectations_from_academy_program:
    "Please provide your expectations from the Academy",
};
// Reset error messages
const resetErrors = {
  enrollment_purpose_id: "",
  understanding_of_program_topic_id: "",
  expectations_from_academy_program: "",
};

const AboutProgram = ({
  onboarding,
  submitFunctionRef,
  step,
  onboardingRoutes,
}) => {
  const navigate = useNavigate();
  const isDesktop = useMediaCustom("(min-width: 992px)");
  const userObjectRef = useRef();
  const { user, setUser } = useContext(GlobalContext);
  // States
  const [saveBtnDisabled, setSaveBtnDisabled] = useState(true);
  const [hasCompletedMutation, setHasCompletedMutation] = useState(false);
  const [errors, setErrors] = useState(resetErrors);
  //  ----------------------------------------------------------------
  // GRAPHQL GET REQUEST
  const {
    loading: queryLoading,
    error: queryError,
    data: queryData,
  } = useQuery(GET_PROGRAM_DATA, {
    fetchPolicy: "cache-and-network",
  });
  //  ----------------------------------------------------------------
  // GRAPHQL POST REQUEST (MUTATION)
  const [
    updateUser,
    {
      data: mutationData,
      loading: mutationLoading,
      error: mutationError,
      called: mutationCalled,
    },
  ] = useMutation(UpdateUserProgramGoalsAndExpectationsInfo, {
    onCompleted: (data) => {
      handleUserUpdate(data.UpdateUser);
      if (!onboarding) {
        setSaveBtnDisabled(true);
        setHasCompletedMutation(true);
        handleCompletedMutation();
      } else {
        const currentStep = onboardingRoutes.find(
          (eachStep) => eachStep === step
        );
        navigate(
          makeAppRoute(`onboarding`, {
            STEP: onboardingRoutes[onboardingRoutes.indexOf(currentStep) + 1],
          })
        );
      }
    },
  });

  // ----------------------------------------------------------------
  // VARIABLES
  const hasFetchedUser = queryData?.user !== undefined;

  useEffect(() => {
    if (mutationCalled) {
      handleUserUpdate(mutationData?.UpdateUser);
    }
  }, [mutationCalled, queryData, mutationData]);

  useEffect(() => {
    // pass submit function to the ref that is coming from parent where this function is called
    if (submitFunctionRef?.current) {
      submitFunctionRef.current = handleSubmitForm;
    }
    if (!mutationCalled && hasFetchedUser) {
      userObjectRef.current = queryData?.user;
      handleUserUpdate(queryData?.user);
    }
  }, [queryLoading, mutationLoading, hasFetchedUser]);

  // ----------------------------------------------------------------
  // Update the user object in Global context after submission and on page load

  const handleUserUpdate = (updatedUserData) => {
    setUser((prevUser) => ({
      ...prevUser,
      ...updatedUserData,
      user_info: {
        ...prevUser?.user_info,
        ...updatedUserData?.user_info,
      },
    }));
  };

  // ----------------------------------------------------------------
  // Return hasCompletedMutation to false to remove the style for success message

  const handleCompletedMutation = () => {
    const timer = setTimeout(() => {
      setHasCompletedMutation(false);
    }, 4000);

    // Clear the timeout if the component unmounts
    return () => clearTimeout(timer);
  };

  //  ----------------------------------------------------------------
  // Function checkRequiredFields is called on form submission to validate the required fields

  const checkRequiredFields = (_userObjectRef) => {
    // Extract the required fields
    const expectationsFromAcademyProgram =
      _userObjectRef?.expectations_from_academy_program;
    const enrollmentPurposeId =
      _userObjectRef?.user_info?.enrollment_purpose_id;
    const understandingOfProgramTopicId =
      _userObjectRef?.user_info?.understanding_of_program_topic_id;

    // Check if the array is not empty
    const isArrayNotEmpty = expectationsFromAcademyProgram.length > 0;

    // Check if the other fields are not null
    const isEnrollmentPurposeIdValid =
      enrollmentPurposeId !== null && enrollmentPurposeId !== undefined;
    const isUnderstandingOfProgramTopicIdValid =
      understandingOfProgramTopicId !== null &&
      understandingOfProgramTopicId !== undefined;

    // Return true if all conditions are met, else false
    return (
      isArrayNotEmpty &&
      isEnrollmentPurposeIdValid &&
      isUnderstandingOfProgramTopicIdValid
    );
  };

  //  ----------------------------------------------------------------
  // ON INPUT CHANGE

  const onInputChange = (_inputName, _value) => {
    if (
      _inputName === "enrollment_purpose_id" ||
      _inputName === "understanding_of_program_topic_id"
    ) {
      userObjectRef.current = {
        ...userObjectRef.current,
        user_info: {
          ...userObjectRef.current.user_info,
          [_inputName]: _value,
        },
      };
    } else {
      userObjectRef.current = {
        ...userObjectRef.current,
        [_inputName]: _value,
      };
    }
    setSaveBtnDisabled(false);
    // ----------------------------------------------------------------
    // Show error messages if required field is empty

    if (_value === "" || _value.length === 0) {
      setErrors({
        ...errors,
        [_inputName]: errorMessages[_inputName],
      });
    } else {
      setErrors({ ...errors, [_inputName]: "" });
    }

  };

  // ----------------------------------------------------------------
  // FORM SUBMIT

  const handleSubmitForm = async (e) => {
    e?.preventDefault();

    /*__typename is returned from GraphQL and it is needed to be removed
       before storing values in formData */

    const expectations =
      userObjectRef?.current?.expectations_from_academy_program?.map(
        ({ __typename, ...expectation }) => expectation
      );
    const circumstances =
      userObjectRef?.current?.circumstances_that_prevent_active_participation?.map(
        ({ __typename, ...circumstances }) => circumstances
      );

    const formData = {
      onboarding_step: step,
      user_info: {
        enrollment_purpose_id:
          +userObjectRef?.current?.user_info?.enrollment_purpose_id,
        understanding_of_program_topic_id:
          userObjectRef?.current?.user_info?.understanding_of_program_topic_id,
      },
      expectations_from_academy_program: expectations,
      circumstances_that_prevent_active_participation: circumstances,
    };

    // ----------------------------------------------------------------
    // Validate empty fields

    if (
      !userObjectRef?.current?.user_info?.enrollment_purpose_id ||
      !userObjectRef?.current?.user_info?.understanding_of_program_topic_id ||
      expectations?.length === 0
    ) {
      setSaveBtnDisabled(true);

      // Update errors state with error messages for empty input fields
      const errorFields = Object.keys(errorMessages).reduce(
        (acc, fieldName) => {
          if (
            fieldName === "enrollment_purpose_id" ||
            fieldName === "understanding_of_program_topic_id"
          ) {
            if (!userObjectRef?.current?.user_info?.[fieldName]) {
              acc[fieldName] = [errorMessages[fieldName]];
            }
          } else {
            if (userObjectRef?.current?.[fieldName]?.length === 0) {
              acc[fieldName] = [errorMessages[fieldName]];
            }
          }

          return acc;
        },
        {}
      );

      setErrors(errorFields);
      return;
    }

    // ----------------------------------------------------------------
    // submit the form

    try {
      await updateUser({ variables: { inputData: formData } });
    } catch (err) {
      console.log(err);
    }
  };

  // -------------------------------------------------------------------------------
  // LOADING ERRORS

  if (queryError || mutationError)
    return (
      <ErrorMessageComponent
        firstText="Oops!"
        secondText="Something went wrong while fetching your data."
        thirdText="Please try again later."
      />
    );

  // -------------------------------------------------------------------------------
  // MAIN COMPONENT

  return (
    <div>
      {!onboarding ? (
        <MobileHeader
          disabled={saveBtnDisabled || mutationLoading}
          hasSubmitted={hasCompletedMutation}
          onClick={handleSubmitForm}
          isLoading={mutationLoading}
        />
      ) : (
        ""
      )}

      <StyledContainer>
        <StyledFormHeader>
          <Text fontSize="14px">Program goals and Expectations</Text>
        </StyledFormHeader>

        <StyledInnerWrap onboarding={onboarding} ref={submitFunctionRef}>
          {queryLoading ? (
            <StyledLoaderBox>
              <LoaderDots
                className="loader"
                dotsBgColor={theme.darkBlue}
                dotHeight="8px"
                dotWidth="8px"
                dotMargin="8px"
                isLoading={queryLoading}
              />
            </StyledLoaderBox>
          ) : (
            <StyledForm ref={submitFunctionRef}>
              <StyledInfoText>* Indicates required</StyledInfoText>

              <FormSelectGroup
                fieldLabel={`${textPages.profile.about_program.about_program_purpose_enrollment} *`}
                selectLabel={
                  textPages.profile.about_program.about_program_choose_purpose
                }
                isRequired
                errors={errors.enrollment_purpose_id}
                name={"enrollment_purpose_id"}
                options={queryData?.enrollment_purposes}
                defaultValue={
                  user ? +user?.user_info?.enrollment_purpose_id : "-1"
                }
                getInputValue={(newValue) => {
                  onInputChange("enrollment_purpose_id", newValue);
                }}
              />
              <FormSelectGroup
                fieldLabel="Previous knowledge/experience relevant to the Academy Program *"
                isRequired
                errors={errors.understanding_of_program_topic_id}
                options={queryData?.understanding_of_program_topic}
                selectLabel={"Please select"}
                defaultValue={
                  (user &&
                    +user?.user_info?.understanding_of_program_topic_id) ||
                  "-1"
                }
                getInputValue={(newValue) => {
                  onInputChange(
                    "understanding_of_program_topic_id",
                    parseInt(newValue)
                  );
                }}
              />
              <FormMultipleSelectGroup
                options={queryData?.expectations_from_academy_program}
                defaultValue={user?.expectations_from_academy_program || ""}
                errors={errors.expectations_from_academy_program}
                maximumOptionsToSelect={3}
                additionalLabel="(Choose up to 3)"
                isRequired
                fieldLabel="What are your expectations from this academy *"
                getInputValue={(newValue) => {
                  onInputChange("expectations_from_academy_program", newValue);
                }}
              />
              <FormMultipleSelectGroup
                options={
                  queryData?.circumstances_that_prevent_active_participation
                }
                defaultValue={
                  user?.circumstances_that_prevent_active_participation || ""
                }
                maximumOptionsToSelect={3}
                additionalLabel="(Choose up to 3)"
                fieldLabel="Circumstances that would prevent you from active participation (optional)"
                getInputValue={(newValue) => {
                  onInputChange(
                    "circumstances_that_prevent_active_participation",
                    newValue
                  );
                }}
              />

              {!onboarding && isDesktop ? (
                <SubmitButton
                  variant="lightBlueBtn"
                  type="submit"
                  disabled={saveBtnDisabled || mutationLoading}
                  hasSubmitted={hasCompletedMutation}
                  onClick={handleSubmitForm}
                  isLoading={mutationLoading}
                />
              ) : (
                ""
              )}
            </StyledForm>
          )}
        </StyledInnerWrap>
      </StyledContainer>
    </div>
  );
};
export default AboutProgram;
