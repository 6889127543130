import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { axios, makeApiRoute } from "../../Router";
import { GlobalContext } from "../../Context/GlobalProvider";
import { ErrorMessage } from "../CommonStyledComponents/CommonStyledComponents";
import theme from "../../Consts/theme";
import Button from "../Button/Button";
import LoaderDots from "../Loader/LoaderDots";
import PasswordInput from "../Input/PasswordInput";

// styled-components

const StyledForm = styled.form`
  margin-top: 50px;

  .mb-15 {
    margin-bottom: 15px;
    text-align: center;
  }

  @media only screen and (min-width: ${(props) => props.theme.laptop}) {
    width: 60%;
    margin-left: auto;
    margin-right: auto;

    .mb-15 {

    text-align: left;
  }
  }
  @media only screen and (min-width: 1024px) {
    width: 100%;
  }

  .error {
    margin-top: 8px;
    margin-left: 25px;
    @media only screen and (min-width: ${(props) => props.theme.desktop}) {
      margin-left: 0px;
    }
  }

  div.submit-button {
    width: 100%;
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    @media only screen and (min-width: 768px) and (max-width: 992px) {
      justify-content: space-between;
      flex-direction: row;
    }
    @media only screen and (min-width: 1025px) {
      justify-content: space-between;
      flex-direction: row;
    }

    p {
      display: inline-block;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 18px;
      margin-top: 16px;
      text-align: center;
      cursor: ${(props) => (props.isLoading ? "not-allowed" : "pointer")};
      pointer-events: ${(props) => props.isLoading && "none"};
      color: ${(props) => props.theme.lightBlue};

      @media only screen and (min-width: 1025px) {
        text-align: left;
        width: 60%;
      }
    }
  }
`;
const StyledFormGroup = styled.div`
  width: 100%;
  margin-bottom: 40px;
  font-style: normal;

  input {
    font-weight: 400;
    padding: 16px 24px;
    background-color: ${(props) => props.theme.lightGray2};
    border: none;
    border-radius: 100px;
    width: 100%;
    font-size: 16px;
    line-height: 18px;
    color: ${(props) => props.theme.lightBlue};

    ::placeholder {
      font-size: 14px;
    }

    :focus {
      outline: 2px solid ${(props) => props.theme.lightBlue};
    }
    @media only screen and (min-width: ${(props) => props.theme.desktop}) {
      border-radius: 0px;
      color: ${(props) => props.theme.lightGrayText};
    }
  }

  label {
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    color: ${(props) => props.theme.lightGrayText};
    margin-bottom: 5px;
    margin-left: 30px;
    display: inline-block;
    @media only screen and (min-width: ${(props) => props.theme.desktop}) {
      color: ${(props) => props.theme.lightBlue};
      margin-left: 0px;
    }
  }
`;

const LoginForm = () => {
  const navigate = useNavigate();
  // states
  const { setUser } = useContext(GlobalContext);
  const [isLoading, setIsLoading] = useState(false);
  const [credentials, setCredentials] = useState({ email: "", password: "" });
  const [serverResponse, setServerResponse] = useState("");
  const [errorContract, setErrorContract] = useState(false)



  const onInputChange = (e) => {
    setServerResponse("");
    setCredentials((credentials) => ({
      ...credentials,
      [e.target.name]: e.target.value,
    }));
  };
  const handlePasswordReset = (e) => {
    e.preventDefault();
    navigate("/request-password-reset");
  };
  const handleLoginUser = (e) => {
    e.preventDefault();
    setIsLoading(true);

    axios
      .post(makeApiRoute("login"), {
        email: credentials.email,
        password: credentials.password,
      })
      .then(function (response) {
        if (response.status === 200) {
          localStorage.setItem("userAT", response.data.access_token);
          setUser(response?.data?.user);
          setIsLoading(false);
        } else {
          setIsLoading(false);
          // throw new Error("Bad Request");
        }
      })
      .catch(function (error) {

        if (error?.response?.status === 402) setErrorContract(error?.response?.status)

        setServerResponse(error?.response?.data?.error?.message);
        setIsLoading(false);
      });
  };

  return (
    <StyledForm onSubmit={handleLoginUser} isLoading={isLoading}>
      <StyledFormGroup>
        <label>Email</label>
        <input
          type="email"
          name="email"
          placeholder="example@example.com"
          value={credentials.email}
          onChange={onInputChange}
          disabled={isLoading}
        />
        {!errorContract && serverResponse !== "" ? (
          <div className="error">
            <ErrorMessage>{serverResponse}</ErrorMessage>
          </div>
        ) : (
          ""
        )}
      </StyledFormGroup>

      <StyledFormGroup>
        <label>Password</label>
        <PasswordInput
          name="password"
          placeholder="xxxxxxxx"
          value={credentials.password}
          onChange={onInputChange}
          disabled={isLoading}
        />
        {!errorContract && serverResponse !== "" ? (
          <div className="error">
            <ErrorMessage>{serverResponse}</ErrorMessage>
          </div>
        ) : (
          ""
        )}
      </StyledFormGroup>

      {errorContract ? <div className="mb-15"><ErrorMessage>{serverResponse}</ErrorMessage></div> : ""}

      <div className="submit-button">
        <p onClick={handlePasswordReset}>Forgot your password? We got you!</p>
        <Button
          text={
            !isLoading ? (
              "Log in"
            ) : (
              <LoaderDots
                isLoading={isLoading}
                dotsBgColor={theme.lightBlue}
                dotHeight="5px"
                dotWidth="5px"
                dotMargin="10px"
                buttonSubmit
              />
            )
          }
          variant="blueBtn"
          className="login-submit"
          disabled={serverResponse !== "" || isLoading}
        />
      </div>
    </StyledForm>
  );
};
export default LoginForm;
