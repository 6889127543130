// setting styling properties
const theme = {
    lightgray: '#FDFDFD',
    lightGray2: '#EFEFF4',
    lightGray3: '#F9F9F9',
    lightLightGray: '#C5C5C5',
    lightGrayText: '#65676c',
    gray: '#E1E2E7',
    white: '#fff',
    black: '#191919',
    darkLightGray: '#2C2E30',
    darkGray: '#232426',
    textGrey: '#4D4D4D',
    textGrey2: '#969696',
    concreteGray: '#e6e7eb',
    lightBlue:' #005EFF',
    darkBlue: '#0A08E5',
    darkBlue2: '#005EFF',
    greenGradient: 'radial-gradient(131.25% 39562.5% at 1.09% 0%, #00D762 15.51%, #005EFF 100%)',
    blueGradient: 'linear-gradient(90deg, rgba(147,207,238,1) 9%, rgba(0,94,255,1) 93%)',
    red: '#ff003a',
    green: '#00d762',
    darkGreen: '#44a570',
    yellow: '#fbdb65',
    darkenYellow: '#ebcc5d',
    bronzeYellow: '#7D5E00',
    forestGreen: '#00944A',
    transparent: 'transparent',



    // font size
    small: '0.625rem',
    normal: '0.75rem',
    medium: '0.875rem',
    large: '1.125rem',

    // media query
    tablet: "550px",
    laptop: "768px",
    desktop: "992px",
    large_desktop: "1440px"
};

export default theme;
