import React from "react";
import SelectElement from "../SelectElement/SelectElement";

import { StyledFormInputGroup } from "../CommonStyledComponents/CommonStyledComponents";
import Label from "../Label/Label";

const FormSelectGroup = ({ fieldLabel, className, name, defaultValue, options, getInputValue, selectLabel, isRequired, errors }) => {
  return (
    <StyledFormInputGroup>
      <Label
        label={fieldLabel}
        className={className}
      />
      <SelectElement
        name={name}
        selectLabel={selectLabel}
        defaultValue={defaultValue}
        options={options}
        getInputValue={getInputValue}
        className={className}
        isRequired={isRequired}
        errors={errors}
      />
    </StyledFormInputGroup>
  );
};
export default FormSelectGroup;
