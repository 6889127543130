import React from "react";
import styled from "styled-components";

// styled components
const StyledBar = styled.div`
  position: relative;
  width: 100%;
  height: 1rem;
  border-radius: 100px;
  background-color: #fff;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.16);
  margin-top: 1rem;
  /* transition: all 1s ease-in-out; */

  ::before {
    content: "";
    position: absolute;
    display: flex;
    align-items: center;
    width: calc((${(props) => props.percentage}) * 1%);
    height: inherit;
    max-width: 100%;
    border-radius: inherit;
    background: ${(props) =>
      props.percentage >= 100 ? props.theme.greenGradient : props.theme.blueGradient};
    transition: all 1s ease-in-out;

    animation: progressBar 1s linear forwards;

    @keyframes progressBar {
      0% {
        width: 0%;
      }
    }
  }
`;

const StyledThumb = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  font-weight: 700;
  top: -4px;
  height: 22px;
  border-radius: 15px;
  background-color: #e6e7eb;
  box-shadow: 0px 8px 30px rgba(0, 0, 0, 0.08);
  color: black;
  opacity: 0;

  animation: fadeInFromNone 1s forwards 1s;

  @keyframes fadeInFromNone {
    100% {
      opacity: 1;
    }
  }

  @media (min-width: 320px) and (max-width: 425px) {
    width: ${(props) => parseInt(props.thumbWidth)}%;
    padding: 4px 6px;
    left: ${(props) =>
      props.percentage &&
      props.thumbWidth &&
      parseInt(props.percentage) > parseInt(props.thumbWidth)
        ? props.percentage - props.thumbWidth + "%"
        : "-1%"};
  }
  @media only screen and (min-width: ${(props) => props.theme.laptop}) {
    width: ${(props) => parseInt(props.thumbWidth)}%;
    padding: 0;
    height: 26px;
    top: -6px;

    font-size: 12px;
    left: ${(props) =>
      props.percentage &&
      props.thumbWidth &&
      parseInt(props.percentage) > parseInt(props.thumbWidth)
        ? props.percentage - props.thumbWidth + "%"
        : "-1%"};
  }
`;

const ProgressBar = ({
  percentage,
  slug,
  onClick,
  progressBarContent,
  thumbWidth,
}) => {
  return (
    <StyledBar percentage={percentage} onClick={onClick} data-slug={slug}>
      <StyledThumb percentage={percentage} thumbWidth={thumbWidth}>
        {progressBarContent}
      </StyledThumb>
    </StyledBar>
  );
};
export default ProgressBar;
