import { useState } from "react";


export default function useShowMoreFiles ( _initialNumOfFiles, _allFiles ) {
    const [numberOfInitialFilesDisplayed, setNumberOfInitialFilesDisplayed] =
    useState(_initialNumOfFiles);

    const totalNumberOfAchievements = _allFiles.length;

    const onShowMoreAchievements = () => {
        if (numberOfInitialFilesDisplayed < totalNumberOfAchievements) {
          setNumberOfInitialFilesDisplayed(numberOfInitialFilesDisplayed + 4);
        } else {
          setNumberOfInitialFilesDisplayed(_initialNumOfFiles);
        }
      };

      return { numberOfInitialFilesDisplayed, totalNumberOfAchievements, onShowMoreAchievements };
}