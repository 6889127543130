import React, { useCallback, useContext, useState } from "react";
import styled from "styled-components";
import { GlobalContext } from "../../Context/GlobalProvider";
import { axios, makeApiRoute } from "../../Router";

import { StyledAddPhotoIcon } from "../CommonStyledComponents/CommonStyledComponents";
import ImageCropper from "../ImageCropper/ImageCropper";
import { useMutation } from "@apollo/client";

// styled-components
const StyledProfileImageWrap = styled.figure`
  position: relative;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.16);

  /* @media only screen and (min-width: ${(props) => props.theme.laptop}) {
    width: 100px;
    height: 100px;
  } */
`;
const StyledProfileImage = styled.img`
  display: flex;
  justify-content: center;
  object-fit: cover;
  align-items: center;
  width: inherit;
  height: inherit;
  border-radius: 50%;
`;

const EditProfileImage = ({ url, className, data, setData, editProfile }) => {
  const [image, setImage] = useState(false);
  const { user, setUser } = useContext(GlobalContext);

  // const [uploadImage, {data: mutationData, error: mutationError, loading: mutationLoading}] = useMutation()


  // invoked on input onChane
  const onFileChange = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      let imageDataUrl = await readFile(file);
      setImage(imageDataUrl);
    } else {
      setImage(false);
    }
  };

  // invoked inside onFileChange
  const readFile = useCallback((file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.addEventListener("load", () => resolve(reader.result), false);
      reader.readAsDataURL(file);
    });
  }, []);

  // invoked in ImageCroper when cropping and saving the image
  const uploadImage = (image) => {
    axios
      .post(makeApiRoute("studentUploadFile"), image)
      .then((response) => {
        localStorage.setItem(
          "user",
          JSON.stringify(response?.data?.data?.profile)
        );
        setUser(response.data.data);

        setImage(false);
      })
      .catch((error) => {});
  };

  return (
    <StyledProfileImageWrap className={className}>
      <StyledProfileImage src={url}></StyledProfileImage>
      {image && (
        <ImageCropper
          image={image}
          setImage={setImage}
          uploadImage={uploadImage}
          // data={user}
          // setData={setUser}
        />
      )}

      {editProfile ? (
        <StyledAddPhotoIcon>
          <input
            type="file"
            accept="image/*, .pdf"
            onChange={(e) => {
              onFileChange(e);
            }}
            // onClick is added because react cannot use fileinput twice without refreshing the page
            onClick={(e) => {
              onFileChange(e);
            }}
          />
        </StyledAddPhotoIcon>
      ) : (
        ""
      )}
    </StyledProfileImageWrap>
  );
};
export default EditProfileImage;
