import React from "react";
import styled, { css } from "styled-components";

import theme from "../../Consts/theme";

const variantOptions = {
  blueBtn: {
    backgroundColor: theme.darkBlue2,
    color: theme.white,
    hover: theme.darkBlue,
  },
  lightBlueBtn: {
    backgroundColor: "rgba(0, 94, 255, 0.15)",
    color: theme.lightBlue,
    hover: theme.darkBlue,
    hoverColor: theme.white,
  },
  grayBtn: {
    backgroundColor: theme.gray,
    color: theme.black,
    hoverColor: theme.lightBlue,
  },
  declineBtn: {
    backgroundColor: theme.gray,
    color: "red",
    hover: theme.lightLightGray,
  },
  blackBtn: {
    backgroundColor: theme.black,
    color: theme.white,
    padding: "10px 20px",
  },
  greenBtn: {
    backgroundColor: theme.green,
    color: theme.white,
    hover: theme.darkGreen,
  },
  yellowBtn: {
    backgroundColor: theme.yellow,
    color: theme.black,
    hover: theme.darkenYellow,
  },
  transparentBtn: {
    backgroundColor: theme.transparent,
    color: theme.lightBlue,
    hoverColor: theme.darkBlue,
  },
};

const StyledButton = styled.button`
  padding: 13px 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  outline: none;
  font-weight: 700;
  font-size: ${(props) => props.theme.medium};
  line-height: 14px;
  cursor: ${(props) => (!props.disabled ? "pointer" : "not-allowed")};

  width: ${(props) => props.className === "mobileBtn" && "100%"};
  border-radius: ${(props) => (props.className === "linkBtn" ? "25px" : "6px")};

  &.login-submit {
    border-radius: 100px !important;
    @media only screen and (min-width: ${(props) => props.theme.desktop}) {
      border-radius: 0px !important;
    }
  }
  &.leaderboard-btn {
    width: 100%;
    border-radius: 5px;
  }

  span.button-icon {
    margin-left: 5px;
    align-self: center;
  }

  position: relative;

  &:before {
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.9);
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 0;
    height: 0;
  }

  ${({ variant }) =>
    variant &&
    variantOptions[variant] &&
    css`
      background-color: ${(props) =>
        !props.disabled
          ? variantOptions[variant].backgroundColor
          : props.theme.gray};
      color: ${(props) =>
        !props.disabled ? variantOptions[variant].color : theme.lightGrayText};
      padding: ${variantOptions[variant].padding};
      transition: background-color 0.3s;
      :hover,
      :active {
        /* color: ${(props) =>
          !props.disabled
            ? variantOptions[variant].hoverColor
            : props.theme.gray}; */

        color: ${(props) =>
          !props.disabled ? variantOptions[variant].hoverColor : "#929296"};
        background-color: ${(props) =>
          !props.disabled ? variantOptions[variant].hover : props.theme.gray};
        transition: all 0.3s ease-in-out;
      }
    `}
`;

const Button = ({
  variant,
  text,
  type,
  onClick,
  disabled,
  className,
  icon,
}) => {
  return (
    <StyledButton
      type={type}
      variant={variant}
      onClick={onClick}
      disabled={disabled}
      className={className}
    >
      {text}
      {icon && <span className="button-icon">{icon}</span>}
    </StyledButton>
  );
};
export default Button;
