import React from "react";
import styled from "styled-components";

import AIAssistant from "../../Components/AIAssistant/AIAssistant";
import Navbar from "../../Components/Navbar/Navbar";
import EventsNotification from "../../Components/Events/EventsNotification";

const StyledContainer = styled.div`
  margin-top: -80px;
`;

const BrainsterChat = () => {
  return (
    <StyledContainer>
      <Navbar />
      <EventsNotification />
      <AIAssistant />
    </StyledContainer>
  );
};

export default BrainsterChat;
