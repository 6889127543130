import React, { useState } from 'react'
import { IoIosSend } from "react-icons/io";
import styled from "styled-components";

import theme from "../../Consts/theme";
import Button from '../Button/Button';

// styled-components
const StyledFormWrap = styled.div`
  background-color: ${theme.lightGray3};
  position: fixed;
  padding-bottom: 30px;
  bottom: 0;
  left: 0;
  width: 100%;
`;
const StyledForm = styled.form`
  width: 40%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;

  input, textarea {
    width: 85%;
    border: 1px solid ${theme.gray};
    outline: none;
    padding: 16px;
    border-radius: 10px;
    color: ${theme.textGrey};
    margin-right: 15px;
  }
`;
const AiDisclaimer = styled.div`
  width: 40%;
  margin: 0 auto;
  margin-top: 10px;
  font-size: 0.8rem;
  font-style: italic;
  padding-left: 5px;
  color:darkgray;
`;
const SubmitForm = ({ submitForm }) => {
  const [inputValue, setInputValue] = useState("");

  return (
    <StyledFormWrap>
      <StyledForm onSubmit={(e) => {
        submitForm(e, inputValue)
        setInputValue("")
      }}>
        <textarea rows="1"
          onChange={(e) => { setInputValue(e.target.value); }}
          value={inputValue}
          onKeyDown={(e) => {
            if (e.keyCode == 13 && e.shiftKey == false) {
              e.preventDefault();
              submitForm(e, inputValue)
              setInputValue("")
            }
          }}
        ></textarea>

        <Button
          variant="blueBtn"
          type="submit"
          text="Send"
          disabled={inputValue === ""}
        >
          <IoIosSend />
        </Button>
      </StyledForm>
      <AiDisclaimer>Aida may not always be correct. Ensure to double-check essential details.</AiDisclaimer>

    </StyledFormWrap >
  )
}

export default SubmitForm
