import React from "react";
import styled from "styled-components";

// styled-components
const StyledContainer = styled.div`
  width: ${(props) => props.width};
  margin-left: auto;
  margin-right: auto;
  height: 4px;
  background-color: ${(props) => props.theme.lightGray2};
  display: flex;
`;
const StyledProgress = styled.div`
  height: 100%;
  background-color: ${(props) => props.theme.lightBlue};
  width: ${(props) => props.progressWidth && props.progressWidth};
  transition: width 0.5s ease-in-out;
`;

const ProgressStep = ({ width, progressWidth }) => {
  return (
    <>
      <StyledContainer width={width}>
        <StyledProgress progressWidth={progressWidth}></StyledProgress>
      </StyledContainer>
    </>
  );
};

export default ProgressStep;
