import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { BiHide, BiShowAlt } from "react-icons/bi";

import { axios, makeApiRoute } from "../../Router";
import textPages from "../../Consts/textPages.json";
import useMediaCustom from "../../Hooks/useMediaCustom";
import FormInputGroup from "../FormGroup/FormInputGroup";
import {
  ErrorMessage,
  StyledForm,
} from "../CommonStyledComponents/CommonStyledComponents";
import LoaderDots from "../Loader/LoaderDots";
import theme from "../../Consts/theme";
import MobileHeader from "./MobileHeader/MobileHeader";
import SubmitButton from "../Button/SubmitButton";
import { Text } from "../../Consts/Text";
import { GET_EMPLOYMENT_DATA } from "../../Consts/GraphqlQueries";
import { useQuery } from "@apollo/client";

// styled-components
const StyledContainer = styled.section`
  gap: 16px;
  border: 1px solid ${theme.concreteGray};
  border-radius: 16px;
  background-color: ${theme.white};
`;
const StyledFormHeader = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 16px;
  padding: 16px 0;
  border-bottom: 1px solid ${theme.concreteGray};

  @media only screen and (min-width: ${(props) => props.theme.desktop}) {
    margin-left: 24px;
    margin-right: 24px;
  }
`;
const StyledInfoText = styled.p`
  font-style: normal;
  font-size: 10px;
  line-height: 14px;
  color: ${(props) => props.theme.lightGrayText};
  margin-bottom: 16px;
`;
const StyledLoaderBox = styled.div`
  height: 60vh;
  width: 100%;
  display: flex;
  justify-content: center;

  .loader {
    padding-top: 0px;
  }
`;

const StyledInnerWrap = styled.section`
  width: 100%;

  @media only screen and (min-width: ${(props) => props.theme.desktop}) {
    display: flex;
  }
`;
const StyledInputGroup = styled.div`
  position: relative;
  @media only screen and (min-width: ${(props) => props.theme.desktop}) {
    width: 90%;

    input {
      width: 100%;
    }
  }

  .icons {
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(calc(-50% + 7px));
    padding: 3px 6px 0;
    border-radius: 50%;
    @media only screen and (min-width: ${(props) => props.theme.desktop}) {
      right: 30px;
    }

    svg {
      font-size: 20px;
      cursor: pointer;

      @media only screen and (min-width: ${(props) => props.theme.desktop}) {
        font-size: 25px;
      }
    }

    :hover {
      background-color: #e1e2e7;
      transition: all 0.2s ease-in-out;
    }
  }
`;

const Security = () => {
  const isDesktop = useMediaCustom("(min-width: 992px)");
  const [inputValues, setInputValues] = useState({
    currentPassword: "",
    password: "",
    confirmPassword: "",
  });
  const [passwordShown, setPasswordShown] = useState({
    currentPassword: undefined,
    password: undefined,
    confirmPassword: undefined,
  });
  const [errors, setErrors] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [saveBtnDisabled, setSaveBtnDisabled] = useState(true);
  const [hasCompletedMutation, setHasCompletedMutation] = useState(false);

  // on input change, Save button is not disabled and form can be submitted, otherwise Save button is disabled and form can not be submitted
  useEffect(() => {
    if (
      inputValues.currentPassword !== "" &&
      (inputValues.password !== "") && (inputValues.confirmPassword !== "")
    ) {
      setSaveBtnDisabled(false);
    } else {
      setSaveBtnDisabled(true);
    }
  }, [inputValues, saveBtnDisabled]);

  // ----------------------------------------------------------------
  // Return hasCompletedMutation to false to remove the style for success message

  const handleCompletedMutation = () => {
    const timer = setTimeout(() => {
      setHasCompletedMutation(false);
    }, 4000);

    // Clear the timeout if the component unmounts
    return () => clearTimeout(timer);
  };

  // submit form
  const onFormSubmit = (e) => {
    e.preventDefault();

    const formData = {
      currentPassword: inputValues.currentPassword,
      password: inputValues.password,
      confirmPassword: inputValues.confirmPassword,
    };

    // set loader
    setIsLoading(true);
    // put request
    axios
      .put(makeApiRoute("changePassword"), formData)
      .then((response) => {
        setIsLoading(false);
        setSaveBtnDisabled(true);
        handleCompletedMutation();
      })
      .catch((error) => {
        setIsLoading(false);
        if (error?.response?.status === 400) {
          setErrors(error?.response?.data?.message);
        }
      });
  };

  return (
    <div>
      <MobileHeader
       disabled={saveBtnDisabled}
       hasSubmitted={hasCompletedMutation}
       onClick={onFormSubmit}
       isLoading={isLoading}
      />

      <StyledContainer>
        <StyledFormHeader>
          <Text fontSize="14px">Change your password</Text>
        </StyledFormHeader>

        <StyledInnerWrap>
          {isLoading ? (
            <StyledLoaderBox>
              <LoaderDots
                className="loader"
                dotsBgColor={theme.darkBlue}
                dotHeight="8px"
                dotWidth="8px"
                dotMargin="8px"
                isLoading={isLoading}
              />
            </StyledLoaderBox>
          ) : (
            <StyledForm className="basic-info">
              <StyledInfoText>* Indicates required</StyledInfoText>
              <StyledInputGroup>
                <FormInputGroup
                  type={passwordShown?.currentPassword ? "text" : "password"}
                  name={"currentPassword"}
                  label={textPages.profile.privacy.privacy_current_password}
                  placeholder={"Type here..."}
                  getInputValue={(newValue) => {
                    setInputValues({
                      ...inputValues,
                      currentPassword: newValue,
                    });
                  }}
                />
                <div className="icons">
                  {passwordShown.currentPassword ? (
                    <BiHide
                      onClick={() =>
                        setPasswordShown({
                          ...passwordShown,
                          currentPassword: false,
                        })
                      }
                    />
                  ) : (
                    <BiShowAlt
                      onClick={() =>
                        setPasswordShown({
                          ...passwordShown,
                          currentPassword: true,
                        })
                      }
                    />
                  )}
                </div>
              </StyledInputGroup>
              {errors && errors === "Wrong current password!" && (
                <div style={{ marginTop: "-16px", marginBottom: "16px" }}>
                  <ErrorMessage>{errors}</ErrorMessage>
                </div>
              )}
              <StyledInputGroup>
                <FormInputGroup
                  type={passwordShown?.password ? "text" : "password"}
                  name={"password"}
                  label={textPages.profile.privacy.privacy_new_password}
                  placeholder={"Type here..."}
                  getInputValue={(newValue) => {
                    setInputValues({
                      ...inputValues,
                      password: newValue,
                    });
                  }}
                />
                <div className="icons">
                  {passwordShown?.password ? (
                    <BiHide
                      onClick={() =>
                        setPasswordShown({ ...passwordShown, password: false })
                      }
                    />
                  ) : (
                    <BiShowAlt
                      onClick={() =>
                        setPasswordShown({ ...passwordShown, password: true })
                      }
                    />
                  )}
                </div>
              </StyledInputGroup>
              {errors && errors !== "Wrong current password!" && (
                <div style={{ marginTop: "-16px", marginBottom: "16px" }}>
                  <ErrorMessage>{errors}</ErrorMessage>
                </div>
              )}

              <StyledInputGroup>
                <FormInputGroup
                  type={passwordShown?.confirmPassword ? "text" : "password"}
                  name={"confirmPassword"}
                  label={textPages.profile.privacy.privacy_confirm_password}
                  placeholder={"Type here..."}
                  getInputValue={(newValue) => {
                    setInputValues({
                      ...inputValues,
                      confirmPassword: newValue,
                    });
                  }}
                />
                <div className="icons">
                  {passwordShown?.confirmPassword ? (
                    <BiHide
                      onClick={() =>
                        setPasswordShown({
                          ...passwordShown,
                          confirmPassword: false,
                        })
                      }
                    />
                  ) : (
                    <BiShowAlt
                      onClick={() =>
                        setPasswordShown({
                          ...passwordShown,
                          confirmPassword: true,
                        })
                      }
                    />
                  )}
                </div>
              </StyledInputGroup>
              {errors && errors !== "Wrong current password!" && (
                <div style={{ marginTop: "-16px", marginBottom: "16px" }}>
                  <ErrorMessage>{errors}</ErrorMessage>
                </div>
              )}

              {isDesktop ? (
                <SubmitButton
                  variant="lightBlueBtn"
                  type="submit"
                  disabled={saveBtnDisabled}
                  hasSubmitted={hasCompletedMutation}
                  onClick={onFormSubmit}
                  // isLoading={mutationLoading}
                />
              ) : (
                ""
              )}
            </StyledForm>
          )}
        </StyledInnerWrap>
      </StyledContainer>

      {/* <StyledInnerProfileSection>
        {isDesktop && (
          <StyledSectionHeading>
            {textPages.profile.privacy.privacy_heading}
          </StyledSectionHeading>
        )}
        <StyledForm onSubmit={onFormSubmit}>
          <StyledInputGroup>
            <FormInputGroup
              type={passwordShown?.currentPassword ? "text" : "password"}
              name={"currentPassword"}
              label={textPages.profile.privacy.privacy_current_password}
              placeholder={"Type here..."}
              getInputValue={(newValue) => {
                setInputValues({
                  ...inputValues,
                  currentPassword: newValue,
                });
              }}
            />
            <div className="icons">
              {passwordShown.currentPassword ? (
                <BiHide
                  onClick={() =>
                    setPasswordShown({
                      ...passwordShown,
                      currentPassword: false,
                    })
                  }
                />
              ) : (
                <BiShowAlt
                  onClick={() =>
                    setPasswordShown({
                      ...passwordShown,
                      currentPassword: true,
                    })
                  }
                />
              )}
            </div>
          </StyledInputGroup>
          {errors && errors === "Wrong current password!" && (
            <div style={{ marginTop: "-16px", marginBottom: "16px" }}>
              <ErrorMessage>{errors}</ErrorMessage>
            </div>
          )}
          <StyledInputGroup>
            <FormInputGroup
              type={passwordShown?.password ? "text" : "password"}
              name={"password"}
              label={textPages.profile.privacy.privacy_new_password}
              placeholder={"Type here..."}
              getInputValue={(newValue) => {
                setInputValues({
                  ...inputValues,
                  password: newValue,
                });
              }}
            />
            <div className="icons">
              {passwordShown?.password ? (
                <BiHide
                  onClick={() =>
                    setPasswordShown({ ...passwordShown, password: false })
                  }
                />
              ) : (
                <BiShowAlt
                  onClick={() =>
                    setPasswordShown({ ...passwordShown, password: true })
                  }
                />
              )}
            </div>
          </StyledInputGroup>
          {errors && errors !== "Wrong current password!" && (
            <div style={{ marginTop: "-16px", marginBottom: "16px" }}>
              <ErrorMessage>{errors}</ErrorMessage>
            </div>
          )}

          <StyledInputGroup>
            <FormInputGroup
              type={passwordShown?.confirmPassword ? "text" : "password"}
              name={"confirmPassword"}
              label={textPages.profile.privacy.privacy_confirm_password}
              placeholder={"Type here..."}
              getInputValue={(newValue) => {
                setInputValues({
                  ...inputValues,
                  confirmPassword: newValue,
                });
              }}
            />
            <div className="icons">
              {passwordShown?.confirmPassword ? (
                <BiHide
                  onClick={() =>
                    setPasswordShown({
                      ...passwordShown,
                      confirmPassword: false,
                    })
                  }
                />
              ) : (
                <BiShowAlt
                  onClick={() =>
                    setPasswordShown({
                      ...passwordShown,
                      confirmPassword: true,
                    })
                  }
                />
              )}
            </div>
          </StyledInputGroup>
          {errors && errors !== "Wrong current password!" && (
            <div style={{ marginTop: "-16px", marginBottom: "16px" }}>
              <ErrorMessage>{errors}</ErrorMessage>
            </div>
          )}

          <div style={{ display: "flex", marginBottom: "8px" }}>
            <Button
              variant={"blueBtn"}
              text={
                !isLoading ? (
                  "Save Changes"
                ) : (
                  <LoaderDots
                    isLoading={isLoading}
                    dotsBgColor={theme.white}
                    dotHeight="5px"
                    dotWidth="5px"
                    dotMargin="10px"
                    buttonSubmit
                  />
                )
              }
              type={"submit"}
              className={!isDesktop ? "mobileBtn" : ""}
              disabled={saveBtnDisabled}
            />
          </div>
        </StyledForm>
      </StyledInnerProfileSection> */}
    </div>
  );
};
export default Security;
