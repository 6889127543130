import React, { useEffect } from "react";
import styled from "styled-components";

const StyledConfettiContainer = styled.div`
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  display: ${(props) => (props.showConfetti ? "block" : " none")};
  z-index: 999;

  .confetti {
    position: absolute;
    top: -100px;
  }
  /* Start Animation */
  .confetti:nth-child(100n + 2) {
    animation: animateStart 1.5s ease 1;
  }
  .confetti:nth-child(100n + 4) {
    animation: animateStart 1.4s ease 1;
  }
  .confetti:nth-child(1000n + 0) {
    animation: animateStart 1.6s ease 1;
  }
  @keyframes animateStart {
    0% {
      top: 100%;
      left: 50%;
      box-shadow: 0 0 5px #000;
    }
    100% {
      transform: rotate3d(1, 1, 1, 360deg);
      top: -100px;
    }
  }
  /* Falling Animation */
  .confetti:nth-child(100n + 0) {
    animation: animateThree 3.4s linear 0.5s 3;
  }
  .confetti:nth-child(100n + 1) {
    animation: animate 4s linear 3;
  }
  .confetti:nth-child(100n + 3) {
    animation: animate 4.2s linear 0.5s 3;
  }
  .confetti:nth-child(100n + 5) {
    animation: animateTwo 3s linear 0.5s 3;
  }
  .confetti:nth-child(100n + 6) {
    animation: animateTwo 2.9s linear 0.5s 3;
  }
  .confetti:nth-child(100n + 7) {
    animation: animate 2.8s linear 0.5s 3;
  }
  .confetti:nth-child(100n + 8) {
    animation: animate 2.7s linear 0.5s 3;
  }
  .confetti:nth-child(100n + 9) {
    animation: animate 2.6s linear 0.5s 3;
  }
  .confetti:nth-child(100n + 10) {
    animation: animate 2.5s linear 0.5s 3;
  }
  @keyframes animate {
    0% {
      top: -100px;
    }
    100% {
      transform: rotate3d(1, 1, 1, 360deg);
      top: 100%;
    }
  }
  @keyframes animateTwo {
    0% {
      top: -100px;
    }
    100% {
      transform: rotate3d(0, 0, 1, 360deg);
      top: 100%;
    }
  }
  @keyframes animateThree {
    0% {
      top: -100px;
    }
    100% {
      transform: rotate3d(0, 1, 0, 360deg);
      top: 100%;
    }
  }
`;

const Confetti = ({ showConfetti, setShowConfetti }) => {


  useEffect(() => {
    const canvas = document.getElementById('confetti');
    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;
    canvas.style.position = 'absolute';
    canvas.style.pointerEvents = 'none';
    canvas.style.top = 0;
    canvas.style.left = 0;

    const ctx = canvas.getContext('2d');
    let pieces = [];
    let numberOfPieces = 100;
    let lastUpdateTime = Date.now();
    // let lastUpdateTime = 1000;



    function randomColor() {
       const colors = [  '#3f51b5', '#2196f3', '#03a9f4', '#00bcd4', '#cddc39', '#ffeb3b', '#ffc107', '#ff9800', '#000' ];
      // const colors = ['#f44336', '#e91e63', '#9c27b0', '#673ab7', '#3f51b5', '#2196f3', '#03a9f4', '#00bcd4', '#009688', '#4caf50', '#8bc34a', '#cddc39', '#ffeb3b', '#ffc107', '#ff9800', '#ff5722'];
      return colors[Math.floor(Math.random() * colors.length)];

    }

    function createPiece() {
      const angle = Math.random() * Math.PI * 2;
      const speed = Math.random() * 300 + 300;  // pixels per second
      return {
        x: canvas.width / 2,
        y: canvas.height / 2,
        size: (Math.random() * 0.4 + 0.65) * 10,
        gravity: 0.6,
        rotation: (Math.PI * 2) * Math.random(),
        rotationSpeed: (Math.PI * 2) * Math.random() * 0.005,
        color: randomColor(),
        shape: Math.random() < 0.5 ? 'circle' : 'rectangle',
        opacity: 1,
        speedX: Math.cos(angle) * speed,
        speedY: Math.sin(angle) * speed
      };
    }

    function update(dt) {
      pieces.forEach(p => {
        p.x += p.speedX * dt;
        p.y += p.speedY * dt;
        p.speedY += p.gravity;
        p.rotation += p.rotationSpeed * dt;
        p.opacity -= 0.005;
      });

      // pieces = pieces.filter(p => p.opacity > 0);

      if (pieces.length < numberOfPieces) {
        pieces.push(createPiece());
      }
    }

    function drawPiece(p) {
      ctx.save();
      ctx.fillStyle = p.color;
      ctx.globalAlpha = p.opacity;
      ctx.translate(p.x, p.y);
      ctx.rotate(p.rotation);

      if (p.shape === 'circle') {
        ctx.beginPath();
        ctx.arc(0, 0, p.size / 2, 0, 2 * Math.PI);
        ctx.fill();
      } else {
        ctx.fillRect(-p.size / 2, -p.size / 2, p.size, p.size);
      }

      ctx.restore();
    }

    function loop() {
      const now = Date.now();
      const dt = (now - lastUpdateTime) / 2000;  // delta time in seconds


      update(dt);
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      pieces.forEach(drawPiece);

      lastUpdateTime = now;
      requestAnimationFrame(loop);
    }

    loop();
  }, []);

  return <canvas id="confetti"></canvas>;


















  // const colors = [
  //   "#a864fd",
  //   "#29cdff",
  //   "#78ff44",
  //   "#ff718d",
  //   "#fdff6a",
  //   "#ae0001",
  //   "#740001",
  // ];

  // useEffect(() => {
  //   if (showConfetti) {
  //     const timer = setTimeout(() => {
  //       setShowConfetti(false)
  //     }, 4500);
  //     return () => clearTimeout(timer);
  //   }
  //  return
  // }, [showConfetti]);



  // const confettis = Array.from(Array(800).keys());

  // return (
  //   <StyledConfettiContainer showConfetti={showConfetti}>
  //     {confettis.map((eachConfetti) => {
  //       let size = Math.random() * 0.01 * eachConfetti;
  //       let width = 3 + size + "px";
  //       let height = 8 + size + "px";
  //       let left = Math.random() * 100 + "%";
  //       let backgroundColor = colors[Math.floor(Math.random() * colors.length)];
  //       let transform = "rotate(" + size * [eachConfetti] + "deg)";

  //       return (
  //         <div
  //           key={eachConfetti}
  //           className="confetti"
  //           style={{ width, height, left, backgroundColor, transform }}
  //         ></div>
  //       );
  //     })}
  //   </StyledConfettiContainer>
  // );
};
export default Confetti;
