import React from "react";
import { MdError } from "react-icons/md";
import styled from "styled-components";

import { Text } from "../../Consts/Text";
import theme from "../../Consts/theme";

const StyledErrorBox = styled.div`
  width: 100%;
  display: grid;
  align-items: center;
  grid-template-columns: 3fr 9fr;
  padding: 16px;

  .icon {
    justify-self: center;
  }
  .error-content {
    align-self: center;
  }

  svg {
    height: 3em;
    width: 3em;
    fill: ${theme.red};
  }

  @media only screen and (min-width: ${(props) => props.theme.desktop}) {
    align-items: flex-start;
    width: 80%;
    margin: 0 auto;
    grid-template-columns: 2fr 10fr;
    p {
      line-height: 22px;
    }
  }
`;

const ErrorMessageComponent = ({ firstText, secondText, thirdText }) => {
  return (
    <StyledErrorBox>
      <div className="icon">
        <MdError />
      </div>
      <div className="error-content">
        {firstText ? (
          <Text fontSize="18px" fontWeight="600">
            {firstText}
          </Text>
        ) : (
          ""
        )}
        {secondText ? (
          <Text color={theme.textGrey} fontSize="14px">
            {secondText}
          </Text>
        ) : (
          ""
        )}
        {thirdText ? (
          <Text color={theme.textGrey} fontSize="14px">
            {thirdText}
          </Text>
        ) : (
          ""
        )}
      </div>
    </StyledErrorBox>
  );
};

export default ErrorMessageComponent;
