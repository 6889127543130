import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { GlobalContext } from "../../Context/GlobalProvider";
import { axios, makeApiRoute, makeAppRoute } from "../../Router";
import { ErrorMessage } from "../CommonStyledComponents/CommonStyledComponents";
import theme from "../../Consts/theme";
import Button from "../Button/Button";
import LoaderDots from "../Loader/LoaderDots";

// styled-components
const StyledForm = styled.form`
  text-align: left;
  width: 100%;

  label {
    display: block;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    color: #a7a7a7;
    margin-bottom: 10px;
  }
  input {
    width: 100%;
    opacity: 0.5;
    border-bottom: 2px solid #191919;
    border-top: none;
    border-left: none;
    border-right: none;
    outline: none;
    color: ${(props) => props.theme.black};
    padding-bottom: 10px;
    display: inline-block;
  }
  input:focus {
    opacity: 1;
  }
  input::placeholder {
    font-size: 12px;
  }
  .button-group {
    margin-top: 25px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
  }

  @media only screen and (min-width: ${(props) => props.theme.laptop}) {
    width: 90%;
    margin: 0 auto;

    .button-group {
      margin-top: 25px;
      flex-direction: row;
      width: 70%;
      margin-left: auto;
      margin-right: auto;
      justify-content: space-between;
    }
  }
`;
const TalentMarketForm = ({ talentMarket }) => {
  const navigate = useNavigate();
  const { popupConfig, setPopupConfig, setPopupDefaultValues } =
    useContext(GlobalContext);
  // states
  const [inputValue, setInputValue] = useState("");
  const [errors, setErrors] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  // function to confirm that the offer is declined
  const onConfirmDeclineOffer = (e) => {
    e.preventDefault();
    if (inputValue === "") {
      setErrors("Field is required");
      return;
    } else {
      const dataToUpdate = {
        offerId: talentMarket.id,
        status: false,
        comment: inputValue || "",
      };
      setIsLoading(true);
      axios
        .put(makeApiRoute("updateTalentMarket"), dataToUpdate)
        .then((response) => {
          if (response.status === 200) {
            setIsLoading(false);
            setPopupConfig({
              ...popupConfig,
              show: true,
              title: "Thank you for confirming!",
              subTitle: "We hope that next offers will be better fit for you.",
              bodyClassname: "action-buttons center",
              maxWidth: "500px",
              body: (
                <Button
                  text="Back to Home page"
                  variant="blueBtn"
                  onClick={() => {
                    navigate(makeAppRoute("home"));
                    setPopupDefaultValues()
                  }}
                />
              ),
            });
            setInputValue("");
          } else {
            setPopupConfig({
              ...popupConfig,
              show: true,
              subTitle: "",
              confirmation: false,
              maxWidth: "500px",
              title: "Something Unexpected Happened. Please try again later!",
              bodyClassname: "action-buttons",
              body: (
                <Button
                  text="Close"
                  variant="blueBtn"
                  onClick={() => setPopupDefaultValues()}
                />
              ),
              closeButton: {
                show: true,
                action: () => {
                  setPopupDefaultValues();
                },
              },
            });
            throw new Error("Bad Request");
          }
        })
        .catch(function (error) {
          setPopupConfig({
            ...popupConfig,
            show: true,
            subTitle: "",
            confirmation: false,
            maxWidth: "500px",
            title: "Something Unexpected Happened. Please try again later!",
            bodyClassname: "action-buttons",
            body: (
              <Button
                text="Close"
                variant="blueBtn"
                onClick={() => setPopupDefaultValues()}
              />
            ),
            closeButton: {
              show: true,
              action: () => {
                setPopupDefaultValues();
              },
            },
          });
          throw error.response;
        });
    }
  };

  return (
    <StyledForm>
      <label>Why are you declining this offer?</label>
      <input
        placeholder="Type here..."
        value={inputValue}
        onChange={(e) => {
          setInputValue(e.target.value);
          setErrors("");
        }}
      />
      {errors ? <ErrorMessage>{errors}</ErrorMessage> : ""}
      <div className="button-group">
        <Button
          text="Cancel"
          variant="grayBtn"
          onClick={() => setPopupDefaultValues()}
          disabled={isLoading}
        />
        <Button
          text={
            !isLoading ? (
              "Confirm"
            ) : (
              <LoaderDots
                isLoading={true}
                dotsBgColor={theme.lightBlue}
                dotHeight="4px"
                dotWidth="4px"
                buttonSubmit
                dotMargin="8px"
              />
            )
          }
          variant="blueBtn"
          onClick={onConfirmDeclineOffer}
          disabled={isLoading}
        />
      </div>
    </StyledForm>
  );
};
export default TalentMarketForm;
