import React, { useContext, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import styled from "styled-components";

import { makeAppRoute } from "../Router";
import { GlobalContext } from "../Context/GlobalProvider";
import useMediaCustom from "../Hooks/useMediaCustom";
import Button from "../Components/Button/Button";
import Frame from "../Components/Frame/Frame";
import ProgressStep from "../Components/ProgressTracker/ProgressStep";
import NotFound from "../Components/NotFound/NotFound";

// styled-components
const StyledContainer = styled.section`
  width: 100%;
  height: 100vh;
  max-height: 200vh;
  overflow: auto;
  background: #f9f9f9;
  margin-top: -80px;
  display: grid;
  padding: 60px 30px;

  @media only screen and (min-width: ${(props) => props.theme.laptop}) {
    padding: 0;
    place-items: center;
    padding: 0px 30px;
  }
`;
const StyledButtonsGroup = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column-reverse;
  width: 100%;
  margin: 0px auto;

  button {
    margin-top: 20px;
  }

  @media only screen and (min-width: ${(props) => props.theme.desktop}) {
    width: 40%;
  }
`;
const StyledFrameBox = styled.div`
  width: 80%;
  margin: 0 auto;
  display: grid;
  grid-row-gap: 16px;
  @media only screen and (min-width: ${(props) => props.theme.laptop}) {
    width: 70%;
  }
  @media only screen and (min-width: ${(props) => props.theme.desktop}) {
    width: 90%;
    margin: 40px auto 24px;
    display: grid;
    grid-template-columns: repeat(5, 18%);
    grid-column-gap: 2%;
  }
`;
const StyledSkipButton = styled.button`
  border: 0;
  outline: 0;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  text-transform: uppercase;
  color: ${(props) => props.theme.textGrey};
  background: #f9f9f9;
  cursor: pointer;

  :hover {
    color: ${(props) => props.theme.darkLightGray};
  }
`;
const StyledInnerLayout = styled.section`
  @media only screen and (min-width: 1024px) {
    width: 100%;
  }
  @media only screen and (min-width: ${(props) => props.theme.large_desktop}) {
    width: 80%;
  }
`;
const StyledInnerContainer = styled.div`
  text-align: center;
  width: 100%;

  h1 {
    margin-top: 45px;
    font-weight: bold;
    font-size: 18px;
  }

  p {
    width: 100%;
    margin-top: 20px;
    margin-bottom: 30px;
    font-weight: 400;
    font-size: 14px;
    line-height: 1.5;
    color: ${(props) => props.theme.lightGrayText};
  }

  button {
    width: 100%;
  }
  @media only screen and (min-width: 375px) {
    h1 {
      font-size: 20px;
    }
    p {
      font-size: 14px;
    }
  }
  @media only screen and (min-width: 425px) {
    h1 {
      font-size: 22px;
    }
    p {
      font-size: 16px;
    }
  }
  @media only screen and (min-width: ${(props) => props.theme.laptop}) {
    h1 {
      font-size: 24px;
    }
  }

  @media only screen and (min-width: ${(props) => props.theme.desktop}) {
    width: 80%;
    margin: 0 auto;
    h1 {
      margin-top: 0;
      margin-bottom: 24px;
    }
    p {
      width: 80%;
      margin: 0 auto;
    }
    p.mb-120 {
      margin-bottom: 120px;
    }
  }
`;

const steps = [
  "general info",
  "education and employment",
  "program goals and expectations",
  "hiring preferences",
  "skills"
];

const Welcome = () => {
  const navigate = useNavigate();
  const isLaptop = useMediaCustom("(min-width: 768px)");
  const { user, setUser } = useContext(GlobalContext);
  const { pathname } = useLocation();
  const userStatus = user?.group?.status;
  const userFirstName = user?.name?.split(" ")[0]

  useEffect(() => {
    if (user?.onboardingProcessComplete) {
      navigate(makeAppRoute(`home`));
    }
  }, [user?.onboardingProcessComplete]);

  return (
    <>
      {pathname === "/welcome" ? (
        <StyledContainer>
          <StyledInnerLayout>
            {!isLaptop && <ProgressStep progressWidth="0%" />}
            <StyledInnerContainer>
              <h1>
                {userStatus === "PREPARATIONAL PROGRAMME"
                  ? `Welcome ${userFirstName ? userFirstName : ""}!`
                  : `Log your updated information`}
              </h1>

              <p>
                {userStatus === "PREPARATIONAL PROGRAMME"
                  ? "Before you access the digital platform and start with learning,complete your profile by filling in information about yourself and your goals. Later, you can go back and assess whether you have accomplished your goals."
                  : "Update your profile by filling in information about yourself, your goals and your skills"}
              </p>

              <StyledFrameBox>
                {steps.map((step, index) => (
                  <Frame key={index} content={`${index + 1}. ${step}`} />
                ))}
              </StyledFrameBox>

              <p className="mb-120">
                Help us get to know you better so that we can better assist you
                on your journey with Brainster.
              </p>

              {userStatus === "PREPARATIONAL PROGRAMME" ? (
                <StyledButtonsGroup>
                  <Button
                    variant="blueBtn"
                    text="Get started"
                    onClick={() =>
                      navigate(
                        makeAppRoute(`onboarding`, { STEP: "general-info" })
                      )
                    }
                  />
                </StyledButtonsGroup>
              ) : (
                <StyledButtonsGroup>
                  <StyledSkipButton
                    type="button"
                    onClick={() => {
                      setUser((prevState) => ({
                        ...prevState,
                        onboardingProcessComplete: true,
                      }));
                    }}
                  >
                    I'll do it later
                  </StyledSkipButton>
                  <Button
                    variant="blueBtn"
                    text="Take me there"
                    onClick={() =>
                      navigate(
                        makeAppRoute(`onboarding`, { STEP: "general-info" })
                      )
                    }
                  />
                </StyledButtonsGroup>
              )}
            </StyledInnerContainer>
          </StyledInnerLayout>
        </StyledContainer>
      ) : (
        <NotFound textBtn="Back to Onboarding" to={makeAppRoute("welcome")} />
      )}
    </>
  );
};

export default Welcome;
