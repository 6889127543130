import React, { useEffect, useState }  from "react";
import styled from "styled-components";

// styled-components
const StyledLoaderWrap = styled.div`
  display: ${(props) => (props.isLoading ? "flex" : "none")};
  align-items: ${(props) => props.buttonSubmit && "centar"};
  justify-content: center;
  height: ${(props) => props.height && props.height};
  padding-top: ${(props) => !props.buttonSubmit && "80px"};
  background-color: ${(props) => props.bgColor && props.bgColor};

  &.flex-start{
    justify-content: flex-start !important;
    margin-top: 5px;
    margin-left: 5px;
  }

  .dot1 {
    animation: jump 1.6s -0.32s linear infinite;
  }
  .dot2 {
    animation: jump 1.6s -0.16s linear infinite;
  }
  .dot3 {
    animation: jump 1.6s linear infinite;
  }
  @keyframes jump {
    0%,
    80%,
    100% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }
    40% {
      -webkit-transform: scale(2);
      transform: scale(2);
    }
  }
`;
const StyledDot = styled.div`
  background: ${(props) => props.dotsBgColor && props.dotsBgColor};
  width: ${(props) => props.dotWidth && props.dotWidth};
  height: ${(props) => props.dotHeight && props.dotHeight};
  border-radius: 50%;
  // margin will be received as a prop
  margin: ${(props) => props.dotMargin && props.dotMargin};
`;
const LoaderDots = ({ isLoading, dotsBgColor, height, dotHeight, dotWidth, buttonSubmit, dotMargin, bgColor, className}) => {
  const [showLoader, setShowLoader] = useState(false)
  useEffect( () => {
    setShowLoader(isLoading)
  }, [isLoading] )


  return (
    <StyledLoaderWrap
      isLoading={showLoader}
      height={height}
      bgColor={bgColor}
      buttonSubmit={buttonSubmit}
      dotsBgColor={dotsBgColor}
      dotHeight={dotHeight}
      dotWidth={dotWidth}
      dotMargin={dotMargin}
      className={className}
    >
      <StyledDot dotsBgColor={dotsBgColor} dotMargin={dotMargin} dotHeight={dotHeight}  dotWidth={dotWidth} className="dot1"></StyledDot>
      <StyledDot dotsBgColor={dotsBgColor} dotMargin={dotMargin} dotHeight={dotHeight}  dotWidth={dotWidth} className="dot2"></StyledDot>
      <StyledDot dotsBgColor={dotsBgColor} dotMargin={dotMargin} dotHeight={dotHeight}  dotWidth={dotWidth} className="dot3"></StyledDot>
    </StyledLoaderWrap>
  );
};
export default LoaderDots;
