import React, { useContext, useEffect } from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";

import { makeAppRoute, makeExternalLink } from "../../Router";
import { GlobalContext } from "../../Context/GlobalProvider";
import MainMenuLinks from "./MainMenuLinks";
import Button from "../Button/Button";
import theme from "../../Consts/theme";
import Tooltip from "../Tooltip/Tooltip";

const StyledSideMenuContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: ${({ open }) => (open ? "block" : "none")};
  backdrop-filter: blur(${({ open }) => (open ? "4px" : "0")});
  z-index: 9;
`;
const StyledGroupLinks = styled.div`
  border-bottom: 2px solid ${theme.lightGray2};
  padding-bottom: 32px;
  a,
  button {
    display: block;
  }

  &.block-style {
    padding: 32px 0px;
    border-bottom: 0;
  }
`;
const StyledSideMenu = styled.div`
  display: flex;
  flex-flow: column nowrap;
  background-color: #fff;
  position: absolute;
  top: 0;
  right: 0;
  height: 100vh;
  width: 90vw;
  padding-top: 3.5rem;
  transition: transform 0.5s ease-in-out;
  z-index: 200;
  padding: 82px 30px 30px;
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 3px;
  }

  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px #fff;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${theme.textGrey2};
    outline: 1px solid slategrey;
  }

  a.payment-link {
    display: block;
    margin-bottom: 16px;
  }
  a.payment-link button {
    width: 100%;
    max-height: 40px;
  }
  .line-height-24 {
      line-height: 24px;
  }
  @media only screen and (min-width: 375px) {
    width: 80vw;
  }

  @media only screen and (min-width: 550px) {
    width: 45vw;
  }
`;

const StyledNavLink = styled(NavLink)`
  padding-top: 32px;
  color: ${theme.darkGray};
  line-height: 21px;
  font-size: ${theme.large};
  font-weight: 700;

  &.p-bottom {
    padding-bottom: 32px;
  }
  &.border-top {
    border-top: 2px solid ${theme.lightGray2};
  }
  &.active {
    color: ${theme.darkBlue};
  }

  &:hover,
  &:focus {
    color: ${theme.darkBlue};
  }
  &.logout {
    border-top: 2px solid ${theme.lightGray2};
  }
`;
const StyledLogoutBtn = styled.button`
  padding: 32px 0 0 0;
  color: ${(props) => props.theme.darkGray};
  line-height: 21px;
  font-size: ${(props) => props.theme.large};
  font-weight: 700;
  outline: none;
  border: none;
  background-color: ${(props) => props.theme.white};
  text-align: left;
  cursor: pointer;

  &.active {
    color: ${(props) => props.theme.darkBlue};
  }

  &:hover,
  &:focus {
    color: ${(props) => props.theme.darkBlue};
  }
  &.logout {
    border-top: 2px solid #e1e2e7;
  }
`;
const StyledLogo = styled.span`
  text-transform: uppercase;
  color: ${(props) => props.theme.lightLightGray};
  font-size: ${(props) => props.theme.large};
  font-weight: 900;
  display: inline-block;
  margin-top: 8rem;
`;

const SideMenu = ({ open, userId, handleSideMenu, handleShowLogoutPopup }) => {
  const { user } = useContext(GlobalContext);

  const groupHasBrainsterPlusAccess = user?.group?.brainster_plus_access;

  const handleToggleActiveClass = (event) => {
    event?.currentTarget?.classList?.toggle("active");
  };

  useEffect(() => {
    if (open) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }
  }, [open]);

  const handleLogout = () => {
    handleSideMenu();
    handleShowLogoutPopup();
    handleToggleActiveClass();
  };

  return (
    <StyledSideMenuContainer open={open}>
      <StyledSideMenu open={open}>
        {/* ------------ Card Details Button ------------- */}
        {user && user?.order ? (
          <a
            onClick={handleSideMenu}
            className="payment-link"
            href={user?.order}
            rel="noreferrer"
          >
            <Button
              type="button "
              className="card-details-btn"
              variant="lightBlueBtn"
              text="Enter card details"
              icon={
                <svg
                  width="20"
                  height="16"
                  viewBox="0 0 20 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2.3077 15.5C1.80257 15.5 1.375 15.325 1.025 14.975C0.675 14.625 0.5 14.1974 0.5 13.6923V2.3077C0.5 1.80257 0.675 1.375 1.025 1.025C1.375 0.675 1.80257 0.5 2.3077 0.5H17.6923C18.1974 0.5 18.625 0.675 18.975 1.025C19.325 1.375 19.5 1.80257 19.5 2.3077V13.6923C19.5 14.1974 19.325 14.625 18.975 14.975C18.625 15.325 18.1974 15.5 17.6923 15.5H2.3077ZM1.99998 7.5961H18V4.40385H1.99998V7.5961Z"
                    fill={theme.lightBlue}
                  />
                </svg>
              }
            />
          </a>
        ) : (
          ""
        )}
        {/* ------------ Brainster Plus - Heartbeat Link --------------- */}
        <a
         className="payment-link"
          href={makeExternalLink("heartbeatPlatform")}
          target="_blank"
          rel="noreferrer"
        >
          <Tooltip
            text={
              !groupHasBrainsterPlusAccess
                ? "Once you complete your academy program, you’ll unlock Brainster+ courses!"
                : ""
            }
          >
            <Button
              type="button "
              variant="yellowBtn"
              text="Brainster +"
              disabled={groupHasBrainsterPlusAccess ? false : true}
              icon={
                !groupHasBrainsterPlusAccess ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      d="M6 22C5.45 22 4.97917 21.8042 4.5875 21.4125C4.19583 21.0208 4 20.55 4 20V10C4 9.45 4.19583 8.97917 4.5875 8.5875C4.97917 8.19583 5.45 8 6 8H7V6C7 4.61667 7.4875 3.4375 8.4625 2.4625C9.4375 1.4875 10.6167 1 12 1C13.3833 1 14.5625 1.4875 15.5375 2.4625C16.5125 3.4375 17 4.61667 17 6V8H18C18.55 8 19.0208 8.19583 19.4125 8.5875C19.8042 8.97917 20 9.45 20 10V20C20 20.55 19.8042 21.0208 19.4125 21.4125C19.0208 21.8042 18.55 22 18 22H6ZM12 17C12.55 17 13.0208 16.8042 13.4125 16.4125C13.8042 16.0208 14 15.55 14 15C14 14.45 13.8042 13.9792 13.4125 13.5875C13.0208 13.1958 12.55 13 12 13C11.45 13 10.9792 13.1958 10.5875 13.5875C10.1958 13.9792 10 14.45 10 15C10 15.55 10.1958 16.0208 10.5875 16.4125C10.9792 16.8042 11.45 17 12 17ZM9 8H15V6C15 5.16667 14.7083 4.45833 14.125 3.875C13.5417 3.29167 12.8333 3 12 3C11.1667 3 10.4583 3.29167 9.875 3.875C9.29167 4.45833 9 5.16667 9 6V8Z"
                      fill="#65676C"
                    />
                  </svg>
                ) : (
                  ""
                )
              }
            />
          </Tooltip>
        </a>
        <StyledNavLink
          to={makeAppRoute("home")}
          onClick={handleSideMenu}
          className={`border-top p-bottom ${(navData) =>
            navData.isActive ? "active " : ""}`}
        >
          <span>Home</span>
        </StyledNavLink>
        <MainMenuLinks handleSideMenu={handleSideMenu} />
        <StyledGroupLinks>
          <StyledNavLink
            to={makeAppRoute("profile")}
            onClick={handleSideMenu}
            className={(navData) => (navData.isActive ? "active" : "")}
          >
            Profile
          </StyledNavLink>
          <StyledNavLink
            to={makeAppRoute("points", { USER_ID: userId })}
            onClick={handleSideMenu}
            className={(navData) => (navData.isActive ? "active" : "")}
          >
            Student Dashboard
          </StyledNavLink>

          {user && user?.rocketChatUrl && (
            <StyledLogoutBtn
              onClick={() => {
                handleSideMenu();
                handleToggleActiveClass();
              }}
            >
              <a href={user?.rocketChatUrl} target="_blank" rel="noreferrer">
                <span>Rocket Chat</span>
              </a>
            </StyledLogoutBtn>
          )}
        </StyledGroupLinks>
        <StyledLogoutBtn
          type="button"
          onClick={() => {
            handleLogout();
          }}
        >
          Logout
        </StyledLogoutBtn>

        <StyledGroupLinks className="block-style">
          <a
            onClick={handleSideMenu}
            href={makeExternalLink("facebookHiringGroup")}
            target="_blank"
            rel="noreferrer"
          >
            <Button
              type="button "
              variant="blueBtn"
              text="Join hiring group"
              className="mobileBtn"
            />
          </a>
        </StyledGroupLinks>

        <StyledLogo>brainster</StyledLogo>
      </StyledSideMenu>
    </StyledSideMenuContainer>
  );
};
export default SideMenu;
