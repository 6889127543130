import React, { useContext, useEffect, useState } from "react";
import { IoIosArrowRoundBack } from "react-icons/io";
import styled from "styled-components";

import { axios, makeApiRoute } from "../../Router";
import { GlobalContext } from "../../Context/GlobalProvider";
import Button from "../Button/Button";
import AcademyScore from "./AcademyScore";
import CertificateGuideInfo from "./CertificateGuideInfo";
import FinalScore from "./FinalScore";
import PersonalInfo from "./PersonalInfo";
import SendScoreComment from "./SendScoreComment";
import LoaderDots from "../Loader/LoaderDots";

// styled-components
const StyledForm = styled.form`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;
const StyledBody = styled.div`
  width: 100%;
`;
const StyledProgressBar = styled.div`
  width: 100%;
  height: 10px;
  background-color: ${(props) => props.theme.gray};
  border-radius: 15px;
  margin-bottom: 50px;
`;
const StyledProgressBarFilled = styled.div`
  height: 100%;
  border-radius: 15px;
  background: radial-gradient(
    100% 52244.9% at 0.9% 0%,
    #0a08e5 0%,
    #ff0000 100%
  );
`;
const StyledHeader = styled.header`
  position: relative;
  text-align: center;
  margin-bottom: 2.5rem;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  color: #191919;

  .backBtn {
    position: absolute;
    top: 55px;
    left: 0;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    cursor: pointer;

    :hover {
      background-color: ${(props) => props.theme.lightGray2};
    }

    svg {
      font-size: 2.5rem;
    }
  }
`;
const StyledFooter = styled.footer`
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
  width: 100%;
`;

const CertificateForm = ({
  certificateData,
  isGeneratingCertificate,
  setIsGeneratingCertificate,
}) => {
  const { user, popupConfig, setPopupConfig, setUser, setPopupDefaultValues } =
    useContext(GlobalContext);
  // states
  const [step, setStep] = useState(0);
  const [formData, setFormData] = useState({});
  const [error, setError] = useState({
    validateOnBrowser: false,
    validateOnServer: "",
  });
  const [isLoading, setIsLoading] = useState(false);


  const stepTitles = [
    "Before we start...",
    "Personal Info",
    "Academy Scores",
    "Final Score",
    "Send your comment",
  ];

  useEffect(() => {
    setFormData({ ...certificateData });
  }, [certificateData]);

  useEffect(() => {
    if (formData.name === "" && step === 1) {
      setError({ ...error, validateOnBrowser: true });
    } else {
      setError({ ...error, validateOnBrowser: false });
    }
  }, [formData.name, error?.validateOnBrowser, step]);
 

  // conditionally displaying progress steps ----------------------------------------------
  const PageDisplay = () => {
    if (step === 0) {
      return <CertificateGuideInfo setStep={setStep} />;
    } else if (step === 1) {
      return (
        <PersonalInfo
          formData={formData}
          setFormData={setFormData}
          user={user}
          error={error.validateOnBrowser}
          setStep={setStep}
        />
      );
    } else if (step === 2) {
      return <AcademyScore formData={formData} />;
    } else if (step === 3) {
      return <FinalScore formData={formData} />;
    } else if (step === 4) {
      return (
        <SendScoreComment
          error={error.validateOnServer}
          formData={formData}
          value={formData?.student_comment}
          onStudentComment={(comment) =>
            setFormData({ ...formData, student_comment: comment })
          }
          label={"Please inform what should be checked again"}
          placeholder={"Leave your message here..."}
        />
      );
    }
  };
  // ----------------------------- submit the results -----------------------------------
  const handleApproveStudentData = (e) => {
    e?.preventDefault();
    let formStudentData;

    if (step === stepTitles.length - 2) {
      // this is the step where user approves the data without declining
      setIsGeneratingCertificate(true);

      // close the pop up
      setPopupDefaultValues();

      formStudentData = {
        name: formData?.name,
        is_confirmed: true,
      };
    } else if (step === stepTitles.length - 1) {
      // this is the step where user declines the data and sends a comment

      setIsLoading(true);

      formStudentData = {
        name: formData?.name,
        is_confirmed: false,
        student_comment: formData?.student_comment,
      };
    }

    axios
      .put(
        makeApiRoute("certificateResponse", { CERTIFICATE_ID: formData?.id }),
        formStudentData
      )
      .then((response) => {
        let updatedUserData;
        if (step === stepTitles.length - 2) {
          // this is the step where user approves the data without declining
          // loop through user object and update certificate property (object)

          setIsGeneratingCertificate(false);

          for (const property in user) {
            if (property === "certificate") {
              updatedUserData = {
                ...user,
                certificate: {
                  ...user[property],
                  is_confirmed: 1,
                  image_url: response?.data?.image_url,
                },
              };
              setUser(updatedUserData);
            }
          }

        } else if (step === stepTitles.length - 1) {
          // this is the step where user declines the data and sends a comment
          // loop through user object and update certificate property (object)
          setIsLoading(false);

          for (const property in user) {
            if (property === "certificate") {
              updatedUserData = {
                ...user,
                certificate: {
                  ...user[property],
                  is_confirmed: 0,
                  image_url: response?.data?.image_url,
                },
              };
              setUser(updatedUserData);
            }
          }

          // close the pop up
          setPopupDefaultValues()

        }
      })
      .catch((errorMessage) => {
        setIsGeneratingCertificate(false);
        setIsLoading(false);

        // this is the step where user approves the data without declining
        if (step === stepTitles.length - 2) {
            setPopupConfig({
              ...popupConfig,
              show: true,
              subTitle: "",
              confirmation: false,
              maxWidth: "500px",
              title:
                errorMessage.response.status === 422
                  ? `${errorMessage?.response?.data?.message}`
                  : "Something Unexpected Happened. Please try again later!",
              bodyClassname: "action-buttons",
              body: (
                <Button
                  text="Close"
                  variant="blueBtn"
                  onClick={() => setPopupDefaultValues()}
                />
              ),
              closeButton: {
                show: true,
                action: () => {
                  setPopupDefaultValues();
                },
              },
            });

          return;
        }

        // this is the step where user declines the approval and sends a comment, but error occurs
        if (
          step === stepTitles.length - 1 &&
          errorMessage.response.status === 422
        ) {
          !isLoading &&
            setError({
              ...error,
              validateOnServer: errorMessage?.response?.data?.message,
            });
        } else {
          !isLoading &&
            setPopupConfig({
              ...popupConfig,
              show: true,
              subTitle: "",
              confirmation: false,
              maxWidth: "500px",
              title: "Something Unexpected Happened. Please try again later!",
              bodyClassname: "action-buttons",
              body: (
                <Button
                  text="Close"
                  variant="blueBtn"
                  onClick={() => setPopupDefaultValues()}
                />
              ),
              closeButton: {
                show: true,
                action: () => {
                  setPopupDefaultValues();
                },
              },
            });
        }
      });
  };

  return (
    <>
      <StyledHeader>
        {step !== 0 && (
          <StyledProgressBar>
            <StyledProgressBarFilled
              style={{
                width:
                  step === 0
                    ? "0%"
                    : step === 1
                    ? "25%"
                    : step === 2
                    ? "50%"
                    : step === 3
                    ? "75%"
                    : "100%",
              }}
            ></StyledProgressBarFilled>
          </StyledProgressBar>
        )}
        {step === 3 && (
          <span
            className="backBtn"
            onClick={() => setStep((currPage) => currPage - 1)}
          >
            <IoIosArrowRoundBack />
          </span>
        )}
        <h2>{stepTitles[step]}</h2>
      </StyledHeader>

      <StyledForm>
        <StyledBody >{PageDisplay()}</StyledBody>
        {step !== 0 && (
          <StyledFooter>
            <Button
              type={"button"}
              text={step === stepTitles.length - 2 ? "Decline" : "Prev"}
              variant={
                step === stepTitles.length - 2 ? "declineBtn" : "grayBtn"
              }
              onClick={() => {
                if (step !== stepTitles.length - 2) {
                  setStep((currPage) => currPage - 1);
                } else {
                  setStep((currPage) => currPage + 1);
                }
              }}
            />
            <Button
              disabled={isLoading}
              type={"button"}
              text={
                step === stepTitles.length - 2 ? (
                  "Approve"
                ) : step === stepTitles.length - 1 && !isLoading ? (
                  "Send"
                ) : step === stepTitles.length - 1 && isLoading ? (
                  <LoaderDots
                    isLoading={isLoading}
                    dotsBgColor="blue"
                    dotHeight="4px"
                    dotWidth="4px"
                    buttonSubmit
                    dotMargin="4px"
                  />
                ) : (
                  "Next"
                )
              }
              variant={"blueBtn"}
              onClick={() => {
                if (step === 1 && error.validateOnBrowser) {
                  setStep((currPage) => currPage);
                } else if (
                  step !== stepTitles.length - 1 &&
                  step !== stepTitles.length - 2
                ) {
                  setStep((currPage) => currPage + 1);
                }

                if (
                  step === stepTitles.length - 2 ||
                  step === stepTitles.length - 1
                ) {
                  // submit the form and show message
                  handleApproveStudentData();
                }
              }}
            />
          </StyledFooter>
        )}
      </StyledForm>
    </>
  );
};
export default CertificateForm;
