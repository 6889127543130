import React, { useState, useCallback, useEffect } from "react";
import styled from "styled-components";
import useMediaCustom from "../../Hooks/useMediaCustom";

import ToggleCheckbox from "../Checkbox/ToggleCheckbox";

// styled-components
const StyledContainer = styled.div`
  background-color: ${(props) => props.theme.darkGray};
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.35);
  border-radius: 10px 10px 0 0;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  color: ${(props) => props.theme.lightGray2};
  margin-top: 30px;

  @media only screen and (min-width: ${(props) => props.theme.desktop}) {
    background-color: ${(props) => props.theme.darkLightGray};
    border-radius: 80px 80px 0px 0px;
    max-height: 88px;
  }
`;
const StyledHeading = styled.div`
  width: 100%;
  display: flex;
  padding: 12px 16px;
  justify-content: space-between;
  cursor: pointer;

  p {
    @media only screen and (min-width: ${(props) => props.theme.desktop}) {
      display: inline-block;
      font-weight: 700;
      font-size: 14px;
      align-self: center;
      text-transform: uppercase;
      color: ${(props) => props.theme.lightGrayText};
    }
  }

  img {
    @media only screen and (min-width: ${(props) => props.theme.desktop}) {
      display: none;
    }
  }

  @media only screen and (min-width: ${(props) => props.theme.desktop}) {
    padding: 30px 60px;
    width: 60%;
    background-color: ${(props) => props.theme.darkGray};
    cursor: default;
    animation: borderRadius 6s forwards;

    @keyframes borderRadius {
      20% {
        border-radius: 30px 0px;
      }
      50% {
        border-radius: 60px 0px;
      }
      100% {
        border-radius: 80px 0px;
      }
    }
  }
`;
const StyledResource = styled.ul`
  margin-bottom: 20px;
  color: ${(props) =>
    props.isCompleted ? props.theme.lightGrayText : props.theme.lightLightGray};
`;
const StyledOrderNumber = styled.span`
  margin-right: 5px;
  align-self: flex-start;
`;
const StyledResourceTitle = styled.li`
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  margin-bottom: 5px;
  position: relative;
  display: flex;
  span.title {
    display: inline-block;
    max-width: 80%;
  }
  img.done {
    margin-left: 12px;
  }
`;
const StyledMaterialType = styled.li`
  margin-left: 20px;
  margin-bottom: 10px;
  cursor: pointer;

  p {
    display: flex;
    color: ${(props) => props.isActive && props.theme.lightBlue};
    font-size: ${(props) => props.isActive && "18px"};
  }
  p span {
    align-self: center;
  }
  p img {
    width: 20px;
    margin-right: 8px;
  }
`;

const StyledDropDownMenu = styled.div`
  border-top: 3px solid ${(props) => props.theme.black};
  border-radius: 10px;
  width: 100%;
  height: auto;
  max-height: 700px;
  overflow-y: auto;
  display: ${(props) => (props.isOpen ? `flex` : "none")};
  flex-flow: column nowrap;
  padding: 24px;
  position: absolute;
  top: 44px;
  z-index: 999;
  background-color: ${(props) => props.theme.darkGray};
  box-shadow: 0px 8px 30px rgba(0, 0, 0, 0.08);
  animation: ${(props) =>
    props.isOpen ? `dropdown 300ms ease-in-out` : `none`};
  transform-origin: center top;
  &::-webkit-scrollbar {
    width: 4px;
    border-radius: 4px;
  }
  &::-webkit-scrollbar-track-piece {
    background-color: ${(props) => props.theme.darkLightGray};
    border-radius: 4px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${(props) => props.theme.lightGrayText};
    border-radius: 4px;
  }

  @keyframes dropdown {
    0% {
      transform: scaleY(0);
    }
    100% {
      transform: scaleY(1);
    }
  }

  @media only screen and (min-width: ${(props) => props.theme.desktop}) {
    display: flex;
    top: 80px;
    height: auto;
    max-height: 70vh;
    overflow-y: auto;
    border-top: 0;
    border-radius: 0;
  }
`;
const ResourceMenu = ({
  currentSubunit,
  activeMaterial,
  setActiveMaterial,
  hasCompletedCategory,
  onResourceStatusChanged,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const isDesktop = useMediaCustom("(min-width: 992px)");

  useEffect(() => {
    setIsOpen(isOpen);
  }, [isOpen]);

  const handleResourceType = useCallback((resourceType) => {
    if (resourceType === "video-url")
      return (
        <>
          <img src="/images/video-link.svg" alt="Video Icon" />{" "}
          <span>External video</span>
        </>
      );
    else if (resourceType === "text-url")
      return (
        <>
          <img src="/images/text-link.svg" alt="Link Icon" />
          <span>External material</span>
        </>
      );
    else if (resourceType === "video")
      return (
        <>
          <img src="/images/presentation.svg" alt="Link Icon" />{" "}
          <span>Video</span>
        </>
      );
    else if (resourceType === "material")
      return (
        <>
          <img src="/images/download.svg" alt="Download Icon" />{" "}
          <span>Download Materials</span>
        </>
      );
    else if (resourceType === "text")
      return (
        <>
          <img src="/images/plain-text.svg" alt="Text" /> <span>Text</span>
        </>
      );

    return;
  }, []);

  return (
    <StyledContainer>
      <StyledHeading
        onClick={() => {
          if (!isDesktop) {
            if (isOpen) {
              setIsOpen(false);
            } else {
              setIsOpen(true);
            }
          }
          return;
        }}
      >
        <p>Content</p>
        <img src="/images/featured-videos.svg" alt="Video Icon" />
      </StyledHeading>
      <StyledDropDownMenu isOpen={isOpen}>
        {currentSubunit?.resources.length > 0
          ? currentSubunit?.resources.map((resource, index) => (
              <StyledResource
                key={resource.id}
                isCompleted={resource.completed}
              >
                <StyledResourceTitle isCompleted={resource.completed}>
                  <StyledOrderNumber>{index + 1}.</StyledOrderNumber>
                  <span className="title">{resource.title} </span>

                  {resource?.resourceData?.length > 0 ? (
                    <ToggleCheckbox
                      resource={resource}
                      hasCompletedCategory={hasCompletedCategory}
                      onResourceStatusChanged={onResourceStatusChanged}
                    />
                  ) : (
                    ""
                  )}
                </StyledResourceTitle>
                {resource?.resourceData?.map((el) => (
                  <StyledMaterialType
                    key={el.id}
                    isActive={el?.id === activeMaterial?.id}
                    onClick={() => {
                      setActiveMaterial(el);
                    }}
                  >
                    <p onClick={() => setIsOpen(false)}>
                      {el?.id === activeMaterial?.id ? (
                        <img src="/images/play-arrow.svg" alt="Arrow Icon" />
                      ) : (
                        ""
                      )}{" "}
                      {handleResourceType(el.type)}
                    </p>
                  </StyledMaterialType>
                ))}
              </StyledResource>
            ))
          : ""}
      </StyledDropDownMenu>
    </StyledContainer>
  );
};
export default ResourceMenu;
