import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { FaRegCalendarCheck } from "react-icons/fa";
import { GiTwoCoins } from "react-icons/gi";
import styled from "styled-components";

import theme from "../../../../Consts/theme";
import { GlobalContext } from "../../../../Context/GlobalProvider";
import { makeAppRoute } from "../../../../Router";
import { Text } from "../../../../Consts/Text";
import { dateFormater } from "../../../../Consts/PureFunctions";
import StatusLabel from "../../../StatusLabel/StatusLabel";

// styled-components
const StyledContainer = styled.article`
  border: 1px solid ${theme.concreteGray};
  border-radius: 8px;
  background-color: ${theme.white};
  margin-bottom: 20px;
  padding: 18px 14px;
`;
const StyledCardHeader = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 0 8px;
  border-bottom: 1px solid ${theme.concreteGray};

  div.order-title {
    display: flex;
    align-items: center;
  }
  svg {
    width: 25px;
    height: 25px;
    margin-right: 5px;
  }

  @media only screen and (min-width: ${(props) => props.theme.desktop}) {
    margin-left: 24px;
    margin-right: 24px;
  }
`;
const StyledInnerContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 16px 0 0;

  .mb-4 {
    margin-bottom: 4px;
  }

  div.order-info .indicator svg {
    width: 13px;
    height: 13px;
    margin-right: 3px;
  }

  div.order-info:last-child p.indicator {
    text-align: right;
  }

  p.status {
    align-self: center;
  }

  @media only screen and (min-width: ${(props) => props.theme.desktop}) {
    text-align: center;
    margin: 16px 24px 0;
  }
`;

const StyledLink = styled(Link)`
  p {
    color: ${theme.darkBlue2};
    transition:   color 0.2s ease-in-out;
  }

  :hover p {
    color: ${theme.darkBlue};
  }
`;

const OrderCard = ({ orders }) => {
  const { setOrderId } = useContext(GlobalContext);
  const reversedOrders = [...orders]?.reverse();

  const onOrderClick = (_order) => {
    setOrderId(_order.id);
  };

  return reversedOrders?.map((order, index) => {
    let count = index + 1;
    const orderCreatedDate = dateFormater(order.created_at, "/");
    const orderAmount = +order.amount;

    return (
      <StyledContainer key={order.id} onClick={() => onOrderClick(order)}>
        <StyledCardHeader>
          <div className="order-title">
            <Text fontSize="18px" fontWeight={700}>
              Order {reversedOrders?.length - count + 1}
            </Text>
          </div>
          <StyledLink
            to={makeAppRoute("orderDetails", { ORDER_ID: order?.id })}
          >
            <Text color={theme.darkBlue2} fontWeight={600}>See details</Text>
          </StyledLink>
        </StyledCardHeader>
        <StyledInnerContainer>
          <div className="order-info">
            <Text fontSize="14px" fontWeight={500} className="mb-4 indicator">
              {" "}
              <FaRegCalendarCheck />
              <span>Created at</span>
            </Text>
            <Text fontSize="14px" color={theme.lightGrayText}>
              {orderCreatedDate}
            </Text>
          </div>

          <Text fontSize="14px" color={theme.lightGrayText} className="status">
            <StatusLabel status={order?.status} />
          </Text>

          <div className="order-info">
            <Text fontSize="14px" fontWeight={500} className="mb-4 indicator">
              <GiTwoCoins />
              <span>Amount</span>
            </Text>
            <Text fontSize="14px" color={theme.lightGrayText}>
              {orderAmount.toLocaleString()} MKD
            </Text>
          </div>
        </StyledInnerContainer>
      </StyledContainer>
    );
  });
};

export default OrderCard;
