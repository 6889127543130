import React from "react";

import { StyledPageWrapper } from "../Components/CommonStyledComponents/CommonStyledComponents";
import Navbar from "../Components/Navbar/Navbar";
import Footer from "../Components/Footer/Footer";
import StudentDashboard from "../Components/StudentDashboard/StudentDashboard";
import ScrollToTopPage from "../Components/ScrollSpy/ScrollToToPage";
import EventsNotification from "../Components/Events/EventsNotification";

const Points = () => {
  return (
    <StyledPageWrapper>
      <Navbar />
      <EventsNotification />
      <StudentDashboard />
      <ScrollToTopPage />
      <Footer />
    </StyledPageWrapper>
  );
};
export default Points;
