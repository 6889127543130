import React, { useCallback, useState } from "react";
import ReactPlayer from "react-player";
import styled from "styled-components";
import useMediaCustom from "../../Hooks/useMediaCustom";

import { axios, makeApiRoute } from "../../Router";

// styled-components
const StyledVideoContainer = styled.div`
  width: 100%;
  margin-top: 20px;

  iframe, video {
    height: 200px !important;
  }

  @media only screen and (min-width: ${(props) => props.theme.tablet}) {
    iframe, video {
    height: 400px !important;
  }
  }

  @media only screen and (min-width: 769px) {
    iframe, video {
    height: 70vh !important;
  }
  }
  @media only screen and (min-width: 1024px) {
    iframe, video {
      height: 400px !important;
  }
  }
  @media only screen and (min-width: 1440px) {
    height: 600px !important;

    iframe, video {
      height: 100% !important;
  }
  }
  @media only screen and (min-width: 1600px) {
    height: 600px !important;
  }
`;
const StyledVideoInfo = styled.div`
  width: 100%;
  background: ${(props) => props.theme.darkGray};
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.35);
  border-radius: 0px 0px 10px 10px;
  padding: 20px 16px;

  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  color: ${(props) => props.theme.lightGray2};
`;

const defaultState = {
  url: null,
  pip: false,
  playing: false,
  controls: true,
  light: false,
  volume: 0.8,
  muted: false,
  played: 0,
  playedSeconds: 0,
  loaded: 0,
  duration: 0,
  playbackRate: 1.0,
  loop: false,
  player: null,
};
const Video = ({ activeMaterial }) => {
  const isDesktop = useMediaCustom("(min-width: 992px)");
  const [videoPlayerSettings, setVideoPlayerSettings] = useState(defaultState);
  const [videoStatsData, setVideoStatsData] = useState({ start: 0, end: 0 });

  const sendVideoStatsData = (videoStats) => {

    axios
      .post(makeApiRoute("videoStats"), videoStats)
      .then((response) => {

      })
      .catch((error) => {

      });
  };

  const handlePlay = useCallback(() => {
    setVideoPlayerSettings({ ...videoPlayerSettings, playing: true });

    let videoStatsDataNew = videoStatsData;
    videoStatsDataNew.start = parseInt(videoPlayerSettings.playedSeconds);
    setVideoStatsData(videoStatsDataNew);
  }, [videoPlayerSettings, videoStatsData]);

  const handlePause = () => {
    setVideoPlayerSettings({ ...videoPlayerSettings, playing: false });

    let videoStatsDataNew = videoStatsData;
    videoStatsDataNew.end = parseInt(videoPlayerSettings.playedSeconds);
    setVideoStatsData(videoStatsDataNew);

    //We will track only if at least 5 seconds were watched
    if (videoStatsDataNew.end - videoStatsDataNew.start > 5) {
      sendVideoStatsData({
        resource_data_id: activeMaterial.id,
        start: videoStatsDataNew.start,
        end: videoStatsDataNew.end,
      });
    } else {
    }
  };
  const handleProgress = (state) => {
    //We only want to update time slider if we are not currently seeking
    if (!videoPlayerSettings.seeking) {
      setVideoPlayerSettings({
        ...videoPlayerSettings,
        played: state.played,
        playedSeconds: state.playedSeconds,
      });
    }
  };

  // enable picture-in-picure mode
  const handleEnablePIP = () => {
    setVideoPlayerSettings({ ...videoPlayerSettings, pip: true });
  };
  // disable picture-in-picure mode
  const handleDisablePIP = () => {
    setVideoPlayerSettings({ ...videoPlayerSettings, pip: false });
  };

  return (
    <StyledVideoContainer>
      <iframe src={activeMaterial.vimeoUrl} style={{ width: '100%' }} allowFullScreen />
      {/* <ReactPlayer
        url={
          activeMaterial.vimeoUrl
        }
        width="100%"
        height="100%"
        onPlay={handlePlay}
        onPause={handlePause}
        onProgress={handleProgress}
        onEnablePIP={handleEnablePIP}
        onDisablePIP={handleDisablePIP}
        controls={true}
        config={{
          file: {
            attributes: {
              controlsList: "nodownload",
            },
          },
        }}
      /> */}
      {!isDesktop ? <StyledVideoInfo>{activeMaterial?.title}</StyledVideoInfo> : ""}
    </StyledVideoContainer>
  );
};
export default Video;
