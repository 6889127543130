import React from "react";
import styled from "styled-components";

// styled-components
const StyledTextWrap = styled.div`
  width: 100%;
  text-align: left;
  margin-bottom: 25px;
  padding-bottom: 15px;
  border-bottom: 1px solid ${(props) => props.theme.lightLightGray};
`;
const StyledInnerBox = styled.div`
  display: flex;
  align-items: center;
  cursor: not-allowed;

  @media only screen and (min-width: ${(props) => props.theme.desktop}) {
   margin-top: 20px;
  }

`;
const StyledIcon = styled.span`
  svg {
    color: ${(props) => props.theme.darkBlue2};
    font-size: 1.7rem;
  }
`;
const StyledContent = styled.p`
  align-self: center;
  margin-left: 15px;
  font-style: italic;
  font-size: 0.9rem;

  @media only screen and (min-width: ${(props) => props.theme.desktop}) {
   font-size: 20px;
   
  }
`;
const StyledDescription = styled.p`
  margin-bottom: 8px;
  font-size: 12px;
  font-weight: bold;

  @media only screen and (min-width: ${(props) => props.theme.desktop}) {
   font-size: 18px;
  }
`;

const TextField = ({ description, content, icon }) => {
  return (
    <StyledTextWrap>
      <StyledDescription>{description}</StyledDescription>
      <StyledInnerBox>
        <StyledIcon>{icon}</StyledIcon>
        <StyledContent>{content}</StyledContent>
      </StyledInnerBox>
    </StyledTextWrap>
  );
};
export default TextField;
