import React from "react";

import { StyledPageWrapper } from "../Components/CommonStyledComponents/CommonStyledComponents";
import ScrollToTopPage from "../Components/ScrollSpy/ScrollToToPage";
import Navbar from "../Components/Navbar/Navbar";
import Footer from "../Components/Footer/Footer";
import OrderDetailsController from "../Components/ProfilePage/MyPayments/Order/OrderDetailsController";
import EventsNotification from "../Components/Events/EventsNotification";

const OrderDetails = () => {
  return (
    <StyledPageWrapper>
      <Navbar />
      <EventsNotification />
      <OrderDetailsController />
      <ScrollToTopPage />
      <Footer />
    </StyledPageWrapper>
  );
};

export default OrderDetails;
