import React from "react";
import styled from "styled-components";

// styled-components
const StyledContainer = styled.section`
  /* width: 100vw; */
  background: #f9f9f9;
  overflow: hidden;

  &.onboarding {
    padding: 55px 20px;
    margin-top: -80px;
    position: relative;
  }
  &.inner-layout {
    width: 100%;
  }
  &.flex-reverse {
    display: flex;
    align-items: center;
    flex-direction: column-reverse;
  }
  @media only screen and (min-width: ${(props) => props.theme.laptop}) {
    &.inner-layout {
      min-height: 60vh;
      width: 80%;
      margin: 0 auto;
    }
  }
  @media only screen and (min-width: 1024px) {
    &.inner-layout {
      width: 100%;
    }
  }
  @media only screen and (min-width: ${(props) => props.theme.large_desktop}) {
    &.inner-layout {
      width: 80%;
      margin: 0 auto;
    }
  }
  @media only screen and (min-width: ${(props) => props.theme.desktop}) {
    &.onboarding {
      /* padding: 74px; */
    }

    &.inner-layout {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &.flex-reverse {
      display: block;
    }
  }
`;

const PageContainer = ({ children, className }) => {
  return <StyledContainer className={className}>{children}</StyledContainer>;
};

export default PageContainer;
