import { useEffect, useRef } from "react";

export default function useOutsideNodeClick(handler) {

    let domNode = useRef();

    useEffect(() => {
      let handleOutsideClick = (event) => {
        if (!domNode?.current?.contains(event?.target)) {
          handler();
        }
      };
      // Bind the event listener
      document.addEventListener("click", handleOutsideClick);
      
      // clean up function
      return () => {
        document.removeEventListener("click", handleOutsideClick);
      };
    });
  
    return domNode;
    
}
