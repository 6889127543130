import React, { useEffect, useRef, useState } from "react";
import { IoIosCheckmarkCircle } from "react-icons/io";
import styled from "styled-components";

import CustomCheckboxItem from "../Checkbox/CustomCheckboxItem";
import SelectedItem from "./SelectedItem";
import DropdownArrow from "../DropdownArrow/DropdownArrow";
import Label from "../Label/Label";
import Tooltip from "../Tooltip/Tooltip";

// styled-components
const StyledContainer = styled.div`
  position: relative;
  display: flex;
  font-size: 14px;
  width: ${(props) => (props.widthAuto ? "auto" : "100%")};
  border: 1px solid #e1e2e7;
  border-color: ${(props) =>
    props?.errors?.length > 0
      ? props.theme.red
      : props.isOpen
      ? props.theme.lightBlue
      : "#e1e2e7"};
  min-height: 35px;
  background-color: white;
  color: ${(props) => props.theme.textGrey};
  font-size: 16px;
  margin: 5px 0;
  border-radius: 4px;
  padding: 16px;
  background-color: ${(props) =>
    props.hasSelectedOption
      ? `${props.theme.lightGray2}`
      : `${props.theme.white}`};
  cursor: pointer;

  div.dropdown-arrow {
    margin: 0 0 0 10px;
    display: flex;
  }

  &:focus {
    outline: ${(props) => props.theme.lightBlue};
    border-color: ${(props) =>
      props?.errors?.length > 0 ? props.theme.red : props.theme.lightBlue};
  }
  :hover {
    border-color: ${(props) => props.theme.lightBlue};
    outline: ${(props) => props.theme.lightBlue};
  }

`;

const StyledInputSpan = styled.span`
  display: inline-flex;
  gap: 10px;
  align-items: center;
  flex-wrap: wrap;
  flex-grow: 1;
  font-size: 14px;
`;

const StyledUl = styled.ul`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  background-color: ${(props) => props.theme.white};
  border: 1px solid #e1e2e7;
  border-radius: 4px;
  visibility: hidden;
  max-height: 0;
  overflow-y: auto;
  transition: all 0.3s ease-in-out;
  padding: 0 16px;
  gap: 10px;

  span.selected-option {
    display: inline-flex;
  }
  span.selected-option .checkmark-circle {
    align-self: center;
    font-size: 16px;
  }

  &::-webkit-scrollbar {
    width: 4px;
    border-radius: 4px;
  }
  &::-webkit-scrollbar-track-piece {
    background-color: ${(props) => props.theme.lightgray};
    border-radius: 4px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${(props) => props.theme.lightGray2};
    border-radius: 4px;
  }

  &.dropdown-list {
    display: flex;
    max-height: 15em;
    padding: 16px;
    visibility: visible;
  }

`;
const StyledError = styled.p`
  font-weight: bold;
  font-size: 12px;
  height: 14px;
  color: ${(props) => props.theme.red} !important;
  transition: all.2s ease-in-out;
`;
const StyledPlaceholder = styled.p`
  color: ${(props) => props.theme.textGrey};
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
`;
const StyledLabelContainer = styled.div`
  display: block;
  label:not(:first-child) {
    margin-top: 5px;
  }

`;

function MultipleSelect({
  options,
  widthAuto,
  getInputValue,
  defaultValue,
  errors,
  className,
  fieldLabel,
  additionalLabel,
  maximumOptionsToSelect,
}) {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [doNotRecommendMe, setDoNotRecommendMe] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  // ref
  const dropdownRef = useRef();

  const handleTooltipText = (_optionId) => {
    if (selectedOptions.some((item) => item.id === _optionId)) {
      return "Already selected";
    } else if (selectedOptions.length >= maximumOptionsToSelect) {
      return `You have already selected ${maximumOptionsToSelect} options`;
    }
    return "";
  };

  const userDontWantToBeRecommended =
    selectedOptions?.length > 0 &&
    selectedOptions?.some(
      (item) => item.name === "I don't want to be recommended"
    );

  useEffect(() => {
    if (defaultValue?.length > 0) {
      setSelectedOptions([...defaultValue]);
    }
  }, [defaultValue]);

  useEffect(() => {
    if (selectedOptions.length >= maximumOptionsToSelect) {
      setIsOpen(false);
    }
  }, [selectedOptions.length]);

  const handleToggle = () => {
    setIsOpen((prevState) => !prevState);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef?.current &&
        !dropdownRef?.current?.contains(event.target)
      ) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside, true);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside, true);
    };
  }, [dropdownRef]);

  return (
    <div ref={dropdownRef}>
      <StyledLabelContainer>
        <Label label={fieldLabel} className={className} />
        {additionalLabel ? <Label label={additionalLabel} /> : ""}
      </StyledLabelContainer>
      <StyledContainer
        tabIndex={0}
        widthAuto={widthAuto}
        errors={errors}
        defaultValue={defaultValue}
        hasSelectedOption={selectedOptions?.length > 0}
      >
        <StyledInputSpan
          onClick={() => {
            setIsOpen(true);
          }}
        >
          {selectedOptions.length > 0 ? (
            selectedOptions.map((option) => (
              <SelectedItem
                key={option.id}
                hasSelectedOption={selectedOptions?.length > 0}
                optionName={option.name}
                onClick={() => {
                  const filteredArray = selectedOptions.filter(
                    (item) => item.id !== option.id
                  );
                  if (option.name === "I don't want to be recommended") {
                    setDoNotRecommendMe(false);
                  }
                  setSelectedOptions(filteredArray);
                  getInputValue(filteredArray);
                }}
              />
            ))
          ) : (
            <StyledPlaceholder>Please choose...</StyledPlaceholder>
          )}
        </StyledInputSpan>
        <div className="dropdown-arrow">
          <DropdownArrow
            className={!isOpen ? "" : "active"}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              handleToggle();
            }}
          />
        </div>
      </StyledContainer>

      <StyledUl isOpen={isOpen} className={!isOpen ? "" : "dropdown-list"}>
        {options &&
          isOpen &&
          options?.map((option, index) => (
            <Tooltip key={index} text={handleTooltipText(option.id)}>
              <CustomCheckboxItem
                key={index}
                value={option.id}
                label={
                  selectedOptions.some((item) => item.id === option.id) ? (
                    <span className="selected-option">
                      {option.name}{" "}
                      <IoIosCheckmarkCircle className="checkmark-circle" />
                    </span>
                  ) : (
                    <span>{option.name}</span>
                  )
                }
                id={option.id}
                disabled={
                  selectedOptions.some((item) => item.id === option.id) ||
                  selectedOptions.length >= maximumOptionsToSelect ||
                  doNotRecommendMe
                }
                onChange={() => {
                  if (
                    (selectedOptions.length > 0 &&
                      selectedOptions.some((item) => item.id === option.id)) ||
                    selectedOptions.length >= maximumOptionsToSelect
                  ) {
                    return;
                  }
                  if (option.name === "I don't want to be recommended") {
                    setDoNotRecommendMe(true);
                    setSelectedOptions([option]);
                    getInputValue([option]);
                  } else {
                    if (userDontWantToBeRecommended) {
                      const filteredArray = selectedOptions?.filter(
                        (item) => item.name !== "I don't want to be recommended"
                      );
                      setSelectedOptions([...filteredArray, option]);
                      getInputValue([...filteredArray, option]);
                    } else {
                      setSelectedOptions([...selectedOptions, option]);
                      getInputValue([...selectedOptions, option]);
                    }
                  }
                }}
              ></CustomCheckboxItem>
            </Tooltip>
          ))}
      </StyledUl>

      <StyledError>{errors && errors}</StyledError>
    </div>
  );
}

export default MultipleSelect;
