import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";

import { axios, makeApiRoute } from "../../Router";
import { GlobalContext } from "../../Context/GlobalProvider";
import { dateFormater, scrollToTop } from "../../Consts/PureFunctions";
import { ErrorMessage } from "../CommonStyledComponents/CommonStyledComponents";
import Button from "../Button/Button";
import SurveyQuestion from "./SurveyQuestion";
import LoaderDots from "../Loader/LoaderDots";
import theme from "../../Consts/theme";
import ScrollToTopPage from "../ScrollSpy/ScrollToToPage";

// styled-components
const StyledMainContainer = styled.main`
  width: 100%;

  @media only screen and (min-width: ${(props) => props.theme.desktop}) {
    width: 80%;
    margin: 0 auto;
    padding-top: 20px;
  }
`;
const StyledSurveyContent = styled.div`
  padding: 40px 20px;
  background-color: ${(props) => props.theme.white};

  @media only screen and (min-width: ${(props) => props.theme.laptop}) {
    padding: 40px;
  }
`;
const StyledHeading = styled.header`
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 28px;
  color: ${(props) => props.theme.darkGray};
  text-align: center;
  display: flex;
  flex-direction: column;

  @media only screen and (min-width: ${(props) => props.theme.laptop}) {
    width: 80%;
    margin: 0 auto;
    font-size: 24px;
    flex-direction: row;
    justify-content: center;

    span.date-period {
      margin-left: 16px;
    }
  }
`;
const StyledForm = styled.form`
  margin: 30px 0;

  button {
    width: 100%;
    border-radius: 50px;
  }
  p.title {
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 28px;
    padding-bottom: 1em;
    color: ${(props) => props.theme.darkGray};
    text-align: left;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }

  @media only screen and (min-width: ${(props) => props.theme.laptop}) {
    width: 90%;
    margin: 30px;
    padding: 0 30px;

    button {
      width: auto;
      border-radius: 0px;
      margin-left: 5%;
    }
  }
`;

const SurveyContainer = ({ surveyData, onHomeRedirect }) => {
  const questionsArray = surveyData?.questions?.data;
  const instructorsArray = surveyData?.instructors?.data;
  const startDate = surveyData?.survey?.week_start_date;
  const endDate = surveyData?.survey?.week_end_date;
  const surveyId = surveyData?.survey?.id;
  const surveyTitle = surveyData?.survey?.title;

  const { popupConfig, setPopupConfig, user, setUser, setPopupDefaultValues } =
    useContext(GlobalContext);
  // states
  const [answers, setAnswers] = useState([]);
  const [errors, setErrors] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isPromoter, setIsPromoter] = useState(false);

  const numberOfTotalQuestions = questionsArray?.length;

  // if the survey is NPS and if the answer of the last question has rating above 8,
  // then user will be prompted to leave google review
  const isNPS = surveyData?.survey?.survey_type === "NPS";
  const surveyQuestionsArray = surveyData?.questions?.data;
  const lastNPSQuestionId =
    surveyQuestionsArray?.[surveyQuestionsArray?.length - 1]?.id;

  useEffect(() => {
    isNPS &&
      answers?.map((answer) => {
        if (answer.questionId === lastNPSQuestionId && answer.rating > 8)
          return setIsPromoter(true);
      });
  }, [answers]);
  // ------------------------------------------------------------------------------------------------

  const onGoogleReviewRedirect = () => {
    // redirect to the link
    window.open("https://g.page/r/CbJ_0dMiHIOIEB0/review", "_blank");
    // close the popup
    setPopupDefaultValues();
    onHomeRedirect();
  };

  // submit the form
  const onSurveySubmit = (e) => {
    e.preventDefault();

    // if uncompleted form is submitted
    if (answers.length < numberOfTotalQuestions) {
      setErrors("Please answer all questions");
      scrollToTop();
      return;
    }
    // if user has rated <= 3 WITHOUT leaving a comment
    const hasRatedBelowThree = answers?.find(
      (eachAnswer) =>
        eachAnswer?.rating <= 3 &&
        (eachAnswer?.comment === null || !Object.hasOwn(eachAnswer, "comment"))
    );
    if (hasRatedBelowThree) {
      setErrors("Please check your answers");
      scrollToTop();
      return;
    }
    // If user has rated or left a comment WITHOUT selecting an instructor.
    // This is only valid for questions that have SELECT options
    const selectOptionsQuestions = questionsArray?.filter(
      (question) => question?.showSelectOptions
    );
    if (selectOptionsQuestions.length > 0) {
      const optionNotSelected = selectOptionsQuestions?.find((question) => {
        let id = question.id;
        const result = answers.some(
          (item) =>
            +item.questionId === id && !Object.hasOwn(item, "instructorId")
        );
        return result;
      });

      if (optionNotSelected) {
        setErrors("Please check your answers");
        scrollToTop();
        return;
      }
    }

    // if user has selected instructor WITHOUT leaving a rate
    const hasNotRated = answers.find((item) => !Object.hasOwn(item, "rating"));
    if (hasNotRated) {
      setErrors("Please leave a rating");
      scrollToTop();
      return;
    }

    // submit the form
    setErrors("");
    setIsLoading(true);
    let dataToSubmit = { surveyId: `${surveyId}` };
    dataToSubmit.answers = answers;

    axios
      .post(makeApiRoute("submitSurvey"), dataToSubmit)
      .then(function (response) {
        if (response.status === 200) {
          setIsLoading(false);
          setUser({ ...user, activeSurvey: null });
          isNPS && isPromoter
            ? setPopupConfig({
                ...popupConfig,
                show: true,
                title: "Thank you for answering the survey!",
                subTitle:
                  "We appreciate you taking the time to share your feedback of your experience so far! If you'd like to spread the word, follow the link below.",
                maxWidth: "500px",
                bodyClassname: "action-buttons",
                body: (
                  <>
                    <Button
                      text="Leave a review on Google"
                      variant="blueBtn"
                      onClick={onGoogleReviewRedirect}
                    />
                    <Button
                      text="Not Now"
                      variant="greyBtn"
                      onClick={() => {
                        setPopupDefaultValues();
                        onHomeRedirect();
                      }}
                    />
                  </>
                ),
              })
            : setPopupConfig({
                ...popupConfig,
                show: true,
                title: "Thank you for answering the survey!",
                subTitle: "",
                maxWidth: "500px",
                bodyClassname: "action-buttons",
                body: (
                  <>
                    <Button
                      text="Back to homepage"
                      variant="blueBtn"
                      onClick={onHomeRedirect}
                    />
                  </>
                ),
              });
        } else {
          throw new Error("Bad Request");
        }
      })
      .catch(function (error) {
        setIsLoading(false);
        if (error?.response?.status === 400) {
          setPopupConfig({
            ...popupConfig,
            show: true,
            title: (
              <>
                <p>Something went wrong</p>
                <p>Please try again.</p>
              </>
            ),
            subTitle: "",
            maxWidth: "500px",
            bodyClassname: "action-buttons",
            body: (
              <>
                <Button
                  text="Back to homepage"
                  variant="blueBtn"
                  onClick={onHomeRedirect}
                />
              </>
            ),
          });
        } else {
          setPopupConfig({
            ...popupConfig,
            show: true,
            title: error?.response?.data?.error?.message,
            subTitle: "",
            maxWidth: "500px",
            bodyClassname: "action-buttons",
            body: (
              <>
                <Button
                  text="Back to homepage"
                  variant="blueBtn"
                  onClick={onHomeRedirect}
                />
              </>
            ),
          });
        }

        // throw error.response;
      });
  };

  return (
    <StyledMainContainer>
      <StyledSurveyContent>
        <StyledHeading>
          <span>{surveyTitle}</span>
          <span className="date-period">
            ({dateFormater(startDate, ".")} - {dateFormater(endDate, ".")})
          </span>
        </StyledHeading>

        <StyledForm onSubmit={onSurveySubmit}>
          <p className="title">Questions</p>
          {errors !== "" ? <ErrorMessage>{errors}</ErrorMessage> : ""}
          {questionsArray?.map(
            ({ id, question, description, stars, showSelectOptions }) => {
              return (
                <SurveyQuestion
                  key={id}
                  questionId={id}
                  question={question}
                  description={description}
                  stars={stars}
                  showSelectOptions={showSelectOptions}
                  instructorsArray={instructorsArray}
                  answers={answers}
                  setAnswers={setAnswers}
                />
              );
            }
          )}
          <Button
            text={
              !isLoading ? (
                "Submit"
              ) : (
                <LoaderDots
                  isLoading={isLoading}
                  dotsBgColor={theme.lightBlue}
                  buttonSubmit
                />
              )
            }
            variant="blueBtn"
            type="submit"
            disabled={isLoading}
          />
        </StyledForm>
        <ScrollToTopPage />
      </StyledSurveyContent>
    </StyledMainContainer>
  );
};
export default SurveyContainer;
