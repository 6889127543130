import React from "react";

const InputFile = ({ id, name, reference, onChange }) => {
    return(
        <input
            type="file"
            id={id}
            name={name}
            ref={reference}
            style={{ display: "none" }}
            onChange={(e) => {
              onChange(e?.target?.files);
            }}
          />
    )
}
export default InputFile;