import React from "react";

import { StyledPageWrapper } from "../Components/CommonStyledComponents/CommonStyledComponents";
import Navbar from "../Components/Navbar/Navbar";
import ProfilePageContainer from "../Components/ProfilePage/ProfilePageContainer";
import Footer from "../Components/Footer/Footer";
import ScrollToTopPage from "../Components/ScrollSpy/ScrollToToPage";
import EventsNotification from "../Components/Events/EventsNotification";

const Profile = () => {
  return (
    <StyledPageWrapper>
      <Navbar />
      <EventsNotification />
      <ProfilePageContainer />
      <ScrollToTopPage />
      <Footer />
    </StyledPageWrapper>
  );
};
export default Profile;
