import React from "react";

import ProfileSectionContainer from "../Components/ProfilePage/ProfileSectionContainer/ProfileSectionContainer";
import { StyledPageWrapper } from "../Components/CommonStyledComponents/CommonStyledComponents";
import ScrollToTopPage from "../Components/ScrollSpy/ScrollToToPage"
import Navbar from "../Components/Navbar/Navbar";
import Footer from "../Components/Footer/Footer";
import EventsNotification from "../Components/Events/EventsNotification";

const ProfileSection = () => {
  return (
    <StyledPageWrapper>
      <Navbar />
      <EventsNotification />
      <ProfileSectionContainer />
      <ScrollToTopPage />
      <Footer />
    </StyledPageWrapper>
  );
};

export default ProfileSection;
