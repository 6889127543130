import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";

import {  makeAppRoute } from "../Router";
import { isTwentyOrOlder } from "../Consts/PureFunctions";
import { GlobalContext } from "../Context/GlobalProvider";
import Button from "../Components/Button/Button";
import OnboardingStep from "../Components/OnboardingSteps/OnboardingStep";
import PageContainer from "../Components/PageContainer/PageContainer";
import ProgressStep from "../Components/ProgressTracker/ProgressStep";
import useMediaCustom from "../Hooks/useMediaCustom";
import NotFound from "../Components/NotFound/NotFound";

// styled-components
const StyledParentContainer = styled.div`
  background: #f9f9f9;
  height: 100vh;
`;

const StyledProgressStepContainer = styled.div`
  width: 100%;

  @media only screen and (min-width: ${(props) => props.theme.laptop}) {
    width: 80%;
    margin: 0 auto;
  }
  @media only screen and (min-width: 1024px) {
    width: 100%;
  }
  @media only screen and (min-width: ${(props) => props.theme.large_desktop}) {
    width: 90%;
    margin: 0 auto;
  }
`;
const StyledProgressStepLabelContainer = styled.div`
  display: flex;
  margin-bottom: 4px;
`;
const StyledButton = styled.button`
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  border: 0;
  outline: 0;
  background: #f9f9f9;
  animation: fadeIn 0.4s;
  transition: opacity 0.5s;
  opacity: 1;

  cursor: pointer;
  display: flex;
  color: #65676c;
  margin-top: 35px;

  span.d-flex {
    position: relative;
    margin-left: 8px;
    align-self: center;
  }
  span {
    ::after {
      position: absolute;
      content: "";
      width: 0;
      left: 0;
      bottom: -7px;
      background: ${(props) => props.theme.lightGrayText};
      height: 2px;
      transition: 0.3s ease-out;
    }
  }
  :hover span::after,
  :active span::after {
    width: 100%;
  }
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @media only screen and (min-width: ${(props) => props.theme.desktop}) {
    display: none;
  }
`;
const educationSteps = [
  "high-school",
  "higher-education",
  "employment-history",
];
const progressBarSteps = [
  {
    id: 1,
    name: "general-info",
    label: "general info",
  },
  {
    id: 2,
    name: educationSteps,
    label: "education & employment history",
  },
  {
    id: 3,
    name: "program-goals-expectations",
    label: "program goals & expectations",
  },
  {
    id: 4,
    name: "skills",
    label: "skills",
  },
  {
    id: 5,
    name: "hiring-preferences",
    label: "hiring preferences",
  },
  {
    id: 6,
    name: "review",
    label: "review",
  },
];

const onboardingRoutes = [
  "general-info",
  educationSteps,
  "program-goals-expectations",
  "skills",
  "hiring-preferences",
  "review",
];
const StyledProgressStepLabel = styled.p`
  font-weight: 700;
  font-size: 11px;
  line-height: 16px;
  text-transform: capitalize;
  color: ${(props) =>
    props.isPrevious
      ? props.theme.black
      : props.isActive
      ? props.theme.lightBlue
      : "#c5c5c5"};
  width: ${100 / progressBarSteps.length}%;
`;

const StyledFooter = styled.footer`
  width: 50%;
  margin: 0 auto;
  text-align: center;

  :not(:last-child) {
    width: 100%;
    margin: 30px auto 20px;
  }
  @media only screen and (min-width: ${(props) => props.theme.large_desktop}) {
    width: 30%;
  }
`;
const StyledButtonsGroup = styled.div`
  display: grid;
  justify-content: center;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 16px;
`;
const Onboarding = () => {
  const isDesktop = useMediaCustom("(min-width: 992px)");
  const navigate = useNavigate();
  const { step } = useParams();

  const { user } = useContext(GlobalContext);

  // ref
  const submitFunctionRef = useRef(null);
  // state
  const [progressWidth, setProgressWidth] = useState(null);
  const [currentIndex, setCurrentIndex] = useState();
  const [isUserTwentyYearsAndOver, setIsUserTwentyYearsAndOver] = useState();
  const [userIsInHighSchool, setUserIsInHighSchool] = useState("");


  useEffect(() => {
    // dynamically update the stepper width
    if (user && user.date_of_birth) {
      const isUserInHigherEducation = isTwentyOrOlder(user?.date_of_birth);
      setIsUserTwentyYearsAndOver(isUserInHigherEducation);

    }
    if (user && !user?.user_info?.still_in_high_school) {
      const isUserStillInHighSchool = false
      setUserIsInHighSchool(isUserStillInHighSchool);
    }
    if (user && user?.user_info?.still_in_high_school) {
      const isUserStillInHighSchool = user?.user_info?.still_in_high_school
      setUserIsInHighSchool(isUserStillInHighSchool);
    }

    let currentStep;
    let width;

    if (educationSteps.find((eduStep) => eduStep === step)) {
      currentStep = educationSteps;
    } else {
      currentStep = onboardingRoutes.find((route) => route === step);
    }

    const index = onboardingRoutes.indexOf(currentStep);

    if (step === "high-school") {
      width = ((index + 0.3) / progressBarSteps.length) * 100;
    } else if (step === "higher-education") {
      width = ((index + 0.6) / progressBarSteps.length) * 100;
    } else if (step === "employment-history") {
      width = ((index + 1) / progressBarSteps.length) * 100;
    } else {
      width = ((index + 1) / progressBarSteps.length) * 100;
    }

    setProgressWidth(width);
    setCurrentIndex(index);
  }, [step, user]);



  const onBackButton = () => {
    if (step === "general-info") {
      navigate(makeAppRoute("welcome"));
    } else {
      let currentStep;
      if (educationSteps.find((eduStep) => eduStep === step)) {
        if (step !== "high-school") {
          navigate(
            makeAppRoute(`onboarding`, {
              STEP: educationSteps[educationSteps.indexOf(step) - 1],
            })
          );
        } else {
          currentStep = educationSteps;
          navigate(
            makeAppRoute(`onboarding`, {
              STEP: onboardingRoutes[
                onboardingRoutes?.indexOf(currentStep) - 1
              ],
            })
          );
        }
      } else {
        if (
          step === "program-goals-expectations" &&
          userIsInHighSchool === "No"
        ) {
          const prevStep =
            onboardingRoutes[onboardingRoutes.indexOf(step) - 1][2];
          navigate(
            makeAppRoute(`onboarding`, {
              STEP: prevStep,
            })
          );
        } else if (
          step === "program-goals-expectations" &&
          user &&
          userIsInHighSchool === "Yes"
        ) {
          const prevStep =
            onboardingRoutes[onboardingRoutes.indexOf(step) - 1][0];
          navigate(
            makeAppRoute(`onboarding`, {
              STEP: prevStep,
            })
          );
        } else if (
          isUserTwentyYearsAndOver &&
          step === "program-goals-expectations"
        ) {
          const prevStep =
            onboardingRoutes[onboardingRoutes.indexOf(step) - 1][2];
          navigate(
            makeAppRoute(`onboarding`, {
              STEP: prevStep,
            })
          );
        } else {

          currentStep = onboardingRoutes.find((route) => route === step);
          navigate(
            makeAppRoute(`onboarding`, {
              STEP: onboardingRoutes[
                onboardingRoutes?.indexOf(currentStep) - 1
              ],
            })
          );
        }
      }
    }
  };

  return (
    <StyledParentContainer>
      {educationSteps.find((eduStep) => eduStep === step) ||
      onboardingRoutes.find((route) => route === step) ||
      step === "finish" ||
      step === "review" ? (
        <PageContainer className="onboarding flex-reverse">
          {isDesktop && step !== "finish" ? (
            <StyledProgressStepContainer>
              <StyledProgressStepLabelContainer>
                {progressBarSteps.map((eachStep, index) => {
                  return (
                    <StyledProgressStepLabel
                      key={step.id}
                      isPrevious={index < currentIndex }
                      isActive={(eachStep.name === step) || (eachStep.name.includes(step))}
                    >
                      {eachStep.label}
                    </StyledProgressStepLabel>
                  );
                })}
              </StyledProgressStepLabelContainer>

              <ProgressStep width="100%" progressWidth={`${progressWidth}%`} />
            </StyledProgressStepContainer>
          ) : (
            ""
          )}
          {step !== "finish" ? (
            <StyledButton onClick={onBackButton}>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12 4L13.05 5.075L6.875 11.25L20 11.25L20 12.75L6.875 12.75L13.05 18.925L12 20L4 12L12 4Z"
                  fill="#65676C"
                />
              </svg>
              <span className="d-flex">
                <span>Go back to the previous section</span>
              </span>
            </StyledButton>
          ) : (
            ""
          )}

          <PageContainer className="inner-layout">
            <OnboardingStep
              submitFunctionRef={submitFunctionRef}
              step={step}
              progressBarSteps={progressBarSteps}
              onboardingRoutes={onboardingRoutes}
              progressWidth={progressWidth}
              educationSteps={educationSteps}
            />
          </PageContainer>

          {isDesktop && step !== "finish" ? (
            <StyledFooter>
              <StyledButtonsGroup>
                <Button
                  variant="grayBtn"
                  type="button"
                  text="Back to the previous section"
                  onClick={onBackButton}
                />
                <Button
                  variant="blueBtn"
                  type="submit"
                  text={step === "review" ? "Finish" : "Next"}
                  onClick={() => submitFunctionRef.current()}
                />
              </StyledButtonsGroup>
            </StyledFooter>
          ) : (
            ""
          )}
        </PageContainer>
      ) : (
        <NotFound textBtn="Back to Onboarding" to={makeAppRoute("welcome")} />
      )}
    </StyledParentContainer>
  );
};

export default Onboarding;
