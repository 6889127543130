import React from "react";
import styled from "styled-components";
import { useQuery } from "@apollo/client";

import { GET_STUDENT_FILES_STATUS } from "../../../Consts/GraphqlQueries";
import AcademyCertificate from "./FileSections/AcademyCertificate";
import ErrorMessageComponent from "../../ErrorMessageComponent/ErrorMessageComponent";
import LoaderDots from "../../Loader/LoaderDots";
import theme from "../../../Consts/theme";
import MobileHeader from "../MobileHeader/MobileHeader";
import AcademyContractContainer from "./FileSections/AcademyContractContainer";
import ModuleAchievementContainer from "./FileSections/ModuleAchievementContainer";
import BrainsterPlusAchievementsContainer from "./FileSections/BrainsterPlusAchievementsContainer";

// styled-components
const StyledContainer = styled.section`
  min-height: 55vh;
`;
const StyledLoaderBox = styled.div`
  height: 60vh;
  width: 100%;
  display: flex;
  justify-content: center;

  .loader {
    padding-top: 0px;
  }
`;

const MyFiles = () => {
  //  ----------------------------------------------------------------
  // GRAPHQL GET REQUESTp
  const {
    loading: queryLoading,
    error: queryError,
    data: userFilesData,
  } = useQuery(GET_STUDENT_FILES_STATUS, {
    fetchPolicy: "cache-and-network",
  });

  // ----------------------------------------------------------------
  // VARIABLES
  const moduleAchievements = userFilesData?.user?.achievements;

  // -------------------------------------------------------------------------------
  // ERRORS

  if (queryError)
    return (
      <ErrorMessageComponent
        firstText="Oops!"
        secondText="Something went wrong while fetching your data."
        thirdText="Please try again later."
      />
    );

  // -------------------------------------------------------------------------------
  // LOADING
  if (queryLoading)
    return (
      <StyledLoaderBox>
        <LoaderDots
          className="loader"
          dotsBgColor={theme.darkBlue}
          dotHeight="8px"
          dotWidth="8px"
          dotMargin="8px"
          isLoading={queryLoading}
        />
      </StyledLoaderBox>
    );

  // -------------------------------------------------------------------------------
  // MAIN COMPONENT

  return (
    <div>
      <MobileHeader />
      <StyledContainer>
        {/* ----------------- ACADEMY CONTRACT ------------------ */}

        <AcademyContractContainer userFilesData={userFilesData} />

        {/* ----------------- MODULE ACHIEVEMENTS (ATTENDANCE RATE) ------------------ */}

        <ModuleAchievementContainer moduleAchievements={moduleAchievements} />

        {/* ----------------- MODULE ACHIEVEMENTS (BRAINSTER PLUS)------------------ */}

        <BrainsterPlusAchievementsContainer moduleAchievements={moduleAchievements} />

        {/* ----------------- ACADEMY CERTIFICATE------------------ */}
        <AcademyCertificate userFilesData={userFilesData} />
      </StyledContainer>
    </div>
  );
};

export default MyFiles;
