import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/client";
import styled from "styled-components";

import { makeAppRoute } from "../../Router";
import { StyledForm } from "../CommonStyledComponents/CommonStyledComponents";
import { GlobalContext } from "../../Context/GlobalProvider";
import { GET_PERSONAL_INFO, UpdateUser } from "../../Consts/GraphqlQueries";
import { Text } from "../../Consts/Text";
import theme from "../../Consts/theme";
import textPages from "../../Consts/textPages.json";
import useMediaCustom from "../../Hooks/useMediaCustom";
import FormInputGroup from "../FormGroup/FormInputGroup";
import EditProfileImage from "../ProfileImage/EditProfileImage";
import ProfileImageAcronym from "../ProfileImage/ProfileImageAcronym";
import FormSelectGroup from "../FormGroup/FormSelectGroup";
import LoaderDots from "../Loader/LoaderDots";
import SubmitButton from "../Button/SubmitButton";
import MobileHeader from "./MobileHeader/MobileHeader";
import ErrorMessageComponent from "../ErrorMessageComponent/ErrorMessageComponent";

// styled-components
const StyledContainer = styled.section`
  gap: 16px;
  border: 1px solid ${theme.concreteGray};
  border-radius: 16px;
  background-color: ${theme.white};
`;

const StyledFormHeader = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 16px;
  padding: 16px 0;
  border-bottom: 1px solid ${theme.concreteGray};

  @media only screen and (min-width: ${(props) => props.theme.desktop}) {
    margin-left: 24px;
    margin-right: 24px;
  }
`;
const StyledInnerWrap = styled.section`
  width: 100%;

  @media only screen and (min-width: ${(props) => props.theme.desktop}) {
    display: flex;
    width: 90%;

    form {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    .basic-info_img {
      margin-right: 60px;
    }
  }
  @media only screen and (min-width: ${(props) => props.theme.large_desktop}) {
    .basic-info_img {
      margin-right: 80px;
    }
  }
`;

const StyledProfileImageBox = styled.div`
  display: none;
  @media only screen and (min-width: ${(props) => props.theme.desktop}) {
    display: flex;
    align-items: center;
    border-radius: 16px;
    border: 1px solid ${theme.concreteGray};
    background-color: ${theme.white};
    padding: 24px;
    margin-bottom: 16px;

    p.profile-image-label {
      margin-left: 18px;
    }
  }
`;
const StyledInfoText = styled.p`
  font-style: normal;
  font-size: 10px;
  line-height: 14px;
  color: ${(props) => props.theme.lightGrayText};
  margin-bottom: 16px;
`;
const StyledLoaderBox = styled.div`
  height: 60vh;
  width: 100%;
  display: flex;
  justify-content: center;

  .loader {
    padding-top: 0px;
  }
`;
// ----------------------------------------------------------------
// DirtyFields will keep track of what input(s) is/are changed and therefore to enable/disable Save button
let dirtyFields = new Set();

// ----------------------------------------------------------------
// Error messages on input validation
const errorMessages = {
  name: "Please provide your name and surname",
  email: "Please provide your email",
  current_location_id: "Please provide your current location",
  birthplace_id: "Please provide your birthplace",
  phone: "Please provide your phone number",
  date_of_birth: "Please provide your date of birth",
  gender: "Please provide your gender",
};
// Reset error messages
const resetErrors = {
  name: "",
  email: "",
  phone: "",
  current_location_id: "",
  birthplace_id: "",
  birthDate: "",
  gender: "",
};

const BasicInfo = ({
  onboarding,
  submitFunctionRef,
  step,
  onboardingRoutes
}) => {
  const navigate = useNavigate();
  const isDesktop = useMediaCustom("(min-width: 992px)");
  const userObjectRef = useRef();
  const { user, setUser } = useContext(GlobalContext);
  // states
  const [saveBtnDisabled, setSaveBtnDisabled] = useState(true);
  // const [alertMissingValue, setAlertMissingValue] = useState(false);
  const [errors, setErrors] = useState(resetErrors);
  const [hasCompletedMutation, setHasCompletedMutation] = useState(false);
  //  ----------------------------------------------------------------
  // GRAPHQL GET REQUEST
  const {
    loading: queryLoading,
    error: queryError,
    data: queryData,
  } = useQuery(GET_PERSONAL_INFO, {
    fetchPolicy: "cache-and-network",
  });
  //  ----------------------------------------------------------------
  // GRAPHQL POST REQUEST (MUTATION)
  const [
    updateUser,
    {
      data: mutationData,
      loading: mutationLoading,
      error: mutationError,
      called: mutationCalled,
    },
  ] = useMutation(UpdateUser, {
    onCompleted: (data) => {
      handleUserUpdate(data.UpdateUser);
      if (!onboarding) {
        setSaveBtnDisabled(true);
        setHasCompletedMutation(true);
        handleCompletedMutation();
      } else {
        const currentStep = onboardingRoutes.find((eachStep) => eachStep === step);
        navigate(
          makeAppRoute(`onboarding`, {
            STEP: onboardingRoutes[onboardingRoutes.indexOf(currentStep) + 1][0], // This means to navigate to the first element of the educationSteps array, which is subarray in onboardingRoutes
          })
        );
      }
    },
  });
  // ----------------------------------------------------------------
  // VARIABLES
  // formattedGender stores a string that represents the gender value in the desired format ( ex: 'Male' instead of 'MALE' )
  const formattedGender =
    queryData?.user?.gender?.charAt(0).toUpperCase() +
    queryData?.user?.gender?.slice(1).toLowerCase();

  let hasErrorValue = Object.values(errors).some((value) => value !== "");
  const requiredFieldIsEmpty =
    userObjectRef?.current &&
    Object.values(userObjectRef?.current).some((value) => value === "");
  const hasFetchedUser = queryData?.user !== undefined;


  useEffect(() => {
    if (mutationCalled) {
      handleUserUpdate(mutationData?.UpdateUser);
    } else {
      handleUserUpdate(queryData?.user);
    }
  }, [mutationCalled, queryData]);

  useEffect(() => {
    // pass submit function to the ref that is coming from parent where this function is called
    if (submitFunctionRef?.current) {
      submitFunctionRef.current = onFormSubmit;
    }
    if (!mutationCalled &&  hasFetchedUser) {
      userObjectRef.current = queryData?.user;
      handleUserUpdate(queryData?.user);
    }
  }, [queryLoading, mutationLoading, hasFetchedUser]);

  // ----------------------------------------------------------------
  // Update the user object in Global context  after submission and on page load

  const handleUserUpdate = (updatedUserData) => {
    setUser((prevUser) => ({
      ...prevUser,
      ...updatedUserData,
      user_info: {
        ...prevUser?.user_info,
        ...updatedUserData?.user_info,
      },
    }));
  };
  

  // ----------------------------------------------------------------
  // Return hasCompletedMutation to false to remove the style for success message

  const handleCompletedMutation = () => {
    const timer = setTimeout(() => {
      setHasCompletedMutation(false);
    }, 4000);

    // Clear the timeout if the component unmounts
    return () => clearTimeout(timer);
  };

  //  ----------------------------------------------------------------
  // ON INPUT CHANGE

  const onInputChange = (_inputName, _value) => {

    if (
      _inputName === "current_location_id" ||
      _inputName === "birthplace_id"
    ) {
      userObjectRef.current = {
        ...userObjectRef.current,
        user_info: {
          ...userObjectRef.current.user_info,
          [_inputName]: _value,
        },
      };
    } else {
      userObjectRef.current = {
        ...userObjectRef.current,
        [_inputName]: _inputName === "gender" ? _value.toUpperCase() : _value,
      };
    }

    // ----------------------------------------------------------------
    // Show error messages if required field is empty
    if (_value === "") {
      setErrors({
        ...errors,
        [_inputName]: errorMessages[_inputName],
      });
    }

    if (hasErrorValue && _value !== "") {
      setErrors({ ...errors, [_inputName]: "" });
    }

    // ----------------------------------------------------------------
    /*
     Validate if some of the required fields is empty and if yes, 
     then disable the Save button (ONLY for My Profile Page)
    */

    if (onboarding) return;

    // Conditions below are for My Profile page only
    if (requiredFieldIsEmpty) {
      setSaveBtnDisabled(true);

      return;
    }

    if (userObjectRef?.current?.[_inputName] !== user?.[_inputName]) {
      dirtyFields.add(_inputName);
    } else if (
      typeof _value === "number" &&
      +userObjectRef.current.user_info?.[_inputName] !==
        +user?.user_info?.[_inputName]
    ) {
      dirtyFields.add(_inputName);
    } else {
      dirtyFields.delete(_inputName);
    }

    if (dirtyFields.size > 0) {
      setSaveBtnDisabled(false);
    } else {
      setSaveBtnDisabled(true);
    }
  };

  // ---------------------------------------------------------------
  // FORM SUBMIT

  const onFormSubmit = async (e) => {
    e?.preventDefault();



    const formData = {
      onboarding_step: step || null,
      name: userObjectRef?.current?.name,
      phone: userObjectRef?.current?.phone,
      date_of_birth: userObjectRef?.current?.date_of_birth,
      gender: userObjectRef?.current?.gender,
      user_info: {
        current_location_id:
          userObjectRef?.current?.user_info?.current_location_id,
        birthplace_id: userObjectRef?.current?.user_info?.birthplace_id,
      },
    };

    // ----------------------------------------------------------------
    // Validate empty fields
    if (
      !userObjectRef?.current?.name ||
      !userObjectRef?.current?.user_info?.current_location_id ||
      !userObjectRef?.current?.user_info?.birthplace_id ||
      !userObjectRef?.current?.phone ||
      !userObjectRef?.current?.date_of_birth ||
      !userObjectRef?.current?.gender
    ) {
      setSaveBtnDisabled(true);
      // setAlertMissingValue(true);
      // Update errors state with error messages for empty input fields
      const errorFields = Object.keys(errorMessages).reduce(
        (acc, fieldName) => {
          if (
            fieldName === "current_location_id" ||
            fieldName === "birthplace_id"
          ) {
            if (!userObjectRef?.current?.user_info?.[fieldName]) {
              acc[fieldName] = [errorMessages[fieldName]];
            }
          } else {
            if (!userObjectRef?.current?.[fieldName]) {
              acc[fieldName] = [errorMessages[fieldName]];
            }
          }

          return acc;
        },
        {}
      );

      setErrors(errorFields);

      return;
    }
    // ----------------------------------------------------------------
    // Submit the form
    try {
      await updateUser({ variables: { inputData: formData } });
    } catch (err) {
      console.log(err);
    }
  };

  // -------------------------------------------------------------------------------
  // LOADING ERRORS

  if (queryError || mutationError)
    return (
      <ErrorMessageComponent
        firstText="Oops!"
        secondText="Something went wrong while fetching your data."
        thirdText="Please try again later."
      />
    );

  // -------------------------------------------------------------------------------
  // MAIN COMPONENT

  return (
    <div>
      {!onboarding ? (
        <MobileHeader
          disabled={saveBtnDisabled || mutationLoading}
          hasSubmitted={hasCompletedMutation}
          onClick={onFormSubmit}
          isLoading={mutationLoading}
        />
      ) : (
        ""
      )}

      <StyledProfileImageBox className="basic-info_img">
        {user && user?.profile ? (
          <EditProfileImage
            editProfile
            url={
              user && user?.profile
                ? process.env.REACT_APP_FILES_URL + "/" + user?.profile
                : ""
            }
            className={"basic-info_img"}
          />
        ) : (
          <ProfileImageAcronym
            data={user}
            setData={setUser}
            width="80px"
            height="80px"
            editProfile
          />
        )}
        <Text className="profile-image-label" fontSize="12px" fontWeight={700}>
          Add or edit your profile picture
        </Text>
      </StyledProfileImageBox>

      <StyledContainer>
        <StyledFormHeader>
          <Text fontSize="14px">Personal information</Text>
        </StyledFormHeader>

        <StyledInnerWrap onboarding={onboarding} ref={submitFunctionRef}>
          {queryLoading ? (
            <StyledLoaderBox>
              <LoaderDots
                className="loader"
                dotsBgColor={theme.darkBlue}
                dotHeight="8px"
                dotWidth="8px"
                dotMargin="8px"
                isLoading={queryLoading}
              />
            </StyledLoaderBox>
          ) : (
            <StyledForm onSubmit={onFormSubmit} className="basic-info">
              <StyledInfoText>* Indicates required</StyledInfoText>
              <FormInputGroup
                // alertMissingValue={alertMissingValue.name}
                label={`${textPages.profile.basic_info.basic_info_name} *`}
                type="text"
                name="name"
                isRequired
                errors={errors.name}
                defaultValue={user?.name || ""}
                placeholder={"Type here..."}
                getInputValue={(newValue) => {
                  onInputChange("name", newValue);
                }}
              />

              <FormInputGroup
                // alertMissingValue={alertMissingValue.email}
                label={`${textPages.profile.basic_info.basic_info_email} *`}
                type="email"
                name="email"
                errors={errors.email}
                disabled={user?.email !== ""}
                isRequired
                defaultValue={user?.email || ""}
                placeholder={"example@example.com..."}
              />
              <FormInputGroup
                // alertMissingValue={alertMissingValue.phone}
                label={`${textPages.profile.basic_info.basic_info_phone} *`}
                type="tel"
                errors={errors.phone}
                name="phone"
                isRequired
                defaultValue={user?.phone || ""}
                placeholder={"Ex: +389XX..."}
                getInputValue={(newValue) => {
                  onInputChange("phone", newValue);
                }}
              />
              <FormSelectGroup
                fieldLabel="Current Location *"
                options={queryData?.cities}
                isRequired
                errors={errors.current_location_id}
                selectLabel={"Please choose your current location"}
                defaultValue={
                  (user && user?.user_info?.current_location_id) || "-1"
                }
                getInputValue={(newValue) => {
                  onInputChange("current_location_id", parseInt(newValue));
                }}
              />
              <FormSelectGroup
                fieldLabel="Birth place *"
                options={queryData?.cities}
                errors={errors.birthplace_id}
                isRequired
                selectLabel={"Please choose your birth place"}
                defaultValue={(user && user?.user_info?.birthplace_id) || "-1"}
                getInputValue={(newValue) => {
                  onInputChange("birthplace_id", parseInt(newValue));
                }}
              />
              <FormInputGroup
                // alertMissingValue={alertMissingValue.dob}
                label={`${textPages.profile.basic_info.basic_info_date_of_birth} *`}
                errors={errors.date_of_birth}
                type="date"
                name="date_of_birth"
                defaultValue={user?.date_of_birth || ""}
                isRequired
                placeholder={"Birth date"}
                getInputValue={(newValue) => {
                  onInputChange("date_of_birth", newValue);
                }}
              />
              <FormSelectGroup
                // alertMissingValue={alertMissingValue.gender}
                isRequired
                fieldLabel={`${textPages.profile.basic_info.basic_info_gender} *`}
                name={"gender"}
                errors={errors.gender}
                defaultValue={user?.gender ? formattedGender : "-1"}
                selectLabel={"Please select gender"}
                options={["Male", "Female", "Other"]}
                getInputValue={(newValue) => {
                  onInputChange("gender", newValue);
                }}
              />

              {!onboarding && isDesktop ? (
                <SubmitButton
                  variant="lightBlueBtn"
                  type="submit"
                  disabled={saveBtnDisabled || mutationLoading}
                  hasSubmitted={hasCompletedMutation}
                  onClick={onFormSubmit}
                  isLoading={mutationLoading}
                />
              ) : (
                ""
              )}
            </StyledForm>
          )}
        </StyledInnerWrap>
      </StyledContainer>
    </div>
  );
};
export default BasicInfo;
