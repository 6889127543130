import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { IoIosArrowUp } from "react-icons/io";
import { scrollToTop } from "../../Consts/PureFunctions";

const StyledScroll = styled.span`
  display: ${props => props.showScroll ? "flex" : "none"};
  justify-content: center;
  align-items: center;
  position: fixed;
  font-size: 30px;
  right: 20px;
  bottom: 70px;
  align-items: center;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  background-color: ${props => props.theme.gray};
  justify-content: center;
  z-index: 10;
  padding: 15px;
  cursor: pointer;
  animation: fadeIn .6s;
  transition: opacity .8s;
  opacity: 1;

  &:active {
    opacity: 1;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 0.5;
    }
  }
`;

const ScrollToTopPage = () => {
  const [showScroll, setShowScroll] = useState(false);

  useEffect(() => {
    const checkScrollTop = () => {
      if (!showScroll && window.pageYOffset > 400) {
        setShowScroll(true);
      } else if (showScroll && window.pageYOffset <= 400) {
        setShowScroll(false);
      }
    };

    window.addEventListener("scroll", checkScrollTop);

    return () => window.removeEventListener("scroll", checkScrollTop);
  }, [showScroll]);
  return (
    <StyledScroll showScroll={showScroll}>
      <IoIosArrowUp
        onClick={scrollToTop}
      />
    </StyledScroll>
  );
};
export default ScrollToTopPage;
