import React, { useState } from "react";
import styled from "styled-components";
import { FaCopy, FaCheck } from "react-icons/fa";

import theme from "../../Consts/theme";

// styled-components
const StyledCodeBlockHeader = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: ${theme.lightGrayText};
  color: ${theme.gray};
  padding: 5px 15px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  margin-top: 10px;

  button {
    border: none;
    outline: none;
    background-color: transparent;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    span {
        margin-left: 5px;
    }
  }
`;

const CodeBlockHeader = ({ codeLanguage, code }) => {
  const [isCopied, setIsCopied] = useState(false);
  return (
    <StyledCodeBlockHeader>
      <p>{codeLanguage}</p>
      <button
        onClick={() => {
          setIsCopied(true);
          navigator?.clipboard?.writeText(code);
          setTimeout(() => {
            setIsCopied(false);
          }, 3000);
        }}
      >
        <span>{!isCopied ? <FaCopy /> : <FaCheck />}</span>
        <span>{!isCopied ? "Copy" : "Copied"}</span>
      </button>
    </StyledCodeBlockHeader>
  );
};

export default CodeBlockHeader;
