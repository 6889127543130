import React, { useEffect, useState } from "react";
import styled from "styled-components";

// styled-components
const StyledTextarea = styled.textarea`
  width: 100%;
  outline: none;
  border: 1px solid ${(props) => props.theme.lightLightGray};
  border-color: ${(props) =>
      props?.errors?.length > 0 ? props.theme.red : "#e1e2e7"};
  border-radius: 4px;
  margin: 30px auto;
  color: ${(props) => props.theme.textGrey};
  background-color: ${(props) => (props.value ? `${props.theme.lightGray2}` : `${props.theme.white}`)};
  padding: 10px;
  font-size: 14px;

  .margin-0 {
    margin: 0 auto;
  }
  :hover {
    border-color: ${(props) => props.theme.lightBlue};
  }
  ::placeholder {
    color: ${props => props.theme.textGrey};
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
  }
  :focus {
    outline: none;
    border-color: ${(props) =>
      props?.errors?.length > 0 ? props.theme.red : props.theme.lightBlue};
  }
`;
const StyledError = styled.p`
  font-weight: bold;
  font-size: 12px;
  height: 14px;
  margin-top: 5px;
  color: ${(props) => props.theme.red} !important;
  transition: all.2s ease-in-out;
`;
const Textarea = ({
  name,
  id,
  cols,
  rows,
  placeholder,
  defaultValue,
  className,
  getInputValue,
  onBlur,
  errors,
}) => {
  const [textareaValue, setTextareaValue] = useState("");

  useEffect(() => {
    setTextareaValue(defaultValue || "");
  }, [defaultValue]);

  return (
    <>
      <StyledTextarea
        name={name}
        id={id}
        cols={cols}
        rows={rows}
        errors={errors}
        placeholder={placeholder}
        className={className}
        value={textareaValue}
        onBlur={onBlur}
        onChange={(e) => {
          getInputValue(e.target.value);
          setTextareaValue(e.target.value);
        }}
      />
      <StyledError>{errors && errors}</StyledError>
    </>
  );
};
export default Textarea;
