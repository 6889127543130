import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { BsStars } from "react-icons/bs";
import styled from "styled-components";

import {
  makeAppRoute,
  makeExternalLink,
} from "../../Router";

import { GlobalContext } from "../../Context/GlobalProvider";
import HamburgerIcon from "./HamburgerIcon";
import MainMenuLinks from "./MainMenuLinks";
import useMediaCustom from "../../Hooks/useMediaCustom";
import Button from "../Button/Button";
import ProfileImage from "../ProfileImage/ProfileImage";
import DropDownMenu from "./DropDownMenu";
import Logout from "../UserAuth/Logout";
import theme from "../../Consts/theme";
import Tooltip from "../Tooltip/Tooltip";

const NavbarLink = styled(Link)`
  display: inline-block;
  text-align: center;
  width: 15%;
`;

const StyledButtonBox = styled.div`
  display: flex;
  align-items: center;

  button.card-details-btn {
    padding: 11px 20px;
  }
  button.card-details-btn:hover svg path {
    fill: ${theme.white};
    transition: all 0.3s ease-in-out;
  }
`;
const StyledLinksContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;
const StyledDivider = styled.div`
  width: 1px;
  height: 40px;
  background-color: ${theme.concreteGray};
  display: flex;
  align-items: stretch;
  margin-left: 24px;
`;
const NavbarContainer = styled.nav`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${theme.lightGray3};
  border-bottom: 1px solid ${theme.concreteGray};
  padding: 1rem;
  z-index: 11;

  .external-link {
    margin-left: 16px;
  }
  .external-link button {
    max-height: 40px;
  }

  & .logo {
    width: 150px;
  }
  @keyframes pulsate-button {
    0% {
      box-shadow: 0 0 0 0 rgba(16, 192, 95, 0.7);
    }

    70% {
      box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
    }

    100% {
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
  }
  @media only screen and (min-width: ${(props) => props.theme.laptop}) {
    padding-right: 50px;
  }
  @media only screen and (min-width: ${(props) => props.theme.large_desktop}) {
    justify-content: flex-start;
  }
`;

const StyledAINavLink = styled.a`
  background: linear-gradient(to right, #00d762, #005eff, #6f4c91);
  padding: 10px 20px;
  color: ${theme.white} !important;
  border-radius: 6px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;

  &:hover {
    filter: brightness(110%);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  }
`;

const Navbar = () => {
  const { user, popupConfig, setPopupConfig, setUser } =
    useContext(GlobalContext);
  const isLaptop = useMediaCustom("(min-width: 1440px)");
  // state
  const [toggleProfile, setToggleProfile] = useState(false);

  const aidaEnabledGroups = process.env.REACT_APP_AIDA_ENABLED_GROUPS;
  const hasPaymentOrder = user && user?.order;
  const groupHasBrainsterPlusAccess = user?.group?.brainster_plus_access;

  const handleShowLogoutPopup = () => {
    setPopupConfig({
      ...popupConfig,
      show: true,
      title: "Are you sure you want to log out?",
      confirmation: false,
      maxWidth: "500px",

      body: <Logout />,
    });
    setToggleProfile(false);
  };


  return (
    <NavbarContainer>
      <NavbarLink to={makeAppRoute("home")}>
        <img
          className="logo"
          src="/images/brainster-learn-logo.svg"
          alt="Brainster Logo"
        />
      </NavbarLink>
      {!isLaptop && (
        <HamburgerIcon
          hasPaymentOrder={hasPaymentOrder}
          userId={user?.id}
          handleShowLogoutPopup={handleShowLogoutPopup}
        />
      )}
      {isLaptop && (
        <>
          <StyledDivider></StyledDivider>
          <StyledLinksContainer>
            <div style={{ display: "flex", alignItems: "center" }}>
              <MainMenuLinks />
              {aidaEnabledGroups
                ?.split(",")
                ?.map(Number)
                ?.includes(user?.group?.id) && (
                <StyledAINavLink href={makeAppRoute("chat")} target="_blank">
                  <BsStars /> Aida
                </StyledAINavLink>
              )}
            </div>

            <StyledButtonBox>
              {hasPaymentOrder ? (
                <a
                  className="external-link payment-button"
                  href={user?.order}
                  rel="noreferrer"
                >
                  <Button
                    type="button "
                    className="card-details-btn"
                    variant="lightBlueBtn"
                    text="Enter card details"
                    icon={
                      <svg
                        width="20"
                        height="16"
                        viewBox="0 0 20 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M2.3077 15.5C1.80257 15.5 1.375 15.325 1.025 14.975C0.675 14.625 0.5 14.1974 0.5 13.6923V2.3077C0.5 1.80257 0.675 1.375 1.025 1.025C1.375 0.675 1.80257 0.5 2.3077 0.5H17.6923C18.1974 0.5 18.625 0.675 18.975 1.025C19.325 1.375 19.5 1.80257 19.5 2.3077V13.6923C19.5 14.1974 19.325 14.625 18.975 14.975C18.625 15.325 18.1974 15.5 17.6923 15.5H2.3077ZM1.99998 7.5961H18V4.40385H1.99998V7.5961Z"
                          fill={theme.lightBlue}
                        />
                      </svg>
                    }
                  />
                </a>
              ) : (
                ""
              )}
              {/* Brainster Plus - Heartbeat Link */}
              <a
                className="external-link"
                href={groupHasBrainsterPlusAccess ? makeExternalLink("heartbeatPlatform") : undefined}
                target="_blank"
                rel="noreferrer"
              >
                <Tooltip
                  text={
                    !groupHasBrainsterPlusAccess
                      ? "Once you complete your academy program, you’ll unlock Brainster+ courses!"
                      : ""
                  }
                >
                  <Button
                    type="button "
                    variant="yellowBtn"
                    text="Brainster +"
                    disabled={groupHasBrainsterPlusAccess ? false : true}
                    icon={
                      !groupHasBrainsterPlusAccess ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                        >
                          <path
                            d="M6 22C5.45 22 4.97917 21.8042 4.5875 21.4125C4.19583 21.0208 4 20.55 4 20V10C4 9.45 4.19583 8.97917 4.5875 8.5875C4.97917 8.19583 5.45 8 6 8H7V6C7 4.61667 7.4875 3.4375 8.4625 2.4625C9.4375 1.4875 10.6167 1 12 1C13.3833 1 14.5625 1.4875 15.5375 2.4625C16.5125 3.4375 17 4.61667 17 6V8H18C18.55 8 19.0208 8.19583 19.4125 8.5875C19.8042 8.97917 20 9.45 20 10V20C20 20.55 19.8042 21.0208 19.4125 21.4125C19.0208 21.8042 18.55 22 18 22H6ZM12 17C12.55 17 13.0208 16.8042 13.4125 16.4125C13.8042 16.0208 14 15.55 14 15C14 14.45 13.8042 13.9792 13.4125 13.5875C13.0208 13.1958 12.55 13 12 13C11.45 13 10.9792 13.1958 10.5875 13.5875C10.1958 13.9792 10 14.45 10 15C10 15.55 10.1958 16.0208 10.5875 16.4125C10.9792 16.8042 11.45 17 12 17ZM9 8H15V6C15 5.16667 14.7083 4.45833 14.125 3.875C13.5417 3.29167 12.8333 3 12 3C11.1667 3 10.4583 3.29167 9.875 3.875C9.29167 4.45833 9 5.16667 9 6V8Z"
                            fill="#65676C"
                          />
                        </svg>
                      ) : (
                        ""
                      )
                    }
                  />
                </Tooltip>
              </a>
            </StyledButtonBox>
          </StyledLinksContainer>
          <ProfileImage
            url={
              user && user.profile
                ? process.env.REACT_APP_FILES_URL + "/" + user.profile
                : ""
            }
            user={user}
            toggleProfile={toggleProfile}
            setToggleProfile={setToggleProfile}
            onClick={() => setToggleProfile(!toggleProfile)}
            width="60px"
            height="48px"
          />
        </>
      )}
      {toggleProfile && (
        <DropDownMenu
          toggleProfile={toggleProfile}
          setToggleProfile={setToggleProfile}
          handleShowLogoutPopup={handleShowLogoutPopup}
        />
      )}
    </NavbarContainer>
  );
};

export default Navbar;
