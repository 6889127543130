import React from "react";
import styled from "styled-components";
import SkeletonElement from "./SkeletonElement";

// styled-components

const StyledContainer = styled.div`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  margin: 0 auto;
  position: absolute;
  top: 0;
  left: 0;

  @media only screen and (min-width: ${(props) => props.theme.laptop}) {
    /* left: 30px; */
  }
`;

const SkeletonImage = ({ width, height, variant }) => {
 
  return (
    <StyledContainer width={width} height={height}>
      <SkeletonElement variant={variant} />
    </StyledContainer>
  );
};
export default SkeletonImage;
