import React from "react";
import styled from "styled-components";
import theme from "../../../Consts/theme";

// styled-components
const StyledContainer = styled.article`
  border-radius: 16px;
  border: 1px solid ${theme.concreteGray};
  padding: 16px;
  height: 100%;
  background-color: ${theme.white};

  ${(props) =>
    props.onClick &&
    `
    &:hover {
      border: 1px solid ${theme.darkBlue2};
      cursor: pointer;
    }
  `}

  /* &:hover {
    border: 1px solid ${theme.darkBlue2};
    cursor: pointer;
  } */

  .description {
    display: ${(props) => (props.displayContent ? "block" : "none")};
    margin-top: 8px;
    color: ${theme.lightGrayText};
    font-size: 13px;
    font-weight: 400;
    line-height: 16px;
  }

  @media only screen and (min-width: ${(props) => props.theme.laptop}) {
    padding: 24px;

    .description {
      display: block;
    }
  }
`;
const StyledInnerWrap = styled.div`
  display: ${(props) => (props.displayContent ? "block" : "flex")};
  .title {
    font-size: 16px;
    font-weight: 700;
    align-self: center;
    margin-left: ${(props) => (props.displayContent ? 0 : "8px")};
  }
  img,
  .title {
    display: block;
  }
  @media only screen and (min-width: ${(props) => props.theme.tablet}) {
    display: block;

    img {
      width: 40px;
      height: 40px;
    }
    .title {
      margin-left: 0;
      margin-top: 22px;
    }
  }
`;


const ProfileSectionCard = ({
  sectionData,
  onClick,
  displayContent,
}) => {
  const { icon, title, description } = sectionData;

  return (
    <StyledContainer onClick={onClick} displayContent={displayContent}>

      <StyledInnerWrap displayContent={displayContent}>
        <img src={icon} alt="" />
        <span className="title">{title}</span>
      </StyledInnerWrap>
      <p className="description">{description}</p>
    </StyledContainer>
  );
};

export default ProfileSectionCard;
