import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

import { FiArrowUpLeft } from "react-icons/fi";
import { BiSearchAlt2 } from "react-icons/bi";
import Label from "../Label/Label";
import { latinToCyrillicMap } from "../../Consts/StaticData";

// styled-components
const StyledUl = styled.ul`
  width: 100%;
  display: ${(props) => (props.active ? "block" : "none")};
  height: auto;
  /* position: absolute; */
  background-color: white;
  /* z-index: 999; */
  overflow: auto;
  max-height: 200px;
  border: 1px solid #e1e2e7;
  border-radius: 4px;
  box-shadow: 1px 2px 5px 0px rgba(0, 0, 0, 0.2);
  transition: height 0.4s ease;
`;
const StyledListEl = styled.li`
  padding: 16px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #e1e2e7;
  color: ${(props) => (props.foundQuery ? "red" : "black")};

  :hover {
    background-color: ${(props) => props.theme.lightGray2};
  }
`;

const StyledFormInputGroup = styled.fieldset`
  margin-bottom: 1rem;
  width: 100%;
  position: relative;
  /* z-index: 1; // z-index is for mobile to avoid problems with the side menu when it is opened */
`;
const StyledField = styled.div`
  position: relative;
  /* z-index: 1; // z-index is for mobile to avoid problems with the side menu when it is opened */

  .search-icon {
    position: absolute;
    right: 16px;
    bottom: 50%;
    transform: translateY(50%);
  }
`;
const StyledInput = styled.input`
  border: none;
  padding: 16px;
  outline: none;
  color: black;
  display: block;
  width: 100%;
  border: 1px solid #e1e2e7;
  border-color: ${(props) =>
    props?.errors?.length > 0 ? props.theme.red : "#e1e2e7"};
  min-height: 35px;
  border-radius: 3px;
  padding: ${(props) => (props.widthAuto ? "4px" : "16px")};
  margin: 5px 0;
  border-radius: 4px;
  color: ${(props) => props.theme.textGrey};
  font-size: 16px;
  background-color: ${(props) =>
    props.value ? `${props.theme.lightGray2}` : `${props.theme.white}`};

  :focus {
    outline: ${(props) => props.theme.lightBlue};
    border-color: ${(props) =>
      props?.errors?.length > 0 ? props.theme.red : props.theme.lightBlue};
  }
  :hover {
    border-color: ${(props) => props.theme.lightBlue};
  }
  ::placeholder {
    color: ${(props) => props.theme.textGrey};
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
  }
  outline: none;
  cursor: pointer;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
`;
const StyledError = styled.p`
  font-weight: bold;
  font-size: 12px;
  height: 14px;
  color: ${(props) => props.theme.red} !important;
  transition: all.2s ease-in-out;
`;
const StyledInfo = styled.p`
  font-weight: bold;
  font-size: 12px;
  height: 14px;
  color: ${(props) => props.theme.lightBlue} !important;
  transition: all.2s ease-in-out;
`;
const Autocomplete = ({
  data,
  label,
  placeholder,
  defaultValue,
  getInputValue,
  isRequired,
  errors,
  bilingualFilter,
}) => {
  // states
  const [value, setValue] = useState("");
  const [open, setOpen] = useState(false);
  const [filteredArray, setFilteredArray] = useState([]);
  const [queryFound, setQueryFound] = useState(true);
  // ref
  const dropdownRef = useRef();


  useEffect(() => {
    if (typeof defaultValue === "number") {
      const findSelectedItem = data?.find((item) => +item.id === defaultValue);
      const selectedItemName = findSelectedItem?.name;
      setValue(selectedItemName);
    } else if (typeof defaultValue === "string") {
      setValue(defaultValue);
    } else {
      setValue("");
    }
  }, [defaultValue]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setOpen(false);
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [dropdownRef]);

  useEffect(() => {
    if (!value) return;
    const isQueryFound = filteredArray.find((item) => item.name === value);
    if (isQueryFound && filteredArray.length > 0) {
      setQueryFound(true);
    }
  }, [value]);

  const onInputBlur = () => {
    setQueryFound(true);
  };

  const onInputChange = (e) => {
    const inputValue = e?.target?.value;
    setValue(inputValue);
    setOpen(true);

    if (!isRequired && inputValue === "") {
      setValue("");
      getInputValue("");
      return;
    }
    const isQueryFound = data.find(
      (item) => item?.name?.toLowerCase() === inputValue.toLowerCase()
    );

    if (isQueryFound && filteredArray.length > 0) {
      setQueryFound(isQueryFound);
      getInputValue(isQueryFound.id);
    } else if (!isQueryFound || inputValue === "") {
      setQueryFound(isQueryFound);
      getInputValue(e.target.value);
    }

    if (!isQueryFound) {
      if (bilingualFilter) {
        // Transliterate the input from Latin to Cyrillic
        const cyrillicInput =
          transliterateLatinToCyrillic(inputValue).toLowerCase();

        const filteredArr = data?.filter(({ name }) =>
          name.toLowerCase().includes(cyrillicInput.toLowerCase())
        );

        setFilteredArray(filteredArr);
        setValue(e.target.value);
        getInputValue(e.target.value);
        return;
      }
      {
        const filteredArr = data?.filter(({ name }) =>
          name.toLowerCase().includes(inputValue.toLowerCase())
        );
        setFilteredArray(filteredArr);
        setValue(e.target.value);
        getInputValue(e.target.value);
      }

    } else if (inputValue === "") {
      setFilteredArray([]);
      setOpen(false);
      getInputValue("");
    }
  };

  const transliterateLatinToCyrillic = (latinText) => {
    const multiCharKeys = Object.keys(latinToCyrillicMap).filter(key => key.length > 1).sort((a, b) => b.length - a.length);
    const singleCharKeys = Object.keys(latinToCyrillicMap).filter(key => key.length === 1);
  
    let cyrillicText = '';
    for (let i = 0; i < latinText.length; ) {
      let matched = false;
  
      // Check multi-character keys first
      for (const key of multiCharKeys) {
        if (latinText.substr(i, key.length).toLowerCase() === key.toLowerCase()) {
          cyrillicText += latinToCyrillicMap[key];
          i += key.length;
          matched = true;
          break;
        }
      }
  
      // If no multi-character key match, check single-character keys
      if (!matched) {
        const singleCharKey = latinText[i];
        cyrillicText += latinToCyrillicMap[singleCharKey] || singleCharKey;
        i++;
      }
    }
  
    return cyrillicText;

  };

  const getHighlightedText = (option) => {
    if (bilingualFilter) {
      // Transliterate the input from Latin to Cyrillic
      const cyrillicInput = transliterateLatinToCyrillic(value).toLowerCase();
      const parts = option?.split(new RegExp(`(${cyrillicInput})`, "gi"));
      if (typeof cyrillicInput === "string") {
        return parts.map((part, index) => {
          if (part?.toLowerCase() === cyrillicInput?.toLowerCase()) {
            return (
              <span key={index} style={{ fontWeight: "bold" }}>
                {part}
              </span>
            );
          }
          return part;
        });
      }
    } else {
      const parts = option?.split(new RegExp(`(${value})`, "gi"));
      if (typeof value === "string") {
        return parts.map((part, index) => {
          if (part?.toLowerCase() === value?.toLowerCase()) {
            return (
              <span key={index} style={{ fontWeight: "bold" }}>
                {part}
              </span>
            );
          }
          return part;
        });
      }
    }
  };

  return (
    <>
      <StyledFormInputGroup active={open} value={value}>
        {!queryFound ? (
          <StyledInfo>Haven't found your option? Write and save!.</StyledInfo>
        ) : (
          <Label label={label} />
        )}
        <StyledField value={value}>
          <StyledInput
            errors={errors}
            type="text"
            placeholder={placeholder}
            value={value}
            onChange={onInputChange}
            // onBlur={onInputBlur}
          />
          <BiSearchAlt2 className="search-icon" />
        </StyledField>
        {filteredArray?.length > 0 ? (
          <StyledUl active={open} ref={dropdownRef}>
            {filteredArray?.map((item) => (
              <StyledListEl
                key={item.id}
                onClick={() => {
                  setValue(item.name);
                  setOpen(false);
                  getInputValue(parseInt(item.id));
                }}
              >
                <span>{getHighlightedText(item.name)}</span> <FiArrowUpLeft />
              </StyledListEl>
            ))}
          </StyledUl>
        ) : (
          ""
        )}
        <StyledError>{errors && errors}</StyledError>
      </StyledFormInputGroup>
    </>
  );
};

export default Autocomplete;

