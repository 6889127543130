import React, { useState } from "react";
import styled from "styled-components";

import FilterButton from "../ModulesFilter/FilterButton";
import RadioButton from "../Button/RadioButton";

// styled-components
const StyledModulesFilter = styled.section`
  background: #ffffff;
  box-shadow: 0px 8px 30px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
  margin: 24px 0;
  position: relative;
`;
const StyledModulesFilterHeader = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 11px 24px;

  p {
    font-weight: bold;
    font-size: 16px;
    line-height: 18px;
    color: #191919;
    margin-bottom: 0px;
  }
`;
const StyledButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;
const StyledMenuItems = styled.ul`
  .module {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
    span {
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 28px;
      color: #232426;
      width: 85%;
    }
  }
`;
const StyledDropDownMenu = styled.div`
  border-top: 2px solid #e1e2e7;
  border-radius: 0 0 10px 10px;
  width: 100%;
  height: auto;
  max-height: 700px;
  overflow-y: auto;
  display: ${(props) => (props.show ? `flex` : "none")};
  flex-flow: column nowrap;
  position: absolute;
  top: 40px;
  padding: 20px 16px;

  z-index: 99;
  background-color: ${(props) => props.theme.white};
  box-shadow: 0px 8px 30px rgba(0, 0, 0, 0.08);
  animation: ${(props) => (props.show ? `dropdown 300ms ease-in-out` : `none`)};
  transform-origin: center top;
  &::-webkit-scrollbar {
    width: 4px;
    border-radius: 4px;
  }
  &::-webkit-scrollbar-track-piece {
    background-color: ${(props) => props.theme.lightGray2};
    border-radius: 4px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #e1e2e7;
    border-radius: 4px;
  }

  @keyframes dropdown {
    0% {
      transform: scaleY(0);
    }
    100% {
      transform: scaleY(1);
    }
  }

  @media only screen and (min-width: ${(props) => props.theme.desktop}) {
    display: flex;
    top: 80px;
    max-height: 650px;
    overflow-y: auto;
    border-top: 0;
    border-radius: 0;
  }
`;
const ModulesFilter = ({
  units,
  materialsData,
  slug,
  setUnitsPerCategory,
  setTotalResources,
  setTotalCompletedResources,
  setTotalDuration,
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [filteredUnits, setFilteredUnits] = useState([]);
  
  const allUnitsPerCategory = materialsData
    ? materialsData?.units?.filter((unit) => unit.categorySlug === slug)
    : "";

  const onOpenMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleCheckboxModuleChanged = (e) => {
    const filtered = units?.filter((unit) =>
      unit.id === +e.target.value ? unit : ""
    );

    setFilteredUnits((filteredUnits) => {
      return [...filteredUnits, ...filtered];
    });
  };

  const onApplyFilters = () => {
    let totalR = 0;
    let totalD = 0;
    let totalCompletedArr = [];
    filteredUnits?.forEach((unit) => {
      if (unit?.categorySlug === slug) {
        totalD += unit.duration;
        totalR += unit.totalResources;
        totalCompletedArr = [
          ...totalCompletedArr,
          ...unit.resourceStatus.filter(
            (resource) => resource.completed === true
          ),
        ];
        setTotalResources(totalR);
        setTotalDuration(totalD);
        setTotalCompletedResources(totalCompletedArr.length);
      }

      setUnitsPerCategory(filteredUnits);
    });

    setIsMenuOpen(false);
  };

  const onResetFilters = () => {
    let totalR = 0;
    let totalD = 0;
    let totalCompletedArr = [];
    materialsData?.units?.forEach((unit) => {
      if (unit?.categorySlug === slug) {
        totalD += unit.duration;
        totalR += unit.totalResources;
        totalCompletedArr = [
          ...totalCompletedArr,
          ...unit.resourceStatus.filter(
            (resource) => resource.completed === true
          ),
        ];
        setTotalResources(totalR);
        setTotalDuration(totalD);
        setTotalCompletedResources(totalCompletedArr.length);
      }
    })
    setFilteredUnits([]);
    setUnitsPerCategory(allUnitsPerCategory);
    setIsMenuOpen(false);
  };

  return (
    <StyledModulesFilter>
      <StyledModulesFilterHeader onClick={onOpenMenu}>
        <p>Modules</p>
        <img src="/images/filterIcon.svg" alt="Filter Icon" />
      </StyledModulesFilterHeader>
      <StyledDropDownMenu show={isMenuOpen}>
        <StyledButtonGroup>
          <FilterButton
            buttonContent="Reset Filters"
            icon="/images/clear.svg"
            onClick={onResetFilters}
          />
          <FilterButton
            isApplayFilters
            buttonContent="Apply Filters"
            icon="/images/done.svg"
            onClick={onApplyFilters}
          />
        </StyledButtonGroup>
        <StyledMenuItems>
          {allUnitsPerCategory &&
            allUnitsPerCategory?.map((unit, index) => (
              <div key={unit.id} className="module">
                <span>{index + 1 + "." + unit.title}</span>
                <RadioButton
                  value={unit.id}
                  onChange={handleCheckboxModuleChanged}
                  resetFilters={filteredUnits}
                />
              </div>
            ))}
        </StyledMenuItems>
      </StyledDropDownMenu>
    </StyledModulesFilter>
  );
};

export default ModulesFilter;
