import React from "react";
import styled from "styled-components";

import useMediaCustom from "../../Hooks/useMediaCustom";
import ProgressBar from "./ProgressBar";

// styled-components
const StyledProgressContainer = styled.article`
  background: #ffffff;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.06);
  border-radius: 10px;
  width: 100%;
  padding: 28px 16px;
  margin-bottom: 16px;
  cursor: ${(props) => props.pointer && "pointer"};
  display: flex;
  flex-direction: column;
  pointer-events: all;

  img {
    max-width: 50px;
  }
  @media only screen and (min-width: ${(props) => props.theme.desktop}) {
    padding: 30px;
    flex-direction: row;
  }

`;

const StyledCategoryTitle = styled.h2`

  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  color: #000000;

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  /* number of lines to show */
  line-clamp: 1;
  -webkit-box-orient: vertical;

  :hover {
    display: block;
  }
`;

const StyledCategoryInnerWrap = styled.div`
  width: 100%;
  align-self: center;
  margin-top: 8px;
  @media only screen and (min-width: ${(props) => props.theme.desktop}) {
    margin-left: 18px;
  }
`;

const GeneralProgressTracker = ({
  title,
  percentage,
  icon,
  progressBarContent,
  onClick,
}) => {
  const isLaptop = useMediaCustom("(min-width: 768px)");
  const isDesktop = useMediaCustom("(min-width: 1440px)");


  return (
    <StyledProgressContainer
      onClick={onClick}
      pointer={typeof onClick == "function"}
    >
      <img src={icon} alt={title} />
      <StyledCategoryInnerWrap>
        <StyledCategoryTitle>{title}</StyledCategoryTitle>
        <ProgressBar
          thumbWidth={isDesktop ? "15" : isLaptop ? "18" : "25"}
          percentage={percentage > 100 ? 100 : Math.round(percentage)}
          progressBarContent={progressBarContent}
        />
      </StyledCategoryInnerWrap>
    </StyledProgressContainer>
  );
};
export default GeneralProgressTracker;
