import React from "react";
import styled from "styled-components";
import theme from "../../Consts/theme";

//styled-components
const StyledContainer = styled.span`
  border: 1px solid #e6e7eb;
  border-radius: 8px;
  font-weight: 700;
  font-size: 12px;
  line-height: 1.2;
  text-align: center;
  text-transform: capitalize;
  padding: 20px 10px;
  color: ${theme.black};
  background-color: ${theme.white};
  display: inline-flex;
  justify-content: center;
  align-items: center;
`;

const Frame = ({ content }) => {
  return <StyledContainer>{content}</StyledContainer>;
};

export default Frame;
