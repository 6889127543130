import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import styled from "styled-components";

import { makeAppRoute } from "../../Router";
import { GlobalContext } from "../../Context/GlobalProvider";
import { Text } from "../../Consts/Text";
import {
  StyledForm,
  StyledFormRow,
} from "../CommonStyledComponents/CommonStyledComponents";
import {
  COMPLETE_ONBOARDING,
  GET_ONBOARDING_EXPERIENCE_DATA,
  UpdateUserOnboardingExperience,
} from "../../Consts/GraphqlQueries";
import theme from "../../Consts/theme";
import Label from "../Label/Label";
import RatingStar from "../RatingStar/RatingStar";
import FormSelectGroup from "../FormGroup/FormSelectGroup";
import Textarea from "../Textarea/Textarea";
import FormMultipleSelectGroup from "../FormGroup/FormMultipleSelectGroup";
import ErrorMessageComponent from "../ErrorMessageComponent/ErrorMessageComponent";

// styled-components
const StyledContainer = styled.section`
  gap: 16px;
  border: 1px solid ${theme.concreteGray};
  border-radius: 16px;
  background-color: ${theme.white};
`;
const StyledInnerWrap = styled.section`
  width: 100%;

  @media only screen and (min-width: ${(props) => props.theme.desktop}) {
    display: flex;
    width: 90%;

    .basic-info_img {
      margin-right: 60px;
    }
  }
  @media only screen and (min-width: ${(props) => props.theme.large_desktop}) {
    .basic-info_img {
      margin-right: 80px;
    }
  }
`;
const StyledRatingStarWrap = styled.div`
  display: flex;
  margin-top: 5px;
  width: 100%;
`;
const StyledFormHeader = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 16px;
  padding: 16px 0;
  border-bottom: 1px solid ${theme.concreteGray};

  @media only screen and (min-width: ${(props) => props.theme.desktop}) {
    margin-left: 24px;
    margin-right: 24px;
  }
`;
const StyledInfoText = styled.p`
  font-style: normal;
  font-size: 10px;
  line-height: 14px;
  color: ${(props) => props.theme.lightGrayText};
  margin-bottom: 16px;
`;
const StyledTextareaWrap = styled.fieldset`
  textarea {
    margin: 5px 0 0;
  }

  @media only screen and (min-width: ${(props) => props.theme.desktop}) {
    width: ${(props) => (props.onboarding ? "100%" : "49%")};
  }
`;
const StyledError = styled.p`
  font-weight: bold;
  font-size: 12px;
  height: 14px;
  margin-top: 5px;
  color: ${(props) => props.theme.red} !important;
  transition: all.2s ease-in-out;
`;

const stars = 10;

const heardAboutBrainsterOptions = [
  "Student at Brainster",
  "Mentor at Brainster",
  "Employee at Brainster",
  "Social media",
];
// ----------------------------------------------------------------
// Error messages on input validation
const errorMessages = {
  rating: "Please provide your rating about the onboarding process",
  heard_about_brainster: "Please provide how you heard about Brainster",
  heard_about_brainsterTextarea: "Please provide your answer",
  time_following_brainster: "Please provide how long you follow Brainster",
  reasons_for_choosing_brainster:
    "Please provide your reasons for choosing Brainster ",
  reason: "Please provide your answer",
};
// Reset error messages
const resetErrors = {
  rating: "",
  heard_about_brainster: "",
  time_following_brainster: "",
};

const OnboardingReview = ({
  onboarding,
  submitFunctionRef,
  onboardingRoutes,
}) => {
  const navigate = useNavigate();
  const userObjectRef = useRef();
  const { step } = useParams();
  const { user, setUser } = useContext(GlobalContext);
  // states
  const [errors, setErrors] = useState(resetErrors);
  const [hoveredStarIndex, setHoveredStarIndex] = useState(undefined);
  const [isOnboardingCompleted, setIsOnboardingCompleted] = useState(false);
  const [hasHeardAboutBrainster, setHasHeardAboutBrainster] = useState(false);
  const [addAdditionalReasons, setAddAdditionalReasons] = useState(false);
  const [currentAnswer, setCurrentAnswer] = useState({
    rating: "",
  });

  //  ----------------------------------------------------------------
  // GRAPHQL GET REQUEST
  const {
    loading: queryLoading,
    error: queryError,
    data: queryData,
  } = useQuery(GET_ONBOARDING_EXPERIENCE_DATA, {
    fetchPolicy: "cache-and-network",
  });

  //  ----------------------------------------------------------------
  // GRAPHQL POST REQUEST (MUTATION)
  const [
    updateUser,
    {
      data: mutationData,
      loading: mutationLoading,
      error: mutationError,
      called: mutationCalled,
    },
  ] = useMutation(UpdateUserOnboardingExperience, {
    onCompleted: (data) => {
      setIsOnboardingCompleted(true);
      navigate(
        makeAppRoute(`onboarding`, {
          STEP: "finish",
        })
      );
    },
  });
  // ----------------------------------------------------------------
  // VARIABLES
  const hasFetchedUser = queryData?.user !== undefined;

  useEffect(() => {
    // pass submit function to the ref that is coming from parent where this function is called
    if (submitFunctionRef?.current) {
      submitFunctionRef.current = handleFormSubmit;
    }
    if (hasFetchedUser) {
      userObjectRef.current = queryData?.user;
    }
  }, [queryLoading, mutationLoading, hasFetchedUser, queryData]);

  // ----------------------------------------------------------------
  // Update the user object in Global context after submission and on page load

  const handleUserUpdate = (updatedUserData) => {
    setUser((prevUser) => ({
      ...prevUser,
      ...updatedUserData,
      user_info: {
        ...prevUser?.user_info,
        ...updatedUserData?.user_info,
      },
    }));
  };

  // useEffect(() => {
  //   if (isOnboardingCompleted) {
  //     const formDataUser = {
  //       onboarding_process_complete: true,
  //     };
  //     axios
  //       .put(makeApiRoute("student"), formDataUser)
  //       .then((response) => {
  //         setUser(response?.data);
  //         navigate(
  //           makeAppRoute(`onboarding`, {
  //             STEP: "finish",
  //           })
  //         );
  //       })
  //       .catch((error) => {
  //         handleRequestError();
  //       });
  //   }
  // }, [isOnboardingCompleted]);

  const handleHoverRatingStar = (index) => {
    setHoveredStarIndex(index);
  };

  //  ------------------------------------------------------------------
  // ON INPUT CHANGE
  const onInputChange = (_inputName, _value) => {
    if (_inputName === "reason") {
      userObjectRef.current = {
        ...userObjectRef.current,
        user_info: {
          ...userObjectRef.current.user_info,
          [_inputName]: _value,
        },
      };
    } else {
      userObjectRef.current = {
        ...userObjectRef.current,
        [_inputName]: _value,
      };
    }

    const userHasSelectedOther =
      _inputName === "reasons_for_choosing_brainster" &&
      _value?.find((item) => item?.name === "Other");

    if (_inputName === "heard_about_brainster" && _value === "Other") {
      setHasHeardAboutBrainster(true);
    } else if (
      _inputName === "heard_about_brainster" &&
      heardAboutBrainsterOptions.includes(_value)
    ) {
      setHasHeardAboutBrainster(false);
    }
    if (_inputName !== "heard_about_brainster" && hasHeardAboutBrainster) {
      setHasHeardAboutBrainster(true);
    }

    if (
      _inputName === "reasons_for_choosing_brainster" &&
      userHasSelectedOther
    ) {
      setAddAdditionalReasons(true);
    } else if (
      _inputName === "reasons_for_choosing_brainster" &&
      !userHasSelectedOther
    ) {
      setAddAdditionalReasons(false);
    }

    if (_inputName === "rating")
      setCurrentAnswer({ ...currentAnswer, rating: _value });

    if (_value === "") {
      setErrors({
        ...errors,
        [_inputName]: errorMessages[_inputName],
      });
    } else {
      setErrors({ ...errors, [_inputName]: "" });
    }
  };

  //  ------------------------------------------------------------------
  // ON FORM SUBMIT
  const handleFormSubmit = async (e) => {
    e?.preventDefault();

    const reasonsForChoosingBrainsterTrimmed =
      userObjectRef?.current?.reasons_for_choosing_brainster &&
      userObjectRef?.current?.reasons_for_choosing_brainster?.map(
        ({ __typename, ...reasons }) => reasons
      );

    const formData = {
      onboarding_process_complete: true,
      onboarding_experience: {
        rating: userObjectRef?.current?.rating,
        impression: userObjectRef?.current?.impression,
        heard_about_brainster:
          userObjectRef?.current?.heard_about_brainsterTextarea ||
          userObjectRef?.current?.heard_about_brainster,
        time_following_brainster:
          userObjectRef?.current?.time_following_brainster,
      },
      user_info: {
        reason: userObjectRef?.current?.user_info?.reason || null,
      },
      reasons_for_choosing_brainster:
        reasonsForChoosingBrainsterTrimmed || null,
    };


    const userHasSelectedOther =
      userObjectRef?.current?.reasons_for_choosing_brainster?.some(
        (item) => item?.name === "Other"
      );

    // validate empty fields
    if (
      !userObjectRef?.current?.rating ||
      !userObjectRef?.current?.heard_about_brainster ||
      !userObjectRef?.current?.time_following_brainster ||
      (userObjectRef?.current?.heard_about_brainster === "Other" &&
        !userObjectRef?.current?.heard_about_brainsterTextarea) ||
      (userHasSelectedOther && !userObjectRef?.current?.user_info?.reason) ||
      !userObjectRef?.current?.reasons_for_choosing_brainster?.length
    ) {
      // // Update errors state with error messages for empty input fields
      const errorFields = Object.keys(errorMessages).reduce(
        (acc, fieldName) => {
          if (fieldName === "reason") {
            if (!userObjectRef?.current?.user_info?.[fieldName]) {
              acc[fieldName] = [errorMessages[fieldName]];
            }
          } else {
            if (!userObjectRef?.current?.[fieldName]) {
              acc[fieldName] = [errorMessages[fieldName]];
            }
          }

          return acc;
        },
        {}
      );
      setErrors(errorFields);
      return;
    }

    // ----------------------------------------------------------------
    // Submit the form
    try {
      await updateUser({ variables: { inputData: formData } });
    } catch (err) {
      console.log(err);
    }
  };

  // -------------------------------------------------------------------------------
  // LOADING ERRORS

  if (queryError || mutationError)
    return (
      <ErrorMessageComponent
        firstText="Oops!"
        secondText="Something went wrong while fetching your data."
        thirdText="Please try again later."
      />
    );

  // -------------------------------------------------------------------------------
  // MAIN COMPONENT

  return (
    <StyledContainer>
      <StyledFormHeader>
        <Text fontSize="14px">Review</Text>
      </StyledFormHeader>
      <StyledInnerWrap>
        <StyledForm onSubmit={handleFormSubmit} ref={submitFunctionRef}>
          <StyledInfoText>* Indicates required</StyledInfoText>
          <div style={{ marginBottom: "1rem", width: "100%" }}>
            <Label label="How would you rate the admissions process *" />
            <StyledRatingStarWrap>
              {Array(stars)
                ?.fill()
                ?.map((star, index) => {
                  index += 1;
                  return (
                    <RatingStar
                      key={index}
                      star={star}
                      index={index}
                      stars={stars}
                      colored={
                        index <= (hoveredStarIndex || currentAnswer?.rating)
                      }
                      handleHover={handleHoverRatingStar}
                      getInputValue={() => {
                        onInputChange("rating", index);
                      }}
                    />
                  );
                })}
            </StyledRatingStarWrap>
            <StyledError>{!currentAnswer?.rating && errors.rating}</StyledError>
          </div>

          <FormSelectGroup
            fieldLabel="How was your onboarding experience so far? (Optional)"
            options={queryData?.onboarding_experience_options?.impressions}
            selectLabel={"Please select"}
            getInputValue={(newValue) => {
              onInputChange("impression", newValue);
            }}
          />
          <FormSelectGroup
            fieldLabel="How did you find out or heard about Brainster? *"
            options={
              queryData?.onboarding_experience_options?.heard_about_brainster
            }
            isRequired
            errors={errors.heard_about_brainster}
            selectLabel={"Please select"}
            getInputValue={(newValue) => {
              onInputChange("heard_about_brainster", newValue);
            }}
          />
          {hasHeardAboutBrainster && (
            <>
              <div style={{ marginBottom: "1rem" }}>
                <Label
                  label={"Please let us know how you heard about Brainster *"}
                />
                <StyledTextareaWrap onboarding={onboarding}>
                  <Textarea
                    name={"heard_about_brainsterTextarea"}
                    rows={"4"}
                    errors={errors.heard_about_brainsterTextarea}
                    placeholder={"I heard about Brainster from a / on..."}
                    getInputValue={(newValue) => {
                      onInputChange("heard_about_brainsterTextarea", newValue);
                    }}
                  />
                </StyledTextareaWrap>
              </div>
            </>
          )}
          <FormMultipleSelectGroup
            options={queryData?.reasons_for_choosing_brainster}
            defaultValue={user?.reasons_for_choosing_brainster || ""}
            errors={errors.reasons_for_choosing_brainster}
            maximumOptionsToSelect={5}
            additionalLabel="(Choose up to 5)"
            isRequired
            fieldLabel="Reason for choosing Brainster *"
            getInputValue={(newValue) => {
              onInputChange("reasons_for_choosing_brainster", newValue);
            }}
          />
          {addAdditionalReasons && (
            <>
              <div style={{ marginBottom: "1rem" }}>
                <Label
                  label={"Please let us know of other reasons for your choice*"}
                />
                <StyledTextareaWrap onboarding={onboarding}>
                  <Textarea
                    name={"reason"}
                    rows={"4"}
                    errors={errors.reason}
                    placeholder={"Type here your reasons..."}
                    getInputValue={(newValue) => {
                      onInputChange("reason", newValue);
                    }}
                  />
                </StyledTextareaWrap>
              </div>
            </>
          )}

          <StyledFormRow>
            <FormSelectGroup
              fieldLabel="I have been following Brainster for: *"
              options={
                queryData?.onboarding_experience_options
                  ?.time_following_brainster
              }
              errors={errors.time_following_brainster}
              isRequired
              selectLabel={"Please select"}
              getInputValue={(newValue) => {
                onInputChange("time_following_brainster", newValue);
              }}
            />
          </StyledFormRow>
        </StyledForm>
      </StyledInnerWrap>
    </StyledContainer>
  );
};

export default OnboardingReview;
