import React from "react";
import styled from "styled-components";

import { Text } from "../../Consts/Text";
import theme from "../../Consts/theme";

// styled-components
const StyledPaymentStatus = styled.div`
  background-color: ${(props) => props.bgColor};
  color: ${(props) => props.color};
  display: inline-block;
  text-align: center;
  border-radius: 8px;
  padding: 3px 8px;
  width: 100%;

  @media only screen and (min-width: ${(props) => props.theme.desktop}) {
    min-width: 80px;
    width: auto;
  }
`;

const StatusLabel = ({ status }) => {
  const statusBgColor = () => {
    if (status === "pending") return theme.yellow;
    if (status === "fail") return theme.red;
    if (status === "cancelled") return theme.textGrey2;
    if (status === "success") return theme.forestGreen;
  };
  const statusColor = () => {
    const currentStatus = status;
    if (currentStatus === "pending") return theme.bronzeYellow;
    if (currentStatus === "fail") return theme.lightGray2;
    if (currentStatus === "cancelled") return theme.lightGray2;
    if (currentStatus === "success") return theme.lightGray2;
  };
  return (
    <StyledPaymentStatus bgColor={statusBgColor()} color={statusColor()}>
      <Text fontSize="13px" fontWeight={600}>
        {status}
      </Text>
    </StyledPaymentStatus>
  );
};

export default StatusLabel;
