import React, { useContext } from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";

import { makeAppRoute } from "../../Router";
import useMediaCustom from "../../Hooks/useMediaCustom";
import { GlobalContext } from "../../Context/GlobalProvider";
import theme from "../../Consts/theme";


const StyledMenuUl = styled.ul`
  display: flex;
  justify-content: flex-start;
  flex-flow: column nowrap;
  border-top: 2px solid ${theme.lightGray2};
  border-bottom: 2px solid ${theme.lightGray2};
  padding-bottom: 32px;
  li {
    &:focus,
    &:hover {
      color: ${(props) => props.theme.darkBlue};
    }
  }
  .active {
    color: ${(props) => props.theme.darkBlue};
  }

  @media only screen and (min-width: ${(props) => props.theme.large_desktop}) {
    flex-flow: row nowrap;
    border: none;
    padding-bottom: 0;
  }
`;
const StyledLi = styled.li`
  padding-top: 32px;
  color: ${(props) => props.theme.darkGray};
  line-height: 21px;
  font-size: ${(props) => props.theme.large};
  font-weight: 700;
  display: inline-block;

  &:focus,
  &:hover {
    color: ${(props) => props.theme.darkBlue};
  }

  @media only screen and (min-width: ${(props) => props.theme.large_desktop}) {
    padding: 0 ${(props) => props.theme.large};
  }
`;

const MainMenuLinks = ({ handleSideMenu }) => {
  const isDesktop = useMediaCustom("(min-width: 1440px)");
  const { categories } = useContext(GlobalContext);

  return categories?.length > 0 ? (
    <StyledMenuUl>
      {categories.map((category) => {
        return (
          <StyledLi key={category.id}>
            {/* <img src={process.env.REACT_APP_FILES_URL + "/" + category.icon} alt="" /> */}
            <NavLink
              to={makeAppRoute("category", { CATEGORY: category.slug })}
              className={(navData) => (navData.isActive ? "active" : "")}
              onClick={() => {
                if (!isDesktop) {
                  handleSideMenu();
                }
                return;
              }}
            >
              {category.title}
            </NavLink>
          </StyledLi>
        );
      })}
    </StyledMenuUl>
  ) : (
    <div style={{width: "700px"}}></div>
  );
};
export default MainMenuLinks;
