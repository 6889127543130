import React, { useState, useEffect } from "react";
import styled from "styled-components";

import Message from "./Message";
import usePreviousValue from "../../../Hooks/usePreviousValue";
import useMediaCustom from "../../../Hooks/useMediaCustom";

// styled-components
const StyledWrap = styled.div`
  position: relative;
  @media only screen and (min-width: ${(props) => props.theme.desktop}) {
    width: 15%;
  }
`;
const StyledContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 60px;
  background-color: ${(props) => props.theme.white};
  box-shadow: 0px -4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 100px 100px 0px 0px;
  z-index: 10;

  @media only screen and (min-width: ${(props) => props.theme.desktop}) {
    position: absolute;
    right: 0;
    top: 170px;
    height: 600px;
    width: 60px;
    border-radius: 100px 0px 0px 100px;
    z-index: 0;
  }
`;
const StyledImage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -40px;
  padding-top: 20px;
  width: 80px;
  height: 60px;
  border-top-left-radius: 50px;
  border-top-right-radius: 50px;
  background-color: ${(props) => props.theme.white};
  box-shadow: -0 -8px 10px -6px rgba(0, 0, 0, 0.1);
  flex-direction: column;
  cursor: pointer;

  .red-circle {
    width: 6px;
    height: 6px;
    background-color: red;
    border-radius: 50%;
    margin-top: 6px;
  }

  @media only screen and (min-width: ${(props) => props.theme.desktop}) {
    margin-top: 0;
    padding-top: 0px;
    box-shadow: none;
  }
`;
const StyledInboxPanel = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 650px;
  border-radius: 60px 60px 0px 0px;

  div.modal-content {
    background-color: ${(props) => props.theme.white};
    border-radius: 60px 60px 0px 0px;
  }
  &.modal-show div.modal-content {
    animation: panelFadeUp 0.7s ease-in-out forwards;
  }
  &.modal-hide div.modal-content {
    animation: panelFadeDown 0.7s ease-in-out forwards;
  }

  @media only screen and (min-width: ${(props) => props.theme.desktop}) {
    width: 500px;
    height: 600px;
    right: 0;
    bottom: auto;
    background-color: transparent;
    position: absolute;

    div.modal-content {
      position: absolute;
      width: 500px;
      right: 0;
      box-shadow: 0 4px 20px rgba(0, 0, 0, 0.4);
    }
    &.modal-show div.modal-content {
      animation: panelFadeIn 0.6s cubic-bezier(0.4, 0.8, 0.8, 1.25) forwards;
    }
    &.modal-hide div.modal-content {
      animation: panelFadeOut 0.4s ease-in-out forwards;
    }
  }

  // Animations -> mobile
  @keyframes panelFadeUp {
    0% {
      transform: translateY(100%);
    }
    100% {
      transform: translateY(0);
    }
  }
  @keyframes panelFadeDown {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(100%);
    }
  }

  // Animations -> desktop
  @keyframes panelFadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes panelFadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
`;
const StyledInboxPanelHeader = styled.header`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 24px 0;
  border-radius: 60px 60px 0px 0px;
  box-shadow: 0px -4px 15px rgba(0, 0, 0, 0.12);

  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  color: ${(props) => props.theme.black};
  cursor: pointer;

  img {
    margin-right: 16px;
  }

  @media only screen and (min-width: ${(props) => props.theme.desktop}) {
    box-shadow: none;
  }
`;
const StyledMessagesList = styled.ul`
  padding: 24px 18px 18px;
  height: 600px;
  overflow: scroll;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.16);

  &::-webkit-scrollbar {
    width: 4px;
    border-radius: 4px;
  }
  &::-webkit-scrollbar-track-piece {
    background-color: ${(props) => props.theme.lightGray2};
    border-radius: 4px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${(props) => props.theme.lightLightGray};
    border-radius: 4px;
  }
`;
const MessagesInbox = ({ messagesInbox }) => {
  // state
  const [showInboxPanel, setShowInboxPanel] = useState(false);
  const [isTouched, setIsTouched] = useState(false);
  const [arrLength, setArrLength] = useState();
  const prev = usePreviousValue(arrLength);

  useEffect(() => {
    setArrLength(messagesInbox?.length);
  }, [messagesInbox]);

  const handleOpenInboxPanel = () => {
    setIsTouched(true);
    setShowInboxPanel(true);
  };
  const handleCloseInboxPanel = () => {
    setIsTouched(false);

    setTimeout(() => {
      setShowInboxPanel(false);
    }, 400);
  };

  return (
    <>
      <StyledWrap>
        <StyledContainer showInboxPanel={showInboxPanel}>
          <StyledImage onClick={handleOpenInboxPanel}>
            <img src="/images/inbox.svg" alt="Inbox Icon" />
            {prev < messagesInbox?.length ? (
              <div className="red-circle"></div>
            ) : (
              ""
            )}
          </StyledImage>
          {showInboxPanel ? (
            <StyledInboxPanel
              className={!isTouched ? "modal-hide" : "modal-show"}
            >
              <div className="modal-content">
                <StyledInboxPanelHeader onClick={handleCloseInboxPanel}>
                  <img src="/images/inbox.svg" alt="" />
                  <span>Messages from Brainster</span>
                </StyledInboxPanelHeader>

                <StyledMessagesList>
                  {messagesInbox?.length > 0
                    ? messagesInbox?.map((message) => (
                        <Message key={message?.id} message={message} />
                      ))
                    : ""}
                </StyledMessagesList>
              </div>
            </StyledInboxPanel>
          ) : (
            ""
          )}
        </StyledContainer>
      </StyledWrap>
    </>
  );
};
export default MessagesInbox;
