import React from "react";
import styled from "styled-components";

// styled-components
const StyledNotificationWrap = styled.div`
  background: ${props => props.bgColor && props.bgColor};
  padding: 20px;
  text-align: center;
  color: white;
  font-weight: bold;
  margin-bottom: 8px;


  span.underline {
    text-decoration: underline;
    text-decoration-thickness: 2px;
    cursor: pointer;
  }
`;
const StyledInnerWrap = styled.div`
  display: flex;
  flex-direction: column;

  @media only screen and (min-width: ${(props) => props.theme.desktop}) {
    flex-direction: row;
    justify-content: center;
  }
`;
const Notification = ({ bgColor, onClick, children,  content, disabled }) => {
 
  return (
    <StyledNotificationWrap
      onClick={onClick}
      disabled={disabled}
      bgColor={bgColor}
    >
      {children}
      <StyledInnerWrap>{content}</StyledInnerWrap>
    </StyledNotificationWrap>
  );
};
export default Notification;
