import React, { useContext, useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";

import { axios, makeApiRoute, makeAppRoute } from "../Router";
import { GlobalContext } from "../Context/GlobalProvider";
import { StyledMainContainer } from "../Components/CommonStyledComponents/CommonStyledComponents";
import theme from "../Consts/theme";
import Confetti from "../Components/Confetti/Confetti";
import Navbar from "../Components/Navbar/Navbar";
import ProfileInfo from "../Components/ProfileInfo/ProfileInfo";
import ProgressTrackerMainComponent from "../Components/ProgressTracker/ProgressTrackerMainComponent";
import Badge from "../Components/Achievements/Badge";
import Footer from "../Components/Footer/Footer";
import Leaderboard from "../Components/Leaderboard/Leaderboard";
import LoaderDots from "../Components/Loader/LoaderDots";
import MessagesInbox from "../Components/Notification/MessagesInbox/MessagesInbox";
import ScrollSpySideMenu from "../Components/ScrollSpy/ScrollSpySideMenu";
import ScrollToTopPage from "../Components/ScrollSpy/ScrollToToPage";
import ModuleAchievement from "../Components/Achievements/Module Achievements/ModuleAchievements";
import SurveyPopupBody from "../Components/Survey/SurveyPopupBody";
import Loader from "../Components/Loader/Loader";
import CertificateNotification from "../Components/Certificate/CertificateNotification";
import TalentMarketNotification from "../Components/TalentMarket/TalentMarketNotification";
import EventsNotification from "../Components/Events/EventsNotification";
import MotionElements from "../Components/MotionElements/MotionElements";

const StyledPageWrapper = styled.div`
  position: relative;
  background: #f9f9f9;
  padding-bottom: 100px;
  min-width: 100%;
  max-width: 100%;
  min-height: 100vh;
  @media only screen and (min-width: ${(props) => props.theme.desktop}) {
    display: flex;
    scroll-behavior: smooth;
  }
`;

const MasterLayout = () => {
  const navigate = useNavigate();
  const { user } = useContext(GlobalContext);

  const [showConfetti, setShowConfetti] = useState(false);
  const [isGeneratingCertificate, setIsGeneratingCertificate] = useState(false);
  const [messagesInbox, setMessagesInbox] = useState([]);

  const isUserObjectEmpty = Object.keys(user).length === 0;
  const isOnboardingProcessCompleted = user?.onboardingProcessComplete;

  useEffect(() => {
    if (!isUserObjectEmpty && isOnboardingProcessCompleted === false) {
      if (!user?.onboardingStep) {
        navigate(makeAppRoute("welcome"));
      } else {
        navigate(
          makeAppRoute(`onboarding`, {
            STEP: user?.onboardingStep,
          })
        );
      }
      return;
    }
  }, [user]);

  useEffect(() => {
    axios
      .get(makeApiRoute("messages"))
      .then((response) => {
        setMessagesInbox(response.data.data);
      })
      .catch(function (error) {
        throw error.response;
      });
  }, []);

  if (isUserObjectEmpty) {
    return (
      <LoaderDots
        isLoading={isUserObjectEmpty}
        bgColor="#f7f7f7"
        dotsBgColor={theme.lightBlue}
        height="100vh"
        dotMargin="25px"
        dotHeight="10px"
        dotWidth="10px"
      />
    );
  }
  return (
    <>
      {showConfetti && (
        <Confetti
          showConfetti={showConfetti}
          setShowConfetti={setShowConfetti}
        />
      )}

      {/* ---------------------------- NOTIFICATIONS -------------------------------------- */}

      <EventsNotification />
      <CertificateNotification
        setIsGeneratingCertificate={setIsGeneratingCertificate}
        isGeneratingCertificate={isGeneratingCertificate}
        setShowConfetti={setShowConfetti}
      />
      <ModuleAchievement />
      <TalentMarketNotification />

      {/*  ------------------------- HOME PAGE COMPONENTS --------------------------------- */}
      <Navbar />

      <StyledPageWrapper>
        {/* <MotionElements /> */}
        <ScrollSpySideMenu />
        <StyledMainContainer>
          <ProfileInfo
            editProfile
            userName={user.name}
            userAcademy={user?.group?.academy?.title}
            userGroup={user?.group?.title}
            userProfile={user?.profile}
          />
          <ProgressTrackerMainComponent />
          <Badge />
          {user &&
            user.group &&
            user.group.status === "LECTURES IN PROGRESS" && <Leaderboard />}

          {user?.activeSurvey && <SurveyPopupBody />}
        </StyledMainContainer>
        <Outlet />
        <MessagesInbox messagesInbox={messagesInbox.messages} />
        <Loader
          isLoading={isGeneratingCertificate}
          loadingMessageOne="Your certificate is being processed."
          loadingMessageTwo="Please wait..."
        />
      </StyledPageWrapper>
      <Footer />

      <ScrollToTopPage />
    </>
  );
};

export default MasterLayout;
