import React from "react";
import styled from "styled-components";
import { Element } from "react-scroll";

import IconText from "../IconAndText/IconText";
import Resource from "./Resource";
import { StyledHeadTitle } from "../CommonStyledComponents/CommonStyledComponents";

// styled-components
const StyledUnitCard = styled.article`
`;
const StyledUnitHeaging = styled.header`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media only screen and (min-width: ${(props) => props.theme.desktop}) {
    flex-direction: row-reverse;
    justify-content: space-between;
  }
`;
const StyledParagraph = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: ${(props) => props.theme.textGrey};
  margin-top: 10px;
  margin-bottom: 24px;

  div  {
    margin: 0 !important;
    float: none !important;
    width: 100% !important;
  }
`;

const UnitCard = ({
  unitTitle,
  description,
  subunits,
  duration,
  resourceStatusUpdated,
  updateCategoryData,

}) => {
  return (
    <StyledUnitCard>
      <StyledUnitHeaging>
        <IconText
          icon="/images/timeIcon.svg"
          description={
            duration === 0 ? "" :
              duration < 60
                ? duration + " minutes"
                : Math.floor(duration / 60) +
                " Hours " +
                (duration % 60) +
                " Minutes"
          }
        />
        <StyledHeadTitle>{unitTitle}</StyledHeadTitle>
      </StyledUnitHeaging>
      <div>

        <StyledParagraph dangerouslySetInnerHTML={{ __html: description }} />
      </div>
      {subunits.length > 0
        ? subunits.map((subunit) => (
          <Element key={subunit.id} name={`${subunit.id}${subunit.title}`}>
            <Resource
              subunitType={subunit.title}
              subunitDuration={subunit.duration}
              subunitResources={subunit.resources}
              currentSubunit={subunit}
              resourceStatusUpdated={resourceStatusUpdated}
              updateCategoryData={updateCategoryData}
            />
          </Element>
        ))
        : ""}
    </StyledUnitCard>
  );
};
export default UnitCard;
