import React, { useContext } from "react";
import { Link } from "react-scroll";
import styled from "styled-components";

import { GlobalContext } from "../../Context/GlobalProvider";
import "./ActiveLink.css";

// styled-components
const StyledContainer = styled.div`
  display: none;
  @media only screen and (min-width: ${(props) => props.theme.desktop}) {
    padding: 0 16px;
    width: 15%;
    display: block;
  }
`;

const StyledScrollSpyMenu = styled.div`
  position: sticky;
  top: 50%;
  transform: translateY(-50%);
 
`;
const StyledLink = styled(Link)`
  display: block;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 6%;
  color: #000000;
  cursor: pointer;
`;
const BulletPoint = styled.div`
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 100px;
  background-color: transparent;
  margin-left: 5px;
  cursor: auto;
`;
const ScrollSpySideMenu = () => {
  const { user } = useContext(GlobalContext);
  return (
    <StyledContainer>
      <StyledScrollSpyMenu>
        {/* <div> */}
          <StyledLink
            activeClass="activeLink"
            to="progressTracker"
            spy={true}
            smooth={true}
            offset={-100}
          >
            Progress Tracker
            <BulletPoint />
          </StyledLink>
          <StyledLink
            activeClass="activeLink"
            to="badges"
            spy={true}
            smooth={true}
            offset={-100}
          >
            Badges
            <BulletPoint />
          </StyledLink>

          {user &&
            user.group &&
            user.group.status === "LECTURES IN PROGRESS" && (
              <StyledLink
                activeClass="activeLink"
                to="leaderboard"
                spy={true}
                smooth={true}
                offset={-100}
              >
                Leaderboards
                <BulletPoint />
              </StyledLink>
            )}
        {/* </div> */}
      </StyledScrollSpyMenu>
    </StyledContainer>
  );
};
export default ScrollSpySideMenu;
