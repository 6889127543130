import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import Button from "../Button/Button";


const StyledContainer = styled.div`
  background: #f9f9f9;
  color: black;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-top: -80px;

  div {
    margin-top: 30vh;
    text-align: center;

    h1 {
      font-size: 100px;
      font-weight: bold;
      letter-spacing: 10px;
    }

    p {
      font-size: 30px;
      margin-bottom: 50px;
    }

    button {
      border-radius: 25px;
      width: 100%;
    }
  }

  @media only screen and (min-width: ${(props) => props.theme.laptop}) {
    div {
      margin-top: 25vh;

      h1 {
        font-size: 200px;
      }
      button {
        border-radius: 0;
        width: auto;
      }
    }
  }
`;

const NotFound = ({ textBtn, to }) => {

  return (
    <StyledContainer>
      <div>
        <h1>404</h1>
        <p>Page not found</p>
        <Link to={to}>
          <Button
            text={textBtn}
            variant="blueBtn"
          />
        </Link>
      </div>
    </StyledContainer>
  );
};

export default NotFound;
