import React, { useContext, useEffect } from "react";
import styled from "styled-components";

import { GlobalContext } from "../../Context/GlobalProvider";

const StyledPopUpBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 30px;
  background-color: rgba(229, 229, 229, 0.9);
  backdrop-filter: blur(4px);
  z-index: 20;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 4px;
    border-radius: 4px;
  }
  &::-webkit-scrollbar-track-piece {
    background-color: ${(props) => props.theme.lightGray2};
    border-radius: 4px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #e1e2e7;
    border-radius: 4px;
  }

  &.modal-show {
    animation: fadeInModal 0.3s ease-in-out forwards;
  }
  &.modal-hide {
    animation: fadeOutModal 0.2s ease-in-out forwards;
  }
  &.modal-show .modal-content {
    animation: fadeInDownModal 0.3s ease-in-out forwards;
  }
  &.modal-hide .modal-content {
    animation: fadeOutUpModal 15s ease-in-out forwards;
  }

  /* Animations */
  @keyframes fadeInModal {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes fadeOutModal {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  @keyframes fadeInDownModal {
    0% {
      opacity: 0;
      transform: translateY(-3rem);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  @keyframes fadeOutUpModal {
    0% {
      opacity: 1;
      transform: translateY(0);
    }
    100% {
      opacity: 0;
      transform: translateY(-3rem);
    }
  }
`;
const StyledPopUpContainer = styled.div`
  position: relative;
  margin-top: 20vh;
  width: 100%;
  min-width: 300px;
  max-width: ${(props) => props.maxWidth};
  padding: 40px 30px;
  box-sizing: border-box;
  border-radius: 20px;
  border-width: 1px;
  border-style: solid;
  border: 1px solid transparent !important;
  box-shadow: 0 0px 25px rgba(0, 0, 0, 0.4);
  color: ${(props) => props.theme.black};
  background: radial-gradient(
    99.41% 99.41% at 100% 100%,
    #005eff 0%,
    rgba(0, 94, 255, 0) 98.75%
  );

  &:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    margin: 1px !important;
    border-radius: 20px !important;
    background: ${({ theme }) => theme.white};
  }

  @media only screen and (min-width: ${(props) => props.theme.desktop}) {
    margin-top: 40px;
  }
`;

const StyledTitle = styled.h4`
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: ${(props) => props.theme.black};
  @media only screen and (min-width: ${(props) => props.theme.laptop}) {
    font-size: 24px;
    line-height: 28px;
  }
`;
const StyledSubTitle = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 18px;
  text-align: center;
  color: #4d4d4d;
  margin-top: 1rem;

  @media only screen and (min-width: ${(props) => props.theme.laptop}) {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    @media only screen and (min-width: ${(props) => props.theme.laptop}) {
      width: 100%;
    }
  }
`;
const StyledChildren = styled.section`
  text-align: center;

  &.center {
    justify-content: center;
  }

  @media only screen and (min-width: ${(props) => props.theme.laptop}) {
    &.center {
      justify-content: center;
    }
    &.flex-column {
      flex-direction: column;
    }
  }

  &.action-buttons {
    display: flex;
    margin-top: 40px;
    flex-direction: column;
    button {
      width: 100%;
      margin-bottom: 16px;
      border-radius: 50px;
    }

    @media only screen and (min-width: ${(props) => props.theme.laptop}) {
      flex-direction: row-reverse;
      justify-content: space-evenly;
      button {
        margin-bottom: 0;
        width: auto;
        border-radius: 0;
      }
    }
  }
`;
const StyledClosePopup = styled.span`
  &.close {
    position: absolute;
    right: 50px;
    top: 10px;
    cursor: pointer;
    opacity: 0.3;
    transition: all 0.2s ease-in-out;
  }
  &.close:hover {
    opacity: 1;
  }
  &.close:before,
  &.close:after {
    position: absolute;
    left: 20px;
    content: " ";
    height: 20px;
    width: 2px;
    background-color: #333;
  }
  &.close:before {
    transform: rotate(45deg);
  }
  &.close:after {
    transform: rotate(-45deg);
  }
`;
const PopUpMessage = () => {
  const { popupConfig } = useContext(GlobalContext);

  useEffect(() => {
    if (popupConfig?.show) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }
  }, [popupConfig?.show]);

  return (
    popupConfig?.show && (
      <StyledPopUpBackground
        className={!popupConfig.confirmation ? "modal-show" : "modal-hide"}
      >
        <StyledPopUpContainer
          maxWidth={`${popupConfig.maxWidth}`}
          className="modal-content"
        >
          {popupConfig.title && <StyledTitle>{popupConfig.title}</StyledTitle>}
          {popupConfig.subTitle && (
            <StyledSubTitle dangerouslySetInnerHTML={{ __html: popupConfig.subTitle }}></StyledSubTitle>
          )}
          <StyledChildren className={popupConfig.bodyClassname}>
            {popupConfig.body}
          </StyledChildren>

          {popupConfig.closeButton?.show && (
            <StyledClosePopup
              onClick={popupConfig?.closeButton?.action}
              className="close"
            ></StyledClosePopup>
          )}
        </StyledPopUpContainer>
      </StyledPopUpBackground>
    )
  );
};
export default PopUpMessage;
