import React, { useContext, useState, useEffect } from "react";
import styled from "styled-components";

import { GlobalContext } from "../../Context/GlobalProvider";
import EditProfileImage from "../ProfileImage/EditProfileImage";
import ProfileImageAcronym from "../ProfileImage/ProfileImageAcronym";

// styled components
const StyledProfileInfoWrap = styled.header`
  display: flex;
  align-items: center;
  padding: 60px 1rem 1rem;
`;

const StyledProfileInfoTextWrap = styled.section`
  align-self: center;
  margin-left: 30px;
  @media only screen and (min-width: ${(props) => props.theme.laptop}) {
    display: flex;
  }
`;
const StyledProfileInfoText = styled.span`
  display: block;

  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #232426;
  &:not(:last-child) {
    margin-bottom: 6px;
  }

  &.user-name {
    font-weight: 700;
    font-size: 16px;
    line-height: 18px;
  }

  @media only screen and (min-width: ${(props) => props.theme.laptop}) {
    color: #4d4d4d;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    display: block;
    border: none;
    padding-right: 10px;
    margin-bottom: 0px;
    margin-left: 20px;
    :not(:last-child) {
      border-right: 1px solid #4d4d4d;
    }

    &.user-name {
      font-size: 12px;
      line-height: 14px;
    }
  }
`;

const ProfileInfo = ({
  userName,
  userAcademy,
  userGroup,
  userProfile,
  editProfile,
}) => {
  const { user } = useContext(GlobalContext);
  const [data, setData] = useState();

  useEffect(() => {
    setData({ ...user });
  }, [user]);



  return (
    <StyledProfileInfoWrap>
      {/* user image  */}
      {data?.profile || userProfile ? (
        <EditProfileImage
          data={data}
          setData={setData}
          editProfile={editProfile}
          url={
            process.env.REACT_APP_FILES_URL +
            "/" +
            `${editProfile ? data?.profile : userProfile}`
          }
        />
      ) : (
        <ProfileImageAcronym
          data={data}
          setData={setData}
          userName={userName}
          editProfile={editProfile}
          width="80px"
          height="80px"
        />
      )}
      {/* user info details */}
      <StyledProfileInfoTextWrap>
        <StyledProfileInfoText className="user-name">
          {userName}
        </StyledProfileInfoText>
        <StyledProfileInfoText>{userAcademy}</StyledProfileInfoText>
        <StyledProfileInfoText>{userGroup}</StyledProfileInfoText>
      </StyledProfileInfoTextWrap>
    </StyledProfileInfoWrap>
  );
};
export default ProfileInfo;


