import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { Element } from "react-scroll";

import UnitCard from "./UnitCard";
import IconText from "../IconAndText/IconText";
import ProgressBar from "../ProgressTracker/ProgressBar";
import useMediaCustom from "../../Hooks/useMediaCustom";
import ModulesFilter from "../ModulesFilter/ModulesFilter";
import { GlobalContext } from "../../Context/GlobalProvider";

// styled-components
const StyledMaterialsResourcesContainer = styled.section`
  background: #ffffff;
  

  @media only screen and (min-width: ${(props) => props.theme.desktop}) {
    box-shadow: 0px 4px 16px rgb(0 0 0 / 6%);
    border-radius: 80px 80px 10px 10px;
  }
`;
const StyledUnitsBody = styled.div`
  padding: 40px 32px;

`;
const StyledElement = styled(Element)`
  &:not(:first-child) {
    margin-top: 50px;
  }
`
const StyledHeader = styled.header`
  padding: 10px 32px 0;

  .icon-wrap {
    display: flex;
    width: 100%;
  }
  @media only screen and (min-width: ${(props) => props.theme.desktop}) {
    display: flex;
    background-color: #f9f9f9;
    height: 88px;
    border-radius: 200px 200px 0px 0px;
    padding: 0;

    .icon-wrap {
      display: flex;
      align-items: center;
      border-radius: 200px 0px 200px;
      background-color: white;
      width: 45%;
      height: 100%;
      padding: 12px 60px;
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      line-height: 14px;
      text-transform: uppercase;

      /* Light Gray/Text */

      color: #65676c;
    }

    .progress-bar-wrap {
      width: 40%;
      margin: -1rem auto 0;
      align-self: center;
    }
  }
`;

const UnitsContainer = ({ slug, materialsData, isLoading, updateCategoryData, }) => {
  const isDesktop = useMediaCustom("(min-width: 992px)");
  const isLaptop = useMediaCustom("(min-width: 768px)");
  const [unitsPerCategory, setUnitsPerCategory] = useState();
  const [totalResources, setTotalResources] = useState(0);
  const [totalCompletedResources, setTotalCompletedResources] = useState(0);
  const [totalDuration, setTotalDuration] = useState(0);

  const { categories } = useContext(GlobalContext);

  useEffect(() => {
    const allUnitsPerCategory = materialsData
      ? materialsData?.units?.filter((unit) => unit.categorySlug === slug)
      : "";
    setUnitsPerCategory(allUnitsPerCategory);

    materialsData?.categories?.categories?.forEach((category) => {
      if (category.slug === slug)
        return (
          setTotalResources(category.totalResources),
          setTotalCompletedResources(category.totalCompletedResources)
        );
    });
    const totalDurationSum =
      allUnitsPerCategory && allUnitsPerCategory?.reduce(
        (accumulator, currentValue) => accumulator + currentValue.duration,
        0
      );
    setTotalDuration(totalDurationSum);
  }, [slug, materialsData, categories]);

  return (
    <>
      <StyledMaterialsResourcesContainer>
        <StyledHeader>
          {!isDesktop ? (
            <ModulesFilter
              slug={slug}
              materialsData={materialsData}
              setUnitsPerCategory={setUnitsPerCategory}
              setTotalResources={setTotalResources}
              setTotalCompletedResources={setTotalCompletedResources}
              setTotalDuration={setTotalDuration}
              units={unitsPerCategory}
            />
          ) : (
            ""
          )}

          <div className="icon-wrap">
            <IconText
              className="margin-right"
              icon="/images/resources-blue.svg"
              description={totalResources + " Resources"}
            />
            <IconText
              icon="/images/time-blue.svg"
              description={
                totalDuration < 60
                  ? totalDuration + " minutes"
                  : Math.floor(totalDuration / 60) +
                  " Hours " +
                  (totalDuration % 60) +
                  " Minutes"
              }
            />
          </div>
          <div className="progress-bar-wrap">
            <ProgressBar
              thumbWidth={isLaptop ? "12" : "15"}
              percentage={
                totalResources > 0
                  ? Math.round((totalCompletedResources / totalResources) * 100)
                  : 0
              }
              slug={slug}
              progressBarContent={`${totalResources > 0
                ? Math.round((totalCompletedResources / totalResources) * 100)
                : 0
                }%`}
            />
          </div>
        </StyledHeader>
        <StyledUnitsBody>
          {unitsPerCategory?.length > 0
            ? unitsPerCategory?.map((unit) => (
              <StyledElement name={unit?.title} key={unit.id}>
                <UnitCard
                  unitTitle={unit.title}
                  description={unit.description}
                  subunits={unit.subunits}
                  duration={unit.duration}
                  resourceStatusUpdated={isLoading}
                  updateCategoryData={updateCategoryData}
                />
              </StyledElement>
            ))
            : ""}
        </StyledUnitsBody>
      </StyledMaterialsResourcesContainer>
    </>
  );
};
export default UnitsContainer;
