import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  ApolloLink,
} from "@apollo/client";

const httpLink = createHttpLink({
  uri: ` ${process.env.REACT_APP_API_GRAPHQL_URL}`,
});

const authLink = new ApolloLink((operation, forward) => {
  const token = localStorage.getItem("userAT");
  operation.setContext({
    headers: {
      "X-Client-ID": `Client1`, // GraphQl Schema
      Authorization: token ? `Bearer ${token}` : "",
    },
  });
  return forward(operation);
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

export default client;
