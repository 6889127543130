import React, { useContext, useEffect, useState } from "react";
import { Element } from "react-scroll";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { axios, makeApiRoute, makeAppRoute } from "../../Router";
import EditProfileImage from "../ProfileImage/EditProfileImage";
import { GlobalContext } from "../../Context/GlobalProvider";
import ProfileImageAcronym from "../ProfileImage/ProfileImageAcronym";
import theme from "../../Consts/theme";
import profileSections from "../../Consts/StaticData";
import ProfileSectionCard from "./ProfileSectionCard/ProfileSectionCard";

// styled-components
const StyledProfilePageContainer = styled.section`
  display: flex;
  padding-bottom: 100px;
`;
const StyledHeading = styled.h2`
  font-size: 18px;
  font-weight: 700;

  @media only screen and (min-width: ${(props) => props.theme.desktop}) {
    font-size: 24px;
  }
`;
const StyledDetailSection = styled.div`
  display: none;
  @media only screen and (min-width: ${(props) => props.theme.desktop}) {
    display: block;
    font-size: 14px;
    color: ${theme.textGrey};
    font-weight: 400;
    margin-top: 20px;
    margin-bottom: 40px;

    span:not(:last-child) {
      padding-right: 13px;
      border-right: 1px solid ${theme.textGrey};
    }
    span:not(:first-child) {
      padding-left: 13px;
    }
  }
`;
const StyledProfileSectionCardsBox = styled.section`
  margin-top: 16px;
  a {
    height: 100%;
    border-radius: 16px;
    display: block;
    margin-bottom: 16px;
  }


  a:hover {
    outline: 1px solid ${theme.darkBlue2};
 
  }


  @media only screen and (min-width: ${(props) => props.theme.laptop}) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;
    justify-content: center;
    align-items: start;
  }

  @media only screen and (min-width: ${(props) => props.theme.desktop}) {
    grid-template-columns: repeat(3, 1fr);
  }
`;
const StyledUserImageContainer = styled.section`
  border-radius: 16px;
  margin-top: 16px;
  padding: 24px 0;
  border: 1px solid ${theme.concreteGray};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media only screen and (min-width: ${(props) => props.theme.desktop}) {
    display: none;
  }
`;
const StyledUserName = styled.p`
  font-weight: 700;
  font-size: 18px;
  margin: 16px 0 8px;
`;
const StyledInfoBadge = styled.div`
  font-size: 14px;
  color: ${theme.textGrey2};
  margin-top: 8px;
  text-align: center;

  span {
    display: block;
  }
  span:not(:last-child) {
    margin-bottom: 5px;
  }
`;
const StyledPageContentContainer = styled.section`
  background-color: #f9f9f9;
  width: 100%;
  padding: 40px 16px;
  @media only screen and (min-width: ${(props) => props.theme.desktop}) {
    padding-top: 90px;
    width: 90%;
    margin: 0 auto;
  }
  @media only screen and (min-width: ${(props) => props.theme.large_desktop}) {
    width: 70%;
    margin: 0 auto;
  }
`;

const ProfilePageContainer = () => {

  // context
  const { user, setUser } = useContext(GlobalContext);
  // state



  return (
    <StyledProfilePageContainer>
      <StyledPageContentContainer>
        <StyledHeading>My Account</StyledHeading>
        <StyledDetailSection>
          <span>{user.name}</span>
          <span>{user.email}</span>
          <span>{user?.group?.academy?.title}</span>
          <span>{user?.group?.title}</span>
        </StyledDetailSection>
        <StyledUserImageContainer>
          {user && user?.profile ? (
            <EditProfileImage
              editProfile
              url={
                user && user?.profile
                  ? process.env.REACT_APP_FILES_URL + "/" + user?.profile
                  : ""
              }
              className={".basic-info_img"}
            />
          ) : (
            <ProfileImageAcronym
              data={user}
              setData={setUser}
              width="80px"
              height="80px"
              editProfile
            />
          )}
          <StyledUserName>{user.name}</StyledUserName>
          <StyledInfoBadge>
            <span>{user?.group?.academy?.title}</span>
            <span>{user?.group?.title}</span>
          </StyledInfoBadge>
        </StyledUserImageContainer>
        <StyledProfileSectionCardsBox>
          {profileSections.map((section, index) => (
            <Link to={makeAppRoute("profileSection", { SECTION: section.path })}>
              <ProfileSectionCard
                key={index}
                sectionData={section}
              />
            </Link>
          ))}
        </StyledProfileSectionCardsBox>
      </StyledPageContentContainer>
    </StyledProfilePageContainer>
  );
};
export default ProfilePageContainer;
