import React from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { a11yDark } from "react-syntax-highlighter/dist/esm/styles/prism";

import CodeBlockHeader from "./CodeBlockHeader";

const codeBlockRegex = /```(.*?)\n([\s\S]*?)```/g;
const inlineCodeRegex = /`([^`]+)`/g;
const orderedListRegex = /^\d+\.\s(.+)/;
const unorderedListRegex = /^[-*]\s(.+)/;


const SyntaxHighlighterTest = ({ response }) => {
  const processResponse = (text, key) => {
    // Check for code blocks
    const codeBlockMatch = codeBlockRegex.exec(text);

    if (codeBlockMatch) {
      const beforeCode = text.slice(0, codeBlockMatch.index);
      const afterCode = text.slice(codeBlockRegex.lastIndex);
      const languageSpecifier = codeBlockMatch[1].trim();
      const codeWithoutLanguage = codeBlockMatch[2];

      return (
        <>
          {beforeCode && processResponse(beforeCode, `${key}-before`)}
          <div style={{ marginBottom: "15px" }}>
            <CodeBlockHeader
              className='code-block'
              codeLanguage={languageSpecifier}
              code={codeWithoutLanguage}
            />

            <SyntaxHighlighter
              className='syntax-highlight'
              key={`${key}-code`}
              language={languageSpecifier}
              style={a11yDark}
              customStyle={{
                padding: "15px",
                margin: 0,
                borderTopLeftRadius: 0,
                borderTopRightRadius: 0,
              }}
            >
              {codeWithoutLanguage}
            </SyntaxHighlighter>
          </div>

          {afterCode && processResponse(afterCode, `${key}-after`)}
        </>
      );
    }

    // Check for inline code
    const inlineCodeMatch = inlineCodeRegex.exec(text);
    if (inlineCodeMatch) {
      const beforeInlineCode = text.slice(0, inlineCodeMatch.index);
      const inlineCode = inlineCodeMatch[1];

      const afterInlineCode = text.slice(inlineCodeRegex.lastIndex);

      return (
        <>
          {beforeInlineCode &&
            processResponse(beforeInlineCode, `${key}-before-inline`)}
          <code key={`${key}-inline`}>
            <b>{inlineCode}</b>
          </code>
          {afterInlineCode &&
            processResponse(afterInlineCode, `${key}-after-inline`)}
        </>
      );
    }

    // Split text by new lines to check for lists and paragraphs
    const lines = text?.split("\n");

    return lines?.map((line, index) => {
      if (orderedListRegex.test(line)) {
        return (
          <ol key={`${key}-ol-${index}`}>
            {
              <li style={{ margin: "16px 0", lineHeight: 1.3 }}>
                <b>{orderedListRegex.exec(line)?.[0]}</b>
              </li>
            }
          </ol>
        );
      } else if (unorderedListRegex.test(line)) {
        return (
          <ul key={`${key}-ul-${index}`}>
            {
              <li style={{ margin: "8px 0" }}>
                {unorderedListRegex.exec(line)?.[0]}
              </li>
            }
          </ul>
        );
      } else {
        return (
          <span style={{ lineHeight: 1.5 }} key={`${key}-p-${index}`}>
            {line}
          </span>
        );
      }
    });
  };

  return <div>{processResponse(response, "response")}</div>;
};

export default SyntaxHighlighterTest;
