import React from "react";
import styled from "styled-components";

// styled-components

const StyledLabel = styled.label`
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  margin-right: 5px;
  transform: scale(1);
  font-size: ${(props) =>
    props.stars <= 6
      ? "40px"
      : props.stars > 6 && props.stars < 9
      ? "36px"
      : "28px"};
  color: ${(props) =>
    props.colored ? props.theme.lightBlue : props.theme.gray};
  width: 8%;

  transition: all 0.3s ease-in-out;
  input {
    display: none;
  }
  :hover {
    transform: scale(1.4);
  }
  .tooltiptext {
    visibility: hidden;
    display: inline-block;
    width: auto;
    background-color: ${(props) => props.theme.textGrey};
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 3px;
    font-size: 9px;
    /* Position the tooltip */
    position: absolute;
    top: 0;
    z-index: 1;
  }
  :hover .tooltiptext {
    visibility: visible;
  }
  @media only screen and (min-width: ${(props) => props.theme.laptop}) {
    font-size: 46px;
    .tooltiptext {
      padding: 4px;
      font-size: 10px;
    }
  }
  @media only screen and (min-width: ${(props) => props.theme.desktop}) {
    font-size: 30px;
  }
`;

const RatingStar = ({
  index,
  handleHover,
  colored,
  rating,
  stars,
  getInputValue,
}) => {
  // we determine if icon is in readonly mode by the rating that we receive from backend
  // if there is rating (true), then all events applied to the element have to stop

  return (
    <>
      <StyledLabel
        stars={stars}
        onMouseEnter={(e) => {
          if (rating) {
            e.stopPropagation();
          } else {
            handleHover(index);
          }
        }}
        onMouseLeave={(e) => {
          if (rating) {
            e.stopPropagation();
          } else {
            handleHover(undefined);
          }
        }}
        onClick={(e) => {
          if (rating) {
            e.stopPropagation();
          } else {
            getInputValue();
          }
        }}
        // colored prop receives boolean;
        // different conditions evaluate to true depending on the mode of the component;
        colored={colored}
      >
        <input
          type="radio"
          onChange={(e) => {
            getInputValue(e.target.value);
          }}
        />
        <span className="tooltiptext">{index}</span>
        &#9733;
      </StyledLabel>
    </>
  );
};

export default RatingStar;
