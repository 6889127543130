import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { axios, makeApiRoute } from "../../Router";
import Button from "../Button/Button";
import {
  ErrorMessage,
  InfoMessage,
} from "../CommonStyledComponents/CommonStyledComponents";
import theme from "../../Consts/theme";
import LoaderDots from "../Loader/LoaderDots";
import Input from "../Input/Input";
import InputCheckbox from "../Checkbox/InputCheckbox";
import FormSelectGroup from "../FormGroup/FormSelectGroup";

const StyledBackgroundHomeworkPopUpWrapper = styled.section`
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 10000;
  min-height: -webkit-fill-available;
  display: ${(props) => (props.shownPopUp ? "block" : "none")};
  backdrop-filter: blur(4px);
`;
const StyledHomeworkInnerWrapper = styled.div`
  display: ${(props) => (props.shownPopUp ? "block" : "none")};
  text-align: center;
  padding: 20px 40px;
  position: absolute;
  width: 300px;
  left: 50%;
  margin-left: -150px;
  top: 286px;
  margin-top: -120px;
  background-color: #ffffff;
  border: 2px solid #efeff4;
  border-radius: 20px;
  z-index: 5000;

  @media (min-width: 374px) and (max-width: 424px) {
    padding: 30px 20px;
    width: 320px;
    margin-left: -160px;
    margin-top: -150px;
  }
  @media (min-width: 425px) and (max-width: 450px) {
    padding: 35px 25px;
    width: 340px;
    margin-left: -170px;
    margin-top: -150px;
  }
  @media only screen and (min-width: ${(props) => props.theme.laptop}) {
    padding: 55px 100px;
    width: 560px;
    margin-left: -280px;
    margin-top: -150px;
  }
`;
const StyledTitle = styled.h2`
  width: 100%;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  margin-bottom: 0;
  color: #191919;

  @media only screen and (min-width: ${(props) => props.theme.laptop}) {
    font-size: 24px;
  }
`;

const StyledForm = styled.form`
  margin-top: 50px;
  text-align: left;

  input:focus {
    opacity: 1;
  }
  input::placeholder {
    font-size: 12px;
  }

  label.checkbox-label {
    display: flex;
    align-items: flex-start;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    color: ${(props) => props.theme.lightGrayText};
  }

  label.homework-label {
    display: inline-block;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    color: ${(props) => props.theme.lightLightGray};
    margin-bottom: 10px;
  }
`;
const StyledButtonWrap = styled.div`
  display: flex;
  justify-content: ${(props) =>
    props.successSubmit ? "center" : "space-between"};
  margin-top: 50px;

  @media only screen and (min-width: 374px) {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
  @media only screen and (min-width: ${(props) => props.theme.laptop}) {
    width: 65%;
  }
`;
const StyledSuccessUpload = styled.div`
  margin-top: 30px;
`;

const teams = ["Team 1", "Team 2", "Team 3", "Team 4", "Team 5", "Team 6", "Team 7", "Team 8", "Team 9", "Team 10"];

const HomeworkPopUp = ({
  shownPopUp,
  currentActivity,
  hasSubmitedLink,
  handleClosePopUp,
  studentData,
  setStudentData,
}) => {
  // state
  const [isLoading, setIsLoading] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [selectedTeam, setSelectedTeam] = useState(currentActivity.team || "");
  const [hasGrantedAccessToInstructor, setHasGrantedAccessToInstructor] =
    useState(false);
  const [serverMessage, setServerMessage] = useState({
    success: "",
    errors: "",
  });

  const submissionDate = currentActivity.deadline;
  const formattedDate = new Date(submissionDate).toLocaleDateString("en-GB");
  const getCheckboxValue = (value) => setHasGrantedAccessToInstructor(value);
  const getInputValue = (value) => setInputValue(value);

  // if user has submited link, show the link prefilled on click Edit button
  useEffect(() => {
    if (!hasSubmitedLink) {
      setInputValue("");
    } else {
      setInputValue(currentActivity.submission_url);
      setHasGrantedAccessToInstructor(true);
    }
  }, [currentActivity, hasSubmitedLink]);

  useEffect(() => {
    let validationMessages = {};
    if (inputValue !== "") {
      validationMessages = { ...validationMessages, errors: "" };
    }
    if (hasGrantedAccessToInstructor) {
      validationMessages = { ...validationMessages, accessValidation: "" };
    }

    if (Object.keys(validationMessages).length !== 0) {
      setServerMessage({ ...serverMessage, ...validationMessages });
      return;
    }
  }, [inputValue, hasGrantedAccessToInstructor]);

  const onCancelSubmitHomework = () => {
    setInputValue("");
    setSelectedTeam("");
    handleClosePopUp();
  };

  // on submit the form
  const onHomeworkSubmit = (e) => {
    e.preventDefault();
    let errorMessages = {};

    // validate empty input
    if (inputValue === "") {
      errorMessages = {
        ...errorMessages,
        errors: "Please link your solution!",
      };
    }
    // validate empty checkbox
    if (!hasGrantedAccessToInstructor) {
      errorMessages = {
        ...errorMessages,
        accessValidation:
          "If your link is set to private, excluding access for the instructor, you may not receive feedback on your assignment.",
      };
    }

    if (Object.keys(errorMessages).length !== 0) {
      setServerMessage({ ...serverMessage, ...errorMessages });
      return;
    } else {
      // prepare the payload
      const data = {
        activityId: currentActivity?.id,
        submissionUrl: inputValue,
        team: selectedTeam
      };


      // setting the loader
      setIsLoading(true);

      if (currentActivity?.submission_url === null) {
        // submit new link
        axios
          .put(makeApiRoute("submitHomework"), data)
          .then((response) => {
            let updatedArr = studentData?.activities?.map((activity) => {
              if (activity.id === currentActivity?.id) {
                return { ...activity, submission_url: inputValue, team: selectedTeam};
              } else {
                return activity;
              }
            });
            setServerMessage({
              ...serverMessage,
              success: response.data?.success?.message,
            });

            setStudentData({ ...studentData, activities: updatedArr });
            setInputValue("");
            setIsLoading(false);
          })
          .catch((msg) => {
            const invalidFormat =
              msg?.response?.data?.errors?.submissionUrl !== undefined &&
              msg?.response?.data?.errors?.submissionUrl;

            setServerMessage({
              ...serverMessage,
              errors: invalidFormat[0] || msg?.response?.data?.error?.message,
            });
            setIsLoading(false);
          });
      } else {
        // edit already submitted link
        axios
          .put(makeApiRoute("editHomework"), data)
          .then((response) => {
            let updatedArr = studentData?.activities?.map((activity) => {
              if (activity.id === currentActivity?.id) {
                return { ...activity, submission_url: inputValue, team: selectedTeam };
              } else {
                return activity;
              }
            });
            setServerMessage({
              ...serverMessage,
              success: response.data?.success?.message,
            });

            setStudentData({ ...studentData, activities: updatedArr });
            setIsLoading(false);
          })
          .catch((msg) => {
            const invalidFormat =
              msg?.response?.data?.errors?.submissionUrl !== undefined &&
              msg?.response?.data?.errors?.submissionUrl;

            setServerMessage({
              ...serverMessage,
              errors: invalidFormat[0] || msg?.response?.data?.error?.message,
            });

            setIsLoading(false);
          });
      }
    }
  };
  return (
    <StyledBackgroundHomeworkPopUpWrapper shownPopUp={shownPopUp}>
      <StyledHomeworkInnerWrapper shownPopUp={shownPopUp}>
        <StyledTitle>
          {serverMessage.success !== ""
            ? serverMessage.success
            : "Upload your solution link"}
        </StyledTitle>
        {serverMessage.success !== "" ? (
          <StyledSuccessUpload
            successSubmit={Boolean(serverMessage.success !== "")}
          >
            <img src="/images/uploadSuccess.svg" alt="Uploaded Successfully" />
            <StyledButtonWrap
              successSubmit={Boolean(serverMessage.success !== "")}
            >
              <Button
                variant={"grayBtn"}
                text={"Close"}
                type={"button"}
                onClick={() => handleClosePopUp()}
              />
            </StyledButtonWrap>
          </StyledSuccessUpload>
        ) : (
          <StyledForm onSubmit={onHomeworkSubmit}>
            <label className="homework-label">Drop your link here</label>
            <Input
              className="homework-link"
              type="text"
              placeholder="https://...."
              // disabled={
              //   currentActivity?.deadline_passed &&
              //   currentActivity?.submission_url !== null
              // }
              defaultValue={inputValue}
              getInputValue={getInputValue}
              onChange={(e) => {
                setServerMessage({ ...serverMessage, errors: "" });
              }}
            />
            {serverMessage.errors !== "" && (
              <ErrorMessage>{serverMessage.errors}</ErrorMessage>
            )}
            <div style={{ marginTop: "10px", marginBottom: "40px" }}>
              <label className="checkbox-label">
                <InputCheckbox
                  value={hasGrantedAccessToInstructor}
                  checked={
                    hasGrantedAccessToInstructor ||
                    (currentActivity?.deadline_passed &&
                      currentActivity?.submission_url !== null)
                  }
                  onChange={getCheckboxValue}
                  disabled={
                    currentActivity?.deadline_passed &&
                    currentActivity?.submission_url !== null
                  }
                />

                <span style={{ marginLeft: "8px" }}>
                  I confirm that I have granted access to the instructor for the
                  link, file or folder that I am trying to upload.{" "}
                </span>
              </label>
              {serverMessage.accessValidation !== "" && (
                <ErrorMessage>{serverMessage.accessValidation}</ErrorMessage>
              )}
            </div>

            <FormSelectGroup
              fieldLabel="Select a Team (only if you have group assignment)"
              options={teams}
              name="team"
              selectLabel={"Please choose your team"}
              defaultValue={(currentActivity && currentActivity?.team) || "-1"}
              getInputValue={(newValue) => {
                setSelectedTeam(newValue);
              }}
            />

            {serverMessage?.errors === "" &&
            !currentActivity?.deadline_passed &&
            currentActivity?.submission_url !== null ? (
              <InfoMessage style={{ textAlign: "center" }}>
                You can edit the link until {formattedDate}
              </InfoMessage>
            ) : (
              ""
            )}
            <StyledButtonWrap>
              <Button
                variant={"grayBtn"}
                text={"Cancel"}
                type={"button"}
                onClick={() => onCancelSubmitHomework()}
              />
              <Button
                variant={"blueBtn"}
                text={
                  !isLoading ? (
                    "Upload"
                  ) : (
                    <LoaderDots
                      isLoading={isLoading}
                      dotsBgColor={theme.darkBlue2}
                      dotHeight="4px"
                      dotWidth="4px"
                      dotMargin="5px"
                      buttonSubmit
                    />
                  )
                }
                type={"submit"}
                disabled={isLoading}
              />
            </StyledButtonWrap>
          </StyledForm>
        )}
      </StyledHomeworkInnerWrapper>
    </StyledBackgroundHomeworkPopUpWrapper>
  );
};
export default HomeworkPopUp;
