import React, { useState } from "react";
import styled from "styled-components";

// styled-component
const TooltipContainer = styled.div`
  position: relative;
`;
const TooltipText = styled.p`
  display: inline-block;
  padding: 10px 16px;
  width: auto;
  background-color: ${(props) => props.theme.concreteGray};
  color: ${(props) => props.theme.lightGrayText};
  text-align: center;
  border-radius: 6px;
  font-size: 14px;
  width: 100%;
  line-height: 1.2;

  /* Position the tooltip */
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: calc(100% + 5px);
  z-index: 1;

  transition: background-color 0.5s ease-in-out;

  // styles for tooltip arrow
  ::after {
    content: " ";
    position: absolute;
    bottom: 100%; /* At the top of the tooltip */
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent ${(props) => props.theme.textGrey}
      transparent;
  }
`;

const Tooltip = ({ children, text, width }) => {
  const [show, setShow] = useState(false);

  const handleShow = () => {
    setShow(true);
  };

  const handleHide = () => {
    setShow(false);
  };

  return (
    <TooltipContainer
    
      onMouseEnter={handleShow}
      onMouseLeave={handleHide}
      onTouchStart={handleShow}
      onTouchEnd={handleHide}
    >
      {children}
      {text !== "" && show ? (
        <TooltipText  className="tooltip-text">{text}</TooltipText>
      ) : (
        ""
      )}
    </TooltipContainer>
  );
};

export default Tooltip;
