const profileSections = [
  {
    id: 1,
    path: "personal-info",
    title: "Personal Info",
    icon: process.env.PUBLIC_URL + "/images/profileIcons/personalInfo.svg",
    description: "Provide personal info & how we can reach you.",
    infoCards: [
      {
        id: 1,
        title: "Personal info",
        icon: process.env.PUBLIC_URL + "/images/profileIcons/personalInfo.svg",
        description:
          "Share your personal information to help us tailor your experience. We respect your privacy and use this data to provide a more customized and relevant service.",
      },
      {
        id: 1,
        title: "Which informations can’t be edited?",
        icon: process.env.PUBLIC_URL + "/images/profileIcons/editNoteIcon.svg",
        description:
          "Some information such as email can’t be edited. If you wish to change some of those information, please contact your student success coordinator.",
      },
    ],
  },
  {
    id: 2,
    path: "education-history",
    title: "Education History",
    icon: process.env.PUBLIC_URL + "/images/profileIcons/educationHistory.svg",
    description: "Share your background to improve our future assistance.",
    infoCards: [
      {
        id: 1,
        title: "Education history",
        icon:
          process.env.PUBLIC_URL + "/images/profileIcons/educationHistory.svg",
        description:
          "We would like to know your background to better assist you in the process later. Think of this as your career path - where you are now and where you’d be after graduating.",
      },
    ],
  },
  {
    id: 3,
    path: "employment-history",
    title: "Employment History",
    icon: process.env.PUBLIC_URL + "/images/profileIcons/employmentHistory.svg",
    description: "Share your background to improve our future assistance.",
    infoCards: [
      {
        id: 1,
        title: "Employment history",
        icon:
          process.env.PUBLIC_URL + "/images/profileIcons/employmentHistory.svg",
        description:
          "We would like to know your background to better assist you in the process later. Think of this as your career path - where you are now and where you’d be after graduating.",
      },
    ],
  },
  {
    id: 4,
    path: "program-goals",
    title: "Program goals & expectations",
    icon: process.env.PUBLIC_URL + "/images/profileIcons/documentIcon.svg",
    description:
      "Share your aspirations and anticipations leading up to the academy.",
    infoCards: [
      {
        id: 1,
        title: "Program goals & expectations",
        icon: process.env.PUBLIC_URL + "/images/profileIcons/documentIcon.svg",
        description:
          "It’s about your goals and expectations prior to starting the academy - what would you like to achieve and what are expectations that you have for the whole experience.",
      },
    ],
  },
  {
    id: 5,
    path: "hiring-preferences",
    title: "Hiring Preferences",
    icon: process.env.PUBLIC_URL + "/images/profileIcons/hiringPreferences.svg",
    description: "Your hiring preferences are the compass for your future.",
    infoCards: [
      {
        id: 1,
        title: "Hiring Preferences",
        icon:
          process.env.PUBLIC_URL + "/images/profileIcons/hiringPreferences.svg",
        description:
          "Your career preferences are the compass for your future. Share them to receive personalized guidance and opportunities tailored to your aspirations.",
      },
    ],
  },
  {
    id: 6,
    path: "profile-hub",
    title: "Profile Hub",
    icon: process.env.PUBLIC_URL + "/images/profileIcons/profileHub.svg",
    description: "Attach your social profiles, bio and CV. ",
    infoCards: [
      {
        id: 1,
        title: "Profile hub",
        icon: process.env.PUBLIC_URL + "/images/profileIcons/profileHub.svg",
        description:
          "Share your social profiles, bio, and CV. These links can help potential employers learn more about you.",
      },
    ],
  },
  {
    id: 7,
    path: "skills",
    title: "Skills",
    icon: process.env.PUBLIC_URL + "/images/profileIcons/skillsIcon.svg",
    description:
      "Share your proficiency in various areas, including your language skills, as well as your soft and technical skills.",
    infoCards: [
      {
        id: 1,
        title: "Skills",
        icon: process.env.PUBLIC_URL + "/images/profileIcons/skillsIcon.svg",
        description:
          "Share your proficiency in various areas, including your language skills, as well as your soft and technical skills.",
      },
    ],
  },
  {
    id: 8,
    path: "my-files",
    title: "My Files",
    icon: process.env.PUBLIC_URL + "/images/profileIcons/myFiles.svg",
    description:
      "Personal archive, including your contract, module achievements & more ",
    infoCards: [
      {
        id: 1,
        title: "My files",
        icon: process.env.PUBLIC_URL + "/images/profileIcons/myFiles.svg",
        description:
          "Personal archive, including your contract, module achievements & more ",
      },
    ],
  },
  {
    id: 9,
    path: "my-payments",
    title: "My Payments",
    icon: process.env.PUBLIC_URL + "/images/profileIcons/payment.svg",
    description:
      "Review your payment history and check the status of your installment plans—all in one place",
    infoCards: [
      {
        id: 1,
        title: "My Payments",
        icon: process.env.PUBLIC_URL + "/images/profileIcons/payment.svg",
        description:
          "Review your payment history and check the status of your installments - all in one place ",
      },
    ],
  },
  {
    id: 10,
    path: "security",
    title: "Security",
    icon: process.env.PUBLIC_URL + "/images/profileIcons/security.svg",
    description: "Manage your password. ",
    infoCards: [
      {
        id: 1,
        title: "Security",
        icon: process.env.PUBLIC_URL + "/images/profileIcons/security.svg",
        description:
          "We value your privacy. When you update your password, we've got your data under lock, ensuring your information remains safe and secure.",
      },
    ],
  },
];

export default profileSections;

export const latinToCyrillicMap = {
  A: "А",
  a: "а",
  B: "Б",
  b: "б",
  V: "В",
  v: "в",
  G: "Г",
  g: "г",
  D: "Д",
  d: "д",
  G: "Ѓ",
  g: "ѓ",
  E: "Е",
  e: "е",
  Zh: "Ж",
  zh: "ж",
  Z: "З",
  z: "з",
  Dz: "Ѕ",
  dz: "ѕ",
  I: "И",
  i: "и",
  J: "Ј",
  j: "ј",
  K: "К",
  k: "к",
  L: "Л",
  l: "л",
  Lj: "Љ",
  lj: "љ",
  M: "М",
  m: "м",
  N: "Н",
  n: "н",
  Nj: "Њ",
  nj: "њ",
  O: "О",
  o: "о",
  P: "П",
  p: "п",
  R: "Р",
  r: "р",
  S: "С",
  s: "с",
  T: "Т",
  t: "т",
  Kj: "Ќ",
  kj: "ќ",
  U: "У",
  u: "у",
  F: "Ф",
  f: "ф",
  H: "Х",
  h: "х",
  C: "Ц",
  c: "ц",
  Ch: "Ч",
  ch: "ч",
  Dj: "Џ",
  dj: "џ",
  Sh: "Ш",
  sh: "ш",
  // Add more mappings as needed
};
