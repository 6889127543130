import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";

import { axios, makeApiRoute, makeAppRoute } from "../Router";
import { GlobalContext } from "../Context/GlobalProvider";
import { StyledPageWrapper } from "../Components/CommonStyledComponents/CommonStyledComponents";
import Footer from "../Components/Footer/Footer";
import Navbar from "../Components/Navbar/Navbar";
import SurveyContainer from "../Components/Survey/SurveyContainer";
import LoaderDots from "../Components/Loader/LoaderDots";
import theme from "../Consts/theme";
import Button from "../Components/Button/Button";


const Survey = () => {
  const navigate = useNavigate();
  const { popupConfig, setPopupConfig, setPopupDefaultValues } = useContext(GlobalContext);
  // states
  const [surveyData, setSurveyData] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const { survey_id } = useParams();

  // fetching data
  useEffect(() => {
    setIsLoading(true);
    axios
      .get(makeApiRoute("survey", { SURVEY_ID: survey_id }))
      .then((response) => {
        if (response.status === 200) {
          setSurveyData(() => response?.data);
          setIsLoading(false);
        }
      })
      .catch(function (error) {
        setIsLoading(false);
        setPopupConfig({
          ...popupConfig,
            show: true,
            subTitle: "",
            title: error?.response?.data?.error?.message || "Something went wrong, please try again later",
            subTitle: "",
            maxWidth: "500px",
            bodyClassname:"action-buttons",
            body: (
              <>
                <Button
                  text="Back to homepage"
                  variant="blueBtn"
                  onClick={onHomeRedirect}
                />
              </>
            ),
        });

        // throw error.response;
      });
  }, []);

  const onHomeRedirect = () => {
    setPopupDefaultValues()
    navigate(makeAppRoute("home"));
  };

  return (
    <StyledPageWrapper>
      <Navbar />
      {!isLoading ? (
        <SurveyContainer
          surveyData={surveyData}
          onHomeRedirect={onHomeRedirect}
        />
      ) : (
        <LoaderDots
          isLoading={isLoading}
          dotsBgColor={theme.lightBlue}
          height="100vh"
        />
      )}

      <Footer />
    </StyledPageWrapper>
  );
};
export default Survey;
