
import React, { useContext } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { GlobalContext } from "../Context/GlobalProvider";
import { makeAppRoute } from "../Router";
import SignContractNotification from "../Components/Notification/SignContractNotification";

const ProtectedRoutes = () => {
  const { isAuth, user, popupConfig, setPopupConfig } = useContext(GlobalContext);
  let location = useLocation();

  if (!isAuth) {
    return <Navigate to="/login" />;
  }

  if (user.signingUrl) {
    const signContractUrl = user.signingUrl
    window.open(signContractUrl)

    return <SignContractNotification />
  }

  if (user?.onboardingProcessComplete == 0 && !(location.pathname.startsWith("/welcome") || location.pathname.startsWith("/onboarding"))) {
    if (!user?.onboardingStep) {
      return <Navigate to={makeAppRoute("welcome")} />;
    } else {
      return (
        <Navigate
          to={makeAppRoute(`onboarding`, {
            STEP: user?.onboardingStep,
          })}
        />
      );
    }
  }

  return <Outlet />;
};

export default ProtectedRoutes;
