import React from "react";
import styled from "styled-components";

// style-components
const StyledDropdownArrow = styled.button`
  border: none;
  outline: none;
  cursor: pointer;
  background-color: transparent;
  padding: 0;
  transform: rotate(0deg);
  transition: all 0.6s;
  &.active {
    transform: rotate(180deg);
  }

  @media only screen and (min-width: ${(props) => props.theme.desktop}) {
    margin-left: 0;
  }
`;

const DropdownArrow = ({ onClick , className} ) => {

  return (
    <StyledDropdownArrow
      type="button"
      className={className}
      onClick={onClick}
    >
      <svg
        width="12"
        height="8"
        viewBox="0 0 12 8"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.41 0.589843L6 5.16984L10.59 0.589844L12 1.99984L6 7.99984L-6.16331e-08 1.99984L1.41 0.589843Z"
          fill="#191919"
        />
      </svg>
    </StyledDropdownArrow>
  );
};

export default DropdownArrow;
