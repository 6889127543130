import React from "react";

import {
  StyledLabel,
} from "../CommonStyledComponents/CommonStyledComponents";

const Label = ({ labelImage, label, className }) => {

  return (
    <StyledLabel
      className={className}
    >
      {className === "social-links" && (
        <span>
          <img src={labelImage} alt={label} />
        </span>
      )}
      <span className={className === "social-links" ? "social-links_link" : ""}>{label}</span>
    </StyledLabel>
  );
};
export default Label;
