import React from "react";
import styled from "styled-components";

import { Text } from "../../../../Consts/Text";
import theme from "../../../../Consts/theme";

// styled-components
const StyledContainer = styled.section`
  gap: 16px;
  border: 1px solid ${theme.concreteGray};
  border-radius: 16px;
  background-color: ${theme.white};
  margin-bottom: 20px;
`;

const StyledFormHeader = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 16px;
  padding: 16px 0;
  border-bottom: 1px solid ${theme.concreteGray};

  @media only screen and (min-width: ${(props) => props.theme.desktop}) {
    margin-left: 24px;
    margin-right: 24px;
  }
`;


const FileSectionContainer = ({ sectionTitle, children }) => {
  return (
    <StyledContainer>
      <StyledFormHeader>
        <Text fontSize="14px">{sectionTitle}</Text>
      </StyledFormHeader>

      {children}
    </StyledContainer>
  );
};

export default FileSectionContainer;
