import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";

import { Text } from "../../../Consts/Text";
import { dateFormater } from "../../../Consts/PureFunctions";
import theme from "../../../Consts/theme";
import ProgressBar from "../../ProgressTracker/ProgressBar";

// styled-components
const StyledContainer = styled.article`
  gap: 16px;
  border: 1px solid ${theme.concreteGray};
  border-radius: 16px;
  background-color: ${theme.white};
  margin-bottom: 20px;
  padding: 24px;
`;

const StyledAmountDetails = styled.div`
  margin-top: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledPaymentDetails = styled.div`
  margin-top: 24px;

  & > div {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
    padding-bottom: 16px;
    border-bottom: 1px solid ${theme.concreteGray};
  }
`;

const StyledProgressBar = styled.div`
  margin-top: 16px;
  margin-bottom: 20px;

  div > div {
    display: none;
  }
`;

const PaymentDetails = ({ data }) => {
  const { orderID } = useParams();
  const [currentPaymentDetails, setCurrentPaymentDetails] = useState({});
  // ----------------------------------------------------------------
  // VARIABLES
  const allUserOrders = data && data?.user?.orders;
  const userOrder = data && data?.user?.orders[0];
  const totalOrderAmount = +currentPaymentDetails?.amount;
  const completedPayments =
    currentPaymentDetails &&
    currentPaymentDetails?.installments?.filter(
      (installment) => installment.status === "success"
    );
  const upcomingPayments =
    currentPaymentDetails &&
    currentPaymentDetails?.installments?.filter(
      (installment) => installment.status !== "success"
    );

  const totalPaidAmount = completedPayments?.length * currentPaymentDetails?.installment_amount;
  const remainingAmount = totalOrderAmount - totalPaidAmount;

  const totalPaidAmountPercentage = Math.floor(
    (totalPaidAmount / totalOrderAmount) * 100
  );

  const nextInstallmentDate =
    upcomingPayments &&
    dateFormater(upcomingPayments?.[0]?.recurring_time, "/");

  useEffect(() => {
    if (allUserOrders?.length === 1 && !orderID) {
      setCurrentPaymentDetails(userOrder);
    } else {
      const currentOrder =
        allUserOrders && allUserOrders?.find((order) => +order.id === +orderID);

      setCurrentPaymentDetails(currentOrder);
    }
  }, [allUserOrders]);

  // -------------------------------------------------------------------------------
  // MAIN COMPONENT
  return (
    <>
      <StyledContainer>
        <div>
          <Text fontWeight={700}>Payment Balance</Text>
        </div>
        <StyledAmountDetails>
          <Text fontSize="24px" fontWeight={700}>
            {totalPaidAmount.toLocaleString()} MKD
          </Text>
          <Text fontWeight={500} fontSize="14px" color={theme.textGrey2}>
            {totalOrderAmount.toLocaleString()} MKD
          </Text>
        </StyledAmountDetails>
        <StyledProgressBar>
          <ProgressBar percentage={totalPaidAmountPercentage} />
        </StyledProgressBar>
      </StyledContainer>

      <StyledContainer>
        <div>
          <Text fontWeight={700}>Payment Details</Text>
        </div>
        <StyledPaymentDetails>
          {upcomingPayments?.length > 0 ? (
            <>
              <div>
                <Text fontWeight={500}>Next Installment</Text>
                <Text>{nextInstallmentDate}</Text>
              </div>
              <div>
                <Text fontWeight={500}>Remaining Installments</Text>
                <Text>{upcomingPayments?.length}</Text>
              </div>
            </>
          ) : (
            ""
          )}

          <div>
            <Text fontWeight={500}>Remaining Balance</Text>
            <Text>{remainingAmount.toLocaleString()} MKD</Text>
          </div>
          <div>
            <Text fontWeight={500}>Total Balance</Text>
            <Text>{totalOrderAmount.toLocaleString()} MKD</Text>
          </div>
        </StyledPaymentDetails>
      </StyledContainer>
    </>
  );
};

export default PaymentDetails;
