import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import styled from "styled-components";

import { makeAppRoute } from "../../../../Router";
import { isTwentyOrOlder } from "../../../../Consts/PureFunctions";
import { GlobalContext } from "../../../../Context/GlobalProvider";
import { Text } from "../../../../Consts/Text";
import {
  GET_HIGH_SCHOOL_INFO,
  UpdateUserHighSchoolInfo,
} from "../../../../Consts/GraphqlQueries";
import theme from "../../../../Consts/theme";
import textPages from "../../../../Consts/textPages.json";
import Autocomplete from "../../../Input/Autocomplete";
import FormSelectGroup from "../../../FormGroup/FormSelectGroup";
import LoaderDots from "../../../Loader/LoaderDots";
import ErrorMessageComponent from "../../../ErrorMessageComponent/ErrorMessageComponent";

// Styled-component
const StyledContainer = styled.section`
  gap: 16px;
  border: 1px solid ${theme.concreteGray};
  border-radius: 16px;
  background-color: ${theme.white};

  form {
    padding: 0 24px 32px;
  }
`;

const StyledFormHeader = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 16px 24px;
  padding: 16px 0;
  border-bottom: 1px solid ${theme.concreteGray};

`;
const StyledInnerWrap = styled.section`
  width: 100%;

  @media only screen and (min-width: ${(props) => props.theme.desktop}) {
    display: flex;
    width: 90%;
  }
`;

const StyledLoaderBox = styled.div`
  height: 60vh;
  width: 100%;
  display: flex;
  justify-content: center;

  .loader {
    padding-top: 0px;
  }
`;
const StyledInfoText = styled.p`
  font-style: normal;
  font-size: 10px;
  line-height: 14px;
  color: ${(props) => props.theme.lightGrayText};
  margin-bottom: 16px;
`;
const StyledForm = styled.form`
  padding: 0 15px;
  width: 100%;
  fieldset {
    width: 100%;
  }

  @media only screen and (min-width: ${(props) => props.theme.desktop}) {
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    gap: 2%;
    .flex-basis {
      flex-basis: 48%;
    }
  }
`;

// ----------------------------------------------------------------
// Error messages on input validation
const errorMessages = {
  high_school_id: "Please provide high school name you attended",
  still_in_high_school: "Please provide info if you are still in high school",
};

// Reset error messages
const resetErrors = {
  high_school_id: "",
  still_in_high_school: "",
};

// ----------------------------------------------------------------
/* This is input config that is used inside onInputChange function
   for dynamically getting values based on different input name. */

const highSchoolOtherID = parseInt(process.env.REACT_APP_HIGH_SCHOOL_OTHER_ID);
const inputConfigurations = {
  high_school_id: {
    defaultValue: highSchoolOtherID,
    relatedFieldName: "high_school_name",
    parseNumericValue: true,
  },
  still_in_high_school: {
    useUserInputAsIs: true,
  },
  // Add other fields if needed
};

const HighSchoolOnboarding = ({
  submitFunctionRef,
  onboardingRoutes,
  educationSteps,
}) => {
  const navigate = useNavigate();
  const userObjectRef = useRef();
  const { step } = useParams();
  const { user, setUser } = useContext(GlobalContext);
  // States
  const [errors, setErrors] = useState(resetErrors);

  //  ----------------------------------------------------------------
  // GRAPHQL GET REQUEST
  const {
    loading: queryLoading,
    error: queryError,
    data: queryData,
  } = useQuery(GET_HIGH_SCHOOL_INFO, {
    fetchPolicy: "cache-and-network",
  });

  //  ----------------------------------------------------------------
  // GRAPHQL POST REQUEST (MUTATION)
  const [
    updateUser,
    {
      data: mutationData,
      loading: mutationLoading,
      error: mutationError,
      called: mutationCalled,
    },
  ] = useMutation(UpdateUserHighSchoolInfo, {
    onCompleted: (data) => {
      handleUserUpdate(data.UpdateUser);

      const stillInHighSchool =
        data.UpdateUser.user_info?.still_in_high_school === "Yes";
      const onboardingRoutesNextElement =
        onboardingRoutes[onboardingRoutes.indexOf(educationSteps) + 1];
      const educationStepsNextElement =
        educationSteps[educationSteps.indexOf(step) + 1];

      navigate(
        makeAppRoute(`onboarding`, {
          STEP: stillInHighSchool
            ? onboardingRoutesNextElement
            : educationStepsNextElement,
        })
      );
    },
  });

  //  ----------------------------------------------------------------
  // VARIABLES
  let hasErrorValue = Object.values(errors).some((value) => value !== "");
  const userIsTwentyOrOlder = isTwentyOrOlder(user?.date_of_birth);
  const hasFetchedUser = queryData?.user !== undefined;
  const highSchoolsWithCities = queryData?.high_schools?.map((school) => ({
    ...school,
    name: `${school.name} - ${school.city?.name}`,
  }));

  useEffect(() => {
    if (mutationCalled) {
      handleUserUpdate(mutationData?.user);
    }
  }, [mutationCalled, queryData, mutationData]);

  useEffect(() => {
    // pass submit function to the ref that is coming from parent where this function is called
    if (submitFunctionRef?.current) {
      submitFunctionRef.current = onFormSubmit;
    }
    if (hasFetchedUser) {
      userObjectRef.current = queryData?.user;
      handleUserUpdate(queryData?.user);
    }
  }, [queryLoading, mutationLoading, hasFetchedUser]);

  // ----------------------------------------------------------------
  // Update the user object variable in Global context after submission

  const handleUserUpdate = (updatedUserData) => {
    setUser((prevUser) => ({
      ...prevUser,
      ...updatedUserData,
      user_info: {
        ...prevUser?.user_info,
        ...updatedUserData?.user_info,
      },
    }));
  };

  //  ----------------------------------------------------------------
  // ON INPUT CHANGE

  const onInputChange = (_inputName, _value) => {
    const config = inputConfigurations[_inputName];
    const isNumberValue = typeof _value === "number";

    if (!_value) {
      userObjectRef.current = {
        ...userObjectRef.current,
        user_info: {
          ...userObjectRef.current.user_info,
          [_inputName]: null,
          [config.relatedFieldName]: null,
        },
      };
    }
    if (config) {
      if (!isNumberValue) {
        // ----------------------------------------------------------------
        // If _value is not a number, it means that the user has typed a custom input that is not mentioned in the suggestion list.
        // In that case we store the _value (string) in profession_name/industry_name, while as ID we manually set the ID for key OTHER as given from server
        // ----------------------------------------------------------------
        const userInputString = _value?.toString();
        if (config.relatedFieldName && userInputString) {
          userObjectRef.current = {
            ...userObjectRef.current,
            user_info: {
              ...userObjectRef.current.user_info,
              [_inputName]: config.defaultValue,
              [config.relatedFieldName]: userInputString,
            },
          };
        } else if (config.useUserInputAsIs) {
          userObjectRef.current = {
            ...userObjectRef.current,
            user_info: {
              ...userObjectRef.current.user_info,
              [_inputName]: _value,
            },
          };
        }
      } else if (config.parseNumericValue) {
        // ----------------------------------------------------------------
        // If _value is a number (id), it means that the user has selected option from the suggestion list.
        // In that case we store the _value (id) in profession_id/industry_id, while as profession_name/industry_name we manually set NULL values.
        // ----------------------------------------------------------------
        const parsedValue = parseInt(_value);
        userObjectRef.current = {
          ...userObjectRef.current,
          user_info: {
            ...userObjectRef.current.user_info,
            [_inputName]: parsedValue,
            [config.relatedFieldName]: null,
          },
        };
      }

      // ----------------------------------------------------------------
      // Show error messages if required field is empty
      if (_value === "") {
        setErrors({
          ...errors,
          [_inputName]: errorMessages[_inputName],
        });
      }

      if (hasErrorValue && _value !== "") {
        setErrors({ ...errors, [_inputName]: "" });
      }
    }

    // ----------------------------------
    // If _value is empty, show the error message for that input
    if (_value === "") {
      setErrors({
        ...errors,
        [_inputName]: errorMessages[_inputName],
      });
    }
    if (hasErrorValue && _value !== "") {
      setErrors({ ...errors, [_inputName]: "" });
    }
  };
  // ----------------------------------------------------------------
  // Validate empty inputs

  const checkRequiredFields = (_array) => {
    const inputIsFilled = _array.length > 0 && _array?.every((item) => item);

    return inputIsFilled;
  };
  // ----------------------------------------------------------------
  // Validate required inputs

  const addRequiredFields = (array, condition, field) => {
    if (condition) {
      array.push(field);
    }
  };

  // ----------------------------------------------------------------
  // FORM SUBMIT

  const onFormSubmit = async (e) => {
    e?.preventDefault();

    let formData;
    let requiredFields = [];

    // Saving values
    const highSchoolID = parseInt(
      userObjectRef?.current?.user_info?.high_school_id
    );
    const highSchoolName = userObjectRef?.current?.user_info?.high_school_name;
    const stillInHighSchool =
      userObjectRef?.current?.user_info?.still_in_high_school;

    // Compare ID values with ID values for OTHER
    const userHasSelectedOtherHighSchool = highSchoolID === highSchoolOtherID;

    /* Depending on the value of userIsTwentyOrOlder, different fields are required 
    and therefore different values (formData) will be sent to server */

    if (userIsTwentyOrOlder) {
      requiredFields = [highSchoolID];
      addRequiredFields(
        requiredFields,
        highSchoolName === highSchoolOtherID,
        highSchoolName
      );

      formData = {
        onboarding_step: step,
        user_info: {
          high_school_id: +userObjectRef?.current?.user_info?.high_school_id,
          high_school_name: userObjectRef?.current?.user_info?.high_school_name,
          still_in_high_school: null,
        },
      };
    } else {
      requiredFields = [highSchoolID, stillInHighSchool];
      addRequiredFields(
        requiredFields,
        highSchoolName === highSchoolOtherID,
        highSchoolName
      );
      formData = {
        onboarding_step: step,
        user_info: {
          high_school_id: +userObjectRef?.current?.user_info?.high_school_id,
          high_school_name: userObjectRef?.current?.user_info?.high_school_name,
          still_in_high_school:
            userObjectRef?.current?.user_info?.still_in_high_school,
        },
      };
    }

    // ----------------------------------------------------------------
    // Validate empty fields

    if (!checkRequiredFields(requiredFields)) {
      // Update errors state with error messages for empty input fields
      const errorFields = Object.keys(errorMessages).reduce(
        (acc, fieldName) => {
          if (
            fieldName === "high_school_id" &&
            userHasSelectedOtherHighSchool &&
            !userObjectRef?.current?.user_info?.high_school_name
          ) {
            acc[fieldName] = [errorMessages[fieldName]];
          } else if (!userObjectRef?.current?.user_info?.[fieldName]) {
            acc[fieldName] = [errorMessages[fieldName]];
          }
          return acc;
        },
        {}
      );

      setErrors(errorFields);

      return;
    }

    // ----------------------------------------------------------------
    // Submit the form

    try {
      await updateUser({ variables: { inputData: formData } });
    } catch (err) {
      console.log(err);
    }
  };

  // -------------------------------------------------------------------------------
  // LOADING ERRORS
  if (queryError || mutationError)
    return (
      <ErrorMessageComponent
        firstText="Oops!"
        secondText="Something went wrong while fetching your data."
        thirdText="Please try again later."
      />
    );
  // -------------------------------------------------------------------------------
  // MAIN COMPONENT
  return (
    <StyledContainer>
      <StyledFormHeader className="mx-header">
        <Text fontSize="14px">High School</Text>
      </StyledFormHeader>
      <StyledInnerWrap ref={submitFunctionRef}>
        {queryLoading ? (
          <StyledLoaderBox>
            <LoaderDots
              className="loader"
              dotsBgColor={theme.darkBlue}
              dotHeight="8px"
              dotWidth="8px"
              dotMargin="8px"
              isLoading={queryLoading}
            />
          </StyledLoaderBox>
        ) : (
          <StyledForm onSubmit={onFormSubmit}>
            <StyledInfoText>* Indicates required</StyledInfoText>

            {/* -------------------- High school field conditions ----------------*/}
            <Autocomplete
              bilingualFilter
              data={highSchoolsWithCities}
              errors={errors.high_school_id}
              label={`${
                textPages?.profile?.education_details
                  ?.education_details_highSchool
              } *`}
              defaultValue={
                (user &&
                  +user?.user_info?.high_school_id === highSchoolOtherID &&
                  user?.user_info?.high_school_name) ||
                (user &&
                  user?.user_info?.high_school_id !== highSchoolOtherID &&
                  +user?.user_info?.high_school_id) ||
                (user &&
                  +user?.user_info?.high_school_id === highSchoolOtherID &&
                  !user?.user_info?.high_school_name &&
                  "")
              }
              placeholder="Search..."
              getInputValue={(newValue) => {
                onInputChange("high_school_id", newValue);
              }}
            />

            {!userIsTwentyOrOlder ? (
              <FormSelectGroup
                fieldLabel="Are you still in high school? *"
                options={["Yes", "No"]}
                errors={errors.still_in_high_school}
                isRequired
                selectLabel={"Please choose"}
                defaultValue={
                  user && user?.user_info?.still_in_high_school === null
                    ? "-1"
                    : user?.user_info?.still_in_high_school
                }
                getInputValue={(newValue) => {
                  onInputChange("still_in_high_school", newValue);
                }}
              />
            ) : (
              ""
            )}
          </StyledForm>
        )}
      </StyledInnerWrap>
    </StyledContainer>
  );
};

export default HighSchoolOnboarding;
