import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Element } from "react-scroll";
import styled from "styled-components";

import { GlobalContext } from "../../Context/GlobalProvider";
import { makeAppRoute } from "../../Router";
import {
  StyledHomepageSection,
  StyledHomePageTitle,
} from "../CommonStyledComponents/CommonStyledComponents";

import GeneralProgressTracker from "./GeneralProgressTracker";

// styled components
const StyledTrackerWrap = styled.section`
  display: grid;
  grid-template-columns: 47% 47%;
  grid-column-gap: 6%;
`;

const ProgressTrackerMainComponent = () => {
  const navigate = useNavigate();
  const { user, categories } = useContext(GlobalContext);

  const handleOnClick = (slug) => {
    navigate(
      makeAppRoute("category", {
        CATEGORY: slug,
      })
    );
  };
  const calculatePercentage = (_category) => {
    const percentage =
      _category?.totalResources > 0
        ? Math.round(
            (_category?.totalCompletedResources / _category?.totalResources) *
              100
          )
        : 0;
    return percentage;
  };

  return (
    <Element name="progressTracker">
      <StyledHomepageSection>
        <StyledHomePageTitle>Progress Tracker</StyledHomePageTitle>
        <StyledTrackerWrap>
          <GeneralProgressTracker
            title={user && user.group && user.group.academy.title}
            percentage={
              user && user?.group && Math.round(user?.group?.groupCompleted)
            }
            progressBarContent={
              user &&
              user?.group &&
              Math.round(user?.group?.groupCompleted) > 100
                ? "100%"
                : `${Math.round(user?.group?.groupCompleted)}%`
            }
            icon={
              Math.round(user?.group?.groupCompleted) === 0
                ? "/images/academy-not-started.svg"
                : "/images/academy-progress.svg"
            }
          />

          {categories.map((category) => (
            <GeneralProgressTracker
              key={category.id}
              title={category.title}
              percentage={calculatePercentage(category)}
              progressBarContent={`${calculatePercentage(category)}%`}
              icon={
                // if student has completed the category material ( 100% ) or is still in progress ( < 100% )
                category.totalCompletedResources <= category.totalResources
                  ? process.env.REACT_APP_FILES_URL +
                    "/" +
                    category.completedIcon
                  : // if student hasn't started completing the materials from some category
                    category.totalCompletedResources === 0 &&
                    category.totalResources > 0 &&
                    process.env.REACT_APP_FILES_URL + "/" + category.icon
              }
              onClick={() => {
                handleOnClick(category.slug);
              }}
            />
          ))}
        </StyledTrackerWrap>
      </StyledHomepageSection>
    </Element>
  );
};
export default ProgressTrackerMainComponent;
