import React, { useEffect, useState, useCallback, useContext } from "react";
import { Element } from "react-scroll";
import { useNavigate } from "react-router-dom";
import { FcPrevious, FcNext } from "react-icons/fc";
import { BsInfoCircleFill } from "react-icons/bs";
import styled from "styled-components";

import { axios, makeApiRoute, makeAppRoute } from "../../Router";
import { StyledHomepageSection } from "../CommonStyledComponents/CommonStyledComponents";
import useSortableData from "../../Hooks/useSortData";
import Button from "../Button/Button";
import useMediaCustom from "../../Hooks/useMediaCustom";
import HeadingRow from "./HeadingRow";
import TableBodyRow from "./TableBodyRow";
import { GlobalContext } from "../../Context/GlobalProvider";

// styled-components
const StyledNavIcons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;

  h2 {
    margin-bottom: 0;
    @media only screen and (min-width: ${(props) => props.theme.desktop}) {
      margin-bottom: 30px;
    }
  }

  span {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #e6e7eb;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  span svg polygon {
    fill: ${(props) => props.theme.darkBlue2};
  }
`;
const StyledHomePageTitle = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  color: #191919;
  display: flex;
  @media only screen and (min-width: ${(props) => props.theme.desktop}) {
    margin-bottom: 30px;
  }
`;
const StyledInfoIcon = styled.div`
  font-size: 15px;
  width: 100%;
  height: auto;
  margin-left: 5px;
  cursor: pointer;
  position: relative;

  svg {
    fill: #65676c;
  }

  p.tooltiptext {
    visibility: hidden;
    display: inline-block;
    width: auto;
    background-color: transparent;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    font-size: 14px;
    /* Position the tooltip */
    position: absolute;
    left: 20px;
    top: 0;
    z-index: 1;
    padding: 2px 5px;
    transition: background-color 0.5s ease-in-out;
  }
  :hover p.tooltiptext {
    visibility: visible;
    background-color: ${(props) => props.theme.textGrey};
  }
`;
const StyledOuterWrap = styled.div`
  margin-bottom: 15px;

  div.tooltiptext {
    margin-bottom: 10px;
    text-align: center;
    font-size: 13px;
  }
`;
const Leaderboard = () => {
  // states
  const [allStudents, setAllStudents] = useState([]);
  const [rowsShown, setRowsShown] = useState(5);
  const [currentStep, setCurrentStep] = useState(0);
  const [showTooltip, setShowTooltip] = useState(false);
  const { user } = useContext(GlobalContext);
  // custom hook
  const { requestSort, sortConfig } = useSortableData(allStudents);
  const isDesktop = useMediaCustom("(min-width: 992px)");
  const navigate = useNavigate();

  // finding the activities of the specific group
  const hasTotalPoints = allStudents[0]?.total_points >= "0.0";

  const hasChallengesPoints =
    allStudents[0]?.challenges_available_points > 0 ||
    allStudents[0]?.challenges_available_extra_points > 0;

  const hasProjectsPoints = allStudents?.find(
    (eachStudent) =>
      eachStudent.projects_available_points > 0 ||
      eachStudent.projects_available_extra_points > 0
  );

  const hasHackathonsPoints = allStudents?.find(
    (eachStudent) =>
      eachStudent.hackathons_available_points > 0 ||
      eachStudent.hackathons_available_extra_points > 0
  );
  const hasExtraPoints = allStudents?.find(
    (eachStudent) => eachStudent.extra_points > 0
  );

  // collecting the activites from the group in array
  const groupActivities = [];

  if (hasTotalPoints) {
    groupActivities.push({ id: "total_points", label: "Total Points" });
  }
  if (hasChallengesPoints) {
    groupActivities.push({
      id: "challenges_acquired_points",
      label: "Challenges",
    });
  }
  if (hasProjectsPoints) {
    groupActivities.push({
      id: "projects_acquired_points",
      label: "Projects",
    });
  }
  if (hasHackathonsPoints) {
    groupActivities.push({
      id: "hackathons_acquired_points",
      label: "Hackathos",
    });
  }
  if (hasExtraPoints) {
    groupActivities.push({ id: "extra_points", label: "Extra Points" });
  }

  // determining what is contained in groupActivities in order to display the points for every student and for the activity,
  // depending what type of activity has the group
  const foundTotalPoints = groupActivities.some(
    (activity) => activity.id === "total_points"
  );
  const foundChallengesPoints = groupActivities.some(
    (activity) => activity.id === "challenges_acquired_points"
  );
  const foundProjectsPoints = groupActivities.some(
    (activity) => activity.id === "projects_acquired_points"
  );
  const foundHackathonsPoints = groupActivities.some(
    (activity) => activity.id === "hackathons_acquired_points"
  );
  const foundExtraPoints = groupActivities.some(
    (activity) => activity.id === "extra_points"
  );

  // fetching data
  useEffect(() => {
    axios.get(makeApiRoute("totalPoints")).then((response) => {
      setAllStudents(response?.data?.data);
    });
  }, []);


  // show more or less of the Leaderboard
  const onViewEntireLeaderboard = useCallback(() => {
    rowsShown !== allStudents?.length
      ? setRowsShown(allStudents?.length)
      : setRowsShown(5);
  }, [rowsShown, allStudents]);

  // display next / prev of the Leaderboard on mobile resolutions
  const prevPage = () => {
    if (currentStep > 0) {
      setCurrentStep((prev) => (prev - 1) % groupActivities.length);
    }
  };

  const nextPage = () => {
    if (groupActivities.length > 0) {
      setCurrentStep((prev) => (prev + 1) % groupActivities.length);
    }
  };

  return (
    <StyledHomepageSection>
      {isDesktop && (
        <Element name="leaderboard">
          <StyledHomePageTitle>
            <span>Leaderboard </span>
            <StyledInfoIcon>
              <BsInfoCircleFill />
              <p className="tooltiptext">
                Leaderboard will be hidden once you finish with all the lectures
                from the program.
              </p>
            </StyledInfoIcon>
          </StyledHomePageTitle>
        </Element>
      )}
      {!isDesktop && (
        <StyledOuterWrap>
          <StyledNavIcons>
            <span onClick={() => prevPage()}>
              <FcPrevious />
            </span>
            <StyledHomePageTitle>
              <p>Leaderboard</p>
              <StyledInfoIcon
                className="leaderboard-info"
                onClick={() => setShowTooltip(!showTooltip)}
              >
                <BsInfoCircleFill />
              </StyledInfoIcon>
            </StyledHomePageTitle>
            <span onClick={() => nextPage()}>
              <FcNext />
            </span>
          </StyledNavIcons>
          {showTooltip && (
            <div className="tooltiptext">
              Leaderboard will be hidden once you finish with all the lectures
              from the program.
            </div>
          )}
        </StyledOuterWrap>
      )}

      {/* -  -  -  -  -  -  -  -  -  -  Mobile resolution -  -  -  -  -  -  -  -  -  -  -   */}

      {/* total points */}
      {groupActivities[currentStep]?.id === "total_points" && !isDesktop && (
        <>
          <HeadingRow
            allStudents={allStudents}
            sortBy={sortConfig?.key}
            onClick={requestSort}
            direction={sortConfig?.direction}
            columnArray={[groupActivities[currentStep]]}
          />
          {allStudents
            ?.slice(0, rowsShown)
            .map(({ id, name, user_id, total_points }) => (
              <TableBodyRow
                key={id}
                name={name}
                studentPoints={[{ points: total_points }]}
                onClick={() => {
                  if (+user.id === +user_id) {
                    navigate(
                      makeAppRoute(`points`, {
                        USER_ID: +user.id,
                      })
                    );
                  }
                  return
                }}
              />
            ))}
        </>
      )}

      {/* total challenges */}
      {groupActivities[currentStep]?.id === "challenges_acquired_points" &&
        !isDesktop && (
          <>
            <HeadingRow
              allStudents={allStudents}
              sortBy={sortConfig?.key}
              onClick={requestSort}
              direction={sortConfig?.direction}
              columnArray={[groupActivities[currentStep]]}
            />
            {allStudents
              ?.slice(0, rowsShown)
              .map(
                ({
                  id,
                  name,
                  user_id,
                  challenges_acquired_points,
                  challenges_available_points,
                }) => (
                  <TableBodyRow
                    key={id}
                    name={name}
                    studentPoints={[
                      {
                        points: `${challenges_acquired_points} / ${challenges_available_points}`,
                      },
                    ]}
                    onClick={() => {
                      if (+user.id === +user_id) {
                        navigate(
                          makeAppRoute(`points`, {
                            USER_ID: +user.id,
                          })
                        );
                      }
                      return
                    }}
                  />
                )
              )}
          </>
        )}
      {/* total projects */}
      {groupActivities[currentStep]?.id === "projects_acquired_points" &&
        !isDesktop && (
          <>
            <HeadingRow
              allStudents={allStudents}
              sortBy={sortConfig?.key}
              onClick={requestSort}
              direction={sortConfig?.direction}
              columnArray={[groupActivities[currentStep]]}
            />
            {allStudents
              ?.slice(0, rowsShown)
              .map(
                ({
                  id,
                  name,
                  user_id,
                  projects_acquired_points,
                  projects_available_points,
                }) => (
                  <TableBodyRow
                    key={id}
                    name={name}
                    studentPoints={[
                      {
                        points: `${projects_acquired_points} / ${projects_available_points}`,
                      },
                    ]}
                    onClick={() => {
                      if (+user.id === +user_id) {
                        navigate(
                          makeAppRoute(`points`, {
                            USER_ID: +user.id,
                          })
                        );
                      }
                      return
                    }}
                  />
                )
              )}
          </>
        )}

      {/* total hackathons */}
      {groupActivities[currentStep]?.id === "hackathons_acquired_points" &&
        !isDesktop && (
          <>
            <HeadingRow
              allStudents={allStudents}
              sortBy={sortConfig?.key}
              onClick={requestSort}
              direction={sortConfig?.direction}
              columnArray={[groupActivities[currentStep]]}
            />
            {allStudents
              ?.slice(0, rowsShown)
              .map(
                ({
                  id,
                  name,
                  user_id,
                  hackathons_acquired_points,
                  hackathons_available_points,
                }) => (
                  <TableBodyRow
                    key={id}
                    name={name}
                    studentPoints={[
                      {
                        points: `${hackathons_acquired_points} / ${hackathons_available_points}`,
                      },
                    ]}
                    onClick={() => {
                      if (+user.id === +user_id) {
                        navigate(
                          makeAppRoute(`points`, {
                            USER_ID: +user.id,
                          })
                        );
                      }
                      return
                    }}
                  />
                )
              )}
          </>
        )}

      {/* extra points */}
      {groupActivities[currentStep]?.id === "extra_points" && !isDesktop && (
        <>
          <HeadingRow
            allStudents={allStudents}
            sortBy={sortConfig?.key}
            onClick={requestSort}
            direction={sortConfig?.direction}
            columnArray={[groupActivities[currentStep]]}
          />
          {allStudents
            ?.slice(0, rowsShown)
            .map(({ id, name, extra_points, user_id }) => (
              // <>
              <TableBodyRow
                key={id}
                name={name}
                studentPoints={[{ points: extra_points }]}
                onClick={() => {
                  if (+user.id === +user_id) {
                    navigate(
                      makeAppRoute(`points`, {
                        USER_ID: +user.id,
                      })
                    );
                  }
                  return;
                }}
              />
              // </>
            ))}
        </>
      )}
      {/* -  -  -  -  -  -  -  -  -  -  Desktop resolution -  -  -  -  -  -  -  -  -  -  -   */}
      {isDesktop && (
        <>
          <HeadingRow
            allStudents={allStudents}
            sortBy={sortConfig?.key}
            onClick={requestSort}
            direction={sortConfig?.direction}
            columnArray={groupActivities}
          />
          {allStudents
            .slice(0, rowsShown)
            .map(
              ({
                id,
                name,
                user_id,
                challenges_acquired_points,
                challenges_available_points,
                challenges_acquired_extra_points,
                projects_available_points,
                projects_acquired_points,
                projects_acquired_extra_points,
                hackathons_available_points,
                hackathons_acquired_points,
                hackathons_acquired_extra_points,
                extra_points,
                total_points,
              }) => (
                <TableBodyRow
                  key={id}
                  pointer={+user.id === +user_id}
                  name={name}
                  onClick={() => {
                    if (+user.id === +user_id) {
                      navigate(
                        makeAppRoute(`points`, {
                          USER_ID: +user.id,
                        })
                      );
                    }
                    return;
                  }}
                  challenges_acquired_extra_points={
                    challenges_acquired_extra_points
                  }
                  projects_acquired_extra_points={
                    projects_acquired_extra_points
                  }
                  hackathons_acquired_extra_points={
                    hackathons_acquired_extra_points
                  }
                  studentPoints={[
                    foundTotalPoints && { total_points },
                    foundChallengesPoints && {
                      challenges_points: `${challenges_acquired_points} / ${challenges_available_points}`,
                    },
                    foundProjectsPoints && {
                      projects_points: `${projects_acquired_points} / ${projects_available_points}`,
                    },
                    foundHackathonsPoints && {
                      hackathons_points: `${hackathons_acquired_points} / ${hackathons_available_points}`,
                    },
                    foundExtraPoints && { extra_points },
                  ]}
                />
              )
            )}
        </>
      )}

      <Button
        type={"button"}
        variant={"blueBtn"}
        onClick={onViewEntireLeaderboard}
        className={"leaderboard-btn"}
        text={
          rowsShown !== allStudents?.length
            ? "VIEW THE ENTIRE LEADERBOARD"
            : "SHOW LESS"
        }
      />
    </StyledHomepageSection>
  );
};
export default Leaderboard;
