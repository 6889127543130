import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { GrClose } from "react-icons/gr";

import ResourceMenu from "./ResourceMenu";
import MaterialDisplay from "./MaterialDisplay";

// styled-components
const StyledResourceDataContainer = styled.section`
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  background-color: ${(props) => props.theme.black};
  z-index: 99999;
  min-height: 150%;
  width: 100%;
  padding: 30px;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 4px;
    border-radius: 4px;
  }
  &::-webkit-scrollbar-track-piece {
    background-color: ${(props) => props.theme.lightGray2};
    border-radius: 4px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #e1e2e7;
    border-radius: 4px;
  }

  @media only screen and (min-width: ${(props) => props.theme.laptop}) {
    min-height: 100vh;
    max-height: 100vh;
  }
`;
const StyledInnerWrap = styled.div`
  display: grid;

  @media only screen and (min-width: ${(props) => props.theme.desktop}) {
    grid-template-columns: 25% auto;
    grid-column-gap: 40px;
  }
`;
const StyledCloseIcon = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  cursor: pointer;

  svg path {
    stroke: ${(props) => props.theme.white};
  }
`;

const MaterialsContainer = ({
  openMaterials,
  onCloseMaterials,
  currentSubunit,
  activeResource,
  hasCompletedCategory,
  onResourceStatusChanged,
}) => {
  // activeResourceData is an object contains the item that is currently selected
  const [activeMaterial, setActiveMaterial] = useState();

  useEffect(() => {
    setActiveMaterial(activeResource && activeResource?.resourceData[0]);
  }, [activeResource]);

  return (
    <StyledResourceDataContainer openMaterials={openMaterials}>
      <StyledCloseIcon className="closeIcon">
        <span onClick={onCloseMaterials}>
          <GrClose />
        </span>
      </StyledCloseIcon>
      {/* ------------------------------------------------------- */}
      <StyledInnerWrap className="innerWrap">
        <ResourceMenu
          currentSubunit={currentSubunit}
          activeMaterial={activeMaterial}
          setActiveMaterial={setActiveMaterial}
          hasCompletedCategory={hasCompletedCategory}
          onResourceStatusChanged={onResourceStatusChanged}
        />
        <MaterialDisplay
          currentSubunit={currentSubunit}
          activeMaterial={activeMaterial}
          setActiveMaterial={setActiveMaterial}
        />
      </StyledInnerWrap>
    </StyledResourceDataContainer>
  );
};
export default MaterialsContainer;
