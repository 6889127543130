import React from "react";
import styled from "styled-components";

import { Text } from "../../../../Consts/Text";
import theme from "../../../../Consts/theme";
import ModuleAchievements from "./ModuleAchievements";
import useShowMoreFiles from "../../../../Hooks/useShowMoreFiles";
import ShowMoreLessButton from "../../../Button/ShowMoreLessButton";
import FileSectionContainer from "./FileSectionContainer";

// styled-components
const StyledInfoMessage = styled.div`
  margin: 0 0 16px 16px;
  padding-left: 8px;
`;

const INITIALLY_DISPLAYED_ACHIEVEMENTS = 4;

const ModuleAchievementContainer = ({ moduleAchievements }) => {
  const attendanceModuleAchievements = moduleAchievements?.filter(
    (achievement) => achievement.type === "Attendance Rate"
  );

  const {
    numberOfInitialFilesDisplayed,
    totalNumberOfAchievements,
    onShowMoreAchievements,
  } = useShowMoreFiles(
    INITIALLY_DISPLAYED_ACHIEVEMENTS,
    attendanceModuleAchievements
  );

  return (
    <FileSectionContainer sectionTitle="Module Achievements">
      {attendanceModuleAchievements.length > 0 ? (
        attendanceModuleAchievements
          ?.slice(0, numberOfInitialFilesDisplayed)
          ?.map((achievement) => (
            <ModuleAchievements key={achievement.id} achievement={achievement} />
          ))
      ) : (
        <StyledInfoMessage>
          <Text fontSize="14px" fontWeight="600" color={theme.textGrey2}>
            You currently do not have any module achievements.
          </Text>
        </StyledInfoMessage>
      )}

      {/* ------------- Show More / Less Button */}
      <ShowMoreLessButton
        totalNumberOfAchievements={totalNumberOfAchievements}
        numberOfInitialFilesDisplayed={numberOfInitialFilesDisplayed}
        initialNumOfFiles={INITIALLY_DISPLAYED_ACHIEVEMENTS}
        onShowMoreAchievements={onShowMoreAchievements}
      />
    </FileSectionContainer>
  );
};

export default ModuleAchievementContainer;
