import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { axios, makeApiRoute, makeAppRoute } from "../../Router";
import {
  ErrorMessage,
  StyledFormAuth,
  StyledFormGroup,
  StyledServerResponse,
} from "../CommonStyledComponents/CommonStyledComponents";
import theme from "../../Consts/theme";
import Button from "../Button/Button";
import LoaderDots from "../Loader/LoaderDots";
import PasswordInput from "../Input/PasswordInput";

const PasswordReset = ({ token }) => {
  const location = useLocation();
  const navigate = useNavigate();
  // states
  const [isLoading, setIsLoading] = useState(false);
  const [inputs, setInputs] = useState({
    password: "",
    password_confirmation: "",
  });
  const [serverResponse, setServerResponse] = useState({
    success: "",
    error: "",
  });

  const onInputChange = (e) => {
    setInputs((inputs) => ({ ...inputs, [e.target.name]: e.target.value }));
    setServerResponse({ ...serverResponse, error: "" });
  };

  const handleBackToPage = () => {
    if (serverResponse?.success !== "") {
      navigate(makeAppRoute("login"));
    } else {
      navigate(makeAppRoute("requestPasswordReset"));
    }
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    const queryParams = new URLSearchParams(location.search);
    const email = queryParams.get("email");
    const formData = { email: email, ...inputs, token: token };

    axios
      .post(makeApiRoute("passwordReset"), formData)
      .then((response) => {
        setServerResponse({
          success: response?.data?.success?.message,
          error: "",
        });
        setIsLoading(false);
      })
      .catch((error) => {
      
        setServerResponse({
          ...serverResponse,
          error: error?.response?.data?.error?.message,
        });
        setIsLoading(false);
      });
  };

  return (
    <StyledFormAuth onSubmit={handleFormSubmit}>
      <StyledFormGroup>
        <label>Password</label>
        <PasswordInput
          name="password"
          placeholder="xxxxxxxx"
          value={inputs.password}
          onChange={onInputChange}
          disabled={isLoading}
        />
        {serverResponse?.error !== "" ? (
          <div className="error">
            <ErrorMessage>{serverResponse?.error}</ErrorMessage>
          </div>
        ) : (
          ""
        )}
      </StyledFormGroup>

      <StyledFormGroup>
        <label>Confirm Password</label>
        <PasswordInput
          name="password_confirmation"
          placeholder="xxxxxxxx"
          value={inputs?.confirmPassword}
          onChange={onInputChange}
          disabled={isLoading}
        />
        {serverResponse.success !== "" ? (
          <StyledServerResponse>{serverResponse.success}</StyledServerResponse>
        ) : (
          ""
        )}
        {serverResponse?.error !== "" ? (
          <div className="error">
            <ErrorMessage>{serverResponse?.error}</ErrorMessage>
          </div>
        ) : (
          ""
        )}
      </StyledFormGroup>

      <div className="submit-button">
        <Button
          text={
            !isLoading ? (
              "Reset password"
            ) : (
              <LoaderDots
                isLoading={isLoading}
                dotsBgColor={theme.lightBlue}
                dotHeight="5px"
                dotWidth="5px"
                dotMargin="10px"
                buttonSubmit
              />
            )
          }
          variant={serverResponse?.success !== "" ? "grayBtn" : "blueBtn"}
          className="login-submit"
          disabled={serverResponse.success !== "" || isLoading}
        />
        <Button
          text={serverResponse?.success !== "" ? "Go to Login" : "Back"}
          variant={serverResponse?.success !== "" ? "blueBtn" : "grayBtn"}
          className="login-submit"
          onClick={handleBackToPage}
          disabled={isLoading}
        />
      </div>
    </StyledFormAuth>
  );
};
export default PasswordReset;
