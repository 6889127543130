import React from "react";
import styled from "styled-components";

import { BsStars } from "react-icons/bs";
import { Text } from "../../Consts/Text"

const StyledContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  .star {
    font-size: 30px;
    margin-bottom: 16px;
    color: purple;
    animation: pulse 1.3s ease-in-out infinite;
  }

  #star1 {
    animation-delay: 0s;
  }

  #star2 {
    animation-delay: 0.4s;
  }

  #star3 {
    animation-delay: 0.8s;
  }

  @keyframes pulse {
    0%,
    100% {
      transform: scale(1);
      opacity: 1;
    }
    50% {
      transform: scale(1.5);
      opacity: 0.5;
    }
  }
`;

const LoaderAI = () => {
  return (
    <StyledContainer class="loader">
      <div class="star" id="star1">
      <BsStars />
      </div>
      <Text color="purple" fontSize="16px" fontWeight="500">Loading...</Text>
    </StyledContainer>
  );
};

export default LoaderAI;
