import React, { useEffect, useState }from "react";
import styled from "styled-components";

import { ErrorMessage, StyledLabel} from "../CommonStyledComponents/CommonStyledComponents";

// styled-components
const StyledFormGroup = styled.fieldset`
  margin-bottom: 2rem;
`;


const StyledInput = styled.input`
  display: block;
  text-align: left;
  margin: 8px auto;
  width: 100%;
  padding: 15px 10px;
  outline: none;
  border: none;
  border: 1px solid ${(props) => props.theme.gray};
  border-bottom: 1px solid ${(props) => props.theme.lightLightGray};
  border-radius: 4px;

/* 
  &:focus {
    outline: .5px solid ${(props) => props.theme.lightBlue};;
    transform: scale(1.05);
  } */

  @media only screen and (min-width: ${(props) => props.theme.desktop}) {
   font-size: 20px;
  }
`;

const FormGroup = ({ type, value, label, onInputUpdate, readOnly, error}) => {
const [inputValue, setInputValue] = useState("")


useEffect(() => {
  setInputValue(value)
}, [value])
  
  
  return (
    <StyledFormGroup>
      <StyledLabel>{label}</StyledLabel>
      <StyledInput
        readOnly={readOnly}
        type={type}
        value={inputValue}
        style={{ color: "black" }}
        onChange={(e) => {
          onInputUpdate(e.target.value)
          setInputValue(e.target.value)
        }}
      />
      {error && <ErrorMessage>Field is required</ErrorMessage>}
    </StyledFormGroup>
  );
};
export default FormGroup;
