import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import styled from "styled-components";

import { makeAppRoute } from "../../Router";
import { GlobalContext } from "../../Context/GlobalProvider";
import { StyledForm } from "../CommonStyledComponents/CommonStyledComponents";
import { Text } from "../../Consts/Text";
import {
  GET_HIRING_PREFERENCES_DATA,
  UpdateUserHiringPreferences,
} from "../../Consts/GraphqlQueries";
import theme from "../../Consts/theme";
import MobileHeader from "./MobileHeader/MobileHeader";
import useMediaCustom from "../../Hooks/useMediaCustom";
import LoaderDots from "../Loader/LoaderDots";
import FormMultipleSelectGroup from "../FormGroup/FormMultipleSelectGroup";
import SubmitButton from "../Button/SubmitButton";
import ErrorMessageComponent from "../ErrorMessageComponent/ErrorMessageComponent";

// styled-components
const StyledContainer = styled.section`
  gap: 16px;
  border: 1px solid ${theme.concreteGray};
  border-radius: 16px;
  background-color: ${theme.white};
`;
const StyledFormHeader = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 16px;
  padding: 16px 0;
  border-bottom: 1px solid ${theme.concreteGray};

  @media only screen and (min-width: ${(props) => props.theme.desktop}) {
    margin-left: 24px;
    margin-right: 24px;
  }
`;
const StyledInnerWrap = styled.section`
  width: 100%;

  @media only screen and (min-width: ${(props) => props.theme.desktop}) {
    display: flex;
    width: 90%;

  }

`;
const StyledLoaderBox = styled.div`
  height: 60vh;
  width: 100%;
  display: flex;
  justify-content: center;

  .loader {
    padding-top: 0px;
  }
`;

const StyledInfoText = styled.p`
  font-style: normal;
  font-size: 10px;
  line-height: 14px;
  color: ${(props) => props.theme.lightGrayText};
  margin-bottom: 16px;
`;

// ----------------------------------------------------------------
// Error messages on input validation
const errorMessages = {
  recommendation_preferences: "Please provide your recommendation preferences",
};
// Reset error messages
const resetErrors = {
  recommendation_preferences: "",
};

// component
const HiringPreferences = ({
  onboarding,
  submitFunctionRef,
  onboardingRoutes,
}) => {
  const navigate = useNavigate();
  const isDesktop = useMediaCustom("(min-width: 992px)");
  const userObjectRef = useRef();
  const { step } = useParams();
  const { user, setUser } = useContext(GlobalContext);
  // states
  const [hasCompletedMutation, setHasCompletedMutation] = useState(false);
  const [errors, setErrors] = useState(resetErrors);
  const [saveBtnDisabled, setSaveBtnDisabled] = useState(true);
  //  ----------------------------------------------------------------
  // GRAPHQL GET REQUEST
  const {
    loading: queryLoading,
    error: queryError,
    data: queryData,
  } = useQuery(GET_HIRING_PREFERENCES_DATA, {
    fetchPolicy: "cache-and-network",
  });
  //  ----------------------------------------------------------------
  // GRAPHQL POST REQUEST (MUTATION)
  const [
    updateUser,
    {
      data: mutationData,
      loading: mutationLoading,
      error: mutationError,
      called: mutationCalled,
    },
  ] = useMutation(UpdateUserHiringPreferences, {
    onCompleted: (data) => {
      handleUserUpdate(data.UpdateUser);
      if (!onboarding) {
        setSaveBtnDisabled(true);
        setHasCompletedMutation(true);
        handleCompletedMutation();
      } else {
        const onboardingRoutesNextStep =
          onboardingRoutes[onboardingRoutes.indexOf(step) + 1];
        navigate(
          makeAppRoute(`onboarding`, {
            STEP: onboardingRoutesNextStep,
          })
        );
      }
    },
  });
  // ----------------------------------------------------------------
  // VARIABLES
  const hasFetchedUser = queryData?.user !== undefined;

  useEffect(() => {
    if (mutationCalled) {
      handleUserUpdate(mutationData?.UpdateUser);
    }
  }, [mutationCalled, queryData, mutationData]);

  useEffect(() => {
    // pass submit function to the ref that is coming from parent where this function is called
    if (submitFunctionRef?.current) {
      submitFunctionRef.current = handleFormSubmit;
    }
    if (!mutationCalled && hasFetchedUser) {
      userObjectRef.current = queryData?.user;
      handleUserUpdate(queryData?.user);
    }
  }, [queryLoading, mutationLoading, hasFetchedUser]);

  // ----------------------------------------------------------------
  // Update the user object in Global context after submission and on page load

  const handleUserUpdate = (updatedUserData) => {
    setUser((prevUser) => ({
      ...prevUser,
      ...updatedUserData,
      user_info: {
        ...prevUser?.user_info,
        ...updatedUserData?.user_info,
      },
    }));
  };

  // ----------------------------------------------------------------
  // Return hasCompletedMutation to false to remove the style for success message

  const handleCompletedMutation = () => {
    const timer = setTimeout(() => {
      setHasCompletedMutation(false);
    }, 4000);

    // Clear the timeout if the component unmounts
    return () => clearTimeout(timer);
  };

  //  ------------------------------------------------------------------
  // ON INPUT CHANGE

  const onInputChange = (_inputName, _value) => {
    userObjectRef.current = {
      ...userObjectRef.current,
      [_inputName]: _value,
    };

    // ----------------------------------------------------------------
    // Show error messages if required field is empty
    if (_inputName === "recommendation_preferences" && _value.length === 0) {
      setErrors({
        ...errors,
        [_inputName]: errorMessages[_inputName],
      });
    } else {
      setErrors({ ...errors, [_inputName]: "" });
    }
    setSaveBtnDisabled(false);

    // ----------------------------------------------------------------
    // If user prefers not to be recommended,
    // then remove all previous selected options in the input with name "recommendation_preferences"
    if (
      _inputName === "recommendation_preferences" &&
      _value.find((item) => item.id === 5)
    ) {
      const doNotRecommendMe = _value.filter((item) => item.id === 5);

      userObjectRef.current = {
        ...userObjectRef.current,
        recommendation_preferences: doNotRecommendMe,
      };
    }
  };

  // ----------------------------------------------------------------
  // FORM SUBMIT

  const handleFormSubmit = async (e) => {
    e?.preventDefault();

    //  __typename is returned from GraphQL and it is needed to be removed before storing values in formData
    const recommendationPreferences =
      userObjectRef?.current?.recommendation_preferences?.map(
        ({ __typename, ...recommendations }) => recommendations
      );
    const workPreferences = userObjectRef?.current?.work_preferences?.map(
      ({ __typename, ...workPreferencesFromUser }) => workPreferencesFromUser
    );

    const formData = {
      onboarding_step: step,
      recommendation_preferences: recommendationPreferences,
      work_preferences: workPreferences,
    };

    // ----------------------------------------------------------------
    // Validate empty fields
    if (userObjectRef?.current?.recommendation_preferences.length === 0) {
      setSaveBtnDisabled(true);

      // Update errors state with error messages for empty input fields
      const errorFields = Object.keys(errorMessages).reduce(
        (acc, fieldName) => {
          if (userObjectRef?.current?.[fieldName].length === 0) {
            acc[fieldName] = [errorMessages[fieldName]];
          }

          return acc;
        },
        {}
      );

      setErrors(errorFields);
      return;
    }

    // Submit the form
    try {
      await updateUser({ variables: { inputData: formData } });
    } catch (err) {
      console.log(err);
    }
  };

  // -------------------------------------------------------------------------------
  // LOADING ERRORS

  if (queryError || mutationError)
    return (
      <ErrorMessageComponent
        firstText="Oops!"
        secondText="Something went wrong while fetching your data."
        thirdText="Please try again later."
      />
    );

  // -------------------------------------------------------------------------------
  // MAIN COMPONENT

  return (
    <div>
      {!onboarding ? (
        <MobileHeader
          disabled={saveBtnDisabled || mutationLoading}
          hasSubmitted={hasCompletedMutation}
          onClick={handleFormSubmit}
          isLoading={mutationLoading}
        />
      ) : (
        ""
      )}
      <StyledContainer>
        <StyledFormHeader>
          <Text fontSize="14px">Hiring Preferences</Text>
        </StyledFormHeader>

        <StyledInnerWrap onboarding={onboarding} ref={submitFunctionRef}>
          {queryLoading ? (
            <StyledLoaderBox>
              <LoaderDots
                className="loader"
                dotsBgColor={theme.darkBlue}
                dotHeight="8px"
                dotWidth="8px"
                dotMargin="8px"
                isLoading={queryLoading}
              />
            </StyledLoaderBox>
          ) : (
            <StyledForm className="basic-info">
              <StyledInfoText>* Indicates required</StyledInfoText>
              <FormMultipleSelectGroup
                options={queryData?.recommendation_preferences}
                defaultValue={(user && user?.recommendation_preferences) || ""}
                errors={errors.recommendation_preferences}
                isRequired
                fieldLabel="After graduating, I want to be recommended for *"
                getInputValue={(newValue) => {
                  onInputChange("recommendation_preferences", newValue);
                }}
              />
              <FormMultipleSelectGroup
                options={queryData?.work_preferences}
                defaultValue={(user && user?.work_preferences) || ""}
                fieldLabel="My preferences include (optional)"
                getInputValue={(newValue) => {
                  onInputChange("work_preferences", newValue);
                }}
              />

              {!onboarding && isDesktop ? (
                <SubmitButton
                  variant="lightBlueBtn"
                  type="submit"
                  disabled={saveBtnDisabled || mutationLoading}
                  hasSubmitted={hasCompletedMutation}
                  onClick={handleFormSubmit}
                  isLoading={mutationLoading}
                />
              ) : (
                ""
              )}
            </StyledForm>
          )}
        </StyledInnerWrap>
      </StyledContainer>
    </div>
  );
};
export default HiringPreferences;
