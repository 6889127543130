import React from "react";
import Button from "./Button";

const ShowMoreLessButton = ({ totalNumberOfAchievements, numberOfInitialFilesDisplayed, initialNumOfFiles, onShowMoreAchievements }) => {


  return totalNumberOfAchievements > initialNumOfFiles ? (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        marginBottom: "16px",
      }}
    >
      <Button
        variant="transparentBtn"
        text={
          numberOfInitialFilesDisplayed < totalNumberOfAchievements
            ? "Show More"
            : "Show Less"
        }
        onClick={onShowMoreAchievements}
      />
    </div>
  ) : (
    ""
  );
};

export default ShowMoreLessButton;
