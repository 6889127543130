import { TbArrowsSort } from "react-icons/tb";
import {
  BsSortAlphaDown,
  BsSortAlphaUpAlt,
  BsSortNumericDown,
  BsSortNumericUpAlt,
} from "react-icons/bs";
import styled from "styled-components";

// styled-components
const StyledSortHeading = styled.span`
  cursor: pointer;
  svg path {
    font-size: 15px;
  }
`;

const SortButton = ({ direction, id, onClick, sortBy, label }) => {
  let arrows;

  if (id === "name") {
    arrows = {
      ascending: <BsSortAlphaDown />,
      descending: <BsSortAlphaUpAlt />,
    };
  } else {
    arrows = {
      ascending: <BsSortNumericDown />,
      descending: <BsSortNumericUpAlt />,
    };
  }
  const arrow = sortBy === id ? arrows[direction] : <TbArrowsSort />;

  return (
    <StyledSortHeading id={id} onClick={onClick}>
      {label} {arrow}
    </StyledSortHeading>
  );
};

export default SortButton;
