import styled from "styled-components/macro";

export const Text = styled.p`
  font-family:  ${props => props.fontFamily ? props.fontFamily : "Inter"};
  font-size: ${props => props.fontSize ? props.fontSize : "16px"};;
  font-style: normal;
  font-weight: ${props => props.fontWeight ? props.fontWeight : 400};
  line-height:  ${props => props.lineHeight ? props.lineHeight : "normal"};
  color:  ${props => props.color ? props.color : "inherit"};
`;
