import React, { useContext, useEffect, useRef, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";

import styled from "styled-components";
import { GlobalContext } from "../../Context/GlobalProvider";
import { StyledForm } from "../CommonStyledComponents/CommonStyledComponents";
import { Text } from "../../Consts/Text";
import {
  GET_PROFILE_HUB_DATA,
  UpdateUserBiographyEn,
  UpdateUserSocialLinks,
  UpdateUserSocialLinksAndBio,
} from "../../Consts/GraphqlQueries";
import theme from "../../Consts/theme";
import useMediaCustom from "../../Hooks/useMediaCustom";
import FormInputGroup from "../FormGroup/FormInputGroup";
import LoaderDots from "../Loader/LoaderDots";
import MobileHeader from "./MobileHeader/MobileHeader";
import UploadFile from "./UploadFile";
import Label from "../Label/Label";
import Textarea from "../Textarea/Textarea";
import SubmitButton from "../Button/SubmitButton";
import ErrorMessageComponent from "../ErrorMessageComponent/ErrorMessageComponent";

// styled-components
const StyledContainer = styled.section`
  gap: 16px;
  border: 1px solid ${theme.concreteGray};
  border-radius: 16px;
  background-color: ${theme.white};
  padding: 0 16px 16px;

  &:not(:last-child) {
    margin-bottom: 16px;
  }
`;
const StyledFormHeader = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 16px 0;
  padding: 16px 0;
  border-bottom: 1px solid ${theme.concreteGray};

  @media only screen and (min-width: ${(props) => props.theme.desktop}) {
    margin-left: 24px;
    margin-right: 24px;
  }
`;

const StyledInnerWrap = styled.section`
  width: 100%;

  &.file-upload {
    width: 100%;
  }

  @media only screen and (min-width: ${(props) => props.theme.desktop}) {
    display: flex;
    width: 90%;

    button {
      width: 30%;
    }
  }
`;
const StyledLoaderBox = styled.div`
  height: 60vh;
  width: 100%;
  display: flex;
  justify-content: center;

  .loader {
    padding-top: 0px;
  }
`;
const StyledTextareaWrap = styled.fieldset`
  textarea {
    margin: 5px 0 0;
  }
`;

const ProfileHub = ({ submitFunctionRef }) => {
  const isDesktop = useMediaCustom("(min-width: 992px)");
  const userObjectRef = useRef();
  const { user, setUser } = useContext(GlobalContext);
  // states
  const [saveBtnDisabled, setSaveBtnDisabled] = useState({
    textAreaBtn: true,
    socialLinksBtn: true,
    universalBtn: true,
  });
  const [hasCompletedMutation, setHasCompletedMutation] = useState({
    textAreaBtn: false,
    socialLinksBtn: false,
    universalBtn: false,
  });
  const [mutationCalled, setMutationCalled] = useState({
    textAreaBtn: false,
    socialLinksBtn: false,
    universalBtn: false,
  });

  //  ----------------------------------------------------------------
  // GRAPHQL GET REQUEST
  const {
    loading: queryLoading,
    error: queryError,
    data: queryData,
  } = useQuery(GET_PROFILE_HUB_DATA, {
    fetchPolicy: "cache-and-network",
  });
  const hasFetchedUser = queryData?.user !== undefined;
  //  ----------------------------------------------------------------
  // GRAPHQL POST REQUEST (BIO_EN MUTATION) THIS IS FOR DESKTOP ONLY
  const [
    updateUser,
    {
      data: mutationDataBio,
      loading: mutationLoadingBio,
      called: mutationCalledBio,
    },
  ] = useMutation(UpdateUserBiographyEn, {
    onCompleted: (data) => {
      onSuccessMutation(data.UpdateUser);
      handleCompletedMutation()
    },
  });
  //  ----------------------------------------------------------------
  // GRAPHQL POST REQUEST (SOCIAL LINK MUTATION) THIS IS FOR DESKTOP ONLY
  const [
    updateUserSocialLinks,
    {
      data: mutationDataSocialLinks,
      loading: mutationLoadingSocialLinks,
      called: mutationCalledSocialLinks,
    },
  ] = useMutation(UpdateUserSocialLinks, {
    onCompleted: (data) => {
      onSuccessMutation(data.UpdateUser);
      handleCompletedMutation()
    },
  });
  //  ----------------------------------------------------------------
  // GRAPHQL POST REQUEST (SOCIAL LINK + BIO_EN MUTATION) THIS IS FOR MOBILE ONLY
  const [
    updateUserSocialLinksAndBio,
    {
      data: mutationDataSocialLinksAndBio,
      loading: mutationLoadingSocialLinksAndBio,
    },
  ] = useMutation(UpdateUserSocialLinksAndBio, {
    onCompleted: (data) => {
      onSuccessMutation(data.UpdateUser);
      handleCompletedMutation()
    },
  });

  useEffect(() => {
    if (
      (!mutationCalledBio && hasFetchedUser) ||
      (!mutationCalledSocialLinks && hasFetchedUser) ||
      (!mutationCalledSocialLinks && hasFetchedUser)
    ) {
      userObjectRef.current = queryData?.user;
    }
  }, [
    mutationCalledBio,
    mutationCalledSocialLinks,
    mutationCalledSocialLinks,
    hasFetchedUser,
    queryData,
  ]);


  // ----------------------------------------------------------------
  // This function is called on success mutation

  const onSuccessMutation = (_data) => {
    handleUserUpdate(_data);
    setSaveBtnDisabled({
      textAreaBtn: true,
      socialLinksBtn: true,
      universalBtn: true,
    });
    if (isDesktop) {
      if (mutationCalled.textAreaBtn) {
        const submittedBioEn = true;
        setHasCompletedMutation({
          textAreaBtn: submittedBioEn,
          socialLinksBtn: false,
          universalBtn: false,
        });
        return;
      } else if (mutationCalled.socialLinksBtn) {
        const submittedSocialLinks = true;
        setHasCompletedMutation({
          textAreaBtn: false,
          socialLinksBtn: submittedSocialLinks,
          universalBtn: false,
        });
        return;
      }
    } else {
      const submittedBoth = true;
      setHasCompletedMutation({
        textAreaBtn: false,
        socialLinksBtn: false,
        universalBtn: submittedBoth,
      });
    }

  };

  // ----------------------------------------------------------------
  // Update the user object in Global context after submission and on page load

  const handleUserUpdate = (updatedUserData) => {
    setUser((prevUser) => ({
      ...prevUser,
      ...updatedUserData,
      user_info: {
        ...prevUser?.user_info,
        ...updatedUserData?.user_info,
      },
    }));
  };
  // Return hasCompletedMutation to false to remove the style for success message

  const handleCompletedMutation = () => {
    const timer = setTimeout(() => {
      const reset = {
        textAreaBtn: false,
        socialLinksBtn: false,
        universalBtn: false,
      };
      setHasCompletedMutation(reset);
      setMutationCalled(reset);
    }, 4000);

    // Clear the timeout if the component unmounts
    return () => clearTimeout(timer);
  };


  //  ----------------------------------------------------------------
  // ON INPUT CHANGE

  const onInputChange = (_network, _value) => {
    userObjectRef.current = {
      ...userObjectRef.current,
      social_profiles: {
        ...userObjectRef.current.social_profiles,
        [_network]: _value,
      },
    };

    if (!isDesktop) {
      setSaveBtnDisabled({ ...saveBtnDisabled, universalBtn: false });
    } else {
      setSaveBtnDisabled({ ...saveBtnDisabled, socialLinksBtn: false });
    }
  };

  //  ----------------------------------------------------------------
  // ON TEXTAREA CHANGE

  const onTextareaChange = (_inputName, _value) => {
    userObjectRef.current = {
      ...userObjectRef.current,
      [_inputName]: _value,
    };

    if (!isDesktop) {
      setSaveBtnDisabled({ ...saveBtnDisabled, universalBtn: false });
    } else {
      setSaveBtnDisabled({ ...saveBtnDisabled, textAreaBtn: false });
    }
  };

  // ----------------------------------------------------------------
  // FORM SUBMIT SOCIAL LINKS AND BIO_EN (ON MOBILE ONLY)

  const onSubmitSocialLinksAndBioEn = async (e) => {
    e?.preventDefault();

    const calledSocialLinsAndBioEn = true;
    setMutationCalled({
      textAreaBtn: false,
      socialLinksBtn: false,
      universalBtn: calledSocialLinsAndBioEn,
    });

    const formData = {
      bio_en: userObjectRef?.current?.bio_en,
      social_profiles: {
        facebook: userObjectRef?.current?.social_profiles?.facebook,
        twitter: userObjectRef?.current?.social_profiles?.twitter,
        linkedin: userObjectRef?.current?.social_profiles?.linkedin,
        github: userObjectRef?.current?.social_profiles?.github,
        upwork: userObjectRef?.current?.social_profiles?.upwork,
        behance: userObjectRef?.current?.social_profiles?.behance,
        website: userObjectRef?.current?.social_profiles?.website,
        dribble: userObjectRef?.current?.social_profiles?.dribble,
        calendly: userObjectRef?.current?.social_profiles?.calendly,
        figma: userObjectRef?.current?.social_profiles?.figma,
      },
    };

    // submit the form
    try {
      await updateUserSocialLinksAndBio({ variables: { inputData: formData } });
    } catch (err) {
      console.log(err);
    }
  };

  // ----------------------------------------------------------------
  // FORM SUBMIT TEXTAREA

  const onSubmitTextarea = async (e) => {
    e?.preventDefault();
    const calledBioEn = true;
    setMutationCalled({
      textAreaBtn: calledBioEn,
      socialLinksBtn: false,
      universalBtn: false,
    });

    const formData = {
      bio_en: userObjectRef?.current?.bio_en,
    };

    // submit the form
    try {
      await updateUser({ variables: { inputData: formData } });
    } catch (err) {
      console.log(err);
    }
  };

  // ----------------------------------------------------------------
  // FORM SUBMIT SOCIAL LINKS

  const onSubmitSocialLinks = async (e) => {
    e?.preventDefault();
    const calledSocialLins = true;
    setMutationCalled({
      textAreaBtn: false,
      socialLinksBtn: calledSocialLins,
      universalBtn: false,
    });
    const formData = {
      social_profiles: {
        facebook: userObjectRef?.current?.social_profiles?.facebook,
        twitter: userObjectRef?.current?.social_profiles?.twitter,
        linkedin: userObjectRef?.current?.social_profiles?.linkedin,
        github: userObjectRef?.current?.social_profiles?.github,
        upwork: userObjectRef?.current?.social_profiles?.upwork,
        behance: userObjectRef?.current?.social_profiles?.behance,
        website: userObjectRef?.current?.social_profiles?.website,
        dribble: userObjectRef?.current?.social_profiles?.dribble,
        calendly: userObjectRef?.current?.social_profiles?.calendly,
        figma: userObjectRef?.current?.social_profiles?.figma,
      },
    };

    // submit the form
    try {
      await updateUserSocialLinks({ variables: { inputData: formData } });
    } catch (err) {
      console.log(err);
    }
  };

  // -------------------------------------------------------------------------------
  // LOADING ERRORS

  if (
    queryError ||
    mutationDataBio?.user ||
    mutationDataSocialLinks?.user ||
    mutationDataSocialLinksAndBio?.user
  )
    return (
      <ErrorMessageComponent
        firstText="Oops!"
        secondText="Something went wrong while fetching your data."
        thirdText="Please try again later."
      />
    );

  // -------------------------------------------------------------------------------
  // MAIN COMPONENT

  return (
    <div>
      <MobileHeader
        disabled={
          saveBtnDisabled.universalBtn || mutationLoadingSocialLinksAndBio
        }
        isLoading={mutationLoadingSocialLinksAndBio}
        hasSubmitted={hasCompletedMutation.universalBtn}
        onClick={onSubmitSocialLinksAndBioEn}
      />
      {/* ============================== CV upload =============================*/}
      <StyledContainer>
        <StyledFormHeader>
          <Text fontSize="14px">CV</Text>
        </StyledFormHeader>
        <StyledInnerWrap ref={submitFunctionRef} className="file-upload">
          {queryLoading ? (
            <StyledLoaderBox>
              <LoaderDots
                className="loader"
                dotsBgColor={theme.darkBlue}
                dotHeight="8px"
                dotWidth="8px"
                dotMargin="8px"
              />
            </StyledLoaderBox>
          ) : (
            <UploadFile user={queryData?.user} />
          )}
        </StyledInnerWrap>
      </StyledContainer>

      {/* ============================== Short Bio =============================*/}
      <StyledContainer>
        <StyledFormHeader>
          <Text fontSize="14px">About</Text>
        </StyledFormHeader>

        <StyledInnerWrap ref={submitFunctionRef}>
          {queryLoading ? (
            <StyledLoaderBox>
              <LoaderDots
                className="loader"
                dotsBgColor={theme.darkBlue}
                dotHeight="8px"
                dotWidth="8px"
                dotMargin="8px"
              />
            </StyledLoaderBox>
          ) : (
            <StyledForm>
              <div style={{ marginTop: "16px" }}>
                <Label label={"Short Bio"} />
              </div>
              <StyledTextareaWrap>
                <Textarea
                  name={"bio_en"}
                  rows={"8"}
                  placeholder={"ENG Bio..."}
                  defaultValue={user ? user?.bio_en : "-1"}
                  getInputValue={(newValue) => {
                    onTextareaChange("bio_en", newValue);
                  }}
                />
              </StyledTextareaWrap>

              {isDesktop ? (
                <SubmitButton
                  variant="lightBlueBtn"
                  type="button"
                  disabled={saveBtnDisabled.textAreaBtn || mutationLoadingBio}
                  hasSubmitted={hasCompletedMutation.textAreaBtn}
                  onClick={onSubmitTextarea}
                  isLoading={mutationLoadingBio}
                />
              ) : (
                ""
              )}
            </StyledForm>
          )}
        </StyledInnerWrap>
      </StyledContainer>

      {/* ============================== Social Links =============================*/}
      <StyledContainer>
        <StyledFormHeader>
          <Text fontSize="14px">Social links</Text>
        </StyledFormHeader>
        <StyledInnerWrap>
          <StyledForm onSubmit={onSubmitSocialLinks} ref={submitFunctionRef}>
            <FormInputGroup
              className="social-links"
              name="linkedin"
              labelImage={
                queryData && queryData?.user?.social_profiles?.linkedin === null
                  ? "/images/linkedinIcon.svg"
                  : "/images/linkedinIconDark.svg"
              }
              label={"LinkedIn"}
              type="text"
              placeholder={"Ex: https:www.linkedin.com/your-profile"}
              defaultValue={queryData?.user?.social_profiles?.linkedin || ""}
              getInputValue={(newValue) => {
                onInputChange("linkedin", newValue);
              }}
            />
            <FormInputGroup
              className="social-links"
              name="facebook"
              labelImage={
                queryData && queryData?.user?.social_profiles?.facebook === null
                  ? "/images/facebookIcon.svg"
                  : "/images/facebookIconDark.svg"
              }
              defaultValue={queryData?.user?.social_profiles?.facebook || ""}
              label={"Facebook"}
              type="text"
              placeholder={"Ex: https:www.facebook.com/your-profile"}
              getInputValue={(newValue) => {
                onInputChange("facebook", newValue);
              }}
            />
            <FormInputGroup
              className="social-links"
              name="twitter"
              labelImage={
                queryData && queryData?.user?.social_profiles?.twitter === null
                  ? "/images/twitterIcon.svg"
                  : "/images/twitterIconDark.svg"
              }
              defaultValue={queryData?.user?.social_profiles?.twitter || ""}
              label={"Twitter"}
              type="text"
              placeholder={"Ex: https:www.twitter.com/your-profile"}
              getInputValue={(newValue) => {
                onInputChange("twitter", newValue);
              }}
            />
            <FormInputGroup
              className="social-links"
              name="upwork"
              labelImage={
                queryData && queryData?.user?.social_profiles?.upwork === null
                  ? "/images/upworkIcon.svg"
                  : "/images/upworkIconDark.svg"
              }
              defaultValue={queryData?.user?.social_profiles?.upwork || ""}
              label={"Upwork"}
              type="text"
              placeholder={"Ex: https:www.upwork.com/your-profile"}
              getInputValue={(newValue) => {
                onInputChange("upwork", newValue);
              }}
            />

            <FormInputGroup
              className="social-links"
              name="github"
              labelImage={
                queryData && queryData?.user?.social_profiles?.github === null
                  ? "/images/gitHubIcon.svg"
                  : "/images/gitHubIconDark.svg"
              }
              defaultValue={queryData?.user?.social_profiles?.github || ""}
              label={"GitHub"}
              type="text"
              placeholder={"Ex: https:www.github.com/your-profile"}
              getInputValue={(newValue) => {
                onInputChange("github", newValue);
              }}
            />
            <FormInputGroup
              className="social-links"
              name="behance"
              labelImage={
                queryData?.user &&
                queryData?.ser?.social_profiles?.behance === null
                  ? "/images/behanceIcon.svg"
                  : "/images/behanceIconDark.svg"
              }
              defaultValue={queryData?.user?.social_profiles?.behance || ""}
              label={"Behance"}
              type="text"
              placeholder={"Ex: https:www.behance.com/your-profile"}
              getInputValue={(newValue) => {
                onInputChange("behance", newValue);
              }}
            />
            <FormInputGroup
              className="social-links"
              name="dribble"
              labelImage={
                queryData && queryData?.user?.social_profiles?.dribble === null
                  ? "/images/dribbleIcon.svg"
                  : "/images/dribbleIconDark.svg"
              }
              defaultValue={queryData?.user?.social_profiles?.dribble || ""}
              label={"Dribble"}
              type="text"
              placeholder={"Ex: https:www.dribble.com/your-profile"}
              getInputValue={(newValue) => {
                onInputChange("dribble", newValue);
              }}
            />
            <FormInputGroup
              className="social-links"
              name="website"
              labelImage={
                queryData && queryData?.user?.social_profiles?.website === null
                  ? "/images/websiteIcon.svg"
                  : "/images/websiteIconDark.svg"
              }
              defaultValue={queryData?.user?.social_profiles?.website || ""}
              label={"Website"}
              type="text"
              placeholder={"Ex: https:www.yourwebsite.com/your-profile"}
              getInputValue={(newValue) => {
                onInputChange("website", newValue);
              }}
            />
            <FormInputGroup
              className="social-links"
              name="calendly"
              labelImage={
                queryData && queryData?.user?.social_profiles?.calendly === null
                  ? "/images/calendlyIcon.svg"
                  : "/images/calendlyIconDark.svg"
              }
              defaultValue={queryData?.user?.social_profiles?.calendly || ""}
              label={"Calendly"}
              type="text"
              placeholder={"Ex: https:www.calendly.com/your-profile"}
              getInputValue={(newValue) => {
                onInputChange("calendly", newValue);
              }}
            />
            <FormInputGroup
              className="social-links"
              name="figma"
              labelImage={
                queryData && queryData?.user?.social_profiles?.figma === null
                  ? "/images/figmaIcon.svg"
                  : "/images/figmaIconDark.svg"
              }
              defaultValue={queryData?.user?.social_profiles?.figma || ""}
              label={"Figma"}
              type="text"
              placeholder={"Ex: https:www.figma.com/your-profile"}
              getInputValue={(newValue) => {
                onInputChange("figma", newValue);
              }}
            />

            {isDesktop ? (
              <SubmitButton
                variant="lightBlueBtn"
                type="button"
                disabled={
                  saveBtnDisabled.socialLinksBtn || mutationLoadingSocialLinks
                }
                hasSubmitted={hasCompletedMutation.socialLinksBtn}
                onClick={onSubmitSocialLinks}
                isLoading={mutationLoadingSocialLinks}
              />
            ) : (
              ""
            )}
          </StyledForm>
        </StyledInnerWrap>
      </StyledContainer>
    </div>
  );
};
export default ProfileHub;
