import React from "react";
import styled from "styled-components";

import theme from "../../Consts/theme"

// styled-components
const StyledBadge = styled.button`
  cursor: pointer;
  background-color: ${theme.lightBlue};
  color: ${(props) => props.theme.textGrey};
  padding: 12px 16px;
  border-radius: 8px;
  border: 1.5px solid
    ${(props) =>
      props.hasSelectedOption ? `${props.theme.lightLightGray}` : "#E6E7EB"};
  display: inline-flex;
  text-align: left;
  align-items: center;
  font-size: 14px;
  line-height: 1.3;
  .option-name {
    color: ${theme.white};
  }

  svg {
    width: 16px;
    margin-left: 10px;
    display: flex;
    align-items: center;
    fill: ${theme.white};
  }

  :hover {
    background-color: ${theme.red};
    transition: all 0.2s ease-in-out;
  }
  :active {
    color: ${(props) => props.theme.white};
    transition: all 0.2s ease-in-out;
  }
  :focus {
    border: 1.5px solid ${(props) => props.theme.red};
  }
`;

function SelectedItem({ onClick, hasSelectedOption, optionName }) {
  return (
    <StyledBadge hasSelectedOption={hasSelectedOption} onClick={onClick}>
      <span className="option-name">{optionName}</span>

      <svg
        className="delete-option"
        width="10"
        height="10"
        viewBox="0 0 10 10"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M9.19851 0.807136C8.93851 0.547136 8.51851 0.547136 8.25851 0.807136L4.99852 4.06047L1.73852 0.800469C1.47852 0.540469 1.05852 0.540469 0.798516 0.800469C0.538516 1.06047 0.538516 1.48047 0.798516 1.74047L4.05852 5.00047L0.798516 8.26047C0.538516 8.52047 0.538516 8.94047 0.798516 9.20047C1.05852 9.46047 1.47852 9.46047 1.73852 9.20047L4.99852 5.94047L8.25851 9.20047C8.51851 9.46047 8.93851 9.46047 9.19851 9.20047C9.45851 8.94047 9.45851 8.52047 9.19851 8.26047L5.93852 5.00047L9.19851 1.74047C9.45185 1.48714 9.45185 1.06047 9.19851 0.807136Z" />
      </svg>
    </StyledBadge>
  );
}

export default SelectedItem;
