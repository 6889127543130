import React, { useContext, useRef, useState, useEffect } from "react";
import styled from "styled-components";

import { axios, makeApiRoute } from "../../Router";
import { GlobalContext } from "../../Context/GlobalProvider";
import InputFile from "../Input/InputFile";
import FileButton from "../Button/FileButton";
import LoaderDots from "../Loader/LoaderDots";
import useMediaCustom from "../../Hooks/useMediaCustom";
import theme from "../../Consts/theme";



const StyledWrap = styled.div`
  width: 100%;
  border: 1px solid ${theme.concreteGray};
  border-radius: 8px;
  padding: 16px;

  @media only screen and (min-width: ${(props) => props.theme.desktop}) {
    margin: 0 24px 32px;
  }
`;
const StyledButtonsGroup = styled.div`
  display: flex;
  margin-bottom: 5px;

  .inner-wrap {
    display: flex;
    align-self: center;
    justify-content: flex-end;
    flex-grow: 1;
  }

  @media only screen and (min-width: ${(props) => props.theme.laptop}) {
    .inner-wrap {
      margin-top: 0px;
      margin-left: 32px;
    }
  }
`;
const StyledAction = styled.p`
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  position: relative;

  :not(:last-child) {
    margin-right: 24px;
  }
  ::after {
    position: absolute;
    content: "";
    width: 0;
    left: 0;
    bottom: -7px;
    background: ${(props) => props.theme.lightGrayText};
    height: 2px;
    transition: 0.3s ease-out;
  }

  :hover::after {
    width: 100%;
  }

  .color-black {
    color: ${(props) => props.theme.black};
  }
  .color-gray {
    color: ${(props) => props.theme.lightLightGray};
  }

  .align-self {
    align-self: center;
  }
`;
const UploadFile = ({ user }) => {
  const inputRef = useRef(null);
  const { setUser } = useContext(GlobalContext);
  const isLaptop = useMediaCustom("(min-width: 768px)");
  const isSmallMobile = useMediaCustom("(max-width: 374px)");
  // state
  const [file, setFile] = useState("");
  const [isLoaded, setIsLoaded] = useState(false);
  // Utility function to convert Base64 to Blob
  function base64ToBlob(base64, mimeType) {
    const byteCharacters = atob(base64);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], { type: mimeType });
  }

  useEffect(() => {
    setFile(user?.cv);
  }, [user]);

  const handleUploadClick = () => {
    //  We redirect the click event onto the hidden input element
    inputRef.current?.click();
  };

  const handleUploadFile = (_files) => {
    const data = { cv: _files[0] };
    setIsLoaded(true);
    const AuthStr = "Bearer " + (localStorage.getItem("userAT") || "");

    axios
      .post(makeApiRoute("uploadFile"), data, {
        headers: {
          Authorization: AuthStr,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        setFile(response?.data?.data?.cv);
        setUser(response?.data?.data);
        setIsLoaded(false);
      })
      .catch((error) => {
        throw error.response;
      });
  };

  const handleDownloadFile = () => {
    setIsLoaded(true);
    axios
      .get(makeApiRoute("downloadFile"), { responseType: "blob" })
      .then((response) => {
        if (response.status === 200) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "cv.pdf");
          document.body.appendChild(link);
          link.click();
          link.remove();
          setIsLoaded(false);
        } else {
          throw new Error("Bad Request");
        }
      })
      .catch((error) => {
        throw error.response;
      });
  };

  const handleRemoveFile = () => {
    setIsLoaded(true);

    axios
      .get(makeApiRoute("removeFile"))
      .then((response) => {
        setFile(response?.data?.data?.cv);
        setUser(response?.data?.data);
        setIsLoaded(false);
      })
      .catch((error) => {
        throw error.response;
      });
  };
  return (
    <StyledWrap>
      {file === null ? (
        <>
          <FileButton
            onClick={handleUploadClick}
            uploaded={file !== null}
            title="Upload your CV"
            icon={
              <svg
                width="40"
                height="40"
                viewBox="0 0 40 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M18.75 23.2492V29.3903C18.75 29.745 18.8697 30.042 19.109 30.2813C19.3483 30.5206 19.6453 30.6403 20 30.6403C20.3547 30.6403 20.6517 30.5206 20.891 30.2813C21.1303 30.042 21.2499 29.745 21.2499 29.3903V23.2492L23.4551 25.4544C23.579 25.5783 23.7185 25.6713 23.8734 25.7332C24.0283 25.7952 24.1832 25.8219 24.3381 25.8134C24.493 25.8048 24.6463 25.7695 24.798 25.7076C24.9497 25.6456 25.0875 25.5527 25.2115 25.4287C25.4529 25.1702 25.5779 24.8775 25.5865 24.5506C25.595 24.2236 25.47 23.9309 25.2115 23.6724L21.0544 19.5154C20.8984 19.3594 20.7339 19.2493 20.5608 19.1852C20.3878 19.1211 20.2008 19.0891 20 19.0891C19.7991 19.0891 19.6121 19.1211 19.4391 19.1852C19.266 19.2493 19.1015 19.3594 18.9455 19.5154L14.7885 23.6724C14.5406 23.9202 14.4183 24.2103 14.4215 24.5426C14.4247 24.8748 14.5555 25.1702 14.8141 25.4287C15.0726 25.6702 15.3653 25.7952 15.6922 25.8037C16.0192 25.8123 16.3119 25.6873 16.5705 25.4287L18.75 23.2492ZM10.5128 35.8326C9.67094 35.8326 8.95833 35.5409 8.375 34.9576C7.79167 34.3743 7.5 33.6617 7.5 32.8198V7.17885C7.5 6.33696 7.79167 5.62435 8.375 5.04102C8.95833 4.45768 9.67094 4.16602 10.5128 4.16602H22.5032C22.9049 4.16602 23.2911 4.24402 23.6618 4.40002C24.0326 4.55599 24.3547 4.77073 24.6282 5.04423L31.6217 12.0377C31.8952 12.3113 32.1099 12.6334 32.2659 13.0041C32.4219 13.3748 32.4999 13.761 32.4999 14.1627V32.8198C32.4999 33.6617 32.2083 34.3743 31.6249 34.9576C31.0416 35.5409 30.329 35.8326 29.4871 35.8326H10.5128ZM22.5 12.6595V6.66598H10.5128C10.3846 6.66598 10.2671 6.71939 10.1602 6.82623C10.0534 6.93309 9.99996 7.05063 9.99996 7.17885V32.8198C9.99996 32.948 10.0534 33.0655 10.1602 33.1724C10.2671 33.2792 10.3846 33.3326 10.5128 33.3326H29.4871C29.6153 33.3326 29.7328 33.2792 29.8397 33.1724C29.9465 33.0655 30 32.948 30 32.8198V14.1659H24.0064C23.5769 14.1659 23.2185 14.0222 22.9311 13.7348C22.6437 13.4474 22.5 13.089 22.5 12.6595Z"
                  fill="#191919"
                />
              </svg>
            }
          />
          <InputFile reference={inputRef} onChange={handleUploadFile} />

          <LoaderDots
            isLoading={isLoaded}
            dotsBgColor="#005EFF"
            dotHeight="5px"
            dotWidth="5px"
            buttonSubmit
            dotMargin="3px"
            className="flex-start"
          />
        </>
      ) : (
        <div>
          <StyledButtonsGroup>
            <FileButton
              onClick={handleDownloadFile}
              uploaded={file !== null}
              title={`Download ${!isSmallMobile ? "CV" : ""}`}
              icon={
                <svg
                  width="40"
                  height="40"
                  viewBox="0 0 40 40"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M18.2243 25.4641L15.5705 22.8102C15.4465 22.6863 15.3114 22.5933 15.165 22.5314C15.0186 22.4694 14.868 22.4384 14.7131 22.4384C14.5582 22.4384 14.4006 22.4694 14.2403 22.5314C14.0801 22.5933 13.938 22.6863 13.8141 22.8102C13.5555 23.0687 13.4262 23.3658 13.4262 23.7012C13.4262 24.0367 13.5555 24.3337 13.8141 24.5922L17.1955 27.9993C17.3515 28.1552 17.516 28.2653 17.6891 28.3294C17.8621 28.3935 18.0491 28.4255 18.25 28.4255C18.4508 28.4255 18.6378 28.3935 18.8108 28.3294C18.9839 28.2653 19.1484 28.1552 19.3044 27.9993L26.1858 21.1179C26.4337 20.87 26.5603 20.5714 26.5656 20.2221C26.571 19.8727 26.4444 19.5687 26.1858 19.3102C25.9273 19.0517 25.626 18.9224 25.282 18.9224C24.938 18.9224 24.6367 19.0517 24.3782 19.3102L18.2243 25.4641ZM10.5128 35.8326C9.67094 35.8326 8.95833 35.5409 8.375 34.9576C7.79167 34.3743 7.5 33.6617 7.5 32.8198V7.17885C7.5 6.33696 7.79167 5.62435 8.375 5.04102C8.95833 4.45768 9.67094 4.16602 10.5128 4.16602H22.5032C22.9049 4.16602 23.2911 4.24402 23.6618 4.40002C24.0326 4.55599 24.3547 4.77073 24.6282 5.04423L31.6217 12.0377C31.8952 12.3113 32.1099 12.6334 32.2659 13.0041C32.4219 13.3748 32.4999 13.761 32.4999 14.1627V32.8198C32.4999 33.6617 32.2083 34.3743 31.6249 34.9576C31.0416 35.5409 30.329 35.8326 29.4871 35.8326H10.5128ZM22.5 12.6595V6.66598H10.5128C10.3846 6.66598 10.2671 6.71939 10.1602 6.82623C10.0534 6.93309 9.99996 7.05063 9.99996 7.17885V32.8198C9.99996 32.948 10.0534 33.0655 10.1602 33.1724C10.2671 33.2792 10.3846 33.3326 10.5128 33.3326H29.4871C29.6153 33.3326 29.7328 33.2792 29.8397 33.1724C29.9465 33.0655 30 32.948 30 32.8198V14.1659H24.0064C23.5769 14.1659 23.2185 14.0222 22.9311 13.7348C22.6437 13.4474 22.5 13.089 22.5 12.6595Z"
                    fill="#005EFF"
                  />
                </svg>
              }
            />

            {!isLaptop ? (
              <LoaderDots
                isLoading={isLoaded}
                dotsBgColor="#005EFF"
                dotHeight="5px"
                dotWidth="5px"
                buttonSubmit
                dotMargin="3px"
                className="flex-start"
              />
            ) : (
              ""
            )}

            <div className="inner-wrap">
              <div>
                <StyledAction onClick={handleUploadClick}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                  >
                    <path
                      d="M2.93655 13.6673C2.76583 13.6673 2.62272 13.6095 2.50723 13.494C2.39173 13.3785 2.33398 13.2354 2.33398 13.0647V11.9096C2.33398 11.747 2.36518 11.5921 2.42758 11.4447C2.48997 11.2974 2.57587 11.169 2.68527 11.0596L11.1276 2.62117C11.2284 2.5296 11.3397 2.45885 11.4615 2.4089C11.5833 2.35896 11.7111 2.33398 11.8447 2.33398C11.9784 2.33398 12.1079 2.35771 12.2332 2.40515C12.3585 2.45258 12.4694 2.52801 12.566 2.63142L13.3801 3.45577C13.4835 3.55235 13.5572 3.66348 13.6013 3.78917C13.6453 3.91485 13.6673 4.04052 13.6673 4.1662C13.6673 4.30026 13.6444 4.42819 13.5986 4.55C13.5528 4.67182 13.48 4.78314 13.3801 4.88395L4.94163 13.316C4.83223 13.4254 4.70387 13.5113 4.55653 13.5737C4.40919 13.6361 4.25423 13.6673 4.09165 13.6673H2.93655ZM11.7019 5.13012L12.6673 4.17113L11.8301 3.33397L10.8712 4.29935L11.7019 5.13012Z"
                      fill="#005EFF"
                    />
                  </svg>
                  <span className="color-black align-self">Edit</span>
                </StyledAction>
                <InputFile reference={inputRef} onChange={handleUploadFile} />
              </div>
              <StyledAction onClick={handleRemoveFile} deleteBtn>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <g clip-path="url(#clip0_5235_13708)">
                    <path
                      d="M12.2005 3.80714C11.9405 3.54714 11.5205 3.54714 11.2605 3.80714L8.00047 7.06047L4.74047 3.80047C4.48047 3.54047 4.06047 3.54047 3.80047 3.80047C3.54047 4.06047 3.54047 4.48047 3.80047 4.74047L7.06047 8.00047L3.80047 11.2605C3.54047 11.5205 3.54047 11.9405 3.80047 12.2005C4.06047 12.4605 4.48047 12.4605 4.74047 12.2005L8.00047 8.94047L11.2605 12.2005C11.5205 12.4605 11.9405 12.4605 12.2005 12.2005C12.4605 11.9405 12.4605 11.5205 12.2005 11.2605L8.94047 8.00047L12.2005 4.74047C12.4538 4.48714 12.4538 4.06047 12.2005 3.80714Z"
                      fill="#C5C5C5"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_5235_13708">
                      <rect width="16" height="16" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                <span className="color-gray align-self">Remove</span>
              </StyledAction>
            </div>
          </StyledButtonsGroup>
          {isLaptop ? (
            <LoaderDots
              isLoading={isLoaded}
              dotsBgColor="#005EFF"
              dotHeight="5px"
              dotWidth="5px"
              buttonSubmit
              dotMargin="3px"
              className="flex-start"
            />
          ) : (
            ""
          )}
        </div>
      )}
    </StyledWrap>
  );
};
export default UploadFile;
