import axios from "axios";

let axiosSetup = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

axiosSetup.interceptors.request.use(function (config) {
  config.headers.Authorization = "Bearer " + localStorage.getItem("userAT");

  return config;
});

axiosSetup.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      localStorage.removeItem("userAT");
    }
    throw error;
  }
);

var makeApiRoute = (route, data) => {
  let apiRoute = Routes.api[route];
  if (typeof data !== typeof undefined) {
    for (let key in data) {
      apiRoute = apiRoute.replace(`{${key}}`, data[key]);
    }
  }

  return apiRoute;
};

var makeAppRoute = (route, data) => {
  let appRoute = Routes.app[route];
  if (typeof data !== typeof undefined) {
    for (let key in data) {
      appRoute = appRoute.replace(`{${key}}`, data[key]);
    }
  }

  return appRoute;
};

//create url from parts, stripping the necessary slashes at the end of each part
var makeRouteFromParts = (...parts) => {
  parts = parts.map((part) => {
    return part && part.replace(/^\/+|\/+$/g, "");
  });

  let url = parts.join("/");

  return url;
};

const makeExternalLink = (key) => {
  let link = Routes.external[key];
  return link;
};

const Routes = {
  api: {
    categories: "/categories",
    student: "/students",
    studentUploadFile: "/students/upload",
    changePassword: "/students/password",
    countries: "/countries",
    certificate: "/certificates/verification",
    certificateResponse: "/certificates/{CERTIFICATE_ID}/response",
    certificateDownload: "/certificates/{CERTIFICATE_ID}/download",
    skills: "/skills",
    onboardingExperience: '/onboarding-experience',
    myProfileData: 'my-profile',
    totalPoints: "/points/total",
    studentPoints: "/points/student/{USER_ID}",
    submitHomework: "/activities/submitHomework",
    editHomework: "/activities/editHomework",
    materials: "/materials",
    updateMaterials: "/materials",
    materialsDownload: "/materials/download/{RESOURCE_ID}/{RESOURCE_SERVICE}",
    videoStats: "/video/stats",
    login: "/login",
    logout: "/logout",
    passwordReset: "/password-reset",
    requestPasswordReset: "/request-password-reset",
    survey: "/survey/{SURVEY_ID}",
    submitSurvey: "/survey",
    hiringProposals: "/hiringProposals",
    updateTalentMarket: "/hiringProposals",
    upcomingEvents: "/events/upcoming",
    joinEvents: "/event/{EVENT_ID}/joinUrl",
    updateAttendance: "/points/attendance",
    messages: "/messages",
    uploadFile: "/students/upload",
    downloadFile: "/students/download",
    removeFile: "/students/remove-cv",
    attendanceRates: "/attendanceRates",
    updateAttendanceRates: "/attendanceRates/{studentUnitAttendanceRate}",
    achievement: "/achievements/{ACHIEVEMENT_ID}",
    chatInitialGreeting: "/chat/initialGreeting",
    chatMessages: "/chat",
    aiConsent: "/chat/giveAiConsent"
  },
  app: {
    home: "/",
    login: "/login",
    requestPasswordReset: "/request-password-reset",
    category: "/category/{CATEGORY}",
    profile: "/my-profile",
    points: "/points/{USER_ID}",
    survey: "/survey/{SURVEY_ID}",
    offer: "/offer",
    welcome: "/welcome",
    onboarding: "/onboarding/{STEP}",
    onboardingSubStep: "/onboarding/{STEP}/{SUB_STEP}",
    profileSection: "/my-profile/{SECTION}",
    chat: "/aida",
    orderDetails: "/my-profile/my-payments/{ORDER_ID}"

  },
  external: {
    facebookHiringGroup: "https://www.facebook.com/groups/326847685817052",
    brainsterFacebook: "https://www.facebook.com/brainster.co/",
    brainsterLinkedin: "https://www.linkedin.com/school/brainster-co/",
    brainsterInstagram: "https://www.instagram.com/brainsterco/",
    brainsterYouTube: "https://youtube.com/@brainster.1?si=7a5rtUz633F1FemZ",
    brainsterTiktok: "https://www.tiktok.com/@brainsterco",
    heartbeatPlatform: "https://plus.brainster.co/",
    // Albania social links
    brainsterFacebookAl: "https://www.facebook.com/brainster.al/ ",
    brainsterInstagramAl: "https://www.instagram.com/brainster_al/",
    brainsterYouTubeAl: "https://www.youtube.com/@BrainsterAlbania",

  },
};

export {
  makeApiRoute,
  makeAppRoute,
  makeRouteFromParts,
  makeExternalLink,
  axiosSetup as axios,
};
