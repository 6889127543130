import React, { createContext, useEffect, useState } from "react";

import { axios, makeApiRoute } from "../Router";

import Button from "../Components/Button/Button";

export const GlobalContext = createContext({});

export const GlobalProvider = ({ children }) => {
  const [user, setUser] = useState({});
  const [categories, setCategories] = useState([]);

  const isAuth = localStorage.getItem("userAT");

  useEffect(() => {
    if (isAuth) {
      // Logic with REST API
      axios
        .get(makeApiRoute("student"))
        .then((response) => {
          setUser(response.data.data);
        })
        .catch(function (error) {
          throw error.response;
        });

      axios
        .get(makeApiRoute("categories"))
        .then((response) => {
          setCategories(response.data.data.categories);
        })
        .catch(function (error) {
          throw error.response;
        });
    }
  }, [isAuth]);

  //Popup logic
  const [popupConfig, setPopupConfig] = useState({
    show: false,
    title: "",
    subTitle: "",
    body: "",
    confirmation: false,
    maxWidth: "",
    classNameModal: "",
    bodyClassname: "",
    confirmButton: {
      show: false,
      text: "",
      action: () => {},
    },
    rejectButton: {
      show: false,
      text: "",
      action: () => {},
    },
    closeButton: {
      show: false,
      action: () => {},
    },
  });

  const handleRequestError = () => {
    setPopupConfig({
      ...popupConfig,
      show: true,
      subTitle: "",
      confirmation: false,
      maxWidth: "500px",
      title: "Something Unexpected Happened. Please try again later!",
      bodyClassname: "action-buttons",
      body: (
        <Button
          text="Close"
          variant="blueBtn"
          onClick={() => setPopupDefaultValues()}
        />
      ),
      closeButton: {
        show: true,
        action: () => {
          setPopupDefaultValues();
        },
      },
    });
  };

  const setPopupDefaultValues = () => {
    setPopupConfig({
      show: false,
      title: "",
      subTitle: "",
      body: "",
      confirmation: false,
      maxWidth: "",
      classNameModal: "",
      bodyClassname: "",
      confirmButton: {
        show: false,
        text: "",
        action: () => {},
      },
      rejectButton: {
        show: false,
        text: "",
        action: () => {},
      },
      closeButton: {
        show: false,
        action: () => {},
      },
    });
  };

  const contextObject = {
    isAuth,
    user,
    categories,
    popupConfig,
    setPopupConfig,
    setUser,
    setCategories,
    setPopupDefaultValues,
    handleRequestError,
  };

  return (
    <GlobalContext.Provider value={contextObject}>
      {children}
    </GlobalContext.Provider>
  );
};
