import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { BsPatchExclamationFill } from "react-icons/bs";
import styled from "styled-components";

import { makeAppRoute } from "../../../Router";
import { GET_MY_PAYMENTS } from "../../../Consts/GraphqlQueries";
import { Text } from "../../../Consts/Text";
import ErrorMessageComponent from "../../ErrorMessageComponent/ErrorMessageComponent";
import LoaderDots from "../../Loader/LoaderDots";
import theme from "../../../Consts/theme";
import MobileHeader from "../MobileHeader/MobileHeader";
import OrderCard from "./Order/OrderCard";

// styled-components
const StyledContainer = styled.section`
  min-height: 55vh;
`;
const StyledInfo = styled.section`
  gap: 16px;
  border: 1px solid ${theme.concreteGray};
  border-radius: 16px;
  background-color: ${theme.white};
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;

  div {
    text-align: center;
    width: 90%;
  }

  svg {
    width: 50px;
    height: 50px;
    margin-bottom: 20px;
  }

  @media only screen and (min-width: ${(props) => props.theme.desktop}) {
    div {
      width: 60%;
    }
  }
`;

const StyledLoaderBox = styled.div`
  height: 60vh;
  width: 100%;
  display: flex;
  justify-content: center;

  .loader {
    padding-top: 0px;
  }
`;

const MyPayments = () => {
  const navigate = useNavigate();
  //  ----------------------------------------------------------------
  // GRAPHQL GET REQUEST
  const {
    loading: queryLoading,
    error: queryError,
    data: queryData,
  } = useQuery(GET_MY_PAYMENTS, {
    fetchPolicy: "cache-and-network",
  });

  // ----------------------------------------------------------------
  // VARIABLES
  const firstUserOrder = queryData && queryData?.user?.orders[0];
  const allUserOrders = queryData && queryData?.user?.orders;

  useEffect(() => {
    if (allUserOrders?.length === 1) {
      navigate(makeAppRoute(`orderDetails`, { ORDER_ID: +firstUserOrder?.id }));
      return;
    }
  }, [allUserOrders]);

  // -------------------------------------------------------------------------------
  // ERRORS

  if (queryError)
    return (
      <ErrorMessageComponent
        firstText="Oops!"
        secondText="Something went wrong while fetching your data."
        thirdText="Please try again later."
      />
    );

  // -------------------------------------------------------------------------------
  // LOADING
  if (queryLoading)
    return (
      <StyledLoaderBox>
        <LoaderDots
          className="loader"
          dotsBgColor={theme.darkBlue}
          dotHeight="8px"
          dotWidth="8px"
          dotMargin="8px"
          isLoading={queryLoading}
        />
      </StyledLoaderBox>
    );

  // -------------------------------------------------------------------------------
  // MAIN COMPONENT

  return (
    <div>
      <MobileHeader />
      <StyledContainer>
        {allUserOrders?.length > 1 ? (
          <OrderCard orders={allUserOrders} />
        ) : (
          <StyledInfo>
            <div>
              <BsPatchExclamationFill />
              <Text fontWeight={500}>
                Please note, we have no record of payments made by you at this
                time.
              </Text>
            </div>
          </StyledInfo>
        )}
      </StyledContainer>
    </div>
  );
};

export default MyPayments;
