import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { axios, makeApiRoute, makeAppRoute } from "../../Router";
import { GlobalContext } from "../../Context/GlobalProvider";
import Button from "../Button/Button";
import TalentMarketForm from "./TalentMarketForm";
import LoaderDots from "../Loader/LoaderDots";
import theme from "../../Consts/theme";

// styled-components
const StyledPageWrapper = styled.div`
  -webkit-animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-top 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  overflow: auto;
  position: relative;

  .banner img {
    display: block;
    width: 100%;
    height: auto;
  }
  // entrance of the page
  @keyframes slide-top {
    0% {
      -webkit-transform: translateY(80vh);
      transform: translateY(80vh);
    }
    100% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }
  }
  @keyframes slide-top {
    0% {
      -webkit-transform: translateY(80vh);
      transform: translateY(80vh);
    }
    100% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }
  }
`;
const StyledContentContainer = styled.div`
  margin: auto;
  padding-top: 40px;
  padding-bottom: 190px;
  width: 80%;
  line-height: 1.5;
  text-align: justify;
  h6 {
    color: ${(props) => props.theme.lightBlue};
  }
  h4 {
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    margin-top: 30px;
  }
  p {
    margin-top: 8px;
  }
  p.grey-content,
  p.grey-content p span {
    font-style: normal;
    font-weight: 400 !important;
    font-size: 18px !important;
    line-height: 21px !important;
    color: ${(props) => props.theme.lightGrayText} !important;
  }
  p a.job-link {
    color: ${(props) => props.theme.lightBlue};

    :hover {
      text-decoration: underline;
    }
  }
  @media only screen and (min-width: ${(props) => props.theme.laptop}) {
    padding-bottom: 100px;
  }
`;
const StyledButtonsGroup = styled.div`
  display: flex;
  flex-direction: column;

  @media only screen and (min-width: ${(props) => props.theme.laptop}) {
    flex-direction: row;
  }
`;
const StyledJobTitle = styled.p`
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 37px;
  margin-top: 5px;
  a {
    text-decoration: underline;
    font-size: 1.3rem;
  }
  :focus a {
    color: ${(props) => props.theme.black};
  }

  @media only screen and (min-width: ${(props) => props.theme.laptop}) {
    margin-bottom: 0.5rem;
    font-weight: 500;
    line-height: 1.2;
    font-size: 32px;

    :hover a {
      color: ${(props) => props.theme.black};
    }
  }
`;
const StyledConfirmContainer = styled.div`
  min-height: 130px;
  background-color: ${(props) => props.theme.lightGrayText};
  opacity: 0.85;
  position: fixed;
  bottom: 0;
  width: 100%;
  color: ${(props) => props.theme.white};
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;

  .inner-wrap {
    width: 90%;
    margin: 0 auto;
    text-align: center;
    display: flex;
    flex-direction: column;

    button {
      width: 100%;
      border-radius: 50px;
      margin-top: 10px;
    }
  }

  @media only screen and (min-width: ${(props) => props.theme.laptop}) {
    min-height: 100px;

    .inner-wrap {
      width: 80%;
      flex-direction: row;
      justify-content: space-between;
      align-self: center;

      button {
        width: auto;
        border-radius: 0px;
        margin-left: 10px;
        margin-top: 0px;
      }
    }
  }
`;

const TalentMarketContainer = ({ talentMarket }) => {
  const navigate = useNavigate();
  const { popupConfig, setPopupConfig, setPopupDefaultValues } =
    useContext(GlobalContext);
  // states
  const [isLoading, setIsLoading] = useState(false);

  function createMarkup(html) {
    return { __html: html };
  }

  // function to open the confirmation modal
  const handleDeclineOfferConfirmation = () => {
    setPopupConfig({
      ...popupConfig,
      show: true,
      title: "Are you sure you want to decline this offer?",
      subTitle: "Once the offer is declined you can not undo this Action",
      maxWidth: "500px",
      bodyClassname: "action-buttons",
      body: <TalentMarketForm talentMarket={talentMarket} />,
    });
  };

  // function to accept the offer and show notification modal
  const onAcceptOffer = (e) => {
    e.preventDefault();
    setIsLoading(true);
    if (talentMarket !== null) {
      const dataToUpdate = {
        offerId: talentMarket.id,
        status: true,
      };

      axios
        .put(makeApiRoute("updateTalentMarket"), dataToUpdate)
        .then((response) => {
          setIsLoading(false);
          if (response.status === 200) {
            setPopupConfig({
              ...popupConfig,
              show: true,
              title: "You have successfully enrolled in our hiring program!",
              subTitle: "We wish you a lot of luck in our enrollment process!",
              bodyClassname: "action-buttons center",
              maxWidth: "500px",
              body: (
                <Button
                  text="Back to Home page"
                  variant="blueBtn"
                  onClick={() => {
                    navigate(makeAppRoute("home"));
                    setPopupDefaultValues();
                  }}
                />
              ),
            });
          } else {
            setPopupConfig({
              ...popupConfig,
              show: true,
              subTitle: "",
              confirmation: false,
              maxWidth: "500px",
              title: "Something Unexpected Happened. Please try again later!",
              bodyClassname: "action-buttons",
              body: (
                <Button
                  text="Close"
                  variant="blueBtn"
                  onClick={() => setPopupDefaultValues()}
                />
              ),
              closeButton: {
                show: true,
                action: () => {
                  setPopupDefaultValues();
                },
              },
            });
            throw new Error("Bad Request");
          }
        })
        .catch(function (error) {
          setIsLoading(false);
          setPopupConfig({
            ...popupConfig,
            show: true,
            subTitle: "",
            confirmation: false,
            maxWidth: "500px",
            title: "Something Unexpected Happened. Please try again later!",
            bodyClassname: "action-buttons",
            body: (
              <Button
                text="Close"
                variant="blueBtn"
                onClick={() => setPopupDefaultValues()}
              />
            ),
            closeButton: {
              show: true,
              action: () => {
                setPopupDefaultValues();
              },
            },
          });
          throw error.response;
        });
    }
  };
  return (
    <>
      <StyledPageWrapper>
        <div className="banner">
          <img
            src="/images/hiringBanner.svg"
            loading="eager|lazy"
            alt="Banner"
          />
        </div>
        <StyledContentContainer>
          <h6>Job position</h6>
          <StyledJobTitle>
            {talentMarket ? talentMarket?.title : null}
          </StyledJobTitle>
          <br />
          <h4>Company name</h4>
          <p className="grey-content">
            {talentMarket ? talentMarket?.name : null}
          </p>
          <br />
          <h4>URL</h4>
          <p>
            {talentMarket ? (
              <a
                target="_blank"
                href={talentMarket?.url}
                className="job-link"
                rel="noreferrer"
              >
                {talentMarket?.url}
              </a>
            ) : null}
          </p>
          <br />
          <h4>Description</h4>
          <p
            className="grey-content"
            dangerouslySetInnerHTML={createMarkup(
              talentMarket ? talentMarket?.description : null
            )}
          ></p>
          <br />
        </StyledContentContainer>
      </StyledPageWrapper>

      <StyledConfirmContainer>
        <div className="inner-wrap">
          <StyledJobTitle>
            <a target="_blank" href={talentMarket?.url} rel="noreferrer">
              {talentMarket ? talentMarket.title : null}
            </a>
          </StyledJobTitle>
          <StyledButtonsGroup>
            <Button
              text="Decline offer"
              variant="declineBtn"
              onClick={handleDeclineOfferConfirmation}
              disabled={isLoading}
            />
            <Button
              text={
                !isLoading ? (
                  "Accept offer"
                ) : (
                  <LoaderDots
                    isLoading={true}
                    dotsBgColor={theme.lightBlue}
                    dotHeight="4px"
                    dotWidth="4px"
                    buttonSubmit
                    dotMargin="10px"
                  />
                )
              }
              variant="blueBtn"
              onClick={onAcceptOffer}
              disabled={isLoading}
            />
          </StyledButtonsGroup>
        </div>
      </StyledConfirmContainer>
    </>
  );
};
export default TalentMarketContainer;
