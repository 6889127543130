import React from "react";
import { NavLink, useParams } from "react-router-dom";
import styled from "styled-components";
import { useQuery } from "@apollo/client";

import { GET_MY_PAYMENTS } from "../../../../Consts/GraphqlQueries";
import { makeAppRoute } from "../../../../Router";
import { Text } from "../../../../Consts/Text";
import theme from "../../../../Consts/theme";
import ErrorMessageComponent from "../../../ErrorMessageComponent/ErrorMessageComponent";
import LoaderDots from "../../../Loader/LoaderDots";
import MobileHeader from "../../MobileHeader/MobileHeader";
import PaymentDetails from "../PaymentDetails";
import ProfileSectionCard from "../../ProfileSectionCard/ProfileSectionCard";
import profileSections from "../../../../Consts/StaticData";
import useMediaCustom from "../../../../Hooks/useMediaCustom";
import Payment from "../Payment";

// styled-components
const StyledContainer = styled.section`
  padding-top: 80px;

  @media only screen and (min-width: ${(props) => props.theme.desktop}) {
    width: 90%;
    margin: 0 auto;
  }

  @media only screen and (min-width: ${(props) => props.theme.large_desktop}) {
    width: 75%;
  }

  @media only screen and (min-width: 1600px) {
    width: 55%;
  }
`;

const StyledInnerContainer = styled.section`
  padding: 0 16px;
  padding-top: 32px;
  row-gap: 16px;
  display: grid;
  margin-bottom: 70px;

  span.title {
    margin: 8px 0;
  }
  @media only screen and (min-width: ${(props) => props.theme.desktop}) {
    grid-template-columns: 4fr 8fr;
    gap: 16px;
  }
`;
const StyledBreadcrumb = styled.div`
  display: none;

  @media only screen and (min-width: ${(props) => props.theme.desktop}) {
    display: flex;
    align-items: center;
    color: ${theme.darkGray};
    margin: 0 24px;

    p:last-child,
    a:not(:first-child) {
      margin-left: 3px;
    }
    a:first-child {
      margin-right: 3px;
    }
  }
`;
const StyledLoaderBox = styled.div`
  height: 60vh;
  width: 100%;
  display: flex;
  justify-content: center;

  .loader {
    padding-top: 0px;
  }
`;

const StyledNavLink = styled(NavLink)`
  text-decoration: underline;
  line-height: normal;
  cursor: pointer;
  font-weight: 700;
  font-size: 13px;
  opacity: 0.7;
  display: inline-block;
  height: 100%;
  transition: opacity 0.2s ease-in-out;

  &:hover {
    text-decoration: underline !important;
    opacity: 1;
  }
`;
const StyledInfoCardContainer = styled.section`
  article:not(:last-child) {
    margin-bottom: 16px;
  }

  span.title {
    font-size: 21px;
    line-height: 26px;
  }

  p.description {
    font-size: 14px;
    line-height: 20px;
    color: #65676c;
  }
  @media only screen and (min-width: ${(props) => props.theme.desktop}) {
    article {
      margin-bottom: 0px;
    }
  }
`;

const OrderDetailsController = () => {
  const isDesktop = useMediaCustom("(min-width: 992px)");
  const { orderID } = useParams();
  //  ----------------------------------------------------------------
  // GRAPHQL GET REQUEST
  const {
    loading: queryLoading,
    error: queryError,
    data: queryData,
  } = useQuery(GET_MY_PAYMENTS, {
    fetchPolicy: "cache-and-network",
  });

  // ----------------------------------------------------------------
  // VARIABLES
  const allUserOrders = queryData && queryData?.user?.orders;
  const currentOrder =
    allUserOrders && allUserOrders?.find((order) => +order.id === +orderID);

  const completedPayments =
    currentOrder &&
    currentOrder?.installments
      ?.filter((installment) => installment.status === "success")
      .map((installment, index) => ({
        ...installment,
        installmentNumber: index + 1,
      }));

  const upcomingPayments =
    currentOrder &&
    currentOrder?.installments
      ?.filter((installment) => installment.status !== "success")
      .map((installment, index) => {
        if (completedPayments.length > 0) {
          return {
            ...installment,
            installmentNumber: completedPayments.length + index + 1,
          };
        } else {
          return { ...installment, installmentNumber: index + 1 };
        }
      });

  const currentSection = profileSections.find((element) => {
    if (element.path === "my-payments") {
      return element;
    }
    return false;
  });

  // -------------------------------------------------------------------------------
  // ERRORS

  if (queryError)
    return (
      <ErrorMessageComponent
        firstText="Oops!"
        secondText="Something went wrong while fetching your data."
        thirdText="Please try again later."
      />
    );

  // -------------------------------------------------------------------------------
  // LOADING
  if (queryLoading)
    return (
      <StyledLoaderBox>
        <LoaderDots
          className="loader"
          dotsBgColor={theme.darkBlue}
          dotHeight="8px"
          dotWidth="8px"
          dotMargin="8px"
          isLoading={queryLoading}
        />
      </StyledLoaderBox>
    );

  // -------------------------------------------------------------------------------
  // MAIN COMPONENT

  return (
    <div>
      <MobileHeader />
      <StyledContainer>
        {/* ------------------------ */}
        {/* StyledBreadcrumb only for desktop */}
        <StyledBreadcrumb>
          <StyledNavLink to={makeAppRoute("profile")}>My Account</StyledNavLink>
          {allUserOrders?.length > 1 ? (
            <>
              &gt;
              <StyledNavLink
                to={makeAppRoute("profileSection", { SECTION: "my-payments" })}
              >
                My Payments
              </StyledNavLink>
              <Text fontWeight={700} fontSize="13px">
                &gt; Order
              </Text>
            </>
          ) : (
            <Text fontWeight={700} fontSize="13px">
              &gt; My Payment
            </Text>
          )}
        </StyledBreadcrumb>
        <StyledInnerContainer>
          {/* ------ left side container desktop only -------------- */}
          <div>
            <StyledInfoCardContainer>
              {currentSection?.infoCards.length > 0 &&
                (isDesktop ? (
                  currentSection?.infoCards.map((card, index) => (
                    <ProfileSectionCard
                      key={index}
                      sectionData={card}
                      displayContent
                    />
                  ))
                ) : (
                  <ProfileSectionCard
                    sectionData={currentSection?.infoCards[0]}
                    displayContent
                  />
                ))}
              <PaymentDetails data={queryData} />
            </StyledInfoCardContainer>
          </div>
          {/* ------ right side container desktop only -------------- */}
          <div>
            <Payment
              heading="Upcoming payments"
              data={upcomingPayments}
              userOrder={currentOrder}
            />
            <Payment
              heading="Completed payments"
              data={completedPayments}
              userOrder={currentOrder}
            />
          </div>
        </StyledInnerContainer>
      </StyledContainer>
    </div>
  );
};

export default OrderDetailsController;
