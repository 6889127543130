import React from "react";
import styled from "styled-components";


import MultipleSelect from "../SelectElement/MultipleSelect";

const StyledContainer = styled.fieldset`
  margin-bottom: 1rem;
`;

const FormMultipleSelectGroup = ({
  fieldLabel,
  className,
  defaultValue,
  options,
  getInputValue,
  isRequired,
  errors,
  maximumOptionsToSelect,
  additionalLabel
}) => {
  return (
    <StyledContainer>
      <MultipleSelect
        options={options}
        getInputValue={getInputValue}
        defaultValue={defaultValue}
        isRequired={isRequired}
        errors={errors}
        maximumOptionsToSelect={maximumOptionsToSelect}
        fieldLabel={fieldLabel}
        className={className}
        additionalLabel={additionalLabel}
      />
    </StyledContainer>
  );
};
export default FormMultipleSelectGroup;
