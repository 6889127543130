import React, { useContext, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";

import { makeAppRoute } from "../Router";
import { StyledLogo } from "../Components/CommonStyledComponents/CommonStyledComponents";
import useMediaCustom from "../Hooks/useMediaCustom";
import LoginForm from "../Components/UserAuth/LoginForm";
import PasswordReset from "../Components/UserAuth/PasswordReset";
import RequestPasswordReset from "../Components/UserAuth/RequestPasswordReset";
import { GlobalContext } from "../Context/GlobalProvider";



// styled-components
const StyledBackgroundWrap = styled.section`
  margin-top: -80px;
  height: 100vh;
  max-width: 100%;
  background-image: url("/images/login-back.svg");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  position: relative;
`;
const StyledHeaderWrap = styled.section`
  display: none;
  @media only screen and (min-width: ${(props) => props.theme.desktop}) {
    padding: 70px 0 0 70px;
    font-style: normal;
    display: block;

    img {
      margin-bottom: 20%;
    }

    span {
      font-weight: 700;
      font-size: 54px;
      text-transform: capitalize;
      line-height: 62px;
      color: ${(props) => props.theme.white};
      display: block;
    }

    p {
      font-weight: 400;
      font-size: 18px;
      line-height: 21px;
      color: ${(props) => props.theme.textGrey};
      width: 25%;
      margin-top: 16px;
    }
  }
`;
const StyledContainer = styled.div`
  background: ${(props) => props.theme.lightgray};
  border-radius: 60px 60px 0px 0px;
  height: 60vh;
  width: 100%;
  position: absolute;
  bottom: 0px;
  right: 0px;
  padding: 40px 24px 32px;

  @media only screen and (min-width: ${(props) => props.theme.desktop}) {
    border-radius: 0;
    right: 40px;
    height: 100vh;
    width: 40%;
    padding: 120px 3% 0px;
  }
`;
const StyledHeading = styled.h2`
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  color: ${(props) => props.theme.black};
  margin-top: 20px;
  @media only screen and (min-width: ${(props) => props.theme.desktop}) {
    text-align: left;
    font-size: 40px;
    line-height: 46px;
  }
`;
const StyledSubheading = styled.p`
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  color: ${(props) => props.theme.lightGrayText};
  width: 100%;
  margin-top: 10px;
`;
const StyledGrayCircle = styled.img`
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  @media only screen and (min-width: ${(props) => props.theme.laptop}) {
    display: none;
  }
`;

const UserAuth = ({ title, subtitle }) => {
  const { user, isAuth } = useContext(GlobalContext);
  const navigate = useNavigate();

  // custom hook
  const isDesktop = useMediaCustom("(min-width: 992px)");
  let params = useParams();
  const location = useLocation();

  const loginRoute = location.pathname === "/login";
  const passwordReset = location.pathname === `/password-reset/${params.token}`;
  const requestPasswordReset = location.pathname === "/request-password-reset";

  useEffect(() => {
    if (isAuth && user?.id) {
      navigate(makeAppRoute("home"));
    }
  }, [isAuth, user?.id]);

  return (
    <StyledBackgroundWrap>
      <StyledGrayCircle src="/images/gray-circle.svg" />

      {!isDesktop ? (
        <StyledLogo>
          <img src="/images/logo-white.svg" alt="Logo" />
        </StyledLogo>
      ) : (
        ""
      )}
      {!isDesktop ? <img src="/images/two-circles.svg" alt="Circles" /> : ""}

      <StyledHeaderWrap>
        <img src="/images/brainster-logo-white.svg" alt="Brainster Logo" />
        <span>Future proof</span>
        <span>your career</span>
        <p>
          Transform your career by joining a community of 9,000+ alumni to get
          an advantage on the job market.
        </p>
      </StyledHeaderWrap>
      <StyledContainer>
        {isDesktop ? (
          <StyledLogo>
            <img src="/images/logo-dark.svg" alt="Logo" />
          </StyledLogo>
        ) : (
          ""
        )}
        <StyledHeading>{title}</StyledHeading>
        {isDesktop ? <StyledSubheading>{subtitle}</StyledSubheading> : ""}

        {loginRoute ? <LoginForm /> : ""}
        {passwordReset ? <PasswordReset token={params.token} /> : ""}
        {requestPasswordReset ? <RequestPasswordReset /> : ""}
      </StyledContainer>
    </StyledBackgroundWrap>
  );
};

export default UserAuth;
