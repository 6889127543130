import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";

import { axios, makeApiRoute, makeAppRoute } from "../../Router";
import { scrollToTop } from "../../Consts/PureFunctions";
import { GlobalContext } from "../../Context/GlobalProvider";
import { StyledHomePageTitle } from "../CommonStyledComponents/CommonStyledComponents";
import theme from "../../Consts/theme";
import useMediaCustom from "../../Hooks/useMediaCustom";
import HomeworkPopUp from "../Homework/HomeworkPopUp";
import LoaderDots from "../Loader/LoaderDots";
import ProfileInfo from "../ProfileInfo/ProfileInfo";
import ActivityPoints from "./ActivityPoints";
import AttendanceCard from "./AttendanceCard";
import GeneralProgressTracker from "../ProgressTracker/GeneralProgressTracker";
import Button from "../Button/Button";
import ErrorMessageComponent from "../ErrorMessageComponent/ErrorMessageComponent";

// styled-componentes
const StyledMainContainer = styled.main`
  width: 100%;
  padding-bottom: 100px;
  @media only screen and (min-width: ${(props) => props.theme.laptop}) {
    overflow: hidden;
  }
  @media only screen and (min-width: ${(props) => props.theme.desktop}) {
    width: 90%;
    margin: 0 auto;
  }
  @media only screen and (min-width: ${(props) => props.theme.large_desktop}) {
    width: 85%;
    margin: 0 auto;
  }
  @media only screen and (min-width: 1550px) {
    width: 70%;
    margin: 0 auto;
  }
`;
const StyledGeneralProgressTrackerWrap = styled.section`
  display: grid;
  grid-row-gap: 16px;
  margin-top: 50px;
  margin-bottom: 30px;
  padding: 0 1rem;
  @media only screen and (min-width: ${(props) => props.theme.laptop}) {
    grid-template-columns: repeat(3, 33%);
    margin-bottom: 0px;
    grid-column-gap: 1%;
    padding-right: calc(1% + 16px);
  }
  @media only screen and (min-width: ${(props) => props.theme.desktop}) {
    grid-template-columns: repeat(3, 32%);
    margin-bottom: 0px;
    grid-column-gap: 2%;
    padding: 0;
    margin-bottom: 40px;
  }
`;
const StyledActivitySection = styled.section`
  width: 100%;
  padding: 0 1rem;
  margin-bottom: 30px;

  @media screen and (min-width: ${(props) => props.theme.desktop}) {
    padding: 0;
  }
`;
const StyledShowMoreButton = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;

  button {
    background-color: transparent;
    display: inline-block;
    padding: 15px 10px;
    outline: none;
    border: none;
    color: #0a08e5;
    font-size: 18px;
    font-weight: bold;
    cursor: pointer;

    :hover {
      box-shadow: 0 4px 2px -2px rgba(0, 0, 0, 0.3);
    }
    :active {
      box-shadow: 0 4px 2px -2px rgba(0, 0, 0, 0.3);
    }
  }
`;
const StyledAttendanceContainer = styled.div`
  display: grid;
  grid-row-gap: 16px;
  margin-top: 16px;

  @media (min-width: 374px) and (max-width: 425px) {
    grid-template-columns: 48% 48%;
    grid-column-gap: 4%;
  }
  @media screen and (min-width: ${(props) => props.theme.laptop}) {
    grid-template-columns: 24% 24% 24% 24%;
    grid-column-gap: 2%;
    padding-right: 2%;
  }
`;
const StyledCardWrap = styled.div`
  /* padding: 0 16px;  */
`;
const StyledErrorContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 100px;
  height: 80vh;

  p {
    margin-bottom: 5px;
  }

  @media screen and (min-width: ${(props) => props.theme.laptop}) {
    .error-inner-box {
      width: 50%;
    }
  }
`;
const StudentDashboard = () => {
  // hooks
  const isLaptop = useMediaCustom("(min-width: 767px)");
  const navigate = useNavigate();
  const { user_id } = useParams();
  const { user, popupConfig, setPopupConfig, setPopupDefaultValues } =
    useContext(GlobalContext);

  // state
  const [studentData, setStudentData] = useState();
  const [shownPopUp, setShownPopUp] = useState(false);
  const [currentActivity, setCurrentActivity] = useState(null);
  const [projectsShown, setProjectsShown] = useState(3);
  const [challengesShown, setChallengesShown] = useState(5);
  const [attendanceShown, setAttendanceShown] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isAuthUser, setIsAuthUser] = useState(true);

  // fetching data
  useEffect(() => {
    if (user && user.id) {
      setIsLoading(true);
      scrollToTop();
      const loggedUser = +user.id === +user_id;
      setIsAuthUser(loggedUser);

      axios
        .get(makeApiRoute("studentPoints", { USER_ID: +user.id }))
        .then((response) => {
          setStudentData(response?.data?.data);
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
          console.log(error);

          setPopupConfig({
            ...popupConfig,
            show: true,
            subTitle: "",
            confirmation: false,
            maxWidth: "500px",
            title: "Something Unexpected Happened. Please try again later!",
            bodyClassname: "action-buttons",
            body: (
              <Button
                text="Back to Homepage"
                variant="blueBtn"
                onClick={() => {
                  setPopupDefaultValues();
                  navigate(makeAppRoute("home"));
                }}
              />
            ),
          });
        });
    }
  }, [user.id]);

  // setting default attendance cards shown depending on the device
  useEffect(() => {
    isLaptop ? setAttendanceShown(12) : setAttendanceShown(4);
  }, [isLaptop]);

  const onHomeworkBtnClick = (_activity) => {
    setShownPopUp(!shownPopUp);
    setCurrentActivity(_activity);
    scrollToTop();
  };

  const handleClosePopUp = () => {
    setShownPopUp(false);
  };

  const onShowMoreChallenges = () => {
    if (challengesShown < challengesArray.length) {
      setChallengesShown(challengesShown + 5);
    } else {
      setChallengesShown(5);
    }
  };
  const onShowMoreProjects = () => {
    if (projectsShown < projectsArray.length) {
      setProjectsShown(projectsShown + 3);
    } else {
      setProjectsShown(3);
    }
  };
  const onShowMoreAttendance = () => {
    if (attendanceShown < studentData?.presence?.length) {
      if (isLaptop) {
        setAttendanceShown(attendanceShown + 12);
      } else {
        setAttendanceShown(attendanceShown + 4);
      }
    } else {
      if (isLaptop) {
        setAttendanceShown(12);
      } else {
        setAttendanceShown(4);
      }
    }
  };

  const totalPoints = studentData?.percentage?.split(".0")?.join("");

  const challengesArray = studentData?.activities?.filter(
    (activity) => activity?.type === "Challenge"
  );
  const projectsArray = studentData?.activities?.filter(
    (activity) => activity?.type === "Project"
  );
  const hackathonsArray = studentData?.activities?.filter(
    (activity) => activity?.type === "Hackathon"
  );

  const challengesPoints = () => {
    if (
      studentData?.challenges_acquired_points === "0.0" ||
      studentData?.challenges_available_points === "0.0"
    ) {
      return 0;
    } else {
      return (
        (studentData?.challenges_acquired_points /
          studentData?.challenges_available_points) *
        100
      );
    }
  };

  const attendancePoints =
    studentData?.presence_acquired_points > 0
      ? (studentData?.presence_acquired_points /
          studentData?.presence_available_points) *
        100
      : 0;

  if (isLoading) {
    return (
      <LoaderDots
        isLoading={isLoading}
        bgColor="#f7f7f7"
        dotsBgColor={theme.lightBlue}
        height="100vh"
        dotMargin="25px"
        dotHeight="10px"
        dotWidth="10px"
      />
    );
  }
  if (!isAuthUser)
    return (
      <StyledErrorContainer>
        <div className="error-inner-box">
          <ErrorMessageComponent
            firstText="You are unauthorized to see that student's profile"
            secondText="You have only access to your personal profile"
          />
        </div>
      </StyledErrorContainer>
    );
  return (
    <StyledMainContainer>
      <>
        <ProfileInfo
          editProfile={+user_id === user?.id}
          userName={studentData?.name}
          userAcademy={studentData?.academy}
          userGroup={studentData?.group}
          userProfile={studentData?.profile}
        />
        <StyledGeneralProgressTrackerWrap>
          <StyledCardWrap>
            <GeneralProgressTracker
              title="Total Points"
              percentage={studentData?.percentage}
              progressBarContent={`${Math.round(totalPoints)}%`}
              icon={
                studentData?.percentage > 0
                  ? "/images/total-points-progress.svg"
                  : "/images/total-points-not-started.svg"
              }
            />
          </StyledCardWrap>
          <StyledCardWrap>
            <GeneralProgressTracker
              title="Challenge Points"
              percentage={challengesPoints()}
              progressBarContent={`${Math.round(challengesPoints())}%`}
              icon={
                challengesPoints() > 0
                  ? "/images/challenges-finished.svg"
                  : "/images/challenges-not-started.svg"
              }
            />
          </StyledCardWrap>
          <StyledCardWrap>
            <GeneralProgressTracker
              title="Attendance Points"
              percentage={attendancePoints}
              progressBarContent={`${Math.round(attendancePoints)}%`}
              icon={
                attendancePoints > 0
                  ? "/images/attendance-finished.svg"
                  : "/images/attendance-not-started.svg"
              }
            />
          </StyledCardWrap>
        </StyledGeneralProgressTrackerWrap>

        {/* ------------------------------- PROJECTS ------------------------------------- */}
        {projectsArray?.length > 0 ? (
          <StyledActivitySection>
            <StyledHomePageTitle>Projects</StyledHomePageTitle>

            {projectsArray?.slice(0, projectsShown).map((activity) => (
              <ActivityPoints
                key={activity.id}
                activity={activity}
                user={user}
                hasSubmitedLink={activity?.submission_url !== null}
                setShownPopUp={() => onHomeworkBtnClick(activity)}
                userLogged={+user_id === user?.id}
                // fullWidth={user?.group?.status === "COMPLETED LECTURES"}
              />
            ))}
            {projectsArray.length > 3 ? (
              <StyledShowMoreButton>
                <button onClick={onShowMoreProjects}>
                  {projectsShown < projectsArray.length
                    ? "Show more"
                    : "Show less"}
                </button>
              </StyledShowMoreButton>
            ) : (
              ""
            )}
          </StyledActivitySection>
        ) : (
          ""
        )}
        {/* ------------------------------- HACKATHONS ------------------------------------- */}
        {hackathonsArray?.length > 0 ? (
          <StyledActivitySection>
            <StyledHomePageTitle>Hackathons</StyledHomePageTitle>

            {hackathonsArray?.map((activity) => (
              <ActivityPoints
                key={activity.id}
                activity={activity}
                user={user}
                hasSubmitedLink={activity?.submission_url !== null}
                setShownPopUp={() => onHomeworkBtnClick(activity)}
                userLogged={+user_id === user?.id}
                // fullWidth={user?.group?.status === "PREPARATIONAL PROGRAMME"}
              />
            ))}
          </StyledActivitySection>
        ) : (
          ""
        )}

        {/* ------------------------------- CHALLENGES ------------------------------------- */}
        {challengesArray?.length > 0 ? (
          <StyledActivitySection>
            <StyledHomePageTitle>Challenges</StyledHomePageTitle>

            {challengesArray?.slice(0, challengesShown)?.map((activity) => (
              <ActivityPoints
                key={activity.id}
                activity={activity}
                user={user}
                hasSubmitedLink={activity?.submission_url !== null}
                setShownPopUp={() => onHomeworkBtnClick(activity)}
                userLogged={+user_id === user?.id}
                // fullWidth={user?.group?.status === "COMPLETED LECTURES"}
              />
            ))}
            {challengesArray.length > 3 ? (
              <StyledShowMoreButton>
                <button onClick={onShowMoreChallenges}>
                  {challengesShown < challengesArray.length
                    ? "Show more"
                    : "Show less"}
                </button>
              </StyledShowMoreButton>
            ) : (
              ""
            )}
          </StyledActivitySection>
        ) : (
          ""
        )}
        {/* ------------------------------- ATTENDANCE ------------------------------------- */}
        <StyledActivitySection>
          <StyledHomePageTitle>Attendance</StyledHomePageTitle>
          {studentData?.presence?.length > 0 ? (
            <StyledAttendanceContainer>
              {studentData?.presence
                ?.slice(0, attendanceShown)
                ?.map((presence) => (
                  <AttendanceCard
                    key={presence?.id}
                    date={presence?.date}
                    title={presence.title}
                    points={presence?.points}
                  />
                ))}
            </StyledAttendanceContainer>
          ) : (
            ""
          )}

          {studentData?.presence?.length > 4 ? (
            <StyledShowMoreButton>
              <button onClick={onShowMoreAttendance}>
                {attendanceShown < studentData?.presence?.length
                  ? "Show more"
                  : "Show less"}
              </button>
            </StyledShowMoreButton>
          ) : (
            ""
          )}
        </StyledActivitySection>

        {shownPopUp ? (
          <HomeworkPopUp
            shownPopUp={shownPopUp}
            currentActivity={currentActivity}
            hasSubmitedLink={currentActivity?.submission_url !== null}
            setStudentData={(obj) => setStudentData(obj)}
            handleClosePopUp={handleClosePopUp}
            studentData={studentData}
          />
        ) : (
          ""
        )}
      </>
    </StyledMainContainer>
  );
};
export default StudentDashboard;
