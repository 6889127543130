import React from "react";
import styled from "styled-components";

import { Text } from "../../../../Consts/Text";
import theme from "../../../../Consts/theme";
import FileActions from "../FileActions";

// styled-components
const StyledContainer = styled.section`
  gap: 16px;
  border: 1px solid ${theme.concreteGray};
  border-radius: 16px;
  background-color: ${theme.white};
  margin-bottom: 20px;
`;
const StyledFormHeader = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 16px;
  padding: 16px 0;
  border-bottom: 1px solid ${theme.concreteGray};

  @media only screen and (min-width: ${(props) => props.theme.desktop}) {
    margin-left: 24px;
    margin-right: 24px;
  }
`;
const StyledFilesContainer = styled.div`
  padding: 0 16px 16px;

  > div {
    margin-bottom: 16px;
  }
`;

const AcademyCertificate = ({ userFilesData }) => {
  const brainsterCBCCertificate = userFilesData?.user?.certificate?.pdf_digital;
  const brainsterNextCertificate =
    userFilesData?.user?.certificate?.next_pdf_digital;

  const onDownloadCertificate = (_fileUrl) => {
    if (!userFilesData) return;

    getFileUrlAndOpenTab(_fileUrl);
  };

  function getFileUrlAndOpenTab(_fileUrl) {
    if (_fileUrl) {
      window.open(_fileUrl, "_blank");
    }
  }
  return (
    <StyledContainer>
      <StyledFormHeader>
        <Text fontSize="14px">Academy certificate</Text>
      </StyledFormHeader>

      <StyledFilesContainer>
        {brainsterCBCCertificate ? (
          <FileActions
            icon="/images/academyCertificate.svg"
            title="Academy certificate"
            onDownload={() => onDownloadCertificate(brainsterCBCCertificate)}
            imgUrl={userFilesData?.user?.certificate?.image}
            isSharable
            isDownloadable
            certification
            userFilesData={userFilesData}
          />
        ) : (
          <FileActions
            icon="/images/academyCertificateDisabled.svg"
            title="Your certificate will be available at the end of the academy"
            disabled={true}
          />
        )}
        {brainsterNextCertificate ? (
          <FileActions
            icon="/images/academyCertificate.svg"
            title="Brainster Next certificate"
            onDownload={() => onDownloadCertificate(brainsterNextCertificate)}
            imgUrl={userFilesData?.user?.certificate?.next_image}
            isSharable
            isDownloadable
          />
        ) : (
          <FileActions
            icon="/images/academyCertificateDisabled.svg"
            title="Brainster Next certificate will be available at the end of the academy"
            disabled={true}
          />
        )}
      </StyledFilesContainer>
    </StyledContainer>
  );
};

export default AcademyCertificate;
