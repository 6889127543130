import React, { useCallback, useContext, useEffect, useState } from "react";
import { Element } from "react-scroll";
import styled from "styled-components";

import { axios, makeApiRoute } from "../../Router";
import { StyledHeadTitle } from "../CommonStyledComponents/CommonStyledComponents";
import { GlobalContext } from "../../Context/GlobalProvider";
import Button from "../Button/Button";
import MaterialsContainer from "./MaterialsContainer";
import IconText from "../IconAndText/IconText";
import useMediaCustom from "../../Hooks/useMediaCustom";
import "./BodyStyle.css";

// styled-components
const StyledSubunitWrap = styled.article`
  width: 100%;
  @media only screen and (min-width: ${(props) => props.theme.desktop}) {
    margin-top: 40px;
  }
`;
const StyledOuterContainer = styled.article`
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  padding: 20px 0;
  border-top: 2px solid ${(props) => props.theme.gray};
  img.icon-img {
    align-self: center;
  }
  :last-child {
    margin-bottom: 20px;
  }
  @media only screen and (min-width: ${(props) => props.theme.desktop}) {
    flex-direction: row;
  }
`;
const StyledHeader = styled.header`
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
`;

const StyledSubunitType = styled.p`
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  text-transform: uppercase;
  color: ${(props) => props.theme.red};
`;

const StyledMaterialsButtonWrap = styled.div`
  align-self: center;
  width: 100%;
  @media only screen and (min-width: ${(props) => props.theme.desktop}) {
    width: auto;
    margin-left: 10px;
    position: relative;
    display: flex;
    border-bottom: 1px dotted black;
    .tooltiptext {
      visibility: hidden;
      width: 120px;
      background-color: ${(props) => props.theme.textGrey};
      color: #fff;
      text-align: center;
      border-radius: 6px;
      padding: 5px 0;

      /* Position the tooltip */
      position: absolute;
      left: -200%;
      top: 30px;
      z-index: 1;
    }
    :hover .tooltiptext {
      visibility: visible;
    }

    div.time-icon {
      font-size: 8px;
    }
  }
`;
const StyledDivDuration = styled.div`
  @media only screen and (min-width: ${(props) => props.theme.desktop}) {
    width: 25%;
    p {
      font-size: 9px;
    }
    flex-grow: 1;
    display: flex;

    align-self: center;
  }
  @media only screen and (min-width: ${(props) => props.theme.large_desktop}) {
    width: 25%;
    p {
      font-size: 12px;
    }
  }
`;

const StyledDescription = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: ${(props) => props.theme.textGrey};
  margin-top: 4px;
  @media only screen and (min-width: ${(props) => props.theme.desktop}) {
    margin-top: 8px;
    width: 60%;
  }
`;
const StyledResourceDescription = styled.div`
  margin-top: 8px;
  overflow: hidden;
`;
const StyledMaterialsButton = styled.button`
  display: block;
  border: none;
  outline: none;
  background-color: transparent;
  color: ${(props) => props.theme.lightBlue};
  padding: 0;
  margin-top: 18px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 10px;
  text-align: start;
  cursor: pointer;

  @media only screen and (min-width: ${(props) => props.theme.desktop}) {
    flex-direction: row;
    margin-bottom: 0;
    margin-top: 0;
    text-align: left;
    cursor: pointer;
  }
`;

const Resource = ({
  subunitType,
  subunitResources,
  subunitDuration,
  currentSubunit,
  updateCategoryData,
}) => {
  // hooks
  const isDesktop = useMediaCustom("(min-width: 992px)");
  // states
  const [openMaterials, setOpenMaterials] = useState(false);
  const [activeResource, setActiveResource] = useState();
  const [resources, setResources] = useState(subunitResources);
  const { categories, setCategories, popupConfig, setPopupConfig, setPopupDefaultValues } = useContext(GlobalContext);

  useEffect(() => {
    if (openMaterials) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }
  }, [openMaterials]);

  // open pop up
  const onOpenMaterials = useCallback(() => {
    setOpenMaterials(true);
  }, []);
  // close pop up
  const onCloseMaterials = useCallback(() => {
    setOpenMaterials(false);
  }, []);

  const onResourceStatusChanged = (_resourceID, _category) => {
    const dataToUpdate = { 
      resourceId: _resourceID 
    };

    axios
      .put(makeApiRoute("updateMaterials"), dataToUpdate)
      .then((response) => {
        // Since the toggle succeeded, we need to just show the change on frontend
        // Find the index of the resource which status we want to change. Resources is array of resource objects.
        let resourceIndex = resources.findIndex(
          (resource) => resource.id === _resourceID
        );
        // Change the completed status of that particular resources
        resources[resourceIndex].completed =
          !resources[resourceIndex].completed;
        setResources([...resources]);

        updateCategoryData(response?.data?.category);


        // Once the toggle button is clicked, we need to update all categories for two reasons:
        // 1. To keep updated progress trackers in Home Page
        // 2. To keep updated Badges in Home page
        let categoryIndex = categories?.findIndex(
          (category) => category?.id === response?.data?.category?.id
        );

        categories[categoryIndex].totalCompletedResources =
          response?.data?.category?.totalCompletedResources;
        setCategories(categories);
      })
      .catch((error) => {
        setPopupConfig({
          ...popupConfig,
          show: true,
          subTitle: "",
          confirmation: false,
          maxWidth: "500px",
          title: "Something Unexpected Happened. Please try again later!",
          bodyClassname: "action-buttons",
          body: (
            <Button
              text="Close"
              variant="blueBtn"
              onClick={() => setPopupDefaultValues()}
            />
          ),
          closeButton: {
            show: true,
            action: () => {
              setPopupDefaultValues();
            },
          },
        });
      });
  };

  return (
    <>
      {resources.length > 0 ? (
        <StyledSubunitWrap>
          <StyledHeader>
            <StyledSubunitType>{subunitType}</StyledSubunitType>
            {isDesktop ? (
              <IconText
                icon={subunitDuration === 0 && "/images/timeIcon.svg"}
                description={
                  subunitDuration === 0
                    ? ""
                    : subunitDuration < 60
                    ? subunitDuration + " minutes"
                    : Math.floor(subunitDuration / 60) +
                      " Hours " +
                      (subunitDuration % 60) +
                      " Minutes"
                }
              />
            ) : (
              ""
            )}
          </StyledHeader>

          {resources.map((resource) => {
            return (
              <Element
                key={resource.id}
                name={`${resource.id}${resource.title}`}
              >
                <StyledOuterContainer>
                  <StyledDescription>
                    <StyledHeadTitle>{resource.title}</StyledHeadTitle>
                    <StyledResourceDescription
                      dangerouslySetInnerHTML={{
                        __html: resource.description,
                      }}
                    />
                  </StyledDescription>
                  <StyledMaterialsButtonWrap>
                    <StyledMaterialsButton
                      onClick={() => {
                        onOpenMaterials();
                        setActiveResource(resource);
                      }}
                    >
                      {!isDesktop ? (
                        "View Materials"
                      ) : (
                        <img src="/images/puzzle.svg" alt="Icon" />
                      )}
                    </StyledMaterialsButton>
                    {isDesktop && (
                      <span className="tooltiptext">View Materials</span>
                    )}
                  </StyledMaterialsButtonWrap>

                  <StyledDivDuration>
                    {isDesktop ? (
                      <IconText
                        className="time-icon"
                        description={
                          resource.duration === 0
                            ? ""
                            : resource.duration < 60
                            ? resource.duration + " minutes"
                            : Math.floor(resource.duration / 60) +
                              " Hours " +
                              (resource.duration % 60) +
                              " Minutes"
                        }
                      />
                    ) : (
                      ""
                    )}
                    <Button
                      variant={!resource.completed ? "blackBtn" : "blueBtn"}
                      text={
                        !resource.completed ? "Mark as complete" : "Completed"
                      }
                      type="button"
                      onClick={() => {
                        onResourceStatusChanged(resource?.id, resource);
                      }}
                    />
                  </StyledDivDuration>
                </StyledOuterContainer>
              </Element>
            );
          })}
        </StyledSubunitWrap>
      ) : (
        ""
      )}
      {openMaterials ? (
        <MaterialsContainer
          openMaterials={openMaterials}
          onCloseMaterials={onCloseMaterials}
          currentSubunit={currentSubunit}
          activeResource={activeResource}
          onResourceStatusChanged={onResourceStatusChanged}
        />
      ) : (
        ""
      )}
    </>
  );
};
export default Resource;
