import React, { useContext } from "react";
import styled from "styled-components";
import { GlobalContext } from "../../Context/GlobalProvider";
import HomeworkPopUp from "./HomeworkPopUp";

// styled-components
const StyledAssignmentBtn = styled.button`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  box-shadow: 0px 8px 30px rgba(0, 0, 0, 0.08);
  border: none;
  border-radius: 10px;
  margin-top: 2%;
  margin-bottom: 2%;
  transition: height 0.2s ease;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  padding: ${(props) => !props.hasSubmitedLink && "15px 0 0 0"};
  cursor: ${(props) =>
    props.hasSubmitedLink ? "default" : "pointer"} !important;

  color: ${(props) => (!props.hasSubmitedLink ? "#191919" : "#C5C5C5")};
  &:active,
  &:focus,
  &:hover {
    outline: none;
    box-shadow: ${(props) =>
      props.uploadedLink
        ? "0px 8px 16px rgba(0, 0, 0, 0.16)"
        : "0px 7px 6px rgba(0, 0, 0, 0.16)"};
    color: ${(props) => !props.hasSubmitedLink && "#005EFF"};
  }

  @media only screen and (max-width: 600px) {
    min-height: auto;
    width: 100%;
    margin-bottom: 4%;
    padding-top: 3px;
    padding-bottom: 3px;
  }
`;
const StyledAttachSpan = styled.span`
  padding: 8px 0;
`;
const StyledAttachmentContent = styled.span`
  margin-bottom: 8px;
`
const StyledEdit = styled.p`
  color: #191919;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  border-top: 1px solid #e6e7eb;
  width: 80%;
  margin: 0 auto;
  cursor: pointer !important;

  span {
    margin-bottom: 0;
    margin-right: 8px;
  }
  img,
  span {
    align-self: center;
  }
`;

const HomeworkButton = ({ hasSubmitedLink, setShownPopUp }) => {

  return (
    <StyledAssignmentBtn
      onClick={() => setShownPopUp()}
      hasSubmitedLink={hasSubmitedLink}
    >
      <StyledAttachSpan>
        <img
          src={
            !hasSubmitedLink
              ? "/images/hyperlink.svg"
              : "/images/hyperlinkGrey.svg"
          }
          alt="Link Icon"
        />
      </StyledAttachSpan>
      <StyledAttachmentContent>
        {!hasSubmitedLink ? "Link your Solution" : "Successfully Uploaded"}
      </StyledAttachmentContent>

      {hasSubmitedLink ? (
        <StyledEdit>
          <span>Edit</span>
          <img src="/images/editIcon.svg" alt="Edit Icon"/>
        </StyledEdit>
      ) : (
        ""
      )}
    </StyledAssignmentBtn>
  );
};
export default HomeworkButton;
