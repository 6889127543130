import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { axios, makeApiRoute, makeAppRoute } from "../../../Router";
import { GlobalContext } from "../../../Context/GlobalProvider";
import Button from "../../Button/Button";
import LoaderDots from "../../Loader/LoaderDots";
import ShareAchievement from "../../Certificate/ShareAchievement";

// styled-components
const StyledContainer = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  width: 100%;

  p.text-gray {
    font-weight: 700;
    font-size: 14px;
    text-align: center;
    color: #a7a7a7;
  }

  p.title {
    margin-bottom: 15px;
  }

  @media only screen and (min-width: ${(props) => props.theme.laptop}) {
    width: 90%;
    margin: 60px auto 0;
    p.title {
      font-size: 18px;
    }
  }
`;

const StyledBody = styled.div`
  margin: 30px 0;
  line-height: 1.5;
  text-align: justify;

  p.margin-top {
    margin-top: 15px;
  }
`;

const StyledLinkWrap = styled.div`
  margin-top: 40px;
  text-align: center;

  @media only screen and (min-width: ${(props) => props.theme.desktop}) {
    text-align: right;
  }
`;

const StyledLink = styled(Link)`
  position: relative;
  color: ${(props) => props.theme.lightBlue} !important;
  &.active {
    color: ${(props) => props.theme.darkBlue};
  }

  &:hover,
  &:focus {
    color: ${(props) => props.theme.darkBlue} !important;
  }

  ::after {
    position: absolute;
    content: "";
    width: 0;
    left: 0;
    bottom: -7px;
    background: ${(props) => props.theme.darkBlue};
    height: 2px;
    transition: 0.3s ease-out;
  }

  :hover::after {
    width: 100%;
  }
`;

const StyledButtonsGroup = styled.div`
  display: flex;
  flex-direction: column-reverse;
  margin-top: 40px;

  button {
    border-radius: 25px !important;
    margin-bottom: 15px !important;
  }

  @media only screen and (min-width: ${(props) => props.theme.desktop}) {
    flex-direction: row;
    justify-content: space-evenly;
    button {
      border-radius: 0 !important;
      margin-bottom: 0 !important;
    }
  }
`;
const ModuleAchievementBody = ({
  activeModuleAchievement,
  onMarkAsSeen,
  onCloseShareModal,
  isLoading,
}) => {
  const { user, popupConfig, setPopupConfig, setPopupDefaultValues } =
    useContext(GlobalContext);
  const [loading, setLoading] = useState(false);

  const eligibleToShare =
    activeModuleAchievement?.attendance_rate?.attendance_rate >= 70 &&
    activeModuleAchievement?.achievement !== null;

    // this condition is added in some rare cases if user has >= 70%, but the achievement is not generated
  const eligibleButHasNoAchievementToShare =
    activeModuleAchievement?.attendance_rate?.attendance_rate >= 70 &&
    activeModuleAchievement?.achievement === null;

  const notEligibleToShare =
    activeModuleAchievement?.attendance_rate?.attendance_rate < 70 ||
    activeModuleAchievement?.achievement === null;

  const handleOpenShareAchievement = () => {
    setLoading(true);
    axios
      .put(
        makeApiRoute("updateAttendanceRates", {
          studentUnitAttendanceRate:
            activeModuleAchievement?.attendance_rate?.id,
        })
      )
      .then((response) => {
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        setPopupConfig({
          ...popupConfig,
          show: true,
          subTitle: "",
          confirmation: false,
          maxWidth: "500px",
          title: "Something Unexpected Happened. Please try again later!",
          bodyClassname: "action-buttons",
          body: (
            <Button
              text="Close"
              variant="blueBtn"
              onClick={() => setPopupDefaultValues()}
            />
          ),
          closeButton: {
            show: true,
            action: () => {
              setPopupDefaultValues();
            },
          },
        });
      });

    !loading &&
      setPopupConfig({
        ...popupConfig,
        show: true,
        subTitle: "",
        confirmation: false,
        maxWidth: "750px",
        title: "",
        body: (
          <ShareAchievement
            show={true}
            onSocialButtonClick={handleCountSharedAchievement}
            imgUrl={
              activeModuleAchievement &&
              activeModuleAchievement?.achievement?.achievement
            }
            sharingLink={activeModuleAchievement &&
              activeModuleAchievement?.achievement?.sharing_link}
          />
        ),
        closeButton: {
          show: true,
          action: () => {
            onCloseShareModal();
          },
        },
      });
  };

  const handleCountSharedAchievement = (_service) => {
    const achievementId = activeModuleAchievement?.achievement?.achievement_id;

    const dataToSubmit = {
      service: _service,
    };

    axios
      .put(
        makeApiRoute("achievement", { ACHIEVEMENT_ID: achievementId }),
        dataToSubmit
      )
      .then((response) => {})
      .catch((error) => {
        setPopupConfig({
          ...popupConfig,
          show: true,
          subTitle: "",
          confirmation: false,
          maxWidth: "500px",
          title: "Something Unexpected Happened. Please try again later!",
          bodyClassname: "action-buttons",
          body: (
            <Button
              text="Close"
              variant="blueBtn"
              onClick={() => setPopupDefaultValues()}
            />
          ),
          closeButton: {
            show: true,
            action: () => {
              setPopupDefaultValues();
            },
          },
        });
      });
  };

  if (eligibleToShare || eligibleButHasNoAchievementToShare) {
    return (
      <StyledContainer>
        <>
          <StyledBody>
            <p className="title">Hello {activeModuleAchievement?.student},</p>
            <p>
              Good job for completing the{" "}
              <strong>
                {activeModuleAchievement?.attendance_rate?.unit_title}{" "}
              </strong>
              module with a{" "}
              <strong>
                {activeModuleAchievement?.attendance_rate?.attendance_rate}%{" "}
              </strong>
              attendance rate!{" "}
            </p>
            {eligibleToShare ? (
              <p className="margin-top">
                Don’t miss out on joining the other fellow-students in sharing
                this milestone with your friends and network.
              </p>
            ) : (
              ""
            )}
          </StyledBody>
          <StyledButtonsGroup>
            {eligibleToShare ? (
              <>
                <Button
                  variant="grayBtn"
                  text={
                    !isLoading ? (
                      "Cancel"
                    ) : (
                      <LoaderDots
                        isLoading={isLoading}
                        dotsBgColor="blue"
                        dotHeight="4px"
                        dotWidth="4px"
                        buttonSubmit
                        dotMargin="4px"
                      />
                    )
                  }
                  disabled={isLoading}
                  onClick={() => onMarkAsSeen()}
                />
                <Button
                  variant="blueBtn"
                  text="Share"
                  disabled={isLoading}
                  onClick={() => handleOpenShareAchievement()}
                />
              </>
            ) : (
              <Button
                variant="grayBtn"
                onClick={() => onMarkAsSeen()}
                disabled={isLoading}
                text={
                  !isLoading ? (
                    "Close"
                  ) : (
                    <LoaderDots
                      isLoading={isLoading}
                      dotsBgColor="blue"
                      dotHeight="4px"
                      dotWidth="4px"
                      buttonSubmit
                      dotMargin="4px"
                    />
                  )
                }
              />
            )}
          </StyledButtonsGroup>
          <StyledLinkWrap>
            <StyledLink
              to={makeAppRoute("points", { USER_ID: user?.id })}
              target="_blank"
              className={(navData) => (navData.isActive ? "active" : "")}
            >
              I want to see my progress
            </StyledLink>
          </StyledLinkWrap>
        </>
      </StyledContainer>
    );
  } else if (notEligibleToShare) {
    return (
      <StyledContainer>
        <>
          <StyledBody>
            <p>
              You completed the{" "}
              <strong>
                {activeModuleAchievement?.attendance_rate?.unit_title}{" "}
              </strong>
              module with a{" "}
              <strong>
                {activeModuleAchievement?.attendance_rate?.attendance_rate}%{" "}
              </strong>{" "}
              attendance rate.
            </p>
            <p className="margin-top">
              Let’s work on improving it in the next module so you don’t miss
              out on joining the other fellow-students in sharing your future
              milestones with your friends and network!
            </p>
          </StyledBody>
          <div>
            <p className="text-gray">
              You need at least 70% attendance points to share a module
              milestone.{" "}
            </p>

            <StyledButtonsGroup>
              <Button
                variant="grayBtn"
                onClick={() => onMarkAsSeen()}
                disabled={isLoading}
                text={
                  !isLoading ? (
                    "Close"
                  ) : (
                    <LoaderDots
                      isLoading={isLoading}
                      dotsBgColor="blue"
                      dotHeight="4px"
                      dotWidth="4px"
                      buttonSubmit
                      dotMargin="4px"
                    />
                  )
                }
              />
            </StyledButtonsGroup>
            <StyledLinkWrap>
              <StyledLink
                to={makeAppRoute("points", { USER_ID: user?.id })}
                target="_blank"
                className={(navData) => (navData.isActive ? "active" : "")}
              >
                I want to see my progress
              </StyledLink>
            </StyledLinkWrap>
          </div>
        </>
      </StyledContainer>
    );
  }
};
export default ModuleAchievementBody;
