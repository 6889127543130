import React, { useContext } from "react";
import styled from "styled-components";

import { GlobalContext } from "../../Context/GlobalProvider";
import Button from "../Button/Button";

// styled-components
const StyledContainer = styled.div`
  text-align: center;
  padding: 40px;
  height: auto;
  background-color: ${(props) => props.theme.white};
  border-radius: 8px;
  background-image: url("/images/congratulation.svg");
  background-repeat: no-repeat;
  background-position: 50% 5%;

  p.content {
    margin-top: 60px;
    margin-bottom: 60px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: ${(props) => props.theme.lightGrayText};
  }
  button {
    border-radius: 25px;
  }
`;

const CategoryCompleted = ({ url, content }) => {
  const { setPopupDefaultValues } = useContext(GlobalContext);

  return (
    <StyledContainer>
      <img src={url} alt="" width="85" />
      <p className="content">{content}</p>
      <Button
        text="Claim Badge"
        variant="blueBtn"
        onClick={() => setPopupDefaultValues()}
      />
    </StyledContainer>
  );
};
export default CategoryCompleted;
