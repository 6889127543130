import React, { useContext, useEffect, useState } from "react";
import { Element } from "react-scroll";
import styled from "styled-components";

import { GlobalContext } from "../../Context/GlobalProvider";
import {
  StyledHomepageSection,
  StyledHomePageTitle,
} from "../CommonStyledComponents/CommonStyledComponents";
import BadgeCard from "./BadgeCard";

// styled-components
const StyledCardContainer = styled.section`
  width: 100%;
  display: grid;
  grid-template-columns: auto auto;
  gap: 16px;
  margin-top: 30px;

  @media only screen and (min-width: ${(props) => props.theme.laptop}) {
    grid-template-columns: auto auto auto ;
  }
  @media only screen and (min-width: ${(props) => props.theme.large_desktop}) {
    grid-template-columns: auto auto auto auto;
  }
`;

const Badges = () => {
  const { categories } = useContext(GlobalContext);
  const [hasCompletedAllCategories, setHasCompletedAllCategories] = useState(true);


  // checking if student has completed all materials
  useEffect(() => {
    if (categories) {
      const hasCompleted = categories.every(
        (category) =>
        category.totalResources > 0 && category.totalCompletedResources === category.totalResources
      );
      setHasCompletedAllCategories(hasCompleted);
    }
  }, [categories]);

  return (
    <Element name="badges">
    <StyledHomepageSection>
      <StyledHomePageTitle>Badges</StyledHomePageTitle>
      <StyledCardContainer>
        {categories?.map((category) => (
          <BadgeCard
            key={category.id}
            unblurred={
              category.totalResources > 0 && category.totalCompletedResources === category.totalResources
            }
            image={
              category.totalCompletedResources >= category.totalResources &&
              category.totalResources > 0
                ? process.env.REACT_APP_FILES_URL +
                  "/" +
                  category?.completedAchievmentIcon
                : process.env.REACT_APP_FILES_URL +
                  "/" +
                  category?.achievmentIcon
            }
            header={category.achievmentTitle}
            text={category.achievmentDescription}
          />
        ))}

        {categories && (
          <BadgeCard
            unblurred={hasCompletedAllCategories}
            header="Highest Achiever"
            text="Successfully Completing the Entire Programme"
            image={
              hasCompletedAllCategories
                ? "/images/highest-achiever-succ.png"
                : "/images/highest-achiever.png"
            }
          />
        )}
      </StyledCardContainer>
    </StyledHomepageSection>
    </Element>
  );
};
export default Badges;
