import React from "react";
import styled from "styled-components";

// styled-components
const StyledAttendanceCard = styled.article`
  background-color: #ffffff;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.06);
  border-radius: 10px;
  padding: 16px 20px;
  border-left: 8px solid
    ${(props) => (props.hasAttended ? "#005eff" : "#E1E2E7")};
`;
const StyledSubTitle = styled.h3`
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  margin-bottom: 8px;
  color: #c5c5c5;
`;
const StyledEventTitle = styled.h3`
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  color: #191919;

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  /* number of lines to show */
  line-clamp: 1;
  -webkit-box-orient: vertical;

  :hover {
    display: block;
  }
`;
const StyledEventBody = styled.div`
  display: flex;
  margin-top: 20px;

  .presence-status {
    align-self: center;
    margin-right: 8px;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    text-transform: uppercase;
    color: #191919;
  }
`;
const AttendanceCard = ({ date, title, points }) => {
  const hasAttended = points > 0;
  return (
    <StyledAttendanceCard hasAttended={hasAttended}>
      <StyledSubTitle>{date}</StyledSubTitle>
      <StyledEventTitle>{title}</StyledEventTitle>
      <StyledEventBody>
        <span className="presence-status">
          {hasAttended ? "present" : "absent"}
        </span>
        <span>
          <img
            src={hasAttended ? "/images/checked.svg" : "/images/absent.svg"}
            alt=""
          />
        </span>
      </StyledEventBody>
    </StyledAttendanceCard>
  );
};
export default AttendanceCard;
