import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

import DropdownArrow from "../DropdownArrow/DropdownArrow";

// styled-components
const StyledSelectLabel = styled.label`
  position: relative;
  display: inline-block;
  font-size: 14px;
  color: #888;
  width: ${(props) => (props.widthAuto ? "auto" : "100%")};

  &.w-100 {
    width: 100%;
  }
  div.dropdown-arrow {
    margin-left: 10px;
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
  }

`;
const StyledCustomSelect = styled.select`
  display: block;
  width: 100%;
  border-color: ${(props) =>
    props?.errors?.length > 0 ? props.theme.red : "#e1e2e7"};
  min-height: 35px;
  background: #cbe7ff;
  border-radius: 3px;
  padding: ${(props) => (props.widthAuto ? "4px" : "16px 30px 16px 16px")};
  margin: 5px 0;
  border-radius: 4px;
  color: ${(props) => props.theme.textGrey};
  font-size: 16px;
  background-color: ${(props) =>
    props.value !== "-1"
      ? `${props.theme.lightGray2}`
      : `${props.theme.white}`};

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  outline: none;
  cursor: pointer;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  box-sizing: border-box;

  option {
    box-sizing: inherit;
    font-weight: normal;
    display: block;
    white-space: nowrap;
    min-height: 1.2em;
    padding: 0px 2px 1px;
    color: ${(props) => props.theme.textGrey};
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
  }

  @media (max-width: 768px) {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  :hover {
    border-color: ${(props) => props.theme.lightBlue};
  }
  :focus {
    outline: none;
    border-color: ${(props) =>
      props?.errors?.length > 0 ? props.theme.red : props.theme.lightBlue};
  }
`;
const StyledError = styled.p`
  font-weight: bold;
  font-size: 12px;
  height: 14px;
  color: ${(props) => props.theme.red} !important;
  transition: all.2s ease-in-out;
`;
const SelectElement = ({
  className,
  selectLabel,
  options,
  name,
  defaultValue,
  getInputValue,
  onBlur,
  widthAuto,
  errors,
}) => {
  const [isInputTouched, setIsInputTouched] = useState(false);
  const [selectedValue, setSelectedValue] = useState(undefined);
  const [isOpen, setIsOpen] = useState(false);
  const selectRef = useRef();

  useEffect(() => {
    setSelectedValue(defaultValue || "-1");
  }, [defaultValue]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (selectRef?.current && !selectRef?.current?.contains(event.target)) {
        setIsOpen(false);
      } else if (
        selectRef?.current &&
        selectRef?.current?.contains(event.target)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [selectRef]);
  const handleParentClick = (event) => {
    event.stopPropagation();
    setIsOpen((prev) => !prev);
  };

  return (
    <>
      <StyledSelectLabel
        className={className}
        widthAuto={widthAuto}
        ref={selectRef}
        onMouseDown={handleParentClick}
      >
        <StyledCustomSelect
          name={name}
          errors={errors}
          widthAuto={widthAuto}
          value={selectedValue}
          isInputTouched={isInputTouched}
          onChange={(e) => {
            e.stopPropagation();
            getInputValue(e.target.value);
            setIsInputTouched(false);
            setSelectedValue(e.target.value);
            setIsOpen((prev) => !prev);
          }}
          onBlur={() => {
            if (selectedValue !== "-1") return;
            setIsInputTouched(true);
            if (onBlur) {
              onBlur();
            }
          }}
        >
          <option value="-1" disabled>
            {selectLabel}
          </option>
          {options?.map((option, index) => (
            <option key={index} value={option.id || option?.name}>
              {option.name || option}
            </option>
          ))}
        </StyledCustomSelect>
        <div className="dropdown-arrow">
          <DropdownArrow className={!isOpen ? "" : "active"} />
        </div>
      </StyledSelectLabel>
      <StyledError>{errors?.length > 0 && errors}</StyledError>
    </>
  );
};

export default SelectElement;
