import React from "react";

import { StyledFormInputGroup } from "../CommonStyledComponents/CommonStyledComponents";
import Input from "../Input/Input";
import Label from "../Label/Label";

const FormInputGroup = ({
  label,
  labelImage,
  className,
  disabled,
  type,
  name,
  defaultValue,
  placeholder,
  getInputValue,
  alertMissingValue,
  isRequired,
  errors,
}) => {
  return (
    <StyledFormInputGroup>
      <Label label={label} labelImage={labelImage} className={className} />
      <Input
        alertMissingValue={alertMissingValue}
        type={type}
        name={name}
        disabled={disabled}
        defaultValue={defaultValue}
        placeholder={placeholder}
        getInputValue={getInputValue}
        isRequired={isRequired}
        errors={errors}
      />
    </StyledFormInputGroup>
  );
};
export default FormInputGroup;
