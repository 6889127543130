import React, { useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";

import { makeAppRoute } from "../../Router";
import { GlobalContext } from "../../Context/GlobalProvider";
import Button from "../Button/Button";

const SurveyPopupBody = () => {
  const navigate = useNavigate();

  const { user, setUser, popupConfig, setPopupConfig, setPopupDefaultValues } =
    useContext(GlobalContext);

  
  useEffect(() => {
    if (Object.keys(user).length > 0 && user?.activeSurvey !== null) {
      setPopupConfig({
        ...popupConfig,
        show: true,
        title: "Hello, new survey available!",
        subTitle:
          "By answering this survey, give us your feedback about your experience with our academies.",
        maxWidth: "500px",
        confirmation: false,
        bodyClassname: "action-buttons",
        body: (
          <>
            <Button
              text="Take the survey"
              variant="blueBtn"
              onClick={() => {
                // close pop up
                setPopupDefaultValues();
                // navigate to Survey page
                navigate(
                  makeAppRoute("survey", {
                    SURVEY_ID: user?.activeSurvey,
                  })
                );
              }}
            />
            <Button
              text="Remind me later"
              variant="grayBtn"
              onClick={() => {
                setUser({ ...user, activeSurvey: null });
                setPopupDefaultValues();
              }}
            />
          </>
        ),
      });
    }
  }, [user?.activeSurvey]);

  return <></>;
};

export default SurveyPopupBody;
