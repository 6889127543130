import React, { useEffect, useState } from "react";
import { AiOutlineCloudDownload } from "react-icons/ai";
import styled from "styled-components";

import { axios, makeApiRoute } from "../../Router";
import useMediaCustom from "../../Hooks/useMediaCustom";
import Button from "../Button/Button";
import IconText from "../IconAndText/IconText";
import Video from "./Video";
import LoaderDots from "../Loader/LoaderDots";
import theme from "../../Consts/theme";

// styled-components
const StyledContainer = styled.div`
  height: 90vh;
  overflow: auto;
  &::-webkit-scrollbar {
    width: 4px;
    border-radius: 4px;
  }
  &::-webkit-scrollbar-track-piece {
    background-color: ${(props) => props.theme.darkLightGray};
    border-radius: 4px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${(props) => props.theme.lightGrayText};
    border-radius: 4px;
  }
`;
const StyledHeadingContainer = styled.div`
  @media only screen and (min-width: ${(props) => props.theme.desktop}) {
    display: block;
    background-color: ${(props) => props.theme.darkLightGray};
    border-radius: 200px 200px 0px 0px;
  }
`;
const StyledInnerWrap = styled.div`
  width: 100%;
  padding: 80px 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #c5c5c5;
  line-height: 1.3;
  background-color: ${(props) => props.theme.darkGray};
  overflow: auto;

  p {
    margin-bottom: 20px;
  }
  p.plain-text-title {
    text-align: left !important;
  }

  &.plain-text-wrap {
    height: 60vh;
    overflow: scroll;
    &::-webkit-scrollbar {
      width: 4px;
      border-radius: 4px;
    }
    &::-webkit-scrollbar-track-piece {
      background-color: ${(props) => props.theme.darkLightGray};
      border-radius: 4px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: ${(props) => props.theme.lightGrayText};
      border-radius: 4px;
    }
  }

  @media only screen and (min-width: ${(props) => props.theme.desktop}) {
    &.plain-text-wrap {
      height: 70vh;
    }
  }
`;
const StyledLink = styled.a`
  background-color: ${(props) => props.theme.darkBlue2};
  color: ${(props) => props.theme.white} !important;
  padding: 13px 30px;
  position: relative;
  overflow: hidden;
  &:before {
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.9);
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 0;
    height: 0;
  }

  &:active:before {
    transition: all 0.2s ease-out;
    opacity: 0;
    width: 160px;
    height: 160px;
    margin-top: -80px;
    margin-left: -80px;
  }
`;
const StyledSubunitInfo = styled.div`
  display: flex;
  width: 100%;
  margin-top: 30px;

  @media (min-width: ${(props) => props.theme.desktop}) and (max-width: ${(props) => props.theme.large_desktop}) {
    width: 70%;
    background-color: ${(props) => props.theme.darkGray};
    padding: 30px 60px;
    animation: borderRadius 6s forwards;

    @keyframes borderRadius {
      0% {
        border-radius: 10px 0px;
      }
      50% {
        border-radius: 80px 0px;
      }
      80% {
        border-radius: 150px 0px;
      }
      100% {
        border-radius: 200px 0px;
      }
    }
  }

  @media only screen and (min-width: ${(props) => props.theme.large_desktop}) {
    width: 40%;
    background-color: ${(props) => props.theme.darkGray};
    padding: 30px 60px;
    animation: borderRadius 6s forwards;

    @keyframes borderRadius {
      0% {
        border-radius: 10px 0px;
      }
      50% {
        border-radius: 80px 0px;
      }
      80% {
        border-radius: 150px 0px;
      }
      100% {
        border-radius: 200px 0px;
      }
    }
  }
`;
const StyledControls = styled.div`
  margin: 30px 0;
`;
const StyledResourceTitle = styled.p`
  font-size: 22px;

  .plain-text-title {
    text-align: left;
  }
`;
const StyledButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 24px;
  outline: none;
  border: 2px solid #65676c;
  border-radius: 100px;
  width: 100%;
  color: #65676c;
  background-color: transparent;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  margin-bottom: 15px;
  position: relative;
  overflow: hidden;
  cursor: pointer;
`;

const StyledPlainText = styled.div`
  color: ${(props) => props.theme.lightLightGray} !important;
  width: 100%;
  height: 100%;
  text-align: left;

  ul, ol {
  margin-bottom: 16px;
  padding-left: 40px;
  }

  ul li {
    list-style-type: disc;
    margin-bottom: 5px;
  }
  ol li {
    list-style-type: decimal;
    margin-bottom: 5px;

  }

  strong {
    /* font-weight: bold; */
  }

  figure img {
    width: 100%;
    height: 100%;
  }
`;

const MaterialDisplay = ({
  currentSubunit,
  activeMaterial,
  setActiveMaterial,
}) => {
  const isDesktop = useMediaCustom("(min-width: 992px)");
  const [isDownloading, setIsDownloading] = useState(false);
  const [allMaterialsFromResources, setAllMaterialsFromResources] = useState(
    []
  );
  const [activeMaterialIndex, setActiveMaterialIndex] = useState();

  useEffect(() => {
    if (activeMaterialIndex === -1) {
      setActiveMaterial(activeMaterial);
    }
    return;
  }, [activeMaterial]);

  // take all materials (resourceData) from subunit resources and merge them ( with flat() method ) into one array
  // we need this new array in order to have all materials in one place,
  // so that we can take next or previous element depending on the position of activeMaterial (currently displayed material) in this array (allMaterialsFromResources)
  useEffect(() => {
    const [...collectedArrays] = currentSubunit.resources?.map(
      (resource) => resource?.resourceData
    );
    setAllMaterialsFromResources(collectedArrays.flat());
  }, [currentSubunit?.resources]);

  useEffect(() => {
    setActiveMaterialIndex(allMaterialsFromResources.indexOf(activeMaterial));
  }, [allMaterialsFromResources, activeMaterial]);

  const nextResource = () => {
    setActiveMaterial(() => {
      if (activeMaterialIndex === allMaterialsFromResources.length - 1) {
        return allMaterialsFromResources[0];
      } else {
        return allMaterialsFromResources[activeMaterialIndex + 1];
      }
    });
  };
  const prevResource = () => {
    setActiveMaterial(() => {
      if (activeMaterialIndex === 0) {
        return allMaterialsFromResources[0];
      } else {
        return allMaterialsFromResources[activeMaterialIndex - 1];
      }
    });
  };

  // download material
  const onDownloadMaterial = () => {
    setIsDownloading(true);
    return axios
      .get(
        makeApiRoute("materialsDownload", {
          RESOURCE_ID: activeMaterial?.id,
          RESOURCE_SERVICE: activeMaterial?.service,
        }),
        { responseType: "blob" }
      )
      .then(function (response) {
        if (response.status === 200) {
          const url = window.URL.createObjectURL(new Blob([response?.data]));
          let fileName = response.headers["file-name"];
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();
          link.remove();

          setIsDownloading(false);
        } else {
          setIsDownloading(false);
          throw new Error("Bad Request");
        }
      })
      .catch(function (error) {
        setIsDownloading(false);
        throw error.response;
      });
  };

  return (
    <StyledContainer>
      <StyledHeadingContainer>
        <StyledSubunitInfo>
          <IconText
            icon="/images/resourcesGray.svg"
            description={currentSubunit?.resources?.length + " Resources"}
          />
          <IconText
            icon="/images/timeIcon.svg"
            description={
              currentSubunit?.duration < 60
                ? currentSubunit?.duration + " minutes"
                : Math.floor(currentSubunit?.duration / 60) +
                  " Hours " +
                  (currentSubunit?.duration % 60) +
                  " Minutes"
            }
          />
        </StyledSubunitInfo>
      </StyledHeadingContainer>

      {/* --------------------------------------------------------------------- */}
      {/* --------------------------------------------------------------------- */}

      {activeMaterial && activeMaterial?.type === "video" ? (
        <Video activeMaterial={activeMaterial} />
      ) : (
        ""
      )}
      {activeMaterial && activeMaterial?.type === "video-url" ? (
        <StyledInnerWrap>
          <StyledResourceTitle>{activeMaterial.title}</StyledResourceTitle>
          <p>
            By clicking the link button, you will be redirected to another page
            where you can find materials related to this lecture.
          </p>
          <StyledLink href={activeMaterial?.data} target="_blank">
            Video Url
          </StyledLink>
        </StyledInnerWrap>
      ) : (
        ""
      )}

      {activeMaterial && activeMaterial?.type === "text-url" ? (
        <StyledInnerWrap>
          <StyledResourceTitle>{activeMaterial.title}</StyledResourceTitle>
          <p>
            By clicking the link button, you will be redirected to another page
            where you can find materials related to this lecture.
          </p>
          <StyledLink href={activeMaterial?.data} target="_blank">
            Text Url
          </StyledLink>
        </StyledInnerWrap>
      ) : (
        ""
      )}
      {activeMaterial && activeMaterial?.type === "material" ? (
        <StyledInnerWrap className="innerWraoDownloads">
          <StyledResourceTitle>{activeMaterial.title}</StyledResourceTitle>
          <p>
            By clicking the download button, the materials provided with this
            lecture will be downloaded to your computer.
          </p>

          <Button
            variant="blueBtn"
            text={
              !isDownloading ? (
                <>
                  <AiOutlineCloudDownload /> <span>Download Material</span>
                </>
              ) : (
                <LoaderDots
                  isLoading={isDownloading}
                  dotsBgColor={theme.white}
                  dotHeight="5px"
                  dotWidth="5px"
                  dotMargin="10px"
                  buttonSubmit
                />
              )
            }
            type="button"
            onClick={() => onDownloadMaterial()}
          />
        </StyledInnerWrap>
      ) : (
        ""
      )}

      {activeMaterial && activeMaterial?.type === "text" ? (
        <StyledInnerWrap className="plain-text-wrap">
          <StyledResourceTitle className="plain-text-title">
            {activeMaterial.title}
          </StyledResourceTitle>
          <StyledPlainText
            className="plain-text"
            dangerouslySetInnerHTML={{ __html: activeMaterial?.data }}
          />
        </StyledInnerWrap>
      ) : (
        ""
      )}

      {!isDesktop ? (
        <StyledControls>
          <StyledButton onClick={nextResource}>Next Material</StyledButton>
          <StyledButton onClick={prevResource}>Previous Material</StyledButton>
        </StyledControls>
      ) : (
        ""
      )}
    </StyledContainer>
  );
};
export default MaterialDisplay;
