import React, { useState } from "react";
import { BsInfoCircleFill } from "react-icons/bs";
import styled from "styled-components";

import useMediaCustom from "../../Hooks/useMediaCustom";

// styled-components
const StyledTableBodyRow = styled.div`
  background-color: white;
  margin-top: 8px;
  margin-bottom: 8px;
  border-radius: 8px;
  box-shadow: 0px 8px 30px rgba(0, 0, 0, 0.08);
  display: inline-flex;
  width: 100%;
  cursor: ${props => props.pointer ? "pointer" : "not-allowed"};
  outline: ${(props) => (props.hover ? "1px solid #005EFF" : "none")};

  &:hover div.points {
    color: #191919;
  }
`;
const StyledName = styled.div`
  padding: 20px 15px;
  color: ${(props) => (props.hover ? "#005EFF" : "#232426")};
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;

  @media only screen and (min-width: ${(props) => props.theme.desktop}) {
    width: calc(30% - 30px);
    border-right: 2px solid #f9f9f9;
    padding-left: 24px;
  }
`;
const StyledPoints = styled.div`

  font-style: normal;
  font-size: 14px;
  line-height: 18px;
  font-weight: 700;
  color: #65676c;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-right: 10px;
  display: flex;
  margin-left: auto;
  position: relative;

  span {
    width: 100%;
  }
  span.extra-points {
    display: flex;
    justify-content: center;

    .extra-points_total {
    width: 100%;

    }

    p {
      display: inline-block;
 
    }
  }
  span.total-points {
    color: #191919;
  }

  @media only screen and (min-width: ${(props) => props.theme.desktop}) {
    border-right: 2px solid #f9f9f9;
    justify-content: center;
    padding-right: 0;
    width: 100%;
    font-size: 16px;

    span {
      display: inline-block;
      width: calc(100% / ${(props) => props.length});
      text-align: center;
    }
  }
`;

const StyledDots = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0 15px;
  border-left: 2px solid #f9f9f9;

  span {
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background-color: ${(props) => (props.hover ? "#005EFF" : "#C5C5C5")};
    margin-top: 2px;
    display: inline-block;
  }

  @media only screen and (min-width: ${(props) => props.theme.desktop}) {
    padding: 0 30px;
  }
`;

const StyledInfoIcon = styled.p`



`;

const StyledExtraPointsDetailes = styled.ul`
  position: absolute;
  top: 45px;
  right: -40px;
  padding: 15px;
  box-shadow: 0px 8px 30px rgba(0, 0, 0, 0.08);
  background-color: white;
  border-radius: 8px;
  font-size: 14px;
  line-height: 18px;
  z-index: 11111;
`;

const TableBodyRow = ({
  studentPoints,
  name,
  onClick,
  pointer,
  challenges_acquired_extra_points,
  projects_acquired_extra_points,
  hackathons_acquired_extra_points,
}) => {
  const isDesktop = useMediaCustom("(min-width: 992px)");
  const [hover, setHover] = useState(false);
  const [hoveredExtraDetails, setHoveredExtraDetails] = useState(false);

  const columnNumber = studentPoints?.filter((el) => el !== false);

  // studentPoints array will return 5 as length, if you need to change width of the spans, always check studentPoints.length first!

  const length = columnNumber.length;

  const onMouseEnter = () => {
    setHover(true);
  };
  const onMouseLeave = () => {
    setHover(false);
  };

  return (
    <StyledTableBodyRow
      isDesktop={isDesktop}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      hover={hover}
      onClick={onClick}
      pointer={pointer}
    >
      <StyledName hover={hover}>
        <span>{name}</span>
      </StyledName>

      <StyledPoints length={length} hover={hover} className="points">
        {!isDesktop && (
          <>
            {studentPoints?.map((student, index) => {
              if (student) {
                return <span key={index}>{student?.points}</span>;
              }
            })}
          </>
        )}

        {isDesktop && (
          <>
            {studentPoints[0]?.total_points && (
              <span className="total-points">
                {studentPoints[0]?.total_points}
              </span>
            )}
            {studentPoints[1]?.challenges_points && (
              <span>{studentPoints[1]?.challenges_points}</span>
            )}
            {studentPoints[2]?.projects_points && (
              <span>{studentPoints[2]?.projects_points}</span>
            )}
            {studentPoints[3]?.hackathons_points && (
              <span>{studentPoints[3]?.hackathons_points}</span>
            )}
            {studentPoints[4]?.extra_points && (
              <span
                className="extra-points"
              >
                <span className="extra-points_total"> {studentPoints[4]?.extra_points} {studentPoints[4]?.extra_points > 0 && (
                  <StyledInfoIcon onMouseEnter={() => {
                    if (studentPoints[4]?.extra_points > 0) {
                      setHoveredExtraDetails(true);
                    }
                  }}
                  onMouseLeave={() => setHoveredExtraDetails(false)}>
                    <BsInfoCircleFill />
                  </StyledInfoIcon>
                )}</span>
               
              </span>
            )}

            {hoveredExtraDetails && (
              <StyledExtraPointsDetailes className="extra-points-details">
                {challenges_acquired_extra_points > 0 ? (
                  <li>Challenges: {challenges_acquired_extra_points}</li>
                ) : (
                  ""
                )}
                {projects_acquired_extra_points > 0 ? (
                  <li>Projects: {projects_acquired_extra_points}</li>
                ) : (
                  ""
                )}
                {hackathons_acquired_extra_points > 0 ? (
                  <li>Hackathons: {hackathons_acquired_extra_points}</li>
                ) : (
                  ""
                )}
              </StyledExtraPointsDetailes>
            )}
          </>
        )}
      </StyledPoints>

      <StyledDots hover={hover}>
        <span></span>
        <span></span>
        <span></span>
      </StyledDots>
    </StyledTableBodyRow>
  );
};
export default TableBodyRow;
