import React, { useContext, useEffect, useRef } from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";

import { GlobalContext } from "../../Context/GlobalProvider";
import { makeAppRoute, makeExternalLink } from "../../Router";
import ProfileImage from "../ProfileImage/ProfileImage";
import Icon from "./Icon";
import Button from "../Button/Button";

const StyledDropDownMenu = styled.ul`
  background-color: ${(props) => props.theme.white};
  width: 300px;
  position: absolute;
  top: 80px;
  right: 3rem;
  display: flex;
  flex-flow: column nowrap;
  padding: 30px;
  box-shadow: 0px 8px 30px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
  animation: dropdown 300ms ease-in-out;
  transform-origin: center top;

  .join-hiring-group {
    margin-left: 0;
    margin-top: 10px;
  }
  .join-hiring-group button {
    width: 100%;
  }

  li {
    margin: 10px 0;
    cursor: pointer;
    :active,
    :focus,
    :hover {
      color: ${(props) => props.theme.darkBlue};
    }
  }

  li a,
  li div.logout {
    display: flex;
  }
  div.logout {
    margin-left: 0px;
    align-self: center;
    color: ${(props) => props.theme.darkGray};
    line-height: 21px;
    font-size: ${(props) => props.theme.large};
    font-weight: 700;
    display: inline-block;

    span {
      margin-left: 10px;
      :active,
      :focus,
      :hover {
        color: ${(props) => props.theme.darkBlue};
      }
    }
  }

  li a span {
    margin-left: 10px;
    align-self: center;
    color: ${(props) => props.theme.darkGray};
    line-height: 21px;
    font-size: ${(props) => props.theme.large};
    font-weight: 700;
    display: inline-block;

    :active,
    :focus,
    :hover {
      color: ${(props) => props.theme.darkBlue};
    }
  }

  @keyframes dropdown {
    0% {
      transform: scaleY(0);
    }
    100% {
      transform: scaleY(1);
    }
  }
`;

const DropDownMenu = ({
  toggleProfile,
  setToggleProfile,
  handleShowLogoutPopup,
}) => {
  const { user } = useContext(GlobalContext);
  const reference = useRef(null);
  useOutsideAlerter(reference);

  function useOutsideAlerter(ref) {
    useEffect(() => {
      /**
       * Close dropdown menu if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setToggleProfile(false);
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  return (
    <StyledDropDownMenu toggleProfile={toggleProfile} ref={reference}>
      <li>
        <NavLink to={makeAppRoute("profile")}>
          <ProfileImage
            className="profile-img"
            width="30px"
            height="30px"
            user={user}
            url={
              user && user.profile
                ? process.env.REACT_APP_FILES_URL + "/" + user.profile
                : ""
            }
          />
          <span>Profile</span>
        </NavLink>
      </li>
      {user && user.rocketChatUrl && (
        <li>
          <a href={user?.rocketChatUrl} target="_blank" rel="noreferrer">
            <Icon icon="rocketChat" />
            <span>Rocket Chat</span>
          </a>
        </li>
      )}

      <li>
        <NavLink to={makeAppRoute("points", { USER_ID: user.id })}>
          <Icon icon="leaderboard" />
          <span>Student Dashboard</span>
        </NavLink>
      </li>
      <li>
        <div className="logout" onClick={handleShowLogoutPopup}>
          <Icon icon="logout" />
          <span>Logout</span>
        </div>
      </li>
      <li>
        <a
          className="join-hiring-group"
          href={makeExternalLink("facebookHiringGroup")}
          target="_blank"
          rel="noreferrer"
        >
          <Button type="button " variant="blueBtn" text="Join hiring group" />
        </a>
      </li>
    </StyledDropDownMenu>
  );
};
export default DropDownMenu;
