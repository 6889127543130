import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";

import { axios, makeApiRoute } from "../../Router";
import { GlobalContext } from "../../Context/GlobalProvider";
import theme from "../../Consts/theme";
import ModulesScrollSpy from "../ModulesFilter/ModulesScrollSpy";
import UnitsContainer from "./UnitsContainer";
import LoaderDots from "../Loader/LoaderDots";
import CategoryCompleted from "./CategoryCompleted";
import ScrollToTopPage from "../ScrollSpy/ScrollToToPage";

// styled-components
const StyledPageEntrance = styled.div``;
const StyledMaterialsPageContainer = styled.div`
  background-color: #f9f9f9;
  padding-bottom: 100px;

  @media only screen and (min-width: ${(props) => props.theme.desktop}) {
    display: grid;
    grid-template-columns: 25% 70%;
    grid-column-gap: 5%;
    padding-top: 60px;
    width: 92%;
    margin: 0 auto;
  }
`;

const CategoryPageContainer = ({ slug }) => {
  const { popupConfig, setPopupConfig } =
    useContext(GlobalContext);
  // states
  const [materialsData, setMaterialsData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const activeCategory = materialsData?.categories?.categories?.find(
    (category) => category?.slug === slug
  );
  const updateCategoryData = (_category) => {
    let categoryIndex = materialsData?.categories?.categories?.findIndex(
      (category) => category?.id === _category.id
    );

    materialsData.categories.categories[categoryIndex].totalCompletedResources =
      _category.totalCompletedResources;
    setMaterialsData({ ...materialsData });

    if (_category.totalCompletedResources === _category.totalResources) {
      setPopupConfig({
        ...popupConfig,
        show: true,
        title: "Congratulations !",
        subTitle: "",
        maxWidth: "500px",
        bodyClassName: "action-buttons",
        body: (
          <CategoryCompleted
            url={
              process.env.REACT_APP_FILES_URL +
              "/" +
              activeCategory?.completedAchievmentIcon
            }
            content={
              "Congratulations for " + activeCategory?.achievmentDescription
            }
          />
        ),
      });
    }
  };

  useEffect(() => {
    setIsLoading(true);
    axios
      .get(makeApiRoute("materials"))
      .then((response) => {
        setMaterialsData(response.data.data);
        setIsLoading(false);
      })
      .catch(function (error) {
        throw error.response;
      });
  }, []);

  if (isLoading) {
    return (
      <LoaderDots
        isLoading={isLoading}
        bgColor="#f7f7f7"
        dotsBgColor={theme.lightBlue}
        height="100vh"
        dotMargin="25px"
        dotHeight="10px"
        dotWidth="10px"
      />
    );
  }
  return (
    <>
      <StyledPageEntrance>
        <StyledMaterialsPageContainer>
          <ModulesScrollSpy slug={slug} materialsData={materialsData} />
          <UnitsContainer
            slug={slug}
            materialsData={materialsData}
            isLoading={isLoading}
            updateCategoryData={updateCategoryData}
          />
          <ScrollToTopPage />
        </StyledMaterialsPageContainer>
      </StyledPageEntrance>
    </>
  );
};
export default CategoryPageContainer;
