import React from "react";
import styled from "styled-components";
import theme from "../../Consts/theme";

const StyledButton = styled.button`
  width: 100%;
  color: ${(props) => props.theme.lightGrayText};
  background-color: ${(props) => props.theme.white};
  border: none;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  transition: color .3s ease-in-out;

  .align-self {
    align-self: center;
    :not(:last-child) {
      margin-right: 16px;
    }
  }
  .download {
    width: 20px;
    height: 20px;
    margin-right: 6px;
  }
  :hover,
  :focus {
    color: ${theme.black};
  }

  :active {
    background-color: #f0f0f1;
    border-radius: 4px;
    box-shadow: rgba(0, 0, 0, 0.06) 0 2px 4px;
    color: rgba(0, 0, 0, 0.65);

  }
  @media only screen and (min-width: ${(props) => props.theme.laptop}) {
    width: auto;
  }
`;

const FileButton = ({ onClick, title, icon, uploaded }) => {
  return (
    <StyledButton onClick={onClick} uploaded={uploaded}>
      {icon}
      <span className="align-self">{title}</span>
    </StyledButton>
  );
};
export default FileButton;
