import React from "react";
import { FaRegCalendarCheck } from "react-icons/fa";
import styled from "styled-components";

import { Text } from "../../../Consts/Text";
import { dateFormater } from "../../../Consts/PureFunctions";
import theme from "../../../Consts/theme";
import StatusLabel from "../../StatusLabel/StatusLabel";

// styled-components
const StyledContainer = styled.article`
  border: 1px solid
    ${(props) => (props.borderColor)};
  border-radius: 8px;
  display: flex;
  align-items: center;
  padding: 8px 0;

  background-color: ${theme.white};
  margin-bottom: 20px;

  div.installment-number {
    display: none;
  }

  @media only screen and (min-width: ${(props) => props.theme.desktop}) {
    padding: 0;
    div.installment-number {
      border-right: 3px solid
        ${(props) => (props.borderColor)};
      display: inline-block;
      text-align: center;
      width: 90px;
      padding: 18px 8px;
    }
  }
`;

const StyledInnerCarsWrap = styled.div`
  margin-left: 14px;
  margin-right: 14px;
  width: 100%;

  & > div {
    margin-bottom: 8px;
  }

  div.payment-status-box {
    width: 35%;
  }

  p {
    margin-bottom: 4px;
  }

  p.installment-date {
    display: flex;
    align-items: center;
  }
  p.installment-date span {
    align-self: center;
    margin-left: 5px;
  }
  div.payment-status-box,
  div.amount {
    align-self: center;
  }

  @media only screen and (min-width: 375px) {
    display: grid;
    grid-template-columns: 3fr 2fr 2fr;
    grid-gap: 20px;
    p {
      margin-bottom: 0;
    }

    div.amount {
      text-align: right;
      justify-self: end;
    }

    div.payment-status-box {
      width: 100%;
    }
  }
`;

const InstallmentCard = ({ data, userOrder, cardIndex }) => {
  const status = data.status;
  const formattedDate = dateFormater(data.recurring_time, "/");
  const installmentAmount = +userOrder?.installment_amount;

  const borderColor = () => {
    if (status === "fail") {
      return theme.red;
    } else if (status === "cancelled") {
      return theme.textGrey2;
    }

    return theme.concreteGray;
  };

  return (
    <StyledContainer status={status} borderColor={borderColor()}>
      <div className="installment-number">
        <Text color={theme.lightGrayText} fontSize="14px">
          {" "}
          <span>{cardIndex}</span> of <span>{userOrder.total_installments}</span>
        </Text>
      </div>
      <StyledInnerCarsWrap>
        <div>
          <Text fontSize="14px" fontWeight={700}>
            Installment #{cardIndex}
          </Text>
          <Text
            className="installment-date"
            color={theme.textGrey2}
            fontSize="13px"
            fontWeight={500}
          >
            <FaRegCalendarCheck />
            <span> {formattedDate}</span>
          </Text>
        </div>
        <div className="payment-status-box">
          <StatusLabel status={data.status} />
        </div>
        <div className="amount">
          <Text fontWeight={500}>
            {installmentAmount?.toLocaleString()} MKD
          </Text>
        </div>
      </StyledInnerCarsWrap>
    </StyledContainer>
  );
};

export default InstallmentCard;
