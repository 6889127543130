import React, { useState, useEffect } from "react";
import styled from "styled-components";

import {
  ErrorMessage,
  StyledSurveyQuestion,
} from "../CommonStyledComponents/CommonStyledComponents";
import RatingStar from "../RatingStar/RatingStar";
import Textarea from "../Textarea/Textarea";
import SurveyInstructorSelect from "./SurveyInstructorSelect";

//styled-components
const StyledSurveyQuestionWrap = styled.article`
  width: 100%;
  margin-top: 15px;
  font-style: normal;

  @media only screen and (min-width: ${(props) => props.theme.laptop}) {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
  }
`;

const StyledDescription = styled.small`
  font-size: 14px;
  line-height: 20px;
  color: ${(props) => props.theme.darkGray};
  text-align: left;
  display: inline-block;
  margin-bottom: 5px;
`;
const StyledRatingStarWrap = styled.div`
  display: flex;
  margin-top: 10px;

  @media only screen and (min-width: ${(props) => props.theme.laptop}) {
    margin-top: 20px;
  }
`;
const StyledCommentWrap = styled.div`
  margin-top: 15px;
  margin-bottom: 40px;
  label {
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    color: ${(props) => props.theme.darkGray};
    text-align: left;
  }
  textarea {
    margin-top: 10px;
    margin-bottom: 5px;
  }

  @media only screen and (min-width: ${(props) => props.theme.laptop}) {
    margin-top: 20px;
  }
`;

const SurveyQuestion = ({
  questionId,
  question,
  description,
  stars,
  showSelectOptions,
  instructorsArray,
  answers,
  setAnswers,
}) => {
  const [hoverdStarIndex, setHoverdStarIndex] = useState(undefined);
  const [currentAnswer, setCurrentAnswer] = useState({})

  useEffect( () => {
    const obj = answers.find((elem) => elem.questionId === questionId);
    setCurrentAnswer(obj)

  }, [answers, questionId])
  
  const handleHoverRatingStar = (index) => {
    setHoverdStarIndex(index);
  };

  // Check if the answer object exists already in the array. If not, add it, if yes, update it.
  const handleAnswerChanged = (questionId, type, value) => {
    let answerObject = answers.findIndex((elem) => elem.questionId === questionId);
 
      if(answerObject === -1) {
        return setAnswers([...answers, {'questionId': questionId, [type]: value }])
      } else {
        answers[answerObject][type] = value;
       return setAnswers([...answers])
      } 
  }



  return (
    <StyledSurveyQuestionWrap>
      {!showSelectOptions ? (
        <StyledSurveyQuestion>{question}</StyledSurveyQuestion>
      ) : (
        <SurveyInstructorSelect
          question={question}
          instructorsArray={instructorsArray}
          getInputValue={(value) => {
            handleAnswerChanged(questionId, 'instructorId', value)
          }}
        />
      )}

      <StyledDescription>{description}</StyledDescription>
      <StyledRatingStarWrap>
        {Array(stars)
          ?.fill()
          ?.map((star, index) => {
            index += 1;
            return (
              <RatingStar
                key={index}
                star={star}
                index={index}
                stars={stars}
                colored={
                  index <= (hoverdStarIndex || currentAnswer?.rating)
                }
                handleHover={handleHoverRatingStar}
                getInputValue={() => {
                   handleAnswerChanged(questionId, 'rating', index)
                }}
              />
            );
          })}
          
      </StyledRatingStarWrap>
      <StyledCommentWrap>
        <label htmlFor="">Leave a comment</label>
        <Textarea
          rows="6"
          getInputValue={(value) => {
            handleAnswerChanged(questionId, 'comment', value)
          }}
        />
        {/* -------------------------------- SHOW ERROR MESSAGES ON INPUT CHANGE --------------------------------------- */}
        {/* ------------------------------------------------------------------------------------------------------------ */}

        {/* if the user rated <= 3 and didn't leave a comment */}
        {currentAnswer?.rating <= 3 && (currentAnswer?.comment === "" || !Object.hasOwn(currentAnswer, "comment")) ?
         <ErrorMessage>
         Comment is required for rating below or equal 3.
       </ErrorMessage> : ""
        }
        {/* if the question has select options and the user has rated without selecting an instructor OR left a comment without selecting an instructor */}
        {showSelectOptions && currentAnswer && 
        ((!Object.hasOwn(currentAnswer, "instructorId") &&
          Object.hasOwn(currentAnswer, "rating")) ||
          (!Object.hasOwn(currentAnswer, "instructorId") &&
            Object.hasOwn(currentAnswer, "comment"))) ? (
          <ErrorMessage>Please select an instructor.</ErrorMessage>
        ) : (
          ""
        )}
      </StyledCommentWrap>
    </StyledSurveyQuestionWrap>
  );
};
export default SurveyQuestion;
