import React from "react";
import styled from "styled-components";

// styled-components
const StyledIconTextWrap = styled.div`
  display: flex;
  margin-bottom: 16px;
  width: 100%;
  &.margin-right {
    margin-right: 15px;
  }

  img {
    align-self: center;
  }

  @media only screen and (min-width: ${(props) => props.theme.desktop}) {
    margin-bottom: 0;
    justify-content: flex-end;
    &.time-icon {
      padding-right: 10px;
      p {
        font-size: 8px;
      }
    }
    &.margin-rigth {
      margin-right: 30px;
    }
  }
`;
const StyledText = styled.p`

  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  text-transform: uppercase;
  color: ${(props) => props.theme.lightGrayText};
  align-self: center;
  margin-left: 11px;
  width: 100%;
  @media only screen and (min-width: ${(props) => props.theme.desktop}) {
    width: auto;
  }
`;
const IconText = ({ icon, description, className }) => {
  return (
    <StyledIconTextWrap className={className}>
      {icon ? <img src={icon} alt="Icon"/> : ""}
      <StyledText>{description}</StyledText>
    </StyledIconTextWrap>
  );
};
export default IconText;
