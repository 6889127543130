import React from "react";
import styled from "styled-components";

import { Text } from "../../../../Consts/Text";
import theme from "../../../../Consts/theme";
import AcademyContract from "./AcademyContract";
import useShowMoreFiles from "../../../../Hooks/useShowMoreFiles";
import ShowMoreLessButton from "../../../Button/ShowMoreLessButton";
import FileSectionContainer from "./FileSectionContainer";

// styled-components
const StyledInfoMessage = styled.div`
  margin: 0 0 16px 16px;
  padding-left: 8px;
`;


const INITIALLY_DISPLAYED_ACHIEVEMENTS = 4;
const AcademyContractContainer = ({ userFilesData }) => {
  const onlineContracts = userFilesData?.user?.contracts;

  const {
    numberOfInitialFilesDisplayed,
    totalNumberOfAchievements,
    onShowMoreAchievements,
  } = useShowMoreFiles(INITIALLY_DISPLAYED_ACHIEVEMENTS, onlineContracts);

  return (
    <FileSectionContainer sectionTitle="Contract">
      {onlineContracts.length > 0 ? (
        onlineContracts
          ?.slice(0, numberOfInitialFilesDisplayed)
          .map((contract, index) => (
            <AcademyContract key={index} contract={contract} />
          ))
      ) : (
        <StyledInfoMessage>
          <Text fontSize="14px" fontWeight="600" color={theme.textGrey2}>
            You currently do not have any signed online contracts.
          </Text>
        </StyledInfoMessage>
      )}

      {/* ------------- Show More / Less Button */}
      <ShowMoreLessButton
        totalNumberOfAchievements={totalNumberOfAchievements}
        numberOfInitialFilesDisplayed={numberOfInitialFilesDisplayed}
        initialNumOfFiles={INITIALLY_DISPLAYED_ACHIEVEMENTS}
        onShowMoreAchievements={onShowMoreAchievements}
      />
    </FileSectionContainer>
  );
};

export default AcademyContractContainer;
