import React, { useContext, useState } from "react";
import styled from "styled-components";

import theme from "../../Consts/theme";
import { GlobalContext } from "../../Context/GlobalProvider";
import { axios, makeApiRoute } from "../../Router";
import Button from "../Button/Button";
import LoaderDots from "../Loader/LoaderDots";

// styled-components
const StyledButtonGroup = styled.div`
  margin-top: 70px;

  button {
    border-radius: 25px;
    width: 100%;
    margin-bottom: 16px;
    padding: 16px 24px;
  }
  @media only screen and (min-width: ${(props) => props.theme.laptop}) {
  }
`;

const Logout = () => {
  const { popupConfig, setPopupConfig, setPopupDefaultValues, setUser} =
    useContext(GlobalContext);
  const [isLoading, setIsLoading] = useState(false);

  // close pop up
  const handleCancelButtonClicked = () => {
    setPopupDefaultValues();
  };
  // logout the user
  const handleLogoutBtnClicked = (e) => {
    e.preventDefault();
    setIsLoading(true);
    axios
      .get(makeApiRoute("logout"))
      .then(function (response) {
        if (response.status === 200) {
          localStorage.removeItem("userAT");
          setIsLoading(false);
          // set user is set to initial in order to trigger Context and therefore to update 'isAuth'
          setUser({})
          setPopupDefaultValues();
        } else {
          setPopupConfig({
            ...popupConfig,
            show: true,
            subTitle: "",
            confirmation: false,
            maxWidth: "500px",
            title: "Something Unexpected Happened. Please try again later!",
            bodyClassname: "action-buttons",
            body: (
              <Button
                text="Close"
                variant="blueBtn"
                onClick={() => setPopupDefaultValues()}
              />
            ),
            closeButton: {
              show: true,
              action: () => {
                setPopupDefaultValues();
              },
            },
          });
          throw new Error("Bad Request");
        }
      })
      .catch(function (error) {
        setIsLoading(false);
        setPopupConfig({
          ...popupConfig,
          show: true,
          subTitle: "",
          confirmation: false,
          maxWidth: "500px",
          title: "Something Unexpected Happened. Please try again later!",
          bodyClassname: "action-buttons",
          body: (
            <Button
              text="Close"
              variant="blueBtn"
              onClick={() => setPopupDefaultValues()}
            />
          ),
          closeButton: {
            show: true,
            action: () => {
              setPopupDefaultValues();
            },
          },
        });
        throw error.response;
      });
  };
  return (
    <StyledButtonGroup>
      <Button
        variant="grayBtn"
        onClick={handleLogoutBtnClicked}
        disabled={isLoading}
        text={
          !isLoading ? (
            "Logout"
          ) : (
            <LoaderDots
              isLoading={isLoading}
              dotsBgColor={theme.lightBlue}
              dotHeight="4px"
              dotWidth="4px"
              dotMargin="5px"
              buttonSubmit
            />
          )
        }
      />
      <Button
        variant="blueBtn"
        text="Cancel"
        onClick={handleCancelButtonClicked}
        disabled={isLoading}
      />
    </StyledButtonGroup>
  );
};
export default Logout;
