import React, { useContext, useEffect, useRef, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";

import { makeAppRoute } from "../../Router";
import { GlobalContext } from "../../Context/GlobalProvider";
import { Text } from "../../Consts/Text";
import { StyledForm } from "../CommonStyledComponents/CommonStyledComponents";
import { GET_SKILLS_DATA, UpdateUserSkills } from "../../Consts/GraphqlQueries";
import MobileHeader from "./MobileHeader/MobileHeader";
import theme from "../../Consts/theme";
import LoaderDots from "../Loader/LoaderDots";
import textPages from "../../Consts/textPages.json";
import FormSelectGroup from "../FormGroup/FormSelectGroup";
import SubmitButton from "../Button/SubmitButton";
import useMediaCustom from "../../Hooks/useMediaCustom";
import CustomCheckbox from "../Checkbox/CustomCheckbox";
import ErrorMessageComponent from "../ErrorMessageComponent/ErrorMessageComponent";

// Styled-components
const StyledContainer = styled.section`
  gap: 16px;
  border: 1px solid ${theme.concreteGray};
  border-radius: 16px;
  background-color: ${theme.white};
`;
const StyledFormHeader = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 16px;
  padding: 16px 0;
  border-bottom: 1px solid ${theme.concreteGray};

  @media only screen and (min-width: ${(props) => props.theme.desktop}) {
    margin-left: 24px;
    margin-right: 24px;
  }
`;

const StyledInnerWrap = styled.section`
  width: 100%;

  @media only screen and (min-width: ${(props) => props.theme.desktop}) {
    display: flex;
    width: 90%;
  }
`;
const StyledInfoText = styled.p`
  font-style: normal;
  font-size: 10px;
  line-height: 14px;
  color: ${(props) => props.theme.lightGrayText};
  margin-bottom: 16px;
`;
const StyledLoaderBox = styled.div`
  height: 60vh;
  width: 100%;
  display: flex;
  justify-content: center;

  .loader {
    padding-top: 0px;
  }
`;

// ----------------------------------------------------------------
// DirtyFields will keep track of what input(s) is/are changed and therefore to enable/disable Save button
let dirtyFields = new Set();

// ----------------------------------------------------------------
// Error messages on input validation
const errorMessages = {
  english_level: "Please provide your english level",
  soft_skills: "Please provide your soft skills",
  tech_skills: "Please provide your tech skills",
};
// Reset error messages
const resetErrors = {
  english_level: "",
  soft_skills: "",
  tech_skills: "",
};

const Skills = ({ submitFunctionRef, onboarding, onboardingRoutes }) => {
  const userObjectRef = useRef();
  const { step } = useParams();
  const navigate = useNavigate();
  const isDesktop = useMediaCustom("(min-width: 992px)");
  const { user, setUser } = useContext(GlobalContext);
  // States
  const [errors, setErrors] = useState(resetErrors);
  const [softSkills, setSoftSkills] = useState([]);
  const [techSkills, setTechSkills] = useState([]);
  const [saveBtnDisabled, setSaveBtnDisabled] = useState(true);
  const [hasCompletedMutation, setHasCompletedMutation] = useState(false);
  const [selectedSkills, seSelectedSkills] = useState([]);

  //  ----------------------------------------------------------------
  // GRAPHQL GET REQUEST
  const {
    loading: queryLoading,
    error: queryError,
    data: queryData,
  } = useQuery(GET_SKILLS_DATA, {
    fetchPolicy: "cache-and-network",
  });
  //  ----------------------------------------------------------------
  // GRAPHQL POST REQUEST (MUTATION)
  const [
    updateUser,
    {
      data: mutationData,
      loading: mutationLoading,
      error: mutationError,
      called: mutationCalled,
    },
  ] = useMutation(UpdateUserSkills, {
    onCompleted: (data) => {
      handleUserUpdate(data.UpdateUser);

      if (!onboarding) {
        setSaveBtnDisabled(true);
        setHasCompletedMutation(true);
        handleCompletedMutation();
      } else {
        const onboardingRoutesNextStep =
          onboardingRoutes[onboardingRoutes.indexOf(step) + 1];
        navigate(
          makeAppRoute(`onboarding`, {
            STEP: onboardingRoutesNextStep,
          })
        );
      }
    },
  });

  // ----------------------------------------------------------------
  // VARIABLES

  const hasFetchedUser = queryData?.user !== undefined;
  let hasErrorValue = Object.values(errors).some((value) => value !== "");
  let softSkillsSelected = selectedSkills?.filter(
    (skill) => skill.type === "SOFT_SKILL"
  );
  let techSkillsSelected = selectedSkills?.filter(
    (skill) => skill.type === "TECH_SKILL"
  );

  useEffect(() => {
    if (mutationCalled) {
      handleUserUpdate(mutationData?.UpdateUser);
    } 
  }, [mutationCalled, , mutationData]);

  useEffect(() => {
    // pass submit function to the ref that is coming from parent where this function is called
    if (submitFunctionRef?.current) {
      submitFunctionRef.current = onFormSubmit;
    }
    if (!mutationCalled && hasFetchedUser) {
      userObjectRef.current = queryData?.user;
      const userSkills = [...queryData?.user?.skills];
      seSelectedSkills(userSkills);
    }
  }, [queryLoading, mutationLoading, hasFetchedUser]);

  useEffect(() => {
    const softSkillsArr = queryData?.skills?.filter(
      (skill) => skill.type === "SOFT_SKILL"
    );
    setSoftSkills(softSkillsArr);
    const techSkillsArr = queryData?.skills?.filter(
      (skill) => skill.type === "TECH_SKILL"
    );
    setTechSkills(techSkillsArr);
  }, [queryData, mutationData]);


  // ----------------------------------------------------------------
  // Update the user object in Global context after submission and on page load

  const handleUserUpdate = (updatedUserData) => {
    setUser((prevUser) => ({
      ...prevUser,
      ...updatedUserData,
      user_info: {
        ...prevUser?.user_info,
        ...updatedUserData?.user_info,
      },
    }));
  };

  // ----------------------------------------------------------------
  // Return hasCompletedMutation to false to remove the style for success message

  const handleCompletedMutation = () => {
    const timer = setTimeout(() => {
      setHasCompletedMutation(false);
    }, 4000);

    // Clear the timeout if the component unmounts
    return () => clearTimeout(timer);
  };

  //  ----------------------------------------------------------------
  // ON INPUT CHANGE

  const onInputChange = (_inputName, _value) => {
    if (_inputName === "english_level") {
      userObjectRef.current = {
        ...userObjectRef.current,
        user_info: {
          ...userObjectRef.current.user_info,
          [_inputName]: _value,
        },
      };
    }

    // ----------------------------------------------------------------
    // Show error messages if required field is empty

    if (_value === "") {
      setErrors({
        ...errors,
        [_inputName]: errorMessages[_inputName],
      });
    }
    if (hasErrorValue && _value !== "") {
      setErrors({ ...errors, [_inputName]: "" });
    }

    setSaveBtnDisabled(false);

  };

  const onCheckboxChange = (_value, _removeSelectedItem, _key, skillType) => {
    let selectedSoftSkills;
    let selectedTechSkills;
    setSaveBtnDisabled(false);
    if (_removeSelectedItem) {

      // removing from selectedItems array
      const updateArray = userObjectRef.current?.[_key]?.filter(
        (item) => item.id !== _value.id
      );

      userObjectRef.current = {
        ...userObjectRef.current,
        [_key]: updateArray,
      };
      seSelectedSkills(userObjectRef.current?.skills);

      const userSkills = userObjectRef.current?.skills;
      const userSkillsTrimmed = userSkills?.map(
        ({ __typename, ...skills }) => skills
      );
      selectedSoftSkills = userSkillsTrimmed?.filter(
        (skill) => skill.type === "SOFT_SKILL"
      );
      selectedTechSkills = userSkillsTrimmed?.filter(
        (skill) => skill.type === "TECH_SKILL"
      );
      if (hasErrorValue && selectedSoftSkills?.length !== 0) {
        setErrors({ ...errors, [skillType]: "" });
      }
      if (hasErrorValue && selectedTechSkills?.length !== 0) {
        setErrors({ ...errors, [skillType]: "" });
      }

      if (
        hasErrorValue &&
        (selectedSoftSkills?.length !== 0 || selectedTechSkills?.length !== 0)
      ) {
        setErrors({ ...errors, [skillType]: "" });
      }
    } else {

      // adding to selectedItems array
      userObjectRef.current = {
        ...userObjectRef.current,
        [_key]: [...userObjectRef?.current?.[_key], _value],
      };
      seSelectedSkills(userObjectRef.current?.skills);
      const userSkills = userObjectRef.current?.skills;
      const userSkillsTrimmed = userSkills?.map(
        ({ __typename, ...skills }) => skills
      );
      selectedSoftSkills = userSkillsTrimmed?.filter(
        (skill) => skill.type === "SOFT_SKILL"
      );
      selectedTechSkills = userSkillsTrimmed?.filter(
        (skill) => skill.type === "TECH_SKILL"
      );
      // ----------------------------------------------------------------
      // Show error messages if required field is empty
      if (hasErrorValue && selectedSoftSkills?.length !== 0) {
        setErrors({ ...errors, [skillType]: "" });
      }
      if (hasErrorValue && selectedTechSkills?.length !== 0) {
        setErrors({ ...errors, [skillType]: "" });
      }
    }
  };

  // ----------------------------------------------------------------
  // FORM SUBMIT

  const onFormSubmit = async (e) => {
    e?.preventDefault();

    const userSkillsTrimmed = userObjectRef.current?.skills?.map(
      ({ __typename, ...skills }) => skills
    );
    const selectedSoftSkills = userObjectRef.current?.skills?.filter(
      (skill) => skill.type === "SOFT_SKILL"
    );
    const selectedTechSkills = userObjectRef.current?.skills?.filter(
      (skill) => skill.type === "TECH_SKILL"
    );

    const formData = {
      user_info: {
        english_level: userObjectRef?.current?.user_info?.english_level,
      },
      skills: userSkillsTrimmed,
    };

    // ----------------------------------------------------------------
    // Validate empty fields

    if (
      !userObjectRef?.current?.user_info?.english_level ||
      selectedSoftSkills?.length === 0 ||
      selectedTechSkills?.length === 0
    ) {
      // Update errors state with error messages for empty input fields

      const errorFields = Object.keys(errorMessages).reduce(
        (acc, fieldName) => {
          if (
            fieldName === "english_level" &&
            !userObjectRef?.current?.user_info?.english_level
          ) {
            acc["english_level"] = [errorMessages.english_level];
          } else if (
            fieldName === "soft_skills" &&
            selectedSoftSkills?.length === 0
          ) {
            acc["soft_skills"] = [errorMessages.soft_skills];
          } else if (
            fieldName === "tech_skills" &&
            selectedTechSkills?.length === 0
          ) {
            acc["tech_skills"] = [errorMessages.tech_skills];
          }

          return acc;
        },
        {}
      );

      setErrors(errorFields);

      return;
    }

    // submit the form
    try {
      await updateUser({ variables: { inputData: formData } });
    } catch (err) {
      console.log(err);
    }
  };
  // -------------------------------------------------------------------------------
  // LOADING ERRORS
  if (queryError || mutationError)
    return (
      <ErrorMessageComponent
        firstText="Oops!"
        secondText="Something went wrong while fetching your data."
        thirdText="Please try again later."
      />
    );

  // -------------------------------------------------------------------------------
  // MAIN COMPONENT
  return (
    <div>
      {!onboarding ? (
        <MobileHeader
          disabled={saveBtnDisabled || mutationLoading}
          hasSubmitted={hasCompletedMutation}
          onClick={onFormSubmit}
          isLoading={mutationLoading}
        />
      ) : (
        ""
      )}

      <StyledContainer>
        <StyledFormHeader>
          <Text fontSize="14px">Skills</Text>
        </StyledFormHeader>

        <StyledInnerWrap onboarding={onboarding} ref={submitFunctionRef}>
          {queryLoading ? (
            <StyledLoaderBox>
              <LoaderDots
                className="loader"
                dotsBgColor={theme.darkBlue}
                dotHeight="8px"
                dotWidth="8px"
                dotMargin="8px"
                // isLoading={queryLoading}
              />
            </StyledLoaderBox>
          ) : (
            <StyledForm className="basic-info">
              <StyledInfoText>* Indicates required</StyledInfoText>
              <FormSelectGroup
                fieldLabel="Current Level of English *"
                isRequired
                errors={errors.english_level}
                options={queryData?.english_proficiency_levels}
                selectLabel={"Please select"}
                defaultValue={
                  (queryData && queryData?.user?.user_info?.english_level) ||
                  "-1"
                }
                getInputValue={(newValue) => {
                  onInputChange("english_level", newValue);
                }}
              />

              <CustomCheckbox
                dataProp={softSkills}
                fetchedData={softSkillsSelected}
                errors={errors.soft_skills}
                isRequired
                label={`${textPages.profile.career.career_soft_skills} *`}
                getInputValue={(newValue, removeSelectedItem) => {
                  onCheckboxChange(
                    newValue,
                    removeSelectedItem,
                    "skills",
                    "soft_skills"
                  );
                }}
              />
              <CustomCheckbox
                dataProp={techSkills}
                fetchedData={techSkillsSelected}
                errors={errors.tech_skills}
                label={`${textPages.profile.career.career_tech_skills} *`}
                isRequired
                getInputValue={(newValue, removeSelectedItem) => {
                  onCheckboxChange(
                    newValue,
                    removeSelectedItem,
                    "skills",
                    "tech_skills"
                  );
                }}
              />

              {!onboarding && isDesktop ? (
                <SubmitButton
                  variant="lightBlueBtn"
                  type="button"
                  disabled={saveBtnDisabled || mutationLoading}
                  hasSubmitted={hasCompletedMutation}
                  onClick={onFormSubmit}
                  isLoading={mutationLoading}
                />
              ) : (
                ""
              )}
            </StyledForm>
          )}
        </StyledInnerWrap>
      </StyledContainer>
    </div>
  );
};

export default Skills;
