import React, { useState } from "react";
import { BsFillInfoCircleFill } from "react-icons/bs";
import styled from "styled-components";

import { Text } from "../../../Consts/Text";
import theme from "../../../Consts/theme";
import InstallmentCard from "./InstallmentCard";
import Button from "../../Button/Button";

// styled-components
const StyledContainer = styled.section`
  gap: 16px;
  border: 1px solid ${theme.concreteGray};
  border-radius: 16px;
  background-color: ${theme.white};
  margin-bottom: 20px;
`;

const StyledFormHeader = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 16px;
  padding: 16px 0;
  border-bottom: 1px solid ${theme.concreteGray};

  @media only screen and (min-width: ${(props) => props.theme.desktop}) {
    margin-left: 24px;
    margin-right: 24px;
  }
`;

const StyledInnerContainer = styled.section`
  padding: 0 16px 16px;

  p.info-message {
    display: flex;
    align-items: center;
    margin-left: 8px;
  }
  p.info-message span {
    margin-left: 4px;
  }

  > div {
    margin-bottom: 16px;
  }
`;
const INITIALLY_DISPLAYED_INSTALLMENTS = 4;
const Payment = ({ data, heading, userOrder }) => {
  const [
    numberOfInitialInstallmentsDisplayed,
    setNumberOfInitialInstallmentsDisplayed,
  ] = useState(INITIALLY_DISPLAYED_INSTALLMENTS);

  const totalInstallments = data?.length;

  const onShowMoreInstallments = () => {
    if (numberOfInitialInstallmentsDisplayed < totalInstallments) {
      setNumberOfInitialInstallmentsDisplayed(
        numberOfInitialInstallmentsDisplayed + 4
      );
    } else {
      setNumberOfInitialInstallmentsDisplayed(INITIALLY_DISPLAYED_INSTALLMENTS);
    }
  };
  return (
    <StyledContainer>
      <StyledFormHeader>
        <Text fontSize="14px">{heading}</Text>
      </StyledFormHeader>

      <StyledInnerContainer>
        {data?.length > 0 ? (
          data
            ?.slice(0, numberOfInitialInstallmentsDisplayed)
            ?.map((item) => (
              <InstallmentCard
                key={item.id}
                cardIndex={item.installmentNumber}
                data={item}
                userOrder={userOrder}
              />
            ))
        ) : (
          <Text
            fontSize="14px"
            color={theme.bronzeYellow}
            className="info-message"
          >
            <BsFillInfoCircleFill />{" "}
            <span>
              Currently, there is no additional information regarding your
              payment
            </span>
          </Text>
        )}
      </StyledInnerContainer>

      {totalInstallments > INITIALLY_DISPLAYED_INSTALLMENTS ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "16px",
          }}
        >
          <Button
            variant="transparentBtn"
            text={
              numberOfInitialInstallmentsDisplayed < totalInstallments
                ? "Show More"
                : "Show Less"
            }
            onClick={onShowMoreInstallments}
          />
        </div>
      ) : (
        ""
      )}
    </StyledContainer>
  );
};

export default Payment;
