import React, { useState } from "react";
import styled from "styled-components";
import { FaAward } from "react-icons/fa";

import theme from "../../../Consts/theme";
import { Text } from "../../../Consts/Text";
import Tooltip from "../../Tooltip/Tooltip";
import SocialMediaShare from "../../SocialMediaShare/SocialMediaShare";

// styled-components
const StyledContainer = styled.div`
  border: 1px solid ${theme.concreteGray};
  border-radius: 8px;
  padding: 18px 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const StyledInnerBox = styled.div`
  display: flex;
  align-items: center;

  ${Text} {
    color: ${(props) => (props.disabled ? theme.textGrey2 : theme.darkGray)};
  }
  p.link {
    margin-bottom: 2px;
    text-decoration: underline;
    text-decoration-thickness: 2px;
  }

  .icon-margin {
    margin-right: 8px;
  }
  button.share-icon {
    margin-left: 5px;
  }

  &.action-buttons button {
    border: none;
    outline: none;
    background: none;
    cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  }
`;
const StyledShareButtonWrap = styled.div`
  position: relative;
`;

const StyledCertification = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #efeff4;
  color: ${theme.black};
  border-radius: 100%;
  padding: 10px;

  a span {
    display: none;
  }

  @media only screen and (min-width: ${(props) => props.theme.laptop}) {
    border-radius: 8px;
    gap: 4px;
    margin-right: 8px;

    a {
      display: inline-flex;
      gap: 4px;
    }
    a span {
      display: block;
      font-size: 14px;
    }
  }
`;

const FileActions = ({
  isSharable,
  isDownloadable,
  title,
  icon,
  disabled,
  onDownload,
  sharingLink,
  imgUrl,
  onSocialButtonClick,
  sharingFileId,
  linkPath,
  certification,
  userFilesData,
}) => {
  const certId = userFilesData?.user?.certificate?.uuid;
  const certUrl = userFilesData?.user?.certificate?.credential_url;
  const certIssueDate = userFilesData && userFilesData?.user?.certificate?.created_at;
  const certIssueParsedDate = new Date(certIssueDate)
  const certIssueYear =  new Date(certIssueParsedDate).getFullYear()
  const certIssueMonth = new Date(certIssueParsedDate).getMonth();
  const organizationId = process.env.REACT_APP_BRAINSTER_ORGANIZATION_ID


  const onShareCertificate = (id, media) => {
    let text = "Brainster Graduate";
    let urlToShare = sharingLink ? sharingLink : imgUrl;

    let socialMediaShareUrl = false;
    if (typeof onSocialButtonClick == "function")
      onSocialButtonClick(id, media);

    switch (media) {
      case "facebook":
        socialMediaShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${urlToShare}&quote=${text}`;
        break;
      case "twitter":
        socialMediaShareUrl = `https://twitter.com/intent/tweet?url=${urlToShare}&text=${text}`;
        break;
      case "linkedin":
        socialMediaShareUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${urlToShare}`;
        break;
      default:
        break;
    }

    if (socialMediaShareUrl) {
      javascript: window.open(
        socialMediaShareUrl,
        `${media} Share`,
        "width=600,height=400"
      );
    }
  };

  return (
    <StyledContainer>
      <StyledInnerBox disabled={disabled}>
        <img src={icon} alt="Icon" className="icon-margin" />
        {!linkPath ? (
          <Text fontSize="14px" fontWeight="600">
            {title}
          </Text>
        ) : (
          <Tooltip text="Open">
            <a href={linkPath} target="_blank">
              <Text fontSize="14px" fontWeight="600" className="link">
                {title}
              </Text>
            </a>
          </Tooltip>
        )}
      </StyledInnerBox>

      <StyledInnerBox className="action-buttons tooltip" disabled={disabled}>
        {certification ? (
          <Tooltip text="LinekdIn Certification">
            <StyledCertification>
              <a
                target="_blank"
                href={`https://www.linkedin.com/profile/add?startTask=CERTIFICATION_NAME&name=Brainster%20Certificate&organizationId=${organizationId}&issueYear=${certIssueYear}&issueMonth=${certIssueMonth + 1}&certUrl=${certUrl}&certId=${certId}`}
              >
                <FaAward fill="#232426"/>
                <span>Add Certification</span>
              </a>
            </StyledCertification>
          </Tooltip>
        ) : (
          ""
        )}
        {!isSharable && isDownloadable ? (
          <Text fontSize="12px" fontWeight="500">
            Download
          </Text>
        ) : (
          ""
        )}
        {isDownloadable ? (
          <Tooltip text="Download">
            <button onClick={() => onDownload()}>
              <img src="/images/downloadIcon.svg" alt="Download Icon" />
            </button>
          </Tooltip>
        ) : (
          ""
        )}
        {isSharable ? (
          <StyledShareButtonWrap>
            <SocialMediaShare
              onShareCertificate={onShareCertificate}
              sharingFileId={sharingFileId}
            />
          </StyledShareButtonWrap>
        ) : (
          ""
        )}
      </StyledInnerBox>
    </StyledContainer>
  );
};

export default FileActions;
