import React, { useRef } from "react";
import { NavLink, useParams } from "react-router-dom";
import styled from "styled-components";

import { Text } from "../../../Consts/Text";
import { makeAppRoute } from "../../../Router";
import theme from "../../../Consts/theme";
import BasicInfo from "../BasicInfo";
import profileSections from "../../../Consts/StaticData";
import ProfileSectionCard from "../ProfileSectionCard/ProfileSectionCard";
import useMediaCustom from "../../../Hooks/useMediaCustom";
import EducationDetails from "../EducationDetails";
import AboutProgram from "../AboutProgram";
import HiringPreferences from "../HiringPreferences";
import NotFound from "../../NotFound/NotFound";
import EmploymentHistory from "../EmploymentHistory";
import ProfileHub from "../ProfileHub";
import Skills from "../Skills";
import Security from "../Security";
import MyFiles from "../MyFiles/MyFiles";
import MyPayments from "../MyPayments/MyPayments";


// styled-components
const StyledContainer = styled.section`
  padding-top: 80px;

  @media only screen and (min-width: ${(props) => props.theme.desktop}) {
    width: 90%;
    margin: 0 auto;
  }

  @media only screen and (min-width: ${(props) => props.theme.large_desktop}) {
    width: 75%;
  }

  @media only screen and (min-width: 1600px) {
    width: 55%;
  }
`;
const StyledInnerContainer = styled.section`
  padding: 0 16px;
  padding-top: 32px;
  row-gap: 16px;
  display: grid;
  margin-bottom: 70px;

  span.title {
    margin: 8px 0;
  }
  @media only screen and (min-width: ${(props) => props.theme.desktop}) {
    grid-template-columns: 4fr 8fr;
    gap: 16px;
  }
`;
const StyledBreadcrumb = styled.div`
  display: none;

  @media only screen and (min-width: ${(props) => props.theme.desktop}) {
    display: flex;
    align-items: center;
    color: ${theme.darkGray};
    margin: 0 24px;

    p:last-child {
      margin-left: 3px;
    }
  }
`;
const StyledNavLink = styled(NavLink)`
  text-decoration: underline;
  line-height: normal;
  cursor: pointer;
  font-weight: 700;
  font-size: 13px;
  opacity: 0.7;
  display: inline-block;
  height: 100%;
  transition: opacity 0.2s ease-in-out;

  &:hover {
    text-decoration: underline !important; /* Keeps the underline even on hover */
    opacity: 1;
  }
`;
const StyledInfoCardContainer = styled.section`
  article:not(:last-child) {
    margin-bottom: 16px;
  }

  span.title {
    font-size: 21px;
    line-height: 26px;
  }

  p.description {
    font-size: 14px;
    line-height: 20px;
    color: #65676c;
  }
  @media only screen and (min-width: ${(props) => props.theme.desktop}) {
    article {
      margin-bottom: 0px;
    }
  }
`;

const ProfileSectionContainer = () => {
  const isDesktop = useMediaCustom("(min-width: 992px)");
  const { section } = useParams();


  // ref
  const submitFunctionRef = useRef(null);

  const renderForm = () => {
    switch (section) {
      case "personal-info":
        return <BasicInfo submitFunctionRef={submitFunctionRef} />;
      case "education-history":
        return <EducationDetails submitFunctionRef={submitFunctionRef} />;
      case "employment-history":
        return <EmploymentHistory submitFunctionRef={submitFunctionRef} />;
      case "program-goals":
        return <AboutProgram submitFunctionRef={submitFunctionRef} />;
      case "hiring-preferences":
        return <HiringPreferences submitFunctionRef={submitFunctionRef} />;
      case "profile-hub":
        return <ProfileHub submitFunctionRef={submitFunctionRef} />;
      case "skills":
        return <Skills submitFunctionRef={submitFunctionRef} />;
      case "security":
        return <Security submitFunctionRef={submitFunctionRef} />;
      case "my-files":
        return <MyFiles />;
      case "my-payments":
        return <MyPayments />;

      // Add cases for additional forms as needed
      default:
        return <NotFound />;
    }
  };


  const currentSection = profileSections.find((element) => {
    if (element.path === section) {
      return element;
    }
    return false;
  });

  return (
    <StyledContainer>
      {/* ------------------------ */}
      {/* StyledBreadcrumb only for desktop */}
      <StyledBreadcrumb>
        <StyledNavLink to={makeAppRoute("profile")}>My Account</StyledNavLink>
        <Text fontWeight={700} fontSize="13px">
          &gt; {currentSection?.title}
        </Text>
      </StyledBreadcrumb>
      <StyledInnerContainer>
        {/* ------ left side container desktop only -------------- */}
        <div>
          <StyledInfoCardContainer>
            {currentSection?.infoCards.length > 0 &&
              (isDesktop ? (
                currentSection?.infoCards.map((card, index) => (
                  <ProfileSectionCard
                    key={index}
                    sectionData={card}
                    displayContent
                  />
                ))
              ) : (
                <ProfileSectionCard
                  sectionData={currentSection?.infoCards[0]}
                  displayContent
                />
              ))}
          </StyledInfoCardContainer>
        </div>

        {/* ------ right side container desktop only -------------- */}

        {renderForm()}
      </StyledInnerContainer>
    </StyledContainer>
  );
};

export default ProfileSectionContainer;
