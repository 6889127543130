import React from "react";
import styled from "styled-components";

const StyledAchievementCard = styled.article`
  width: 100%;
  background: #fff;
  box-shadow: 0px 8px 30px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
  padding: 32px 8px;
  text-align: center;
  font-style: normal;
`;

const StyledIcon = styled.img`
  display: block;
  max-width: 50px;
  margin: 0 auto;

  filter: ${(props) => (props.unblurred ?  "unset" : "blur(4px)")};
`;

const StyledTitle = styled.h2`
  font-weight: bold;
  font-size: 16px;
  line-height: 16px;
  margin-top: 15px;
  margin-bottom: 15px;
  color: ${(props) => (props.unblurred ? "#232426" : "transparent")};
  text-shadow: ${(props) =>
    props.unblurred ? "unset" : "0 0 5px rgba(0,0,0,0.5)" };
  @media only screen and (min-width: ${(props) => props.theme.laptop}) {
    padding: 0 50px;
  }
`;
const StyledDescription = styled.p`
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  color: ${(props) => (props.unblurred ? "#65676C" :  "transparent")};
  text-shadow: ${(props) =>
    props.unblurred ? "unset" : "0 0 5px rgba(0,0,0,0.5)" };
  @media only screen and (min-width: ${(props) => props.theme.laptop}) {
    padding: 0 50px;
  }
`;

const BadgeCard = ({ unblurred, image, header, text }) => {

  return (
    <StyledAchievementCard unblurred={unblurred}>
      <StyledIcon src={image} alt={header} unblurred={unblurred} />
      <StyledTitle unblurred={unblurred}>{header}</StyledTitle>
      <StyledDescription unblurred={unblurred}>{text}</StyledDescription>
    </StyledAchievementCard>
  );
};
export default BadgeCard;
