import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

import theme from "../../Consts/theme";

// styled-components
const StyledContainer = styled.div`
  border: ${(props) =>
    props.inputValue ? "1px solid transparent" : "1px solid #e1e2e7"};
  border-radius: 4px;
  margin: 5px 0;
  width: 100%;

  &:hover {
    border-color: ${(props) =>
      props.type !== "email" ? props.theme.lightBlue : "none"};
  }

  :focus {
    outline: ${(props) =>
      props?.errors?.length > 0 ? props.theme.red : props.theme.lightBlue};
    border-color: ${(props) =>
      props?.errors?.length > 0 ? props.theme.red : props.theme.lightBlue};
  }

`;
const Wrapper = styled.div`
  position: relative;
  z-index: 1;

`

const StyledDateInput = styled.input`
  position: absolute;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  left: 0;
  right: 40px;
  padding: 16px;
  border: none;
  outline: none;
  background-color: ${(props) =>
    !props.inputDateValue ? theme.white : theme.lightGray2};
  color: ${(props) => props.theme.textGrey} !important;
`;
const StyledInput = styled.input`
  width: 100%;
  border: none;
  border-color: ${(props) =>
    props?.errors?.length > 0 ? props.theme.red : "#e1e2e7"};
  padding: 16px;
  border-radius: 4px;
  color: ${(props) => (props.disabled ? "#005EFF" : props.theme.textGrey)};
  cursor: ${(props) => (props.disabled ? "not-allowed" : "default")};
  font-size: 16px;
  background-color: ${(props) =>
    props.value && props.value !== "-1"
      ? `${props.theme.lightGray2}`
      : `${props.theme.white}`};
  transition: all 0.2s ease-in-out;

  &.homework-link {
    border: 0;
    border-bottom: 1px solid ${theme.black};
    background-color: transparent;
    border-radius: 0;
    padding: 8px;

    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: ${(props) => props.theme.black};
  }

  :hover {
    border-color: ${(props) => props.theme.lightBlue};
  }

  :focus {
    outline: ${(props) =>
      props?.errors?.length > 0 ? props.theme.red : props.theme.lightBlue};
    border-color: ${(props) =>
      props?.errors?.length > 0 ? props.theme.red : props.theme.lightBlue};
  }
  ::placeholder {
    color: ${(props) => props.theme.textGrey};
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
  }

  @media only screen and (min-width: ${(props) => props.theme.desktop}) {
    width: 100%;
  }
`;

const StyledError = styled.p`
  font-weight: bold;
  font-size: 12px;
  height: 14px;
  color: ${(props) => props.theme.red} !important;
  transition: all.2s ease-in-out;
`;
const Input = ({
  type,
  placeholder,
  getInputValue,
  name,
  defaultValue,
  disabled,
  errors,
  className,
}) => {
  const [inputValue, setInputValue] = useState("");
  const [isInputTouched, setIsInputTouched] = useState(false);
  const dateInputRef = useRef(null);

  useEffect(() => {
    setInputValue(defaultValue || "");
  }, [defaultValue]);


  const showDatePicker = () => {
    if (dateInputRef.current) {
      dateInputRef.current.focus();
      
      // Call showPicker if it's supported
      if (typeof dateInputRef.current.showPicker === 'function') {
        dateInputRef.current.showPicker();
      }
    }
  };


  const formatDate = (_date) => {
    let newDateFormat;
    if (!_date) {
      return (newDateFormat = `__/__/____`);
    } else {
      const dateArray = _date?.split("-").reverse();

      newDateFormat = `${dateArray?.[0]}/${dateArray?.[1]}/${dateArray?.[2]}`;

      return newDateFormat;
    }
  };

  return (
    <>
      <StyledContainer type={type} inputValue={inputValue}>
        <Wrapper type={type}>
          <StyledInput
            type={type}
            name={name}
            errors={errors}
            value={inputValue}
            disabled={disabled}
            readOnly={disabled}
            className={className}
            isInputTouched={isInputTouched}
            placeholder={inputValue === "" ? placeholder : ""}
            ref={dateInputRef}
            onChange={(e) => {
              setInputValue(e.target.value);
              setIsInputTouched(false);
              getInputValue(e.target.value);
            }}
            onBlur={(e) => {
              setIsInputTouched(true);
            }}
            />

          {type === "date" ? (
            <StyledDateInput
            onClick={showDatePicker}
            className="date-input"
            type="text"
            style={{ color: "black" }}
            inputDateValue={inputValue}
            value={formatDate(inputValue)}
            readOnly
            />
            ) : (
              ""
            )}
        </Wrapper>
      </StyledContainer>

      {errors ? <StyledError>{errors && errors}</StyledError> : ""}
    </>
  );
};
export default React.memo(Input);
