import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { axios, makeApiRoute, makeAppRoute } from "../../Router";
import Notification from "../Notification/Notification";

const TalentMarketNotification = () => {
  const navigate = useNavigate();
  const [hiringProposals, setHiringProposals] = useState();

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    axios
      .get(makeApiRoute("hiringProposals",{ signal }))
      .then((response) => {
        setHiringProposals(response?.data?.data);
      })
      .catch(function (error) {
        throw error.response;
      });

      return () => {
        controller.abort();
      };
  }, []);

  return hiringProposals && Object.keys(hiringProposals).length !== 0 ? (
    <Notification
      bgColor="radial-gradient(131.25% 39562.5% at 1.09% 0%,#00d762 15.51%,#005eff 100%)"
      onClick={() => navigate(makeAppRoute("offer"))}
      content={
        <p>
          A new job offer is available for you. To see more details and respond
          to the offer, <span className="underline">click this box.</span>
        </p>
      }
    />
  ) : (
    ""
  );
};
export default TalentMarketNotification;
