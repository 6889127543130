import React from "react";
import styled from "styled-components";

// styled-components
const StyledModulesButton = styled.button`
  border: 2px solid
    ${(props) => (props.isApplayFilters ? "#0A08E5" : "#C5C5C5")};
  border-radius: 10px;
  padding: 5px 15px;
  color: ${(props) => (props.isApplayFilters ? "#191919" : "#65676C")};
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  display: inline-flex;
  justify-content: space-between;
  background-color: transparent;

  span.button-content {
    align-self: center;
    margin-right: 14px;
  }
`;

const FilterButton = ({ isApplayFilters, buttonContent, icon, onClick }) => {
  return (
    <StyledModulesButton isApplayFilters={isApplayFilters} onClick={onClick}>
      <span className="button-content">{buttonContent}</span>
      <span><img src={icon} alt="Icon" /></span>

    </StyledModulesButton>
  );
};
export default FilterButton;
