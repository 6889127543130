import React, { useEffect, useState } from "react";

import { axios, makeApiRoute } from "../Router";
import { StyledPageWrapper } from "../Components/CommonStyledComponents/CommonStyledComponents";
import { scrollToTop } from "../Consts/PureFunctions";
import theme from "../Consts/theme";
import Navbar from "../Components/Navbar/Navbar";
import TalentMarketContainer from "../Components/TalentMarket/TalentMarketContainer";
import LoaderDots from "../Components/Loader/LoaderDots";
import ScrollToTopPage from "../Components/ScrollSpy/ScrollToToPage";
import EventsNotification from "../Components/Events/EventsNotification";

const TalentMarket = () => {
  const [talentMarket, setTalentMarket] = useState();
  const [isLoading, setIsLoading] = useState(false);

  scrollToTop();

  useEffect(() => {
    setIsLoading(true);
    axios
      .get(makeApiRoute("hiringProposals"))
      .then((response) => {
        setTalentMarket(response?.data?.data);
        setIsLoading(false);
      })
      .catch(function (error) {
        setIsLoading(false);

        throw error.response;
      });
  }, []);

  if (isLoading) {
    return (
      <LoaderDots
        isLoading={isLoading}
        bgColor="#f7f7f7"
        dotsBgColor={theme.lightBlue}
        height="100vh"
        dotMargin="25px"
        dotHeight="10px"
        dotWidth="10px"
      />
    );
  }
  return (
    <StyledPageWrapper>
      <Navbar />
      <EventsNotification />
      <TalentMarketContainer talentMarket={talentMarket} />
      <ScrollToTopPage/>
    </StyledPageWrapper>
  );
};
export default TalentMarket;
