import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { axios, makeApiRoute, makeAppRoute } from "../../Router";
import { GlobalContext } from "../../Context/GlobalProvider";
import Button from "../Button/Button";
import Confetti from "../Confetti/Confetti";
import MotionElements from "../MotionElements/MotionElements";

// styled-components
const StyledContainer = styled.div`
  text-align: center;
  padding: 0 20px;

  button {
    width: 100%;
  }
  @media only screen and (min-width: ${(props) => props.theme.desktop}) {
    button {
      width: 50%;
      margin: 0 auto;
    }
  }
`;
const StyledBox = styled.div`
  margin-top: 20px;
`;
const StyledTitle = styled.h2`
  font-weight: 700;
  line-height: 37px;
  font-size: 24px;
  text-align: center;
  color: ${(props) => props.theme.black};
  @media only screen and (min-width: ${(props) => props.theme.desktop}) {
    font-size: 32px;
  }
`;
const StyledParagraph = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 1.5;
  text-align: center;
  width: 80%;
  margin: 16px auto 80px;

  /* Light Gray/Text */

  color: #65676c;
`;

const OnboardingCompleted = ({ titleOne, titleTwo, text }) => {
  const navigate = useNavigate();
  const { user, setUser } = useContext(GlobalContext);

  return (
    <StyledContainer>
      <Confetti />
      <svg
        width="170"
        height="129"
        viewBox="0 0 170 129"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M147.481 35.4733L137.219 29.6168L145.428 23.0576H153.182L147.481 35.4733Z"
          fill="#F2E635"
        />
        <path
          d="M33.2933 113.471L45.0743 114.373L40.4608 123.814L33.4529 127.132L33.2933 113.471Z"
          fill="#005EFF"
        />
        <path
          d="M44.4703 9.51129L53.7505 2.19805L56.7485 12.2694L53.8381 19.4559L44.4703 9.51129Z"
          fill="#C5C5C5"
        />
        <path
          d="M19.8657 34.3629L20.0502 40.2677L15.109 38.4816L13.0833 35.1763L19.8657 34.3629Z"
          fill="#005EFF"
        />
        <path
          d="M6.52452 68.466L0.829387 63.2902L6.97708 60.2821L11.9064 61.3796L6.52452 68.466Z"
          fill="#191919"
        />
        <path
          d="M144.386 78.5938L144.135 72.6914L149.096 74.4217L151.159 77.7041L144.386 78.5938Z"
          fill="#191919"
        />
        <path
          d="M105.216 22.2222L100.033 19.3865L104.079 16.0337L107.955 15.9642L105.216 22.2222Z"
          fill="#005EFF"
        />
        <path
          d="M19.123 89.4664L22.1606 84.3994L25.3514 88.5736L25.2682 92.4494L19.123 89.4664Z"
          fill="#F2E635"
        />
        <path
          d="M88.5132 124.71L88.2623 118.808L93.2232 120.538L95.286 123.82L88.5132 124.71Z"
          fill="#C5C5C5"
        />
        <path
          d="M164.691 46.9714L168.941 51.0758L164.153 53.2405L160.396 52.2833L164.691 46.9714Z"
          fill="#C5C5C5"
        />
        <path
          d="M142.448 119.306L132.27 125.308L130.651 114.925L134.5 108.194L142.448 119.306Z"
          fill="#F2E635"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M82.9391 33.306C84.4315 32.4919 86.2352 32.4919 87.7276 33.306L112.061 46.5787C113.667 47.4549 114.667 49.1384 114.667 50.9682V77.0318C114.667 78.8616 113.667 80.5451 112.061 81.4213L87.7276 94.694C86.2352 95.5081 84.4315 95.5081 82.9391 94.694L58.6057 81.4213C56.9994 80.5452 56 78.8616 56 77.0318V50.9682C56 49.1384 56.9994 47.4549 58.6057 46.5787L82.9391 33.306Z"
          fill="url(#paint0_radial_3437_17101)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M82.9391 33.306C84.4315 32.4919 86.2352 32.4919 87.7276 33.306L112.061 46.5787C113.667 47.4549 114.667 49.1384 114.667 50.9682V77.0318C114.667 78.8616 113.667 80.5451 112.061 81.4213L87.7276 94.694C86.2352 95.5081 84.4315 95.5081 82.9391 94.694L58.6057 81.4213C56.9994 80.5452 56 78.8616 56 77.0318V50.9682C56 49.1384 56.9994 47.4549 58.6057 46.5787L82.9391 33.306Z"
          fill="url(#paint1_radial_3437_17101)"
        />
        <path
          d="M79.8996 75.5998C79.6663 75.5998 79.4329 75.5498 79.1996 75.4498C78.9663 75.3498 78.7663 75.1998 78.5996 74.9998L69.3996 65.8498C69.0329 65.4831 68.8496 65.0165 68.8496 64.4498C68.8496 63.8831 69.0329 63.4165 69.3996 63.0498C69.7663 62.6831 70.2163 62.4998 70.7496 62.4998C71.2829 62.4998 71.7496 62.6831 72.1496 63.0498L79.8996 70.8498L97.8496 52.8998C98.2496 52.4998 98.7163 52.2998 99.2496 52.2998C99.7829 52.2998 100.233 52.4998 100.6 52.8998C101 53.2998 101.2 53.7665 101.2 54.2998C101.2 54.8331 101 55.2831 100.6 55.6498L81.2496 74.9998C81.0496 75.1998 80.8329 75.3498 80.5996 75.4498C80.3663 75.5498 80.1329 75.5998 79.8996 75.5998Z"
          fill="white"
        />
        <defs>
          <radialGradient
            id="paint0_radial_3437_17101"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(71.3333 32) rotate(67.5074) scale(69.2694 63.497)"
          >
            <stop stopColor="#0A08E5" />
            <stop offset="1" stopColor="#0A08E5" stopOpacity="0" />
          </radialGradient>
          <radialGradient
            id="paint1_radial_3437_17101"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(114.667 96) rotate(-132.453) scale(86.2331 85.5586)"
          >
            <stop stopColor="#005EFF" />
            <stop offset="0.9875" stopColor="#005EFF" stopOpacity="0" />
          </radialGradient>
        </defs>
      </svg>
      <StyledBox>
        <StyledTitle>{titleOne}</StyledTitle>
        <StyledTitle>{titleTwo}</StyledTitle>
        <StyledParagraph>{text}</StyledParagraph>
        <Button
          variant="blueBtn"
          text="Continue to the Platform"
          onClick={() => {
            axios
              .get(makeApiRoute("student"))
              .then((response) => {
                setUser(response.data.data);
                navigate(makeAppRoute("home"));
              })
              .catch(function (error) {
                throw error.response;
              });
          }}
        />
      </StyledBox>
    </StyledContainer>
  );
};

export default OnboardingCompleted;
