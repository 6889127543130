import React from "react";
import styled from "styled-components";

// styled-components

const StyledSkeleton = styled.div`
  background-color: ${(props) => props.theme.lightLightGray};
  /* margin: 10px 0; */
  border-radius: 4px;
  animation: skeleton-loading 1.2s linear infinite alternate;

  @keyframes skeleton-loading {
    0% {
        background-color: ${(props) => props.theme.lightLightGray};
    }
    100% {
        background-color: ${(props) => props.theme.lightGray2};
    }
    
  }

  // if needed more variant classes can be added depending on the element we want to create
  &.text {
    width: 100%;
    height: 12px;
  }
  &.title {
    width: 50%;
    height: 20px;
    margin-bottom: 12px;
  }
  &.avatar {
    width: 100px;
    height: 100px;
    border-radius: 50%;
  }
  &.square {
    width: 100%;
    height: 100%;
  }

`;

const SkeletonElement = ({ variant }) => {

  return <StyledSkeleton className={variant}></StyledSkeleton>;
};
export default SkeletonElement;
