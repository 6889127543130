import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";

import { ErrorMessage } from "../CommonStyledComponents/CommonStyledComponents";
import useOutsideNodeClick from "../../Hooks/useOutsideNodeClick";
import CheckboxItem from "./CustomCheckboxItem";
import Label from "../Label/Label";
import DropdownArrow from "../DropdownArrow/DropdownArrow";
import theme from "../../Consts/theme";

// styled-components
const StyledContainer = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  font-size: 14px;
  width: ${(props) => (props.widthAuto ? "auto" : "100%")};
  border: 1px solid #e1e2e7;
  border-color: ${(props) =>
    props?.errors?.length > 0
      ? props.theme.red
      : props.isOpen
      ? props.theme.lightBlue
      : "#e1e2e7"};
  min-height: 35px;
  max-height: 10rem;
  overflow: auto;
  color: ${(props) => props.theme.textGrey};
  font-size: 16px;
  margin: 5px 0;
  gap: 10px;
  border-radius: 4px;
  padding: 10px 16px;
  background-color: ${(props) =>
    props.hasSelectedOption ? `${props.theme.lightGray2}` : "#fff"};

  &::-webkit-scrollbar {
    width: 4px;
    border-radius: 4px;
  }
  &::-webkit-scrollbar-track-piece {
    background-color: ${(props) => props.theme.lightgray};
    border-radius: 4px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${(props) => props.theme.gray};
    border-radius: 4px;
  }

  &.dropdown-list {
    display: flex;
    max-height: 15em;
    padding: 16px;
    visibility: visible;
  }

  &:focus {
    outline: ${(props) => props.theme.lightBlue};
    border-color: ${(props) =>
      props?.errors?.length > 0 ? props.theme.red : props.theme.lightBlue};
  }
  :hover {
    border-color: ${(props) => props.theme.lightBlue};
    outline: ${(props) => props.theme.lightBlue};
  }

  /* @media only screen and (min-width: ${(props) => props.theme.desktop}) {
    width: ${(props) => (props.widthAuto ? "auto" : "90%")};
  } */
`;
const StyledOptionUl = styled.ul`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  background-color: ${(props) => props.theme.white};
  border: 1px solid #e1e2e7;
  border-radius: 4px;
  visibility: hidden;
  height: 0;
  overflow-y: auto;
  transition: all 0.3s ease-in-out;
  padding: 0 16px;
  gap: 10px;

  &::-webkit-scrollbar {
    width: 4px;
    border-radius: 4px;
  }
  &::-webkit-scrollbar-track-piece {
    background-color: ${(props) => props.theme.lightgray};
    border-radius: 4px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${(props) => props.theme.lightGray2};
    border-radius: 4px;
  }

  &.dropdown-list {
    display: flex;
    height: 15em;
    padding: 16px;
    visibility: visible;
  }
  &.reset-dropdown-list {
    display: none;
    height: 0;
    padding: 0;
    visibility: hidden;
  }


`;
const StyledLi = styled.li`
  display: inline-block;
`;
const StyledSearchWrap = styled.fieldset`
  position: relative;

  div.dropdown-arrow {
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
  }
  :not(:first-child) {
    margin-top: ${(props) => !props.isSelected && "16px"};
  }

  @media only screen and (min-width: ${(props) => props.theme.desktop}) {
    div.dropdown-arrow {
      margin-right: 0 16px;
    }
  }
`;
const StyledInput = styled.input`
  width: auto;
  border: none;
  outline: none;
  margin: 5px 0;
  border-radius: 4px;
  color: ${(props) => props.theme.textGrey};
  font-size: 16px;
  background-color: transparent;
  :focus {
    outline: none;
    border-color: ${(props) =>
      props?.errors?.length > 0 ? props.theme.red : props.theme.lightBlue};
  }
  ::placeholder {
    font-size: 14px;
  }
  :hover {
    border-color: ${(props) => props.theme.lightBlue};
  }
`;
const StyledError = styled.p`
  font-weight: bold;
  font-size: 12px;
  height: 14px;
  color: ${(props) => props.theme.red} !important;
  transition: all.2s ease-in-out;
`;
const StyledBadge = styled.label`
  input[type="checkbox"] {
    position: absolute;
    opacity: 0;
  }

  cursor: pointer;
  background-color: ${theme.lightBlue};
  color: ${theme.white};
  padding: 12px 16px;
  border-radius: 8px;
  border: 1.5px solid
    ${(props) =>
      props.hasSelectedOption ? `${props.theme.lightLightGray}` : "#E6E7EB"};
  display: inline-flex;
  align-items: center;
  font-size: 14px;
  line-height: 1.2;
  margin-right: 8px;


  svg {
    margin-left: 10px;
    display: flex;
    align-items: center;
    fill: ${theme.white};
  }


  :hover {
    background-color: ${theme.red};
    transition: all 0.2s ease-in-out;
  }
  :active {
    color: ${(props) => props.theme.white};
    transition: all 0.2s ease-in-out;
  }
  :focus {
    border: 1.5px solid ${(props) => props.theme.red};
  }
`;
// function
const getDifferenceBetweenArraysOfObjects = (array1, array2) => {
  return array1?.filter((object1) => {
    return !array2?.some((object2) => {
      return object1.id === object2.id;
    });
  });
};

const CustomCheckbox = ({
  label,
  getInputValue,
  fetchedData,
  dataProp,
  errors,
}) => {
  const [displayedData, setDisplayedData] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [searchedQuery, setSearchedQuery] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  const dropdownRef = useRef();



  const handleToggle = () => {
    setIsOpen((prevState) => !prevState);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef?.current &&
        !dropdownRef?.current?.contains(event.target)
      ) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside, true);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside, true);
    };
  }, [dropdownRef]);

  useEffect(() => {
    if (fetchedData?.length > 0) {
      // if there are items selected by user and returned from server
      const arrayDifference = getDifferenceBetweenArraysOfObjects(
        dataProp,
        fetchedData
      );
      setSelectedItems(fetchedData);
      setDisplayedData(arrayDifference);
    } else if (selectedItems?.length > 0) {
      // if user has selected items, but has not submitted to server yet
      const arrayDifference = getDifferenceBetweenArraysOfObjects(
        dataProp,
        selectedItems
      );
      setDisplayedData(arrayDifference);
    } else if (fetchedData?.length === 0) {
      setSelectedItems(fetchedData);
      setDisplayedData(dataProp);
    } else {
      // if user hasent selected any items yet and there is nothing to be returned from server
      setDisplayedData(dataProp);
    }
  }, [fetchedData, selectedItems, dataProp]);

  // invokes when user start typing in the search field
  const onQuerySearch = (searchedQuery) => {
    if (searchedQuery !== "") {
      const filteredData = displayedData.filter((item) => {
        // return values from the object, make them a string, make all characters low case and search the query
        return Object.values(item)
          .join("")
          .toLocaleLowerCase()
          .includes(searchedQuery);
      });
      setDisplayedData(filteredData);
    } else if (selectedItems.length > 0) {
      // if there are already selected items and the user searches for a query,
      // return only the data with the options that are left (not selected ones), not the original data
      const arrayDifference = getDifferenceBetweenArraysOfObjects(
        dataProp,
        selectedItems
      );
      setDisplayedData(arrayDifference);
    } else {
      setDisplayedData(dataProp);
    }
  };

  // stores ref that is generated in custom-hook
  let domNode = useOutsideNodeClick(() => {
    setIsOpen(false);
  });

  return (
    <div ref={dropdownRef}>
      <StyledSearchWrap>
        <Label label={label} />

        <StyledContainer
          hasSelectedOption={
            fetchedData?.length > 0 && selectedItems?.length > 0
          }
        >
          {selectedItems?.length > 0 &&
            selectedItems.map((eachItem) => (
              <StyledBadge
                key={eachItem.id}
                hasSelectedOption={
                  fetchedData?.length > 0 && selectedItems?.length > 0
                }
              >
                {eachItem.name}
                <input
                  type="checkbox"
                  id={eachItem.id}
                  value={eachItem.id}
                  onChange={() => {
                    setDisplayedData([...displayedData, eachItem]);
                    getInputValue(eachItem, true);
                    const updatedArray = fetchedData?.filter(
                      (item) => item.id !== eachItem.id
                    );
                    setSelectedItems(updatedArray);
                    // if user unselect all previous selected items, return empty array
                    if (updatedArray?.length === 0) {
                      return setSelectedItems(updatedArray);
                    }
                  }}
                  name={eachItem.id}
                />
                <svg
                  className="delete-option"
                  width="10"
                  height="10"
                  viewBox="0 0 10 10"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M9.19851 0.807136C8.93851 0.547136 8.51851 0.547136 8.25851 0.807136L4.99852 4.06047L1.73852 0.800469C1.47852 0.540469 1.05852 0.540469 0.798516 0.800469C0.538516 1.06047 0.538516 1.48047 0.798516 1.74047L4.05852 5.00047L0.798516 8.26047C0.538516 8.52047 0.538516 8.94047 0.798516 9.20047C1.05852 9.46047 1.47852 9.46047 1.73852 9.20047L4.99852 5.94047L8.25851 9.20047C8.51851 9.46047 8.93851 9.46047 9.19851 9.20047C9.45851 8.94047 9.45851 8.52047 9.19851 8.26047L5.93852 5.00047L9.19851 1.74047C9.45185 1.48714 9.45185 1.06047 9.19851 0.807136Z" />
                </svg>
              </StyledBadge>
            ))}
          <StyledInput
            placeholder="Search..."
            errors={errors}
            value={searchedQuery}
            onClick={(e) => {
              e.stopPropagation();
              setIsOpen(true);
            }}
            onChange={(e) => {
              onQuerySearch(e.target.value);
              setSearchedQuery(e.target.value);
            }}
          />
          <div className="dropdown-arrow">
            <DropdownArrow
              className={!isOpen ? "" : "active"}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                handleToggle();
              }}
            />
          </div>
        </StyledContainer>
      </StyledSearchWrap>
      {searchedQuery !== "" && displayedData.length === 0 && (
        <ErrorMessage>No results found for the searched term!</ErrorMessage>
      )}

      <StyledOptionUl
        ref={domNode}
        isOpen={isOpen}
        className={!isOpen || displayedData?.length === 0 ? "reset-dropdown-list" : "dropdown-list"}
        hideDropDown={displayedData?.length === 0}
        // hideDropDown={
        //   fetchedData?.length === dataProp?.length ||
        //   (searchedQuery !== "" && displayedData.length === 0)
        // }
      >
        {displayedData
          ? displayedData?.map((eachItem) => (
              <StyledLi key={eachItem.id}>
                <CheckboxItem
                  className={"display-options"}
                  id={eachItem?.name}
                  value={eachItem.id}
                  label={eachItem.name}
                  onChange={() => {
                    setSelectedItems([...selectedItems, eachItem]);
                    getInputValue(eachItem, false);
                    const updatedArray = displayedData.filter(
                      (item) => item.id !== eachItem.id
                    );
                    const arrayDifference = getDifferenceBetweenArraysOfObjects(
                      displayedData,
                      updatedArray
                    );
                    setDisplayedData(arrayDifference);

                    // empty search field after searching a query and selecting the item
                    if (selectedItems.length >= 0) {
                      setSearchedQuery("");
                    }
                  }}
                />
              </StyledLi>
            ))
          : ""}
      </StyledOptionUl>
      <StyledError>{errors && errors}</StyledError>
    </div>
  );
};
export default CustomCheckbox;
