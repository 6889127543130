import React, {  useState } from "react";
import styled from "styled-components";
import { BiHide, BiShowAlt } from "react-icons/bi";

// styled-components
const StyledInputWrap = styled.div`
  position: relative;
  display: flex;
  input {
    background-color: #efeff4;
    font-weight: 400;
    padding: 16px 24px;
    border: none;
    border-radius: 100px;
    width: 100%;
    font-size: 16px;
    line-height: 18px;
    color: #005eff;

    ::placeholder {
      font-size: 14px;
    }

    :focus {
      outline: 2px solid #005eff;
    }
    @media only screen and (min-width: ${(props) => props.theme.desktop}) {
      border-radius: 0px;
      color: #65676c;
    }
  }

  .icon {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;

    svg {
        width: 1.5em !important;
        height: 1.5em !important;

    }
  }
`;

const PasswordInput = (props) => {
  const [hide, setHide] = useState(false);

  const toggle = () => {
    setHide((prev) => !prev);
  };

  return (
    <StyledInputWrap>
      <input type={!hide ? "password" : "text"} {...props} />

      <i className="icon" onClick={toggle}>
        {hide ? <BiHide /> : <BiShowAlt />}
      </i>
    </StyledInputWrap>
  );
};
export default PasswordInput;
