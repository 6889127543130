import { gql } from "@apollo/client";

export const GET_PROFILE_IMAGE = gql`
  {
    user {
      profile
    }
  }
`;

export const GET_PERSONAL_INFO = gql`
  {
    user {
      name
      email
      phone
      gender
      date_of_birth
      onboarding_step
      user_info {
        current_location_id
        birthplace_id
      }
    }
    cities {
      id
      country_id
      name
    }
  }
`;

export const GET_EDUCATION_INFO = gql`
  {
    user {
      date_of_birth
      user_info {
        high_school_id
        high_school_name
        faculty_id
        faculty_name
        study_subfield_id
        study_subfield_name
        education_level_id
        study_status_id
        still_in_high_school
      }
    }
    high_schools {
      id
      name
      city {
        name
      }
    }
    faculties {
      id
      university
      faculty
      order
      city {
        name
      }
    }
    study_statuses {
      id
      name
    }
    education_levels {
      id
      name
    }
    study_subfields {
      id
      study_field_id
      name
    }
  }
`;

export const GET_HIGH_SCHOOL_INFO = gql`
  {
    user {
      user_info {
        high_school_id
        high_school_name
        still_in_high_school
      }
    }
    high_schools {
      id
      name
      city {
        name
      }
    }
  }
`;

export const GET_HIGHER_EDUCATION_INFO = gql`
  {
    user {
      user_info {
        faculty_id
        faculty_name
        study_subfield_id
        education_level_id
        study_status_id
        study_subfield_name
      }
    }
    faculties {
      id
      university
      faculty
      order
      city {
        name
      }
    }
    study_statuses {
      id
      name
    }
    education_levels {
      id
      name
    }
    study_subfields {
      id
      study_field_id
      name
    }
  }
`;

export const GET_EMPLOYMENT_DATA = gql`
  {
    user {
      onboarding_step
      user_info {
        industry_id
        industry_name
        profession_id
        profession_name
        employment_status_id
      }
    }
    employment_statuses {
      id
      name
    }
    industries {
      id
      name
    }
    professions {
      id
      name
    }
  }
`;

export const GET_PROGRAM_DATA = gql`
  {
    user {
      onboarding_step
      user_info {
        enrollment_purpose_id
        understanding_of_program_topic_id
        still_in_high_school
      }
      expectations_from_academy_program {
        id
        name
      }
      circumstances_that_prevent_active_participation {
        id
        name
      }
    }
    enrollment_purposes {
      id
      name
    }
    understanding_of_program_topic {
      id
      name
    }
    expectations_from_academy_program {
      id
      name
    }
    circumstances_that_prevent_active_participation {
      id
      name
    }
  }
`;
export const GET_HIRING_PREFERENCES_DATA = gql`
  {
    user {
      onboarding_step
      recommendation_preferences {
        id
        name
      }
      work_preferences {
        id
        name
      }
    }
    recommendation_preferences {
      id
      name
    }
    work_preferences {
      id
      name
    }
  }
`;
export const GET_PROFILE_HUB_DATA = gql`
  {
    user {
      cv
      bio_en
      social_profiles {
        facebook
        twitter
        linkedin
        github
        upwork
        behance
        website
        dribble
        calendly
        figma
      }
    }
  }
`;

export const GET_SKILLS_DATA = gql`
  {
    user {
      onboarding_step
      user_info {
        english_level
      }

      skills {
        id
        type
        name
      }
    }
    english_proficiency_levels {
      name
    }
    skills {
      id
      type
      name
    }
  }
`;

export const GET_ONBOARDING_EXPERIENCE_DATA = gql`
  {
    user {
      onboarding_step
      onboarding_process_complete
      user_info {
        reason
      }
      onboarding_experience {
        rating
        impression
        heard_about_brainster
        time_following_brainster
      }
    }
    reasons_for_choosing_brainster {
      id
      name
    }
    onboarding_experience_options {
      time_following_brainster
      heard_about_brainster
      impressions
    }
  }
`;
export const GET_MY_PAYMENTS = gql`
  {
    user {
      orders {
        id
        name
        amount
        discount
        total_installments
        installment_amount
        status
        created_at
        installments {
          id
          name
          status
          recurring_time
        }
      }
    }
  }
`;
export const UPDATE_PROFILE_IMAGE = gql`
  mutation UpdateUser($inputData: UpdateUserInput) {
    UpdateUser(input: $inputData) {
      profile
    }
  }
`;

export const UpdateUser = gql`
  mutation UpdateUser($inputData: UpdateUserInput!) {
    UpdateUser(input: $inputData) {
      name
      email
      phone
      gender
      date_of_birth
      onboarding_step
      user_info {
        current_location_id
        birthplace_id
      }
    }
  }
`;

export const UpdateUserEmploymentInfo = gql`
  mutation UpdateUser($inputData: UpdateUserInput!) {
    UpdateUser(input: $inputData) {
      onboarding_step
      user_info {
        industry_name
        profession_name
        profession_id
        industry_id
        employment_status_id
      }
    }
  }
`;
export const UpdateUserProgramGoalsAndExpectationsInfo = gql`
  mutation UpdateUser($inputData: UpdateUserInput!) {
    UpdateUser(input: $inputData) {
      onboarding_step
      user_info {
        enrollment_purpose_id
        understanding_of_program_topic_id
      }
      expectations_from_academy_program {
        id
        name
      }
      circumstances_that_prevent_active_participation {
        id
        name
      }
    }
  }
`;
export const UpdateUserHiringPreferences = gql`
  mutation UpdateUser($inputData: UpdateUserInput!) {
    UpdateUser(input: $inputData) {
      onboarding_step
      recommendation_preferences {
        id
        name
      }
      work_preferences {
        id
        name
      }
    }
  }
`;
export const UpdateUserBiographyEn = gql`
  mutation UpdateUser($inputData: UpdateUserInput!) {
    UpdateUser(input: $inputData) {
      bio_en
    }
  }
`;
export const UpdateUserSocialLinks = gql`
  mutation UpdateUser($inputData: UpdateUserInput!) {
    UpdateUser(input: $inputData) {
      social_profiles {
        facebook
        twitter
        linkedin
        github
        upwork
        behance
        website
        dribble
        calendly
        figma
      }
    }
  }
`;
export const UpdateUserSocialLinksAndBio = gql`
  mutation UpdateUser($inputData: UpdateUserInput!) {
    UpdateUser(input: $inputData) {
      bio_en
      social_profiles {
        facebook
        twitter
        linkedin
        github
        upwork
        behance
        website
        dribble
        calendly
        figma
      }
    }
  }
`;
export const UpdateUserSkills = gql`
  mutation UpdateUser($inputData: UpdateUserInput!) {
    UpdateUser(input: $inputData) {
      onboarding_step
      user_info {
        english_level
      }
      skills {
        id
        type
        name
      }
    }
  }
`;
export const UpdateUserEducationInfo = gql`
  mutation UpdateUser($inputData: UpdateUserInput!) {
    UpdateUser(input: $inputData) {
      user_info {
        high_school_id
        high_school_name
        faculty_id
        faculty_name
        study_subfield_id
        study_subfield_name
        education_level_id
        study_status_id
        still_in_high_school
      }
    }
  }
`;
export const UpdateUserHighSchoolInfo = gql`
  mutation UpdateUser($inputData: UpdateUserInput!) {
    UpdateUser(input: $inputData) {
      onboarding_step
      user_info {
        high_school_id
        high_school_name
        still_in_high_school
      }
    }
  }
`;
export const UpdateUserHigherEducationInfo = gql`
  mutation UpdateUser($inputData: UpdateUserInput!) {
    UpdateUser(input: $inputData) {
      onboarding_step
      user_info {
        study_status_id
        faculty_id
        faculty_name
        study_subfield_id
        study_subfield_name
        education_level_id
      }
    }
  }
`;
export const UpdateUserOnboardingExperience = gql`
  mutation UpdateUser($inputData: UpdateUserInput!) {
    UpdateUser(input: $inputData) {
      onboarding_process_complete
      user_info {
        reason
      }
      reasons_for_choosing_brainster {
        id
        name
      }

      onboarding_experience {
        rating
        impression
        heard_about_brainster
        time_following_brainster
      }
    }
  }
`;
export const UpdateCountSharedAchievement = gql`
  mutation UpdateStudentAchievement($id: ID!, $service: String) {
    UpdateStudentAchievement(id: $id, service: $service) {
      id
    }
  }
`;

export const COMPLETE_ONBOARDING = gql`
  mutation UpdateUser($inputData: UpdateUserInput!) {
    UpdateUser(input: $inputData) {
      onboarding_process_complete
    }
  }
`;

export const GET_STUDENT_CONTRACT = gql`
  {
    user {
      contracts {
        contract_url
      }
    }
  }
`;
export const GET_STUDENT_MODULE_ACHIEVEMENTS = gql`
  {
    user {
      achievements {
        id
        student_id
        achievement
        sharing_link
        unit {
          id
          title
        }
      }
    }
  }
`;

export const GET_STUDENT_FILES_STATUS = gql`
  {
    user {
      contracts {
        name
        contract_url
      }
      achievements {
        id
        type
        student_id
        achievement
        sharing_link
        course_title
        unit {
          id
          title
        }
      }
      certificate {
        uuid
        id
        pdf_digital
        image
        next_image
        next_pdf_digital
        credential_url
        created_at
      }
    }
  }
`;
export const GET_STUDENT_CERTIFICATE_ID = gql`
  {
    user {
      certificate {
        id
      }
    }
  }
`;
