import React, { useEffect, useState } from "react";
import styled from "styled-components";

import Tooltip from "../Tooltip/Tooltip";

// styled-components
const StyledShareButtonWrap = styled.div`
  position: relative;
`;
const StyledSocialButtonsContainer = styled.div`
  display: ${(props) => (props.show ? "block" : "none")};
  position: absolute;
  left: 100%;
  top: 50%;
  transform: translateY(-50%);
  padding: 6px;
  border-radius: 8px;
`;

const SocialMediaButton = styled.button`
  animation: fadeIn 0.3s ease forwards ${(props) => props.animationDelay};
  opacity: 0; // Start with an opacity of 0 to ensure the fadeIn effect

  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
`;

const socialMediaButtons = [
  {
    id: 1,
    service: "linkedin",
  },
  {
    id: 2,
    service: "twitter",
  },
  {
    id: 3,
    service: "facebook",
  },
];

const SocialMediaShare = ({ sharingFileId, onShareCertificate }) => {
  const [isIconsVisible, setIsIconsVisible] = useState(false);

  const handleClickShareButton = () => {
    const showBtn = true;
    setIsIconsVisible(showBtn);
  };

  const handleClickDocument = (event) => {
    const clickedElement = event.target;

    if (
      clickedElement.id !== "shareButton" &&
      !clickedElement.classList.contains("social-icon")
    ) {
      setIsIconsVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickDocument);

    return () => {
      document.removeEventListener("mousedown", handleClickDocument);
    };
  }, []);

  return (
    <StyledShareButtonWrap>
      <Tooltip text="Share">
        <button id="shareButton" onClick={handleClickShareButton}>
          {" "}
          <img src="/images/shareIcon.svg"   alt="Share Icon" />
        </button>
      </Tooltip>
      <StyledSocialButtonsContainer show={isIconsVisible}>
        {socialMediaButtons.map(({ id, service }, index) => {
          const animationDelay = `${index * 0.2}s`;
          return (
            <SocialMediaButton
              key={id}
              type="button"
              animationDelay={animationDelay}
              onClick={() => onShareCertificate(sharingFileId, service)}
            >
              <img
                className="social-icon"
                src={`/images/${service}IconDark.svg`}
                alt={`${service} Icon`}
              />
            </SocialMediaButton>
          );
        })}
      </StyledSocialButtonsContainer>
    </StyledShareButtonWrap>
  );
};

export default SocialMediaShare;
