import React from "react";
import { ErrorMessage, StyledTextarea } from "../CommonStyledComponents/CommonStyledComponents";



const SendScoreComment = ({
  error,
  onStudentComment,
  label,
  placeholder,
  value,
}) => {
  return (
    <>
      <label>{label}</label>
      <StyledTextarea
        name="student_comment"
        id=""
        cols="30"
        rows="10"
        value={value}
        onChange={(e) => onStudentComment(e.target.value)}
        placeholder={placeholder}
      ></StyledTextarea>
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </>
  );
};
export default SendScoreComment;
