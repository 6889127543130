import React from "react";
import styled from "styled-components";

// styled-components
const StyledCheckbox = styled.div`
  transform: scale(2);
  position: absolute;
  top: -4px;
  right: 0;

  input[type="checkbox"] {
    position: relative;
    appearance: none;
    width: 20px;
    height: 10px;
    background-color: ${(props) => (props.isCompleted ? "#005EFF" : "#ccc")};
    border-radius: 50px;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    transition: 0.4s;
    margin: 0;
  }

  input[type="checkbox"]::after {
    position: absolute;
    content: "";
    width: 6px;
    height: 6px;
    top: 1.5px;
    left: ${(props) => (props.isCompleted ? "11px" : "3px")};
    background: #fff;
    border-radius: 50%;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    transform: scale(1.1);
    transition: 0.4s;
  }

  input:checked[type="checkbox"]::after {
    left: ${(props) => (props.isCompleted ? "11px" : "3px")};
  }

`;

const ToggleCheckbox = ({ resource, onResourceStatusChanged }) => {
  return (
    <StyledCheckbox isCompleted={resource.completed}>
      <input
        type="checkbox"
        value={resource.completed}
        onClick={() => onResourceStatusChanged(resource.id)}
      />
    </StyledCheckbox>
  );
};
export default ToggleCheckbox;
