import React, { useCallback, useContext, useEffect, useState } from "react";
import styled from "styled-components";
import Cropper from "react-easy-crop";

import Button from "../Button/Button";
import { GlobalContext } from "../../Context/GlobalProvider";

//styled-components
const PhotoCropperWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 30px;
  background-color: rgba(229, 229, 229, 0.9);
  backdrop-filter: blur(4px);
  z-index: 11;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 4px;
    border-radius: 4px;
  }
  &::-webkit-scrollbar-track-piece {
    background-color: ${(props) => props.theme.lightGray2};
    border-radius: 4px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #e1e2e7;
    border-radius: 4px;
  }

  .cropper-popup {
    background-color: ${(props) => props.theme.white};
    border-radius: 15px;
    padding: 12px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .cropper-container {
      width: 500px;
      height: 500px;
      position: relative;
      border-radius: 50%;
      .reactEasyCrop_Container {
        border-radius: 15px;
        overflow: hidden;
      }
    }
    .zoom-container {
      width: 100%;
      margin-top: 20px;
      input {
        width: 100%;
      }
    }
    .buttons-container {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin: 20px 0;
    }
  }
  .dimmed-background {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    background-color: ${(props) => props.theme.lightBlack};
    opacity: 0.8;
  }
  @media (max-width: 500px) {
    .cropper-popup {
      width: 95%;
      .cropper-container {
        width: 100%;
        height: 300px;
      }
    }
  }
`;

// ---------------------------------------------------------------------------------------------------------
// functions below are implemented from the npm react-easy-crop

const createImage = (url) =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener("load", () => resolve(image));
    image.addEventListener("error", (error) => reject(error));
    image.setAttribute("crossOrigin", "anonymous"); // needed to avoid cross-origin issues on CodeSandbox
    image.src = url;
  });

export function getRadianAngle(degreeValue) {
  return (degreeValue * Math.PI) / 180;
}

/**
 * Returns the new bounding area of a rotated rectangle.
 */
export function rotateSize(width, height, rotation) {
  const rotRad = getRadianAngle(rotation);

  return {
    width:
      Math.abs(Math.cos(rotRad) * width) + Math.abs(Math.sin(rotRad) * height),
    height:
      Math.abs(Math.sin(rotRad) * width) + Math.abs(Math.cos(rotRad) * height),
  };
}

/**
 * This function was adapted from the one in the ReadMe of https://github.com/DominicTobias/react-image-crop
 */
export async function getCroppedImg(
  imageSrc,
  pixelCrop,
  rotation = 0,
  flip = { horizontal: false, vertical: false }
) {
  const image = await createImage(imageSrc);
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");

  if (!ctx) {
    return null;
  }

  const rotRad = getRadianAngle(rotation);

  // calculate bounding box of the rotated image
  const { width: bBoxWidth, height: bBoxHeight } = rotateSize(
    image.width,
    image.height,
    rotation
  );

  // set canvas size to match the bounding box
  canvas.width = bBoxWidth;
  canvas.height = bBoxHeight;

  // translate canvas context to a central location to allow rotating and flipping around the center
  ctx.translate(bBoxWidth / 2, bBoxHeight / 2);
  ctx.rotate(rotRad);
  ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1);
  ctx.translate(-image.width / 2, -image.height / 2);

  // draw rotated image
  ctx.drawImage(image, 0, 0);

  // croppedAreaPixels values are bounding box relative
  // extract the cropped image using these values
  const data = ctx.getImageData(
    pixelCrop.x,
    pixelCrop.y,
    pixelCrop.width,
    pixelCrop.height
  );

  // set canvas width to final desired crop size - this will clear existing context
  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;

  // paste generated rotate image at the top left corner
  ctx.putImageData(data, 0, 0);

  // As Base64 string
  return canvas.toDataURL("image/jpeg");

  // As a blob
  // return new Promise((resolve, reject) => {
  //   canvas.toBlob((file) => {
  //     resolve(URL.createObjectURL(file));
  //   }, "image/jpeg");
  // });
}

const ImageCropper = ({ image, setImage, uploadImage,   }) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [rotation] = useState(0);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState({});
  const [, setCroppedImage] = useState(null);

  const { user, setUser } = useContext(GlobalContext);

  // useEffect(() => {
  //   if (image) {
  //     document.body.classList.add("overflow-hidden");
  //   } else {
  //     document.body.classList.remove("overflow-hidden");
  //   }
  // }, [image]);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(
        image,
        croppedAreaPixels,
        rotation
      );
      const formData = new FormData();
      formData.append("croppedPhoto", croppedImage);
      uploadImage(formData);
      setCroppedImage(croppedImage);
      setUser({ ...user, profile: croppedImage });
    } catch (e) {
      // console.error(e);
    }
  }, [croppedAreaPixels, rotation, user, image, uploadImage]);

  const onClose = () => {
    setCroppedImage(null);
    setImage(false);
  };

  return (
    <PhotoCropperWrapper>
      <div className="cropper-popup">
        <div className="dimmed-background"></div>
        <div className="cropper-container">
          <Cropper
            image={image}
            crop={crop}
            zoom={zoom}
            aspect={1}
            onCropChange={setCrop}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
            cropShape="round"
            zoomWithScroll={true}
          />
        </div>
        <div className="zoom-container">
          <p>Zoom</p>
          <input
            type="range"
            value={zoom}
            min={1}
            max={3}
            step={0.01}
            onChange={(e) => setZoom(e.target.value)}
          />
        </div>
        <div className="buttons-container">
          <Button onClick={() => onClose()} text={"Cancel"} variant="grayBtn" />
          <Button
            onClick={() => showCroppedImage()}
            text={"Crop and Save"}
            variant="blueBtn"
          />
        </div>
      </div>
    </PhotoCropperWrapper>
  );
};
export default ImageCropper;
