import React from "react";
import styled from "styled-components";
import ProfileImageAcronym from "./ProfileImageAcronym";

// styled-components
const StyledProfileImage = styled.figure`
  margin-left: 20px;
  width: ${props => props.width };
  height: ${props => props.height};
  cursor: ${props => props.onClick ? "pointer" : "default"};
  position: relative;
  border-radius: 50%;
  color: #fff;
  display: inline-block;
  overflow: hidden;
  transition: all 0.45s ease;


  &.profile-img {
    margin-left: 0;
  }

  img {
    display: block;
    width: ${props => props.width };
    height: ${props => props.height };
    border-radius: 50%;
    backface-visibility: hidden;
 
  }
 

  figcaption {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &:hover figcaption {
    opacity: 1;
  }
  
  &:hover {
    transform: scale(1.1);
  }
`;

const ProfileImage = ({ url, onClick, width, height, className, user}) => {


  return (
    <>
      <StyledProfileImage onClick={onClick} width={width} height={height} className={className} onMouseDown={(e) => e.stopPropagation()}>
        {url ? <img src={url} alt="Profile" /> : <ProfileImageAcronym userName={user?.name} width="100%" height="100%"/>}
        
      </StyledProfileImage>
     

    </>
  );
};

export default ProfileImage;
