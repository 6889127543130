import React from "react";
import styled from "styled-components";
import theme from "../../Consts/theme";

const StyledCheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
`;

const HiddenCheckbox = styled.input.attrs({ type: "checkbox" })`
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;
const Icon = styled.svg`
  fill: none;
  stroke: white;
  stroke-width: 2px;
`;
const StyledCheckbox = styled.div`
  display: inline-block;
  width: 16px;
  height: 16px;
  background: ${(props) =>
    props.disabled
      ? theme.lightLightGray
      : props.checked
      ? theme.black
      : "transparent"};
  border: 1.5px solid
    ${(props) =>
      props.disabled ? props.theme.lightLightGray : props.theme.black};
  border-radius: 3px;
  transition: all 150ms;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};

  ${Icon} {
    visibility: ${(props) => (props.checked ? "visible" : "hidden")};
  }
`;

const InputCheckbox = ({
  className,
  checked,
  onChange,
  disabled,
  value,
  ...props
}) => {
  const handleChange = () => {
    const newChecked = !checked;
    onChange(newChecked);
  };

  return (
    <StyledCheckboxContainer>
      <HiddenCheckbox
        checked={checked}
        onChange={handleChange}
        disabled={disabled}
        value={value}
      />
      <StyledCheckbox checked={checked} disabled={disabled} >
        <Icon viewBox="0 0 24 24">
          <polyline points="20 6 9 17 4 12" />
        </Icon>
      </StyledCheckbox>
    </StyledCheckboxContainer>
  );
};

export default InputCheckbox;
