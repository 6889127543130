import React, { useState } from "react";
import styled from "styled-components";

import SideMenu from "./SideMenu";
import theme from "../../Consts/theme";

const StyledBarrIcon = styled.div`
  width: 30px;
  height: 30px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  position: relative;

  cursor: pointer;
  position: relative;
  z-index: 10;

  .menu-bar {
    width: inherit;
    height: 4px;
    border-radius: 4px;
    background-color: ${(props) => (props.open ? "transparent" : "black")};
    transition: all 0.5s ease-in-out;
  }
  .menu-bar::before,
  .menu-bar::after {
    content: "";
    position: absolute;
    width: inherit;
    height: 4px;
    border-radius: 4px;
    background-color: black;
    transition: all 0.5s ease-in-out;
  }
  .menu-bar::before {
    transform: translateY(${(props) => !props.open && "-10px"});
    transform: rotate(${(props) => props.open && "45deg"});
    background-color: black;
  }
  .menu-bar::after {
    transform: translateY(${(props) => !props.open && "10px"});
    transform: rotate(${(props) => props.open && "-45deg"});
    background-color: black;
  }

  @media only screen and (min-width: ${(props) => props.theme.large_desktop}) {
    display: none;
  }
`;

const StyledNotification = styled.div`
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: ${theme.darkBlue2};
  position: absolute;
  top: -5px;
  right: -5px;
  z-index: 11;
  transform: scale(1);
	animation: pulse 1.5s infinite;

  @keyframes pulse {
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(6, 117, 56, 0.7);
	}

	70% {
		transform: scale(1);
		box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
	}

	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
	}
}
`;
const HamburgerIcon = ({ handleShowLogoutPopup, userId, hasPaymentOrder }) => {
  const [open, setOpen] = useState(false);

  const handleSideMenu = () => {
    setOpen(!open);
  };

  return (
    <>
      <StyledBarrIcon open={open} onClick={handleSideMenu}>
        {hasPaymentOrder && !open ? <StyledNotification /> : ""}
        <div className="menu-bar"></div>
      </StyledBarrIcon>
      <SideMenu
        open={open}
        userId={userId}
        handleShowLogoutPopup={handleShowLogoutPopup}
        handleSideMenu={handleSideMenu}
      />
    </>
  );
};
export default HamburgerIcon;
