import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import styled from "styled-components";

import { makeAppRoute } from "../../Router";
import { StyledForm } from "../CommonStyledComponents/CommonStyledComponents";
import { Text } from "../../Consts/Text";
import {
  GET_EMPLOYMENT_DATA,
  UpdateUserEmploymentInfo,
} from "../../Consts/GraphqlQueries";
import theme from "../../Consts/theme";
import MobileHeader from "./MobileHeader/MobileHeader";
import LoaderDots from "../Loader/LoaderDots";
import SubmitButton from "../Button/SubmitButton";
import useMediaCustom from "../../Hooks/useMediaCustom";
import FormSelectGroup from "../FormGroup/FormSelectGroup";
import Autocomplete from "../Input/Autocomplete";
import ErrorMessageComponent from "../ErrorMessageComponent/ErrorMessageComponent";
import { GlobalContext } from "../../Context/GlobalProvider";

// styled-components
const StyledContainer = styled.section`
  gap: 16px;
  border: 1px solid ${theme.concreteGray};
  border-radius: 16px;
  background-color: ${theme.white};
`;
const StyledFormHeader = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 16px;
  padding: 16px 0;
  border-bottom: 1px solid ${theme.concreteGray};

  @media only screen and (min-width: ${(props) => props.theme.desktop}) {
    margin-left: 24px;
    margin-right: 24px;
  }
`;

const StyledInnerWrap = styled.section`
  width: 100%;

  @media only screen and (min-width: ${(props) => props.theme.desktop}) {
    display: flex;
    width: 90%;

  }
`;

const StyledInfoText = styled.p`
  font-style: normal;
  font-size: 10px;
  line-height: 14px;
  color: ${(props) => props.theme.lightGrayText};
  margin-bottom: 16px;
`;
const StyledLoaderBox = styled.div`
  height: 60vh;
  width: 100%;
  display: flex;
  justify-content: center;

  .loader {
    padding-top: 0px;
  }
`;

// ----------------------------------------------------------------
// Error messages on input validation
const errorMessages = {
  employment_status_id: "Please provide employment status",
};
// Reset error messages
const resetErrors = {
  employment_status_id: "",
};
// ----------------------------------------------------------------
/* This is input config that is used inside onInputChange function
   for dynamically getting values based on different input name. */

const professionOtherID = parseInt(process.env.REACT_APP_PROFESSION_OTHER_ID);
const industryOtherID = parseInt(process.env.REACT_APP_INDUSTRY_OTHER_ID);
const unemployedStatusID = 6;

const inputConfigurations = {
  profession_id: {
    defaultValue: professionOtherID,
    relatedFieldName: "profession_name",
    parseNumericValue: true,
  },
  industry_id: {
    defaultValue: industryOtherID,
    relatedFieldName: "industry_name",
    parseNumericValue: true,
  },
  employment_status_id: {
    parseNumericValue: true,
  },

  // Add other fields if needed
};

const EmploymentHistory = ({
  submitFunctionRef,
  onboarding,
  step,
  onboardingRoutes,
  educationSteps,
}) => {
  const isDesktop = useMediaCustom("(min-width: 992px)");
  const navigate = useNavigate();
  const userObjectRef = useRef();
  const { user, setUser } = useContext(GlobalContext);
  // States
  const [saveBtnDisabled, setSaveBtnDisabled] = useState(true);
  const [hasCompletedMutation, setHasCompletedMutation] = useState(false);
  const [errors, setErrors] = useState(resetErrors);
  const [employmentStatus, setEmploymentStatus] = useState("");

  //  ----------------------------------------------------------------
  // GRAPHQL GET REQUEST
  const {
    loading: queryLoading,
    error: queryError,
    data: queryData,
  } = useQuery(GET_EMPLOYMENT_DATA, {
    fetchPolicy: "cache-and-network",
  });
  //  ----------------------------------------------------------------
  // GRAPHQL POST REQUEST (MUTATION)
  const [
    updateUser,
    {
      data: mutationData,
      loading: mutationLoading,
      error: mutationError,
      called: mutationCalled,
    },
  ] = useMutation(UpdateUserEmploymentInfo, {
    onCompleted: (data) => {
      handleUserUpdate(data.UpdateUser);
      if (!onboarding) {
        setSaveBtnDisabled(true);
        setHasCompletedMutation(true);
        handleCompletedMutation();
      } else {
        const onboardingRoutesNextStep =
          onboardingRoutes[onboardingRoutes.indexOf(educationSteps) + 1];
        navigate(
          makeAppRoute(`onboarding`, {
            STEP: onboardingRoutesNextStep,
          })
        );
      }
    },
  });

  // ----------------------------------------------------------------
  // VARIABLES
  const hasFetchedUser = queryData?.user !== undefined;

  const handleEmploymentStatus = (_data) => {
    const employmentStatusValue = parseInt(
      _data?.user_info?.employment_status_id
    );
    setEmploymentStatus(employmentStatusValue);
  };

  useEffect(() => {
    if (mutationCalled) {
      handleUserUpdate(mutationData?.UpdateUser);
      userObjectRef.current = mutationData?.UpdateUser;
      handleEmploymentStatus(mutationData?.UpdateUser);
      return;
    }
  }, [mutationCalled, queryData, mutationData]);

  useEffect(() => {
    // pass submit function to the ref that is coming from parent where this function is called
    if (submitFunctionRef?.current) {
      submitFunctionRef.current = handleFormSubmit;
    }

    if (!mutationCalled && hasFetchedUser) {
      userObjectRef.current = queryData?.user;
      handleUserUpdate(queryData?.user);
      handleEmploymentStatus(queryData?.user);
    }
  }, [queryLoading, mutationLoading, hasFetchedUser]);

  // ----------------------------------------------------------------
  // Update the user object in Global context after submission and on page load

  const handleUserUpdate = (updatedUserData) => {
    setUser((prevUser) => ({
      ...prevUser,
      ...updatedUserData,
      user_info: {
        ...prevUser?.user_info,
        ...updatedUserData?.user_info,
      },
    }));
  };

  // ----------------------------------------------------------------
  // Return hasCompletedMutation to false to remove the style for success message

  const handleCompletedMutation = () => {
    const timer = setTimeout(() => {
      setHasCompletedMutation(false);
    }, 4000);

    // Clear the timeout if the component unmounts
    return () => clearTimeout(timer);
  };

  //  ----------------------------------------------------------------
  // ON INPUT CHANGE

  const onInputChange = (_inputName, _value) => {
    const config = inputConfigurations[_inputName];
    const isNumberValue = typeof _value === "number";

    if (!_value) {
      userObjectRef.current = {
        ...userObjectRef.current,
        user_info: {
          ...userObjectRef.current.user_info,
          [_inputName]: null,
          [config.relatedFieldName]: null,
        },
      };
    }

    if (config) {
      if (!isNumberValue) {
        // ----------------------------------------------------------------
        // If _value is not a number, it means that the user has typed a custom input that is not mentioned in the suggestion list.
        // In that case we store the _value (string) in profession_name/industry_name, while as ID we manually set the ID for key OTHER as given from server
        // ----------------------------------------------------------------
        const userInputString = _value?.toString();
        if (config.relatedFieldName && userInputString) {
          userObjectRef.current = {
            ...userObjectRef.current,
            user_info: {
              ...userObjectRef.current.user_info,
              [_inputName]: config.defaultValue,
              [config.relatedFieldName]: userInputString,
            },
          };
        } else if (config.useUserInputAsIs) {
          userObjectRef.current = {
            ...userObjectRef.current,
            user_info: {
              ...userObjectRef.current.user_info,
              [_inputName]: _value,
            },
          };
        }
      } else if (config.parseNumericValue) {
        // ----------------------------------------------------------------
        // If _value is a number (id), it means that the user has selected option from the suggestion list.
        // In that case we store the _value (id) in profession_id/industry_id, while as profession_name/industry_name we manually set NULL values.
        // ----------------------------------------------------------------
        const parsedValue = parseInt(_value);
        userObjectRef.current = {
          ...userObjectRef.current,
          user_info: {
            ...userObjectRef.current.user_info,
            [_inputName]: parsedValue,
            [config.relatedFieldName]: null,
          },
        };

        // ----------------------------------------------------------------
        /* If user selects UNEMPLOYED in employment status, 
       hide the questions for Job title and Industry */

        if (_inputName === "employment_status_id") {
          setEmploymentStatus(parsedValue);
        }
      }
    }
    setSaveBtnDisabled(false);
    // ----------------------------------------------------------------
    // Show error messages if required field is empty

    if (_inputName === "employment_status_id" && _value === null) {
      setErrors({
        ...errors,
        [_inputName]: errorMessages[_inputName],
      });
    }

    if (_inputName === "employment_status_id" && _value !== null) {
      const resetError = { ...errors, [_inputName]: "" };
      setErrors(resetError);
    }

  };

  // ----------------------------------------------------------------
  // FORM SUBMIT

  const handleFormSubmit = async (e) => {
    e?.preventDefault();

    let formData;

    // Saving value
    const employmentStatusID = parseInt(
      userObjectRef?.current?.user_info?.employment_status_id
    );

    // Compare ID value with ID value for UNEMPLOYED status
    const userHasSelectedUnemployed = employmentStatusID === unemployedStatusID;

    /* Depending on the value of the employment status, different fields are required 
    and therefore different values (formData) will be sent to server. */

    if (userHasSelectedUnemployed) {
      formData = {
        onboarding_step: step,
        user_info: {
          employment_status_id:
            +userObjectRef?.current?.user_info?.employment_status_id,
          profession_id: null,
          profession_name: null,
          industry_id: null,
          industry_name: null,
        },
      };
    } else {
      formData = {
        onboarding_step: step,
        user_info: {
          employment_status_id:
            +userObjectRef?.current?.user_info?.employment_status_id,
          profession_id:
            +userObjectRef?.current?.user_info?.profession_id || null,
          profession_name:
            userObjectRef?.current?.user_info?.profession_name || null,
          industry_id: +userObjectRef?.current?.user_info?.industry_id || null,
          industry_name:
            userObjectRef?.current?.user_info?.industry_name || null,
        },
      };
    }

    // ----------------------------------------------------------------
    // Validate empty fields

    if (!userObjectRef?.current?.user_info?.employment_status_id) {
      // Update errors state with error messages for empty input fields
      const errorFields = Object.keys(errorMessages).reduce(
        (acc, fieldName) => {
          if (!userObjectRef?.current?.userInfo?.[fieldName]) {
            acc[fieldName] = [errorMessages[fieldName]];
          }

          return acc;
        },
        {}
      );

      setErrors(errorFields);

      return;
    }
    // submit form
    try {
      await updateUser({ variables: { inputData: formData } });
    } catch (err) {
      console.log(err);
    }
  };

  // -------------------------------------------------------------------------------
  // LOADING ERRORS
  if (queryError || mutationError)
    return (
      <ErrorMessageComponent
        firstText="Oops!"
        secondText="Something went wrong while fetching your data."
        thirdText="Please try again later."
      />
    );

  // -------------------------------------------------------------------------------
  // MAIN COMPONENT
  return (
    <div>
      {!onboarding ? (
        <MobileHeader
          disabled={saveBtnDisabled || mutationLoading}
          hasSubmitted={hasCompletedMutation}
          onClick={handleFormSubmit}
          isLoading={mutationLoading}
        />
      ) : (
        ""
      )}

      <StyledContainer>
        <StyledFormHeader>
          <Text fontSize="14px">Employment history</Text>
        </StyledFormHeader>

        <StyledInnerWrap onboarding={onboarding} ref={submitFunctionRef}>
          {queryLoading ? (
            <StyledLoaderBox>
              <LoaderDots
                className="loader"
                dotsBgColor={theme.darkBlue}
                dotHeight="8px"
                dotWidth="8px"
                dotMargin="8px"
                isLoading={queryLoading}
              />
            </StyledLoaderBox>
          ) : (
            <StyledForm className="basic-info">
              <StyledInfoText>* Indicates required</StyledInfoText>
              <FormSelectGroup
                fieldLabel="Current Employment Status *"
                options={queryData?.employment_statuses}
                errors={errors.employment_status_id}
                isRequired
                selectLabel={"Please choose your current employment status"}
                defaultValue={
                  (user && +user?.user_info?.employment_status_id) || "-1"
                }
                getInputValue={(newValue) => {
                  onInputChange("employment_status_id", parseInt(newValue));
                }}
              />

              {employmentStatus !== unemployedStatusID ? (
                <>
                  <Autocomplete
                    data={queryData?.professions}
                    label="Job title (Optional)"
                    defaultValue={
                      (user &&
                        +user?.user_info?.profession_id === professionOtherID &&
                        user?.user_info?.profession_name) ||
                      (user &&
                        user?.user_info?.profession_id !== professionOtherID &&
                        +user?.user_info?.profession_id) ||
                      (user &&
                        +user?.user_info?.profession_id === professionOtherID &&
                        !user?.user_info?.profession_name &&
                        "")
                    }
                    placeholder="Ex: Teacher; Developer"
                    getInputValue={(newValue) => {
                      onInputChange("profession_id", newValue);
                    }}
                  />

                  <Autocomplete
                    data={queryData?.industries}
                    label="Industry (Optional)"
                    defaultValue={
                      (user &&
                        +user?.user_info?.industry_id === industryOtherID &&
                        user?.user_info?.industry_name) ||
                      (user &&
                        user?.user_info?.industry_id !== industryOtherID &&
                        +user?.user_info?.industry_id) ||
                      (user &&
                        +user?.user_info?.industry_id === industryOtherID &&
                        !user?.user_info?.industry_name &&
                        "")
                    }
                    placeholder="Ex: Education; Law"
                    getInputValue={(newValue) => {
                      onInputChange("industry_id", newValue);
                    }}
                  />
                </>
              ) : (
                ""
              )}
              {!onboarding && isDesktop ? (
                <SubmitButton
                  variant="lightBlueBtn"
                  type="submit"
                  disabled={saveBtnDisabled || mutationLoading}
                  hasSubmitted={hasCompletedMutation}
                  onClick={handleFormSubmit}
                  isLoading={mutationLoading}
                />
              ) : (
                ""
              )}
            </StyledForm>
          )}
        </StyledInnerWrap>
      </StyledContainer>
    </div>
  );
};

export default EmploymentHistory;
