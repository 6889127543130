import React from "react";
import styled from "styled-components";

// styled-components
const StyledLoaderContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 30px;
  background-color: rgba(229, 229, 229, 0.9);
  backdrop-filter: blur(4px);
  z-index: 11111;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  display: ${(props) => (props.isLoading ? "flex" : "none")};

  p {
    margin-bottom: 10px;
    font-weight: 400;
    font-size: 1.1rem;
  }
`;
const StyledLoader = styled.div`
  width: 15px;
  height: 15px;
  border: 8px solid #c5c5c5;
  border-top: 8px solid #005eff;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  border: 8px solid #c5c5c5;
  border-top: 8px solid #005eff;
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
  margin-top: 20px;

  @keyframes spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const Loader = ({ isLoading, loadingMessageOne, loadingMessageTwo }) => {
 
  return (
    <StyledLoaderContainer isLoading={isLoading}>
      {loadingMessageOne !== "" ? <p>{loadingMessageOne}</p> : ""}
      {loadingMessageTwo !== "" ? <p>{loadingMessageTwo}</p> : ""}
      <StyledLoader></StyledLoader>
    </StyledLoaderContainer>
  );
};
export default Loader;
