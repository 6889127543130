import React from "react";
import styled from "styled-components";

// styled-components
const StyledFieldSet = styled.fieldset`
  input[type="checkbox"] {
    position: absolute;
    opacity: 0;
  }
`;
const StyledCheckboxLabel = styled.label`
  display: inline-flex;
  position: relative;
  background-color: ${(props) =>
    props.isSelected ? "#E1E2E7" : props.theme.white};
  color: ${(props) => (props.isSelected ? "#4D4D4D" : props.theme.textGrey)};
  border: 1.5px solid #e6e7eb;
  border-radius: 8px;
  white-space: nowrap;
  margin: 3px 0px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  padding: 12px 16px;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  transition: all 0.2s ease-in-out;

  span.option-text {
    white-space: break-spaces;
    align-self: center;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: ${(props) =>
      props.disabled ? props.theme.lightLightGray : props.theme.black};
  }
  span.close-icon {
    align-self: center;
    svg {
      margin-bottom: -3px;
    }
  }

  &:hover span.option-text {
    color: ${(props) => (props.disabled ? "" : props.theme.white)};
  }
  :hover {
    background-color: ${(props) =>
      props.disabled ? "" : props.theme.darkBlue2};
  }
  :active {
    background-color: ${(props) =>
      props.disabled ? "" : props.theme.darkBlue2};
  }
`;

const CustomCheckboxItem = ({
  id,
  value,
  onChange,
  label,
  isSelected,
  disabled,
}) => {

  return (
    <StyledFieldSet>
      <StyledCheckboxLabel
        htmlFor={id}
        isSelected={isSelected}
        disabled={disabled}
      >
        <span className="option-text">{label}</span>
      </StyledCheckboxLabel>
      <input
        type="checkbox"
        id={id}
        value={value}
        onChange={onChange}
        name={id}
      />
    </StyledFieldSet>
  );
};
export default CustomCheckboxItem;
