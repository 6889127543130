import React from "react";
import styled from "styled-components";
import { useParams } from "react-router-dom";

import Navbar from "../Components/Navbar/Navbar";
import Footer from "../Components/Footer/Footer";
import CategoryPageContainer from "../Components/MaterialsPageComponents/CategoryPageContainer";
import EventsNotification from "../Components/Events/EventsNotification";

const StyledPageWrapper = styled.div`
  position: relative;
  background: #f9f9f9;
  min-width: 100%;
  max-width: 100%;
  min-height: 100vh;

  @media only screen and (min-width: ${(props) => props.theme.laptop}) {
    .d-flex {
      display: flex;
    }
  }
`;

const Categories = () => {
  let params = useParams();

  return (
    <StyledPageWrapper>
      <Navbar />
      <EventsNotification />
      <CategoryPageContainer slug={params?.category} />
      <Footer />
    </StyledPageWrapper>
  );
};

export default Categories;
