import React, { useContext, useState } from "react";

import { GlobalContext } from "../../Context/GlobalProvider";
import { axios, makeApiRoute } from "../../Router";
import Button from "../Button/Button";
import LoaderDots from "../Loader/LoaderDots";
import Notification from "../Notification/Notification";
import CertificateForm from "./CertificateForm";
import ShareAchievement from "./ShareAchievement";

const CertificateNotification = ({
  isGeneratingCertificate,
  setIsGeneratingCertificate,
  setShowConfetti,
}) => {
  const { user, setUser, popupConfig, setPopupConfig, setPopupDefaultValues } =
    useContext(GlobalContext);
  const [isLoading, setIsLoading] = useState(false);

  // conditions needed to display different notification
  const userHasNotApprovedData =
    user &&
    user?.certificate !== null &&
    user?.certificate?.is_confirmed === null;
  const userHasApprovedData =
    user &&
    user?.certificate?.is_confirmed === 1 &&
    user?.certificate?.is_downloaded === 0;
  const thirdPartyHasToApproveData =
    user &&
    user?.certificate?.is_confirmed === 0 &&
    user?.certificate?.is_downloaded === 0;

  // on click on Approve Certificate button
  const onApproveCertificate = () => {
    setIsLoading(true);
    return axios
      .get(makeApiRoute("certificate"))
      .then((response) => {
        setIsLoading(false);

        // show pop up
        !isLoading &&
          setPopupConfig({
            ...popupConfig,
            show: true,
            maxWidth: "500px",
            body: (
              <CertificateForm
                certificateData={response?.data?.certificate}
                setIsGeneratingCertificate={setIsGeneratingCertificate}
                isGeneratingCertificate={isGeneratingCertificate}
              />
            ),
            closeButton: {
              show: true,
              action: () => {
                setPopupDefaultValues();
              },
            },
          });
      })
      .catch(function (error) {
        setIsLoading(false);

        !isLoading &&
          setPopupConfig({
            ...popupConfig,
            show: true,
            subTitle: "",
            confirmation: false,
            maxWidth: "500px",
            title: "Something Unexpected Happened. Please try again later!",
            bodyClassname: "action-buttons",
            body: (
              <Button
                text="Close"
                variant="blueBtn"
                onClick={() => setPopupDefaultValues()}
              />
            ),
            closeButton: {
              show: true,
              action: () => {
                setPopupDefaultValues();
              },
            },
          });

        throw error.response;
      });
  };

  // on click on Download Certificate button
  const onDownloadCertificate = () => {
    setIsLoading(true);

    return axios
      .get(
        makeApiRoute("certificateDownload", {
          CERTIFICATE_ID: user?.certificate?.id,
        }),
        { responseType: "blob" }
      )
      .then(function (response) {
        if (response.status === 200) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "certificate.pdf");
          document.body.appendChild(link);
          link.click();
          link.remove();

          setIsLoading(false);
          setShowConfetti(true);

          // update user object to hide the message on the screen
          for (const property in user) {
            let updatedUserData;
            if (property === "certificate") {
              updatedUserData = {
                ...user,
                certificate: { ...user[property], is_downloaded: 1 },
              };
              setUser(updatedUserData);
            }
          }

          !isLoading &&
            setPopupConfig({
              ...popupConfig,
              show: true,
              subTitle: "",
              confirmation: false,
              maxWidth: "800px",
              title: "",
              body: (
                <ShareAchievement
                  show={true}
                  imgUrl={user && user?.certificate?.image_url}
                  className="bg-gray"
                />
              ),
              closeButton: {
                show: true,
                action: () => {
                  setPopupDefaultValues();
                },
              },
            });
        } else {
          setIsLoading(false);
          !isLoading &&
            setPopupConfig({
              ...popupConfig,
              show: true,
              subTitle: "",
              confirmation: false,
              maxWidth: "500px",
              title: "Something Unexpected Happened. Please try again later!",
              bodyClassname: "action-buttons",
              body: (
                <Button
                  text="Close"
                  variant="blueBtn"
                  onClick={() => setPopupDefaultValues()}
                />
              ),
              closeButton: {
                show: true,
                action: () => {
                  setPopupDefaultValues();
                },
              },
            });

          throw new Error("Bad Request");
        }
      })
      .catch(function (error) {
        setIsLoading(false);

        !isLoading &&
          setPopupConfig({
            ...popupConfig,
            show: true,
            subTitle: "",
            confirmation: false,
            maxWidth: "500px",
            title: "Something Unexpected Happened. Please try again later!",
            bodyClassname: "action-buttons",
            body: (
              <Button
                text="Close"
                variant="blueBtn"
                onClick={() => setPopupDefaultValues()}
              />
            ),
            closeButton: {
              show: true,
              action: () => {
                setPopupDefaultValues();
              },
            },
          });
        throw error.response;
      });
  };

  if (userHasNotApprovedData) {
    return (
      <Notification
        bgColor="radial-gradient(131.25% 39562.5% at 1.09% 0%,#00d762 15.51%,#005eff 100%)"
        content={
          <>
            {isLoading ? (
              <LoaderDots
                isLoading={isLoading}
                dotsBgColor="white"
                dotHeight="5px"
                dotWidth="5px"
                buttonSubmit
                dotMargin="5px"
              />
            ) : (
              <p>
                You are one step away from your certificate. Please{" "}
                <span className="underline">click this box</span> to check and
                approve all your info and academy data.
              </p>
            )}
          </>
        }
        onClick={() => {
          onApproveCertificate();
        }}
      />
    );
  }
  if (userHasApprovedData) {
    return (
      <Notification
        bgColor="radial-gradient(131.25% 39562.5% at 1.09% 0%,#00d762 15.51%,#005eff 100%)"
        content={
          <>
            {isLoading ? (
              <LoaderDots
                isLoading={isLoading}
                dotsBgColor="white"
                dotHeight="5px"
                dotWidth="5px"
                buttonSubmit
                dotMargin="5px"
              />
            ) : (
              <p>
                Congratulations! Now you can{" "}
                <span className="underline">download</span> your certificate.
              </p>
            )}
          </>
        }
        onClick={() => {
          onDownloadCertificate();
        }}
      />
    );
  }
  if (thirdPartyHasToApproveData) {
    return (
      <Notification
        bgColor="radial-gradient(131.25% 39562.5% at 1.09% 0%,#00d762 15.51%,#005eff 100%)"
        disabled={thirdPartyHasToApproveData}
        content={
          <>
            <p>
              Our team is checking your data. You will be notified once the
              certificate is ready for download
            </p>
          </>
        }
      />
    );
  }
};
export default CertificateNotification;
