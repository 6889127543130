import React, { useEffect, useState } from "react";
import styled from "styled-components";

import useMediaCustom from "../../Hooks/useMediaCustom";
import BasicInfo from "../ProfilePage/BasicInfo";
import AboutProgram from "../ProfilePage/AboutProgram";
import HiringPreferences from "../ProfilePage/HiringPreferences";
import ProgressStep from "../ProgressTracker/ProgressStep";
import Button from "../Button/Button";
import OnboardingReview from "./OnboardingReview";
import OnboardingCompleted from "./OnboardingCompleted";
import HighSchoolOnboarding from "../ProfilePage/EducationAndEmploymentOnboarding/HighSchoolOnboarding/HighSchoolOnboarding";
import Skills from "../ProfilePage/Skills";
import HigherEducationOnboarding from "../ProfilePage/EducationAndEmploymentOnboarding/HigherEducationOnboarding/HigherEducationOnboarding";
import EmploymentHistory from "../ProfilePage/EmploymentHistory";

// styled-components
const StyledInnerContainer = styled.div`
  width: 100%;

  @media only screen and (min-width: ${(props) => props.theme.desktop}) {
    width: 60%;
    margin: 0 auto;
    padding: 50px 0 30px;
    max-height: auto;
    @media only screen and (min-width: ${(props) => props.theme.large_desktop}) {
    width: ${(props) => (props.step === "finish" ? "60%" : "50%")};

  }
  }
`;
const StyledTitle = styled.h1`
  text-align: center;
  margin-top: 60px;
  font-weight: bold;
  font-size: 24px;
  @media only screen and (min-width: ${(props) => props.theme.laptop}) {
    margin-top: 80px;
    margin-bottom: 24px;
  }
`;
const StyledSkipButton = styled.button`
  border: 0;
  outline: 0;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  text-transform: uppercase;
  color: ${(props) => props.theme.textGrey};
  background: #f9f9f9;
  cursor: pointer;
  margin-top: 20px;

  :hover {
    color: ${(props) => props.theme.darkLightGray};
  }
`;
const StyledDescription = styled.p`
  width: 100%;
  text-align: center;
  margin-top: 16px;
  margin-bottom: 30px;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.5;
  color: ${(props) => props.theme.lightGrayText};

  @media only screen and (min-width: ${(props) => props.theme.laptop}) {
    width: 100%;
    margin: 0 auto 30px;
  }
  @media only screen and (min-width: ${(props) => props.theme.large_desktop}) {
    width: 80%;
  }
`;
const StyledFooter = styled.div`
  margin-top: 40px;

  button {
    width: 100%;
  }
`;
const StyledComponent = styled.div`
  @media only screen and (min-width: ${(props) => props.theme.desktop}) {
    margin: 0 auto;
    margin: 40px auto 24px;
  }
`;

const OnboardingStep = ({
  step,
  submitFunctionRef,
  allSteps,
  progressWidth,
  onSkipStep,
  onboardingRoutes,
  educationSteps,
}) => {
  const isDesktop = useMediaCustom("(min-width: 992px)");
  const [activeStep, setActiveStep] = useState();

  const stepsConfig = [
    {
      stepName: "general-info",
      title: "General Info",
      copyText: "Start by telling us a little bit about yourself.",
      component: (
        <BasicInfo
          onboarding
          submitFunctionRef={submitFunctionRef}
          step={step}
          onboardingRoutes={onboardingRoutes}
        />
      ),
    },

    {
      stepName: "high-school",
      title: "Education and Employment History",
      copyText:
        "We would like to know your background to better assist you in the process later. Think of this as your career path - where you are now and where you’d be after graduating.",
      component: (
        <HighSchoolOnboarding
          onboardingRoutes={onboardingRoutes}
          submitFunctionRef={submitFunctionRef}
          educationSteps={educationSteps}
        />
      ),
    },
    {
      stepName: "higher-education",
      title: "Education and Employment History",
      copyText:
        "We would like to know your background to better assist you in the process later. Think of this as your career path - where you are now and where you’d be after graduating.",
      component: (
        <HigherEducationOnboarding
          submitFunctionRef={submitFunctionRef}
          educationSteps={educationSteps}
        />
      ),
    },
    {
      stepName: "employment-history",
      title: "Education and Employment History",
      copyText:
        "We would like to know your background to better assist you in the process later. Think of this as your career path - where you are now and where you’d be after graduating.",
      component: (
        <EmploymentHistory
          onboarding
          onboardingRoutes={onboardingRoutes}
          submitFunctionRef={submitFunctionRef}
          educationSteps={educationSteps}
        />
      ),
    },
    {
      stepName: "program-goals-expectations",
      title: "Program goals & expectations",
      copyText:
        "It’s about your goals and expectations prior to starting the academy - what would you like to achieve and what are expectations that you have for the whole experience.  ",
      component: (
        <AboutProgram
          onboarding
          submitFunctionRef={submitFunctionRef}
          step={step}
          onboardingRoutes={onboardingRoutes}
          educationSteps={educationSteps}
        />
      ),
    },
    {
      stepName: "skills",
      title: "Skills",
      copyText:
        "Let's get to know you better! In this section, share your unique skills and strengths. Whether it's a skill for problem-solving, creativity, or strong technical skills, each of them adds value.",
      component: (
        <Skills
          onboarding
          submitFunctionRef={submitFunctionRef}
          onboardingRoutes={onboardingRoutes}
        />
      ),
    },
    {
      stepName: "hiring-preferences",
      title: "Hiring Preferences",
      copyText:
        "We all come from different backgrounds and places, but worry not. This section will help us devise recommendations as part of our hiring program, personalized to you. ",
      component: (
        <HiringPreferences
          onboarding
          submitFunctionRef={submitFunctionRef}
          onboardingRoutes={onboardingRoutes}
        />
      ),
    },
    {
      stepName: "review",
      title: "We are at the very end",
      copyText: "Lastly, rate your experience so far. ",
      component: (
        <OnboardingReview
          onboarding
          submitFunctionRef={submitFunctionRef}
          onboardingRoutes={onboardingRoutes}
        />
      ),
    },
    {
      stepName: "finish",
      component: (
        <OnboardingCompleted
          titleOne="Congrats!"
          titleTwo="Onboarding successfully done!"
          text="Thank you for taking your time to build your profile! We hope you’ll have a pleasant experience with the preparational study and your further advancement throughout the program. You can now start exploring the platform."
          step={step}
          allSteps={allSteps}
          onboardingRoutes={onboardingRoutes}
        />
      ),
    },
  ];
  useEffect(() => {
    const currentStep = stepsConfig.find(
      (eachStep) => eachStep.stepName === step
    );
    setActiveStep(currentStep);
  }, [step]);

  return (
    <>
      {activeStep ? (
        <StyledInnerContainer step={step}>
          {!isDesktop && step !== "finish" && step !== "review" ? (
            <ProgressStep
              width="100%"
              step={step}
              progressWidth={`${progressWidth}%`}
            />
          ) : (
            ""
          )}
          <StyledTitle>{activeStep.title}</StyledTitle>
          <StyledDescription>{activeStep.copyText}</StyledDescription>
          <StyledComponent>{activeStep.component}</StyledComponent>
          {!isDesktop && step !== "finish" ? (
            <StyledFooter>
              <Button
                variant="blueBtn"
                text={step === "review" ? "Finish" : "Next"}
                onClick={() => submitFunctionRef.current()}
              />
              {step === "on-the-web" && (
                <StyledSkipButton onClick={onSkipStep}>
                  Skip this Section
                </StyledSkipButton>
              )}
            </StyledFooter>
          ) : (
            ""
          )}
        </StyledInnerContainer>
      ) : (
        ""
      )}
    </>
  );
};

export default OnboardingStep;
